import React, { useState } from "react";
import NumberFormat from 'react-number-format';
import { Link } from "react-router-dom";
import UpgrdeRequestModal from './UpgrdeRequestModal';
// Power max subscription card component
function PowerMax(props) {
    const plan = props.data;
    const currentPlan = props.currentPlan;
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    // card action button functionality to perform action subscription either sign up / upgrade
    const CardButton = () => {
        const Sub_Level = plan.Sub_Level;
        const canUpgrade = currentPlan === "12" || currentPlan === "13" ? true : false;
        const canSignUp = currentPlan === "8" || currentPlan === "9" || currentPlan === "11" ? true : false;
        const isUnAvailable = Sub_Level !== currentPlan && currentPlan === "10" ? true : false;
        if (isUnAvailable) {
            return <span className="btn btn-pa btn-danger w-100 rounded  text-truncate unavailable" >Unavailable</span>
        }
        else if (canSignUp) {
            return <Link to={"/checkout/subscription/" + plan.Subscriptions_ID} className="btn w-100  rounded get-started text-white"
            > Sign Up </Link>
        }
        else if (canUpgrade) {
            return <Link onClick={toggle} className="btn w-100 get-started rounded text-white upgrade-plan-button"
            > Upgrade </Link>
        }
        else if (currentPlan === Sub_Level) {
            return <span className="btn currentPlan w-100 text-truncate rounded current-plan" style={{ backgroundColor: "#50c5a9 !important" }}> Current Plan </span>
        }
    }
    return (
        <>
            <div className="col-sm-6 col-lg-3 mb-4 p-0">
                <section className="card custom-card border-top-0 border-left-0">
                   
                    <div className="card-header custom-card-header record-plan">
                        <h3
                            className="m-0 text-center record-title"
                            style={{ textTransform: "uppercase" }}
                        >
                            Power Max{" "}
                        </h3>
                        <p className="m-0 text-center"><i>All 320,000+ Records</i></p>
                    </div>
                    <div className="card-header pricing">
                        <p className="m-0 text-center  ">
                          
                        <span className="title"> 6&cent; </span> <br/>
                        <span className="title"> / Record </span>{" "}
                        </p>
                        <p className="m-0 text-center  ">
                        <span className="title-sub">
                             <NumberFormat value={plan.Sub_AnnualFees} displayType={'text'} thousandSeparator={true} prefix="$" /> / Year
                        </span>
                        </p>
                    </div>
                    <div className="card-body p-0">
                        <ul className="list-group list-group-flush et_pb_pricing ">
                            <li className="m-0">
                                <span>Unlimited Users</span>
                            </li>
                            <li className="m-0">
                                <span>
                            Free Updates for Downloaded Records</span>
                            </li>
                           
                            <li className="m-0">
                                <span>
                                <strong><i>Target New Officials</i></strong></span>
                            </li>
                            <li className="m-0">
                                <span>
                                <strong><i>Access New Roles Early</i></strong></span>
                            </li>
                        </ul>
                    </div>
                    <div className="card-footer border-top-0">
                        {CardButton()}
                    </div>
                </section>
            </div >
            <UpgrdeRequestModal
                modal={modal}
                toggle={toggle}
            />
        </>
    );
}

export default PowerMax;