import React from "react";
import { Field, reduxForm } from "redux-form";
import validate from "./validate";
import Button from '@material-ui/core/Button';
const renderInputField = ({
  input,
  label,
  type,
  meta: { touched, error, warning }
}) => (
  <div>
    <input
      {...input}
      placeholder={label}
      type={type}
      className="form-control"
    />
    {touched &&
      ((error && <span className="text-danger">{error}</span>) ||
        (warning && <span className="text-danger">{warning}</span>))}
  </div>
);
const renderTextAreaField = ({
  input,
  label,
  type,
  meta: { touched, error, warning }
}) => (
  <div>
    <textarea
      {...input}
      placeholder={label}
      type={type}
      className="custom-textarea"
    />
    {touched &&
      ((error && <span className="text-danger">{error}</span>) ||
        (warning && <span className="text-danger">{warning}</span>))}
  </div>
);

// Login Form
const ContactUsContent = props => {
  const { handleSubmit } = props;
  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group loginInput">
        <Field
          name="name"
          type="text"
          required='required'
          label="Name"
          component={renderInputField}
        />
      </div>
      <div className="form-group loginInput">
        <Field
          name="email"
          type="email"
          required='required'
          label="Email Address"
          component={renderInputField}
        />
      </div>
      <div className="form-group loginInput">
        <Field
          name="phone"
          type="text"
          required='required'
          label="Phone"
          component={renderInputField}
        />
      </div>
      <div className="form-group loginInput">
        <Field
          name="comment"
          type="textarea"
          label="Comment"
          placeholder="Comment"
          component={renderTextAreaField}

        />
      </div>

      <div className="row mb-3 login-link ">
        <div className="col-sm-4 login-btn float-right">
          <Button type="submit" className={"btn btn-pa text-uppercase " + props.loading}>
            Submit
          </Button>
        </div>

      </div>
    </form>
  );
};

const reduxContactForm = reduxForm({
  form: "contactus",
  validate
})(ContactUsContent);

export default reduxContactForm;
