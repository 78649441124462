// Vendor Imports
import React from "react";
import { Helmet } from 'react-helmet';

// Local Imports
import Table from './Table';

// Overall Stats Page
class OverallStatsComponent extends React.Component {

    render() {
        return (
            <>
                <Helmet>
                    <title> PA Admin - Overall Stats </title>
                </Helmet>
                <section>
                    <div className="container admin-container">
                        <div className="row">
                            <section className="admin-title">
                                <h1>Overall Stats</h1>
                            </section>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <Table />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );

    }
}

// Export
export default OverallStatsComponent;
