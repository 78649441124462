import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Button from '@material-ui/core/Button';
import { setTamMode, SignOff, setSearchResult, setMySavedSearches, setCurrentSearch } from '../../../reducers/client/actions';
import { doLogOff, saveNewSearch } from '../../../service/client';
import { withRouter } from "react-router-dom";

import UpgrdeRequestModal from '../../client/UpgradeSubscription/UpgrdeRequestModal'
import CreditRequestModal from './CreditRequest'
// Save new search filter with named
function SaveNewSearchModal(props) {
    const [upgradeModal, setUpgradeModal] = useState(false);
    const toggleUpgrade = () => {
        setUpgradeModal(!upgradeModal)
    };

    const [creditModal, setCreditModal] = useState(false);
    // function to show credit request modal to the subusers
    const toggleCreditRequest = () => {
        setCreditModal(!creditModal)
    };

    const [searchName, setSearchName] = useState('');
    const dispatch = useDispatch();
    const [errorMessage, setErrorMessage] = useState('');
    const [SuccessMessage, setSuccessMessage] = useState('');
    const [loading, setLoading] = useState('');
    // function to remove special characters from saved search name
    const handleChange = (e) => {
        let name = e.target.value;
        let newname = name.replace(/[^a-zA-Z 0-9.]+/g, '');
        setSearchName(newname);
    }
    // function to call save new search data into savedsearches
    const handleSaveNewSearch = () => {
        let SearchResult = props.SearchResult;
        if (searchName.trim() === '') {
            setErrorMessage("Please name the search, then click SAVE");
            return false;
        }
        let params = {
            savesearch_label: searchName.trim(),
            table_id: SearchResult.table,
            lastSearchParams: props.currentSearch.search_params,
            lastSearchNumMatched: SearchResult.lastSearchNumMatched
        }
        setLoading('loading');
        setErrorMessage("");
        saveNewSearch(params).then(res =>
            handleResponse(res),
        );
    }
    // handle saved search response and proceed to download or checkout

    const handleResponse = (response) => {
//console.log("responseresponse, ",response)
        setLoading('');
        if (response.data.code === 200) {
            let searchdata = response.data.data;
            let mySavedSearches = props.mySavedSearches;

            mySavedSearches.push(searchdata);
            dispatch(setMySavedSearches(mySavedSearches));
            setSuccessMessage("Search Saved");
            dispatch(setCurrentSearch(searchdata));
            if (props.action === "save-continue") {
                handleActionAfterSaving();
            } else if (props.action === "save-redirect") {
                if (props.redirect === 'signoff') {
                    doLogOff().then(res =>
                        dispatch(SignOff({}))
                    )
                    return false;
                } else if (props.redirect === '/search') {
                    //dispatch(setTamMode(false));
                    dispatch(setCurrentSearch([]));
                    dispatch(setSearchResult([]));
                }
                props.history.push(props.redirect);
            }
            props.toggle();
        } else {
            setErrorMessage(response.data.message)
        }
    }
    // function to handle user to an action either download search result or upgrate or further action
    const handleActionAfterSaving = () => {
        if (props.info.isParent === true) {
            if (props.bestPricing.option === "Download") {
                props.history.push("/search/download");
            } else if (props.bestPricing.option === "Upgrade" && (props.plan.Sub_Level === "11" ||  props.plan.Sub_Level === "9")) {
                props.history.push("/search/download/upgrade-plan");
            }
            else if (props.bestPricing.option === "Upgrade" && (props.plan.Sub_Level !== "10" && props.plan.Sub_Level !== "11" && props.plan.Sub_Level !== "9")) {
                toggleUpgrade();
            } else if (props.bestPricing.option === "Purchase" && (props.plan.Sub_Level !== "10")) {
                props.history.push("/search/download/purchase-credits");
            }
        } else if (props.info.isParent === false) {
            if (props.bestPricing.option === "Download") {
                props.history.push("/search/download");
            } else if (props.bestPricing.option !== "Download") {
                toggleCreditRequest();
            }
        }
    }
    // save new search modal close
    const OnClose = () => {
        setSearchName('');
        setErrorMessage('');
        setSuccessMessage('');
        setLoading('');
        props.toggle();
    }

    const {
        toggle,
        modal,
        title,
    } = props;

    return (
        <div>

            <Modal size="md" isOpen={modal} toggle={OnClose} backdrop={loading !== "" ? false : true} className="modal-dialog">
                <ModalHeader toggle={OnClose}><h5>{title}</h5></ModalHeader>
                <ModalBody>
                    {
                        errorMessage !== '' ?
                            <div className="alert alert-danger" role="alert">
                                {errorMessage}
                            </div>
                            :
                            ""
                    }
                    {
                        SuccessMessage === "" ?
                            <>
                                <div className="row">
                                    <div className="col">
                                        <p className="mb-3">Search Name ( {searchName.length} / 40 )</p>
                                    </div>
                                </div>

                                <div className="row hide-on-success">
                                    <div className="col">
                                        <div className="form-group">
                                            <input value={searchName} onChange={(e) => handleChange(e)} data-inputmask-regex="[a-za-zA-Z0-9 ]*" type="text" id="searchName" className="form-control" name="savesearch_label" maxLength="40" />
                                        </div>
                                    </div>
                                </div>
                            </>
                            :
                            <div className="alert alert-success" role="alert">
                                {SuccessMessage}
                            </div>
                    }


                </ModalBody>
                <ModalFooter className="m-0">
                    <div className=" hide-on-success">
                        <div className="">
                            <div className="manage-sub-btn">
                                <Button variant="container" onClick={OnClose} className="btn btn-secondary close-modal">Close</Button>
                                {SuccessMessage === "" ? <Button onClick={handleSaveNewSearch} type="button" className={"btn btn-pa " + loading}>Save</Button> : ""}
                            </div>
                        </div>
                    </div>
                </ModalFooter>

            </Modal>
            <UpgrdeRequestModal modal={upgradeModal} toggle={toggleUpgrade} />
            {
                props.info.isParent === false ?
                    <CreditRequestModal modal={creditModal} toggle={toggleCreditRequest} />
                    :
                    ""
            }
        </div>
    );
}

// function to set initial props
function mapStateToProps(store, props) {
    return {
        info: store.client.info ? store.client.info : [],
        plan: store.client.plan ? store.client.plan : [],
        bestPricing: store.client.bestPricing ? store.client.bestPricing : [],
        SearchResult: store.client.searchResult ? store.client.searchResult : [],
        mySavedSearches: store.client.mySavedSearches ? store.client.mySavedSearches : [],
        currentSearch: store.client.currentSearch ? store.client.currentSearch : []
    }
}

export default withRouter(connect(mapStateToProps, {})(SaveNewSearchModal));
