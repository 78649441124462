// Vendor Imports
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Helmet } from 'react-helmet';
import Swal from 'sweetalert2'

// Local Imports
import VoiceAgentTable from "./Table";
import { editGovrment, getVoiceAgentDash } from "../../../../service/dms";
import EditGovForm from "./Form";

// Voice agent dashboard
class VoiceAgentDash extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dmsUser: null,
            govId: '',
            loading: '',
            tableDetails: null,
            isSubmitted: false
        };
    }

    componentDidMount() {
        // Api call to get Voice agent dashboard data
        getVoiceAgentDash().then(res => this.handleDataResponse(res));
    }

    // Response handler
    handleDataResponse = res => {
        if (res.data.code === 200) {
            this.setState({
                tableDetails: res.data.data.voiceAgentDashboard
            });
        }
    };

    static getDerivedStateFromProps(props, state) {
        if (props.dmsUser !== null) {
            return {
                dmsUser: props.dmsUser
            };
        }
        return null;
    }

    // Submit Handler
    handleSubmit = values => {
        if (!this.state.isSubmitted) {
            if (!values.gov_id) {
                Swal.fire({
                    text: 'Please enter Government ID',
                    showCloseButton: true,
                    confirmButtonText: "Ok",
                    icon: 'info',
                    allowOutsideClick: false
                });
                return;
            }
            if (values.gov_id.trim() === '') {
                Swal.fire({
                    text: 'Please enter Government ID',
                    showCloseButton: true,
                    confirmButtonText: "Ok",
                    icon: 'info',
                    allowOutsideClick: false
                });
                return;
            }
            let gov_id = values.gov_id.trim();
            gov_id = gov_id.length === 15 ? gov_id.substr(1) : gov_id;
            let flatValues = this.getGovIds();
            let GovIds = flatValues.map(f => f.Government_ID);
            if (GovIds.includes(gov_id)) {
                this.setState({ isSubmitted: false });
                let gov = flatValues.filter(fl => fl.Government_ID === gov_id);
                this.props.history.push(`/dms/voice-agent/edit/${gov[0].Government_ID}/${gov[0].job_id}/${gov[0].category}/${gov[0].ge_ID}`);
            } else {
                this.setState({ loading: 'loading', isSubmitted: true });
                let params = { government_id: gov_id }
                editGovrment(params).then(res => this.handleResponse(res));
            }
        }
    }

    // Get all gov ids
    getGovIds = () => {
        let GovIds = [];
        this.state.tableDetails.NewTasksRegular.forEach(nt => GovIds.push(nt));
        this.state.tableDetails.NewTasksOutofTurn.forEach(ot => GovIds.push(ot));
        this.state.tableDetails.RejectedByQuality.forEach(q => GovIds.push(q));
        return GovIds;
    }

    // Response Handler
    handleResponse = res => {
        if (res.data.code === 200) {
            this.setState({ loading: '', isSubmitted: false });
            this.props.history.push(`/dms/voice-agent/edit/${res.data.data.gid}/${res.data.data.jid}/${res.data.data.mode}/${res.data.data.ge_ID}`);
        } else {
            this.setState({ loading: '', isSubmitted: false });
            Swal.fire({
                text: res.data.message,
                showCloseButton: true,
                confirmButtonText: "Ok",
                icon: 'error',
                allowOutsideClick: false
            });
            return;
        }
    };

    render() {
        return (
            <div>
                <Helmet>
                    <title>Dashboard - Voice Agent</title>
                </Helmet>
                <section className="titlebar">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h1>Voice Agent Dashboard</h1>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="white-bg">
                                    <section className="dmstable">

                                        <div className="table-responsive">
                                            <table className="table table-bordered ">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Logged In As</th>
                                                        <th scope="col">Access Level</th>
                                                        <th scope="col">Primary Role</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.dmsUser ?
                                                        <tr>
                                                            <td>{this.state.dmsUser.first_name} {this.state.dmsUser.last_name}</td>
                                                            <td>{this.state.dmsUser.access_level}</td>
                                                            <td>{this.state.dmsUser.primary_role}</td>
                                                        </tr>
                                                        : <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>}
                                                </tbody>
                                            </table>
                                        </div>
                                    </section>

                                    <section className="govt-id mt-3">
                                        <h3>Search By Government ID</h3>
                                        <div>
                                            <EditGovForm
                                                onSubmit={this.handleSubmit}
                                                loading={this.state.loading}
                                            ></EditGovForm>
                                        </div>
                                    </section>

                                    <VoiceAgentTable tableDetails={this.state.tableDetails} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

const mapStateToProps = (store, props) => {
    return {
        dmsUser: store.dms.dmsUser
    };
};

// Export
export default withRouter(connect(mapStateToProps)(VoiceAgentDash));
