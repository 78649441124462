import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import { loadSavedSearchName,getAppliedName } from '../../../../utils';
import { setAppliedFilters,setValidateFilters } from '../../../../reducers/client/actions';

// Government type filter component
function Governmentname(props) {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = React.useState('');
  const handleChangeAccordian = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  
  const [name, setName] = React.useState('');


  let rolesFilter = useSelector(store => store.client.validFilters);
 
  let [PreviousParams, setPreviousParams] = React.useState({});

  // set initial checked inputs or loaded saved search parameters
  useEffect(() => {
  if (Object.keys(props.currentSearch).length !== 0 && PreviousParams !== props.currentSearch) {
    let loadParams = "";
    let loadedTitle='';
    if (props.currentSearch.id !== "0" || props.currentSearch.id !== "") {
      loadParams = props.currentSearch.search_params;
    } else {
      loadParams = props.currentSearch.params;
    }
    loadedTitle = getAppliedName(loadParams, ['byname']);
    if(loadedTitle.byname!=''){
      setExpanded('govName');
      setName(loadedTitle.byname);
    } else {
      setExpanded('');
      setName('');
    }
  }
  },[props.currentSearch]);
  //function to capture changed inputs and trigger search api
  const handleChange = (event) => {
    let value = event.target.value;
    setName(value);

    let  allSearchFilterdzf = {
      rolesf: rolesFilter.rolesf,
      statesf: rolesFilter.statesf,
      gtypef: rolesFilter.gtypef,
      gftef: rolesFilter.gftef,
      gspendf: rolesFilter.gspendf,
      fydf: rolesFilter.fydf,
      molf: rolesFilter.molf
    }
    dispatch(setValidateFilters(allSearchFilterdzf));
    props.triggerSearch('', false,allSearchFilterdzf);
    
  };

  const searchClear = ( value ) => {
    setName('');

    if ( value ) {
      let  allSearchFilterdzf = {
        rolesf: rolesFilter.rolesf,
        statesf: rolesFilter.statesf,
        gtypef: rolesFilter.gtypef,
        gftef: rolesFilter.gftef,
        gspendf: rolesFilter.gspendf,
        fydf: rolesFilter.fydf,
        molf: rolesFilter.molf
      }
      dispatch(setValidateFilters(allSearchFilterdzf));
      props.triggerSearch('', false,allSearchFilterdzf);
    }
  }
  
 

  return (

    <div id="governmentName" className="row">
      <div className="col ">
        <Accordion expanded={expanded === 'govName'} onChange={handleChangeAccordian('govName')}>
          <AccordionSummary

            expandIcon={<ExpandMoreIcon />}
            aria-controls="govNamea-content"
            id="govNamea-header"
          >
            <Typography className=""> Search by Agency</Typography>
          </AccordionSummary>
          <AccordionDetails>
          <Typography className="w-100">
            <div className="row" style={{width : '100%'}}>
                <div className="col-12">
                  <input placeholder="Search by Agency"  value={name!=='' ? name : ''} className="form-control " name="byname" id="searchbyname" onChange={handleChange}/>
                  <span className='inputClearBTN' onClick={() => searchClear(name)}>X</span>
                </div>
            </div>
            <p id="zipfocusHelptxt1">This will search the contact's agency name (municipality). Ex: Baldwin County. Use the location filters to search by state or zip code.</p>
          </Typography>
          </AccordionDetails>
        </Accordion>

      </div>
    </div >

  )
}


function mapStateToProps(store, props) {
  return {
    currentSearch: store.client.currentSearch ? store.client.currentSearch : [],
    appliedFilters: store.client.appliedFilters ? store.client.appliedFilters : [],
  }
}

export default connect(mapStateToProps, {})(Governmentname);