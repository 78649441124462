// Validations
const validate = values => {
  const errors = {};
  if (!values.firstName) {
    errors.firstName = "Enter your first name";
  }
  if (!values.lastName) {
    errors.lastName = "Enter your last name";
  }
  if (!values.email) {
    errors.email = "Enter your email address";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "*Invalid email address";
  }
  if (!values.companyName) {
    errors.companyName = "Enter your company";
  }
  if (!values.passWord) {
    errors.passWord = "Create a password";
  } else if (values.passWord.length < 8) {
    errors.passWord = "Your password must have at least 8 characters, including at least 1 uppercase, 1 lowercase, 1 digit, and 1 special character";
  } else if (!/[A-Z]+/g.test(values.passWord)) {
    errors.passWord = "Your password must have at least 8 characters, including at least 1 uppercase, 1 lowercase, 1 digit, and 1 special character";
  } else if (!/[a-z]+/g.test(values.passWord)) {
    errors.passWord = "Your password must have at least 8 characters, including at least 1 uppercase, 1 lowercase, 1 digit, and 1 special character";
  } else if (!/[#?!@$%^&*-]+/.test(values.passWord)) {
    errors.passWord = "Your password must have at least 8 characters, including at least 1 uppercase, 1 lowercase, 1 digit, and 1 special character";
  } else if (!/[0-9]+/g.test(values.passWord)) {
    errors.passWord = "Your password must have at least 8 characters, including at least 1 uppercase, 1 lowercase, 1 digit, and 1 special character";
  }
  if (!values.passWord2) {
    errors.passWord2 = "Confirm a password";
  } else if (values.passWord !== values.passWord2) {
    errors.passWord2 = "Passwords do not match";
  }
  return errors;
};

export default validate;
