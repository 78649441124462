// Vendor Imports
import React from "react";
import { Field, reduxForm } from "redux-form";
import { Link } from "react-router-dom";

// Local Imports
import validate from "./validate";

// Input Field
const renderInputField = ({
    input,
    label,
    type,
    meta: { touched, error, warning }
}) => (
    <div>
        <input
            {...input}
            placeholder={label}
            type={type}
            className="form-control"
        />
        {touched &&
            ((error && <span className="text-danger">{error}</span>) ||
                (warning && <span className="text-danger">{warning}</span>))}
    </div>
);

// Add Voice Agent Form
const AddVoiceAgentForm = props => {
    const { handleSubmit } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group loginInput">
                <Field
                    name="f_name"
                    type="text"
                    label="First Name"
                    component={renderInputField}
                />
            </div>

            <div className="form-group loginInput">
                <Field
                    name="l_name"
                    type="text"
                    label="Last Name"
                    component={renderInputField}
                />
            </div>

            <div className="form-group loginInput">
                <Field
                    name="userid"
                    type="text"
                    label="User ID"
                    component={renderInputField}
                />
            </div>

            <div className="form-group loginInput">
                <Field
                    name="password"
                    type="password"
                    label="Password"
                    component={renderInputField}
                />
            </div>

            <div className="row align-items-center login-link">
                <div className="col-sm forgot-col">
                    <button
                        type="submit"
                        className={"btn btn-pa text-uppercase w-100  " + props.loading}
                    >
                        Create an Account
          </button>
                </div>
            </div>
        </form>
    );
};

// Redux Form
const reduxAddVoiceAgentForm = reduxForm({
    // a unique name for the form
    form: "addVoiceAgent",
    validate
})(AddVoiceAgentForm);

// Export
export default reduxAddVoiceAgentForm;
