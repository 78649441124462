import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FooterModal from './FooterModal';
import { useLocation } from 'react-router-dom'

import $ from 'jquery';
function Footer(props) {
  const location = useLocation();
  //console.log(location.pathname);
  let pathname = location.pathname;
  useEffect(()=>{
    var $header = $('#header');
    var $footer = $('#footer');
    var h = window.innerHeight;
    h -= ($header.outerHeight() + parseInt($header.css('margin-bottom')));
    h -= ($footer.outerHeight() + parseInt($footer.css('margin-top')));
    $('.wrapper').css('min-height', h + 'px');
  },[pathname])
  const [modal, setModal] = useState(false);
  const [modalContent, SetModalContent] = useState('');
  const toggle = (content) => {
    setModal(!modal);
    SetModalContent(content);
  }
  

  // $(window).load('resize', function () {
  //   setTimeout(() => {
  //     var $header = $('#header');
  //     var $footer = $('#footer');
  //     var h = window.innerHeight;
  //     h -= ($header.outerHeight() + parseInt($header.css('margin-bottom')));
  //     h -= ($footer.outerHeight() + parseInt($footer.css('margin-top')));
  //     $('.wrapper').css('min-height', h + 'px');
  //   }, 1000);

  // }).trigger('resize');
  const d = new Date();
  let year = d.getFullYear();

  return (
    <>
      {window.location.pathname === '/dms' ? <span /> : <footer id="footer" className="site-footer bg-white">
        <div className="container">
          <p className="copyright">&copy; {" "} {year} Government Executive Media Group, LLC. All Rights Reserved
            <span className="copyright-links">
              {/*<Link onClick={() => toggle('Privacy Policy')} className="iframePolicy" > {" "}Privacy Policy</Link> | {" "}*/}
              <Link to={{ pathname: "https://www.govexec.com/about/privacy-policy/" }} target="_blank">{" "}Privacy Policy</Link> | {" "}
              <Link onClick={() => toggle('Terms of Service')} >Terms of Service</Link> | {" "}
              <Link onClick={() => toggle('Contact Us')} >Contact Us</Link>
            </span>
          </p>
        </div>
      </footer>}
      {modal ?
        <FooterModal modal={modal} content={modalContent} toggle={toggle} />
        :
        ""
      }

    </>
  );

}
export default Footer;