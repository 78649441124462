// Vendor Imports
import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

// Local Imports
import login from "./guest/login";
import client from "./client/client";
import error from "./error";
import dms from "./dms/dms";
import admin from "./admin/admin";

// Combine Reducers
const rootReducer = combineReducers({
  error,
  login,
  client,
  dms,
  admin,
  form: formReducer
});

export default rootReducer;
