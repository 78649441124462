import {
    SET_DMS_USER,
    REMOVE_DMS_USER
} from "./constants";

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

let userData = JSON.parse(localStorage.getItem("PA-dms"));

const initialState = {
    authToken: userData ? userData.token : null,
    dmsUser: userData ? userData.employee : null,
};

const userReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_DMS_USER:
            return {
                ...state,
                dmsUser: action.payload.employee,
                authToken: action.payload.token
            };
        case REMOVE_DMS_USER:
            return {
                ...state,
                dmsUser: null,
                authToken: null
            };
        default:
            return state;
    }
};
const persistConfig = {
    keyPrefix: "PA-",
    key: "dms",
    storage
};

export default persistReducer(persistConfig, userReducer);
