import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import PrivacyPolicy from './PrivacyPolicy';
import ContactUs from './ContactUs';
import TermOfService from './TermOfService';
import { contactUs } from "../../../service/client";

const FooterModal = (props) => {
  const {
    modal,
    toggle,
    content
  } = props;

  const [loading, setLoading] = useState('');
  const [contactFormMessage, setContactFormMessage] = useState(false);

  const handleSubmit = values => {
    let params = {
      name: values.name,
      email: values.email,
      phone: values.phone,
      comment: values.comment
    };
    setLoading("loading");
    contactUs(params).then(res => setResponse(res))
  };
  const setResponse = (res) => {
    setContactFormMessage(false);
    setLoading("");
    if (res.data.code === 200) {
      setContactFormMessage(true);
    }
  }
  return (
    <div>

      <Modal Modal size="lg"
        isOpen={modal}
        toggle={() => toggle('')}
        centered={true}
        className="modal-dialog">
        <ModalHeader toggle={() => toggle('')}><h3>{content}</h3></ModalHeader>
        <ModalBody>
          {content === "Privacy Policy" ? <PrivacyPolicy /> : ""}
          {content === "Terms of Service" ? <TermOfService /> : ""}
          {content === "Contact Us" && contactFormMessage === false ? <ContactUs loading={loading}
            onSubmit={handleSubmit} /> : ""}
          {
            contactFormMessage === true ?
              <div className="alert alert-success" role="alert">
                Thank you for your inquiry! A member of our sales team will respond to you within 24 hours.
              </div>
              :
              ""
          }
        </ModalBody>

      </Modal>
    </div>
  );
}

export default FooterModal;