// Vendor Imports
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Helmet } from 'react-helmet';
import Swal from 'sweetalert2'

// Local Imports
import { qcWeeklyReport, weeklyEmailQuality } from "../../../../service/dms";
import MyActiveTaskTable from './Table';

// My Active Task
class MyActiveTasks extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dmsUser: null,
            loading: '',
            emailLoading: ''
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.dmsUser !== null) {
            return {
                dmsUser: props.dmsUser
            };
        }
        return null;
    }

    // Redirect to create task page
    createTask = () => {
        this.props.history.push("/dms/supervisor/create-task");
    }

    // Download Qc Report handler
    downloadQcReport = () => {
        this.setState({ loading: 'loading' });
        qcWeeklyReport().then(res => {
            if (res.data.code === 200) {
                this.setState({ loading: '' });
                window.open(res.data.data.path, '_blank');
            } else {
                this.setState({ loading: '' });
                Swal.fire({
                    text: res.data.message,
                    showCloseButton: true,
                    confirmButtonText: "Ok",
                    icon: 'error',
                    allowOutsideClick: false
                });
                return;
            }
        })
    }

    // Download Email Report Handler
    downloadEmailReport = () => {
        this.setState({ emailLoading: 'loading' });
        weeklyEmailQuality().then(res => {
            if (res.data.code === 200) {
                this.setState({ emailLoading: '' });
                window.open(res.data.data.path, '_blank');
            } else {
                this.setState({ emailLoading: '' });
                Swal.fire({
                    text: res.data.message,
                    showCloseButton: true,
                    confirmButtonText: "Ok",
                    icon: 'error',
                    allowOutsideClick: false
                });
                return;
            }
        })
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>Dashboard - Supervisor</title>
                </Helmet>
                <section className="titlebar">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h1>Supervisor Dashboard</h1>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="white-bg">
                                    <section className="dmstable">

                                        <div className="table-responsive">
                                            <table className="table table-bordered ">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Logged In As</th>
                                                        <th scope="col">Access Level</th>
                                                        <th scope="col">Primary Role</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.dmsUser ?
                                                        <tr>
                                                            <td>{this.state.dmsUser.first_name} {this.state.dmsUser.last_name}</td>
                                                            <td>{this.state.dmsUser.access_level}</td>
                                                            <td>{this.state.dmsUser.primary_role}</td>
                                                        </tr>
                                                        : <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>}
                                                </tbody>
                                            </table>
                                        </div>
                                    </section>
                                    <section className="pi-subtitle">
                                        <div className="d-flex justify-content-center">
                                            <h2>Tasks In Progress</h2>
                                        </div>
                                        <div className="d-flex justify-content-center mt-3">
                                            {/* <button type="submit" className={"btn pi-btn mr-2 " + this.state.loading} onClick={this.downloadQcReport}>Weekly QC Report</button> */}
                                            <button type="submit" className={"btn pi-btn mr-2 " + this.state.emailLoading} onClick={this.downloadEmailReport}>Weekly Email Quality</button>
                                            <a className="customButtonA mr-2" href="/logic-and-values.zip" target="_blank" download>Logic & Values</a>
                                            <button type="submit" className="btn pi-btn" onClick={this.createTask}>+ Create New Task</button>
                                        </div>
                                    </section>
                                    <MyActiveTaskTable />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

const mapStateToProps = (store, props) => {
    return {
        dmsUser: store.dms.dmsUser
    };
};

// Export
export default withRouter(connect(mapStateToProps)(MyActiveTasks));
