import { SET_ERROR, CLEAR_ERROR } from './constants';

export function setError(error) {
  return {
    type: SET_ERROR,
    error,
  };
}
export function clearError(error) {
  return {
    type: CLEAR_ERROR,
    error,
  };
}