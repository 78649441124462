import React, { useState, useCallback } from 'react';
import { useSelector } from "react-redux";
import { usePlaidLink } from 'react-plaid-link';
import {  VerifyPlaidAccount, StripePackage, StripeCredits, StripeSearchCredits, StripePackageCredits } from '../../../../service/client';
import { renderCurrency } from '../../../../utils';

function PlaidComponent(props) {
  let [token, setToken] = useState('');
  let [verifyProcess, setVerifyProcess] = useState(false);
  let [verificationResult, setVerificationResult] = useState(false);
  let [processingPayment, setProcessingPayment] = useState('');

  const currentSearch = useSelector((store) => store.client.currentSearch);
  const checkout = useSelector((store) => store.client.checkout);
  const searchResult = useSelector((store) => store.client.searchResult);
  const bestPricing = useSelector((store) => store.client.bestPricing);
  let linkToken = useSelector(store => store.client.plaidLink);

  const onSuccess = useCallback((token, metadata) => {
    let params = {
      public_token: token,
      account_id: metadata.account_id
    }
    setVerifyProcess(true);
    setVerificationResult(false);

    //api to generate stripe token with verified back account and plaid
    VerifyPlaidAccount(params).then(res =>
      handlePlaidResponse(res),
    )

  });

  // function to handle plaid response
  const handlePlaidResponse = (res) => {
    setVerifyProcess(false);
    setVerificationResult(res);
  }
  // 
  const CheckOutByACH = () => {
    let params = {};
    if (checkout.type === "credits") { // api to checkout credits
      params = {
        stripeToken: token,
        amount: checkout.invoice.totalPrice,
        description: "Purchase extra credits",
        plan_type: "credits",
        no_ofCredits: checkout.invoice.creditsIncluded
      }
      setProcessingPayment('loading');
      StripeCredits(params).then(res =>
        handleCheckoutResponse(res),
      )
    } else if (checkout.type === "subscription") { // api to upgrade subscription plan
      params = {
        stripeToken: token,
        amount: checkout.invoice.totalPrice,
        description: "Upgrade plan",
        plan_type: "package",
        id: checkout.id
      }
      setProcessingPayment('loading');
      StripePackage(params).then(res =>
        handleCheckoutResponse(res),
      )
    }
    else if (checkout.type === "download") {
      if (bestPricing.option === "Upgrade") { // apit to checkout subscription package with extra credits and proceed to download
        params = {
          stripeToken: token,
          amount: checkout.invoice.totalPrice,
          description: bestPricing.pricing_option,
          plan_type: "package_and_credits",
          no_ofCredits: checkout.invoice.totalPurchase,
          downloadname: currentSearch.name,
          scrvr: searchResult.scrvr,
          checkout_paid_records: searchResult.paid_records,
          Num_Matched_Officials: searchResult.lastSearchNumMatched,
          referrer: "",
          record_id: ""
        }
        setProcessingPayment('loading');
        StripePackageCredits(params).then(res =>
          handleCheckoutResponse(res),
        )
      } else { // apit to checkout search  extra credits and proceed to download
        params = {
          stripeToken: token,
          amount: checkout.invoice.totalPrice,
          description: bestPricing.pricing_option,
          no_ofCredits: checkout.invoice.totalPurchase,
          plan_type: "search_credits",
          downloadname: currentSearch.name,
          scrvr: searchResult.scrvr
        }
        setProcessingPayment('loading');
        StripeSearchCredits(params).then(res =>
          handleCheckoutResponse(res),
        )
      }
    }

  }
  // call back function to susscessfull checkout response for download or refreshing user account
  const handleCheckoutResponse = (response) => {
    setProcessingPayment('');
    props.callBack(response);
  }
  // plaid response for rendering plaid based payment options
  const PlaidResponse = () => {

    if (verificationResult.data.code === 200) {
      let data = verificationResult.data.data.verifyPlaidAccount;

      if (typeof data !== "undefined" && data.status === "success") {
        setToken(data.token);
        return (
          <>
            <div className="stripebankapi alert alert-success mt-3">
              <strong>Verified!</strong> Your Bank Account has been verfied successfully.
            </div>
            <div id="payment-bank-form">
              <button onClick={CheckOutByACH} id="stripeACHSbmitBtn" className={processingPayment}>
                Pay ${renderCurrency(checkout.invoice.totalPrice)}
              </button>
            </div>
          </>
        )
      } else {
        return (
          <div className="stripebankapi alert alert-danger mt-3"><strong>Verfication Failed!</strong> </div>
        )
      }
    } else {
      return (
        <div className="stripebankapi alert alert-danger mt-3"><strong>Verfication Failed!</strong> </div>
      )
    }
  }
  const onExit = (err, metatdata) => {
    // handle error...
    //console.log("errerr", err);
  };
  const config: Parameters<typeof usePlaidLink>[0] = {
    token: linkToken,
    env: process.env.REACT_APP_PLAID_MODE,
    onSuccess,
    onExit,
  };

  const { open, ready, error } = usePlaidLink(config);
  return (
    <>
      <button className="btn btn-pa verify-btn" onClick={() => open()} disabled={!ready}>
        Verify Your Bank Account &amp;  Pay via ACH
      </button>
      {
        verifyProcess ?
          <div className="row mt-3 text-center">
            <div className="spinner-border text-secondary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
          :
          ""
      }
      {
        verificationResult !== false ?
          <PlaidResponse />
          :
          ""
      }


    </>
  );
};

export default PlaidComponent;
