import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import ProtectorForm from "./protectorForm";
import { doProtectorLogin } from "../../../service/client";
import {
  setPasswordProtector
} from "../../../reducers/client/actions";
function Projector(props) {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(true);
  const [loading, setLoading] = useState('');
  const [error, setError] = useState('');
  const toggle = () => setModal(!modal);
  const handleSubmit = values => {
    let params = {
      eMail: values.email,
      passWord: values.password
    };
    setLoading("loading");
    doProtectorLogin(params).then(res => setResponse(res))
  };
  const setResponse = (res) => {
    setError('');
    setLoading("");
    if (res.success === false) {
      setError(res.message);
    } else {
      dispatch(setPasswordProtector(res));
    }

  }
  return (
    <div>

      <Modal size="lg"
        isOpen={modal}
        keyboard={false}
        toggle={toggle}
        backdrop={false}
        centered={true}
        className="modal-dialog">
        <ModalHeader >Login Restricted</ModalHeader>
        <ModalBody>
          {error !== "" ? (
            <div
              className="alert alert-danger"
              role="alert"
              dangerouslySetInnerHTML={{
                __html: error
              }}
            ></div>
          ) : null}
          <ProtectorForm
            loading={loading}
            onSubmit={handleSubmit}
          ></ProtectorForm>
        </ModalBody>

      </Modal>
    </div>
  );
}

export default Projector;