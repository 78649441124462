import React, { useState } from 'react';
import { useSelector } from "react-redux";
import InvoiceModal from './InvoiceModal';

// Offline payment modal with invoice details
function OfflinePayment(props) {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const checkout = useSelector((store) => store.client.checkout);
  return (
    <div className="row">
      <div className="col">
        <h3><strong>Pay By Check </strong></h3>
        {
         props.checkoutType === "subscription" ?
            <p>Prefer to pay by check and you don't mind waiting? Click 'Pay By Check' and we'll send you an invoice. You'll get access to the item you ordered AFTER we've received your check. </p>
            :
            <p>
              <strong>
                <span style={{ color: "#ff0000" }}>WAIT!!</span>&nbsp;
            Don't choose this option if you want to download your file now.</strong> But if you prefer to pay by check and you don't mind waiting, then click "Pay By Check" and we’ll send you an invoice.  We'll update your account AFTER we've received your check, and you can download the file then.
            </p>
        }


        <div className="text-right">
          <button
            onClick={toggle}
            className="btn btn-pa text-white pay-offline-btn"
          >
            <span className="text-uppercase">Pay By Check</span>
            {
              props.checkoutType === "download" ?
                <span className="text-lowercase"> {" "} and download later </span>
                :
                ""
            }
          </button>
        </div>
      </div>
      {
        checkout.length !== 0 ?
          <InvoiceModal
            modal={modal}
            toggle={toggle}
          />
          :
          ""
      }


    </div>
  )
}



export default OfflinePayment;
