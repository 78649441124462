// Vendor Imports
import React from "react";
import FooterModal from "../../../layouts/GuestLayout/FooterModal";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
// Local Imports
import Form from "./Form";
import { doRegister } from "../../../service/client";
import $ from "jquery";
// Registration Component
class RegistrationComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      email: "",
      title: "",
      content: "",
      toggle: false,
      error: false,
      captcha: "",
      captchaError: false,
      backendErrors: null,
      loading: "",
      registerSuccess: false,
    };
  }
  componentDidMount(){
    $("#header").hide();
  }
  // Footer modal handler
  handleFooterModal = (content) => {
    if (this.state.modal === false) {
      this.setState({ modal: true, content: content });
    } else {
      this.setState({ modal: false, content: "" });
    }
  };
  // Handlers
  handleSubmit = (values) => {
    if (!this.state.toggle) {
      this.setState({ error: true });
      return;
    } else {
      this.setState({ error: false });
    }
    if (this.state.captcha === "") {
      this.setState({ captchaError: true });
      return;
    } else {
      this.setState({ captchaError: false });
    }
    let params = {
      firstName: values.firstName,
      lastName: values.lastName,
      eMail: values.email,
      companyName: values.companyName,
      passWord: values.passWord,
      passWord2: values.passWord2,
      AgreeToTOS: 1,
      g_recaptcha_response: this.state.captcha,
    };
    this.setState({ loading: "loading", email: values.email });
    doRegister(params).then((res) => this.handleResponse(res));
  };

  // Response Handler
  handleResponse = (res) => {
    if (res.data.code === 200) {
      this.setState({
        registerSuccess: true,
        backendErrors: null,
        loading: "",
      });
    } else if (res.data.code === 422) {
      this.setState({ backendErrors: res.data.data.message[0], loading: "", email: '' });
    } else {
      this.setState({
        backendErrors: res.data.message, loading: "", email: ''
      });
    }
  };

  // onchange handler
  onChange = (value) => {
    if (value === null) {
      this.setState({ captcha: "" });
    }
    if (typeof value !== "object") {
      this.setState({ captcha: value, captchaError: false });
    }
  };

  // Check toggle handler
  checkToggle = () => {
    this.setState({ toggle: !this.state.toggle });
    if (!this.state.toggle === true) {
      this.setState({ error: false });
    }
  };

  render() {
    return (
      <>
        <Helmet>
          <title> Get My Free 100 Leads - Power Almanac </title>
        </Helmet>
        <div>
          <section className="container login-section register-block">
            <div className="row login-bg login-bg-reg">
              <div className="col-sm-12 reg-center">
              <a href="https://poweralmanac.com"> <img 
              src="/images/logo/poweralmanac.svg"
              alt="Power Almanac"
              className="logo mb-4"
              width="132"
              height="63"
            />
            </a>
                <h2 className="mb-3">Sign Up for Power Almanac</h2>
                <p className="mb-3">Create a free account or <Link to="/login"> <strong> LOG IN </strong></Link><br/>
                Every 30 days your account will renew <br/>
                with 100 fresh download credits.</p>
                
              </div>
              <div className="col-sm-12 col-md-3 ">

              </div>
              <div className="col-sm-12 col-md-6 mt-3">

                {this.state.backendErrors ? (
                  <div
                    className="alert alert-danger"
                    role="alert"
                    dangerouslySetInnerHTML={{
                      __html: this.state.backendErrors,
                    }}
                  ></div>
                ) : null}
                {this.state.registerSuccess ? (
                  <div className="alert alert-success">
                    <strong>Registration Successful!</strong> <br />
                    An activation link was emailed to {this.state.email}.
                    <br />Please click on the link to complete your activation. Not seeing the email? Please check your spam folder.
                  </div>
                ) : (
                  <Form
                    onSubmit={this.handleSubmit}
                    onChange={this.onChange}
                    termsChange={this.checkToggle}
                    openTos={this.handleFooterModal}
                    cvalues={this.state}
                  ></Form>
                )}
              </div>
              <div className="col-sm-12 col-md-3 reg-center  ">

              </div>
            </div>
          </section>
        </div>
        <FooterModal
          modal={this.state.modal}
          content={this.state.content}
          toggle={this.handleFooterModal}
        />
      </>
    );
  }
}

// Export
export default RegistrationComponent;
