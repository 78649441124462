export const GET_SEARCH = "GET_SEARCH";
export const GET_BEST_PRICE = "GET_BEST_PRICE";
export const GET_ZIPCODES = "GET_ZIPCODES";
export const SAVE_NEW_SEARCH = "SAVE_NEW_SEARCH";

export const SIGN_OFF = "SIGN_OFF";
export const SET_USER = "SET_USER";
export const SET_MYSEARCHES = "GET_MYSEARCHES";
export const UPDATE_SEARCH = "UPDATE_SEARCH";
export const SET_MY_TRANSACTIONS = "SET_MY_TRANSACTIONS";

export const SET_MY_ACCOUNT = "SET_MY_ACCOUNT";
export const SET_SUBSCRIPTIONS = "SET_SUBSCRIPTIONS";
export const REMOVE_SUB_USER = "REMOVE_SUB_USER";

export const SET_MY_SAVED_SEARCHES = "SET_MY_SAVED_SEARCHES";
export const UPDATE_MY_SAVED_SEARCH = "UPDATE_MY_SAVED_SEARCH";
export const DELETE_MY_SAVED_SEARCH = "DELETE_MY_SAVED_SEARCH";
export const SUB_USER_CHANGE_PASS = "SUB_USER_CHANGE_PASS";
export const NEW_USER_SERVEY = "NEW_USER_SERVEY";

export const PHONE_VERIFICATION = "PHONE_VERIFICATION";

export const REFRESH_MY_ACCOUNT = "REFRESH_MY_ACCOUNT";
export const CHECKOUT = "CHECKOUT";
export const CURRENT_SEARCH = "CURRENT_SEARCH";
export const SET_SEARCH_RESULT = "SET_SEARCH_RESULT";
export const SET_BEST_PRICING = "SET_BEST_PRICING";
export const SET_ZIPCODES = "SET_ZIPCODES";
export const SET_SEARCHLOADING = "SET_SEARCHLOADING";

export const SET_USER_INFO = "SET_USER_INFO";
export const SET_USER_DETAILS = "SET_USER_DETAILS";
export const SET_PROTECTOR = "SET_PROTECTOR";
export const APPLIED_FILTERS = "APPLIED_FILTERS";

export const SET_LINK_TOKEN = 'SET_LINK_TOKEN';

export const SET_TAM_MODE = "SET_TAM_MODE";

export const SET_STEP = "SET_STEP";

export const SET_VALID_FILTERS = "SET_VALID_FILTERS";







