// Vendor Imports
import React from "react";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Helmet } from 'react-helmet';

// Local Imports
import { getAPIUserList } from "../../../service/admin";

import Form from './Form';
import Table from './Table';

class APIUsersComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tableDetails: null,
            addModal:false
        };
    }

    componentDidMount() {
        this.getAPIUserLists();
    }

    getAPIUserLists = () => {
        // Api call to get subscription history
        getAPIUserList().then(res => this.handleResponse(res));
    }

    // Handle response
    handleResponse = res => {
        //console.log('success',res);
        if (res.data.code === 200) {
            this.setState({
                tableDetails: res.data.data,
            });
        }
    };


    // Modal toggle
    toggle = () => {
        this.setState({ addModal: !this.state.addModal });
    }

    render() {
        return (
            <>
                <Helmet>
                    <title> PA Admin - API Users </title>
                </Helmet>
                <section>
                    <div className="container admin-container">
                        <div className="row">
                            <div className="col-12 mt-3">
                                <section className="admin-title  d-flex justify-content-between">
                                    <h1>API Users</h1>
                                    <button type="button" className="btn trans-button" onClick={this.toggle}>Add API User</button>
                                </section>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <Table refreshProps={this.getAPIUserLists} tableDetails={this.state.tableDetails} />
                                </div>
                            </div>
                        </div>
                        

                        

                        <Modal isOpen={this.state.addModal} backdrop="static" centered={true} >
                            <ModalHeader toggle={this.toggle} className="backColor">Add API User</ModalHeader>
                            <ModalBody>
                                <Form toggle={this.toggle} refreshData={this.getAPIUserLists}/>
                            </ModalBody>
                        </Modal >
                    </div>
                </section>
            </>
        );
    }

}

// Export
export default APIUsersComponent;
