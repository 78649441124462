// Validations
const validate = values => {
  const errors = {};
  if (!values.newpass) {
    errors.newpass = "Create a password";
  } else if (values.newpass.length < 8) {
    errors.newpass = "Your password must have at least 8 characters, including at least 1 uppercase, 1 lowercase, 1 digit, and 1 special character";
  } else if (!/[A-Z]+/g.test(values.newpass)) {
    errors.newpass = "Your password must have at least 8 characters, including at least 1 uppercase, 1 lowercase, 1 digit, and 1 special character";
  } else if (!/[a-z]+/g.test(values.newpass)) {
    errors.newpass = "Your password must have at least 8 characters, including at least 1 uppercase, 1 lowercase, 1 digit, and 1 special character";
  } else if (!/[#?!@$%^&*-]+/.test(values.newpass)) {
    errors.newpass = "Your password must have at least 8 characters, including at least 1 uppercase, 1 lowercase, 1 digit, and 1 special character";
  } else if (!/[0-9]+/g.test(values.newpass)) {
    errors.newpass = "Your password must have at least 8 characters, including at least 1 uppercase, 1 lowercase, 1 digit, and 1 special character";
  }
  if (!values.confirmpass) {
    errors.confirmpass = "Confirm a password";
  } else if (values.newpass !== values.confirmpass) {
    errors.confirmpass = "Passwords do not match";
  }
  return errors;
};

export default validate;
