import React, { useState } from 'react';
import { useSelector } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { PayByCheck } from '../../../../service/client';
import { renderCount, renderCurrency } from '../../../../utils'

// invoice modal and mail sending option
function InvoiceRequest(props) {
  const [loading, setLoading] = useState('');
  const [invoiceResponse, setInvoiceResponse] = useState('');
  const {
    modal,
    toggle
  } = props;

  const checkout = useSelector((store) => store.client.checkout);
  const bestPricing = useSelector((store) => store.client.bestPricing);
  
  const handleInvoiceRequest = () => {
    let params = checkout.hidden;
    params.purchases = InVoiceData.purchases;
    setLoading('loading');
    PayByCheck(params).then(res =>
      handleResponse(res),
    )
  }
  const handleResponse = (res) => {
    setLoading('');
    setInvoiceResponse(res);
  }

  const InVoiceData = checkout.invoice;

  if (typeof InVoiceData === "undefined") {
    return (<></>)
  } else {
    return (
      <div>

        <Modal
          size="lg"
          isOpen={modal}
          toggle={toggle}
          backdrop={false}
          centered={true}
          className="modal-dialog">
          <ModalHeader className="invoice_modal_header text-center" toggle={toggle}>
            <strong>
              <span style={{ margin: "0 auto" }}>This is the invoice you requested from Power Almanac.</span>
            </strong>
          </ModalHeader>
          <ModalBody>
            <div className='status alert d-none'></div>
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 text-center">
                  <img src="/images/logo/poweralmanac.svg" alt="Power Almanac" className="logo pb-3 " style={{ width: "100px" }} />
                </div>
              </div>
              <div className="col-md-12 client-info">
                <table className="table table-striped table-bordered invoice-table">
                  <tbody>
                    <tr>
                      <td className="w-40">Power Almanac Invoice #:</td>
                      <td className="w-60">{InVoiceData.PowerAlmanacInvoice}</td>
                    </tr>

                    <tr>
                      <td>Invoice Date: </td>
                      <td>{InVoiceData.InvoiceDate}</td>
                    </tr>
                    <tr>
                      <td>Payment Due Date:</td>
                      <td>{InVoiceData.PaymentDueDate}</td>
                    </tr>
                    <tr>
                      <td>Amount Due:  </td>
                      <td>${renderCurrency(InVoiceData.totalPrice)}</td>
                    </tr>
                    <tr>
                      <td>Billed To:  </td>
                      <td>
                        <span className="font-weight-bold">{InVoiceData.Username}</span>
                        <br />
                        <span className="font-weight-bold">Company </span>: {InVoiceData.Company}                                          <br />
                        <span className="font-weight-bold">E-mail</span> :  {InVoiceData.EMail}
                      </td>
                    </tr>

                  </tbody>
                </table>
                <table className="table invoice-table">
                  <tbody>
                    <tr>
                      <td width="40%" className=" font-weight-bold" style={{ borderTop: "none" }}  >Check Payable To:</td>
                      <td width="60%" className="font-weight-bold" style={{ borderTop: "none" }}  > Government Executive Media Group, LLC</td>
                    </tr>
                    <tr>
                      <td width="40%" className="font-weight-bold" style={{ borderTop: "none" }}>Mail Check To:</td>
                      <td width="60%" className="font-weight-bold" style={{ borderTop: "none" }} >Government Executive Media Group, LLC <br/> PO Box 21665 <br/> New York, NY 10087-1665</td>
                    </tr>
                  </tbody>
                </table>

                <div className="table-responsive">
                  <table className="table table-striped table-bordered table-condensed  invoice-items">
                    <thead>
                      <tr>
                        <th>Items Ordered </th>
                        <th>Credits Included </th>
                        <th>Unit Price</th>
                        <th className='text-right'>Total Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        InVoiceData.length !== 0 ?
                          InVoiceData.purchases.map(function (item, i) {
                            return (<tr key={i}>
                              <td className="border-bottom">
                                {item.name}
                              </td>
                              <td className="border-bottom">
                                {item.no_of_records === "Unlimited" ? "Unlimited" : renderCount(item.no_of_records)}
                              </td>
                              <td>{item.unit_price}</td>
                              <td className="text-right">${renderCurrency(item.cost)}</td>
                            </tr>)
                          })
                          :
                          ""
                      }


                      <tr>
                        <td className="font-weight-bold">Total Purchase</td>
                        {
                          InVoiceData.totalPurchase === 1000000 ?
                            <td className="font-weight-bold"> Unlimited </td>
                            :
                            <td className="font-weight-bold">
                              {InVoiceData.totalPurchase ?
                                renderCount(InVoiceData.totalPurchase)
                                :
                                checkout.type === 'subscription' && checkout.id === "105" ? "Unlimited" : renderCount(InVoiceData.creditsIncluded)
                              }
                            </td>
                        }

                        <td> </td>
                        <td className="text-right font-weight-bold">${renderCurrency(InVoiceData.totalPrice)}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {checkout.type !== "credits" && bestPricing.option !== "Purchase" ?
                  <div className="table-responsive">
                    <table className="table table-striped table-bordered table-condensed">
                      <tbody>
                        <tr>
                          <td colSpan="2">Subscription Start Date:</td>
                          <td className="font-weight-bold">1 Business day after payment is received </td>
                        </tr>
                        <tr>
                          <td colSpan="2">Subscription End Date:</td>
                          <td className="font-weight-bold">12 Months from the subscription start date </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  :
                  ""
                }
                <hr />
                {
                  invoiceResponse ?
                    invoiceResponse.data.success && invoiceResponse.data.code === 200 && invoiceResponse.data.message === "SUCCESS" ?
                      <div className="alert alert-success" role="alert">
                        We've emailed the invoice to you and we'll keep an eye out for your check. We'll update your account as soon as we've received payment, and we'll notify you when that happens.
                  </div>
                      :
                      ""
                    :
                    ""
                }
                {
                  invoiceResponse ?

                    !invoiceResponse.success && invoiceResponse.code !== 200 && invoiceResponse.message === "FAILED" ?
                      <div className="alert alert-danger" role="alert">
                        Failed to process your request, please try again later
                  </div>
                      :
                      ""
                    :
                    ""
                }


                <div className="modal-footer pay-off-modal invoice-footer">
                  <button onClick={handleInvoiceRequest} type="submit" className={"btn btn-pa " + loading} id="sendRequestSubmit">EMAIL THIS INVOICE TO ME</button>
                  <Button color="btn btn-pa btn-secondary" onClick={toggle}>Close</Button>
                </div>
              </div>
            </div>

          </ModalBody>

        </Modal >
      </div >
    );
  }
}

export default InvoiceRequest;
