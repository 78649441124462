// Vendor Imports
import React from "react";
import Swal from 'sweetalert2';
import $ from "jquery";
import moment from 'moment';

// Local Imports
import { updateUserBillingHistory } from "../../../../service/admin";

// Edit Billing Form
class EditBillingForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            backendErrors: null,
            loading: '',
            paymentMethod: "",
            amount: "",
            item: "",
            date: ""
        };
    }

    componentDidMount() {
        this.setState({
            paymentMethod: this.props.editVal.Payment_Method1,
            amount: this.props.editVal.Total,
            item: this.props.editVal.Item,
            date: this.getFormattedDate(this.props.editVal.Date_dt)
        })
    }

    // Get formatted date
    getFormattedDate = date => {
        return moment(date).format('YYYY-MM-DD');
    }

    // Form Submit
    handleSubmit = () => {
        if (this.state.paymentMethod === '') {
            Swal.fire({
                text: 'Select the Payment Method',
                showCloseButton: true,
                confirmButtonText: "Ok",
                icon: 'warning',
                allowOutsideClick: false
            });
            return;
        }
        if (this.state.amount === '') {
            Swal.fire({
                text: 'Enter the Amount',
                showCloseButton: true,
                confirmButtonText: "Ok",
                icon: 'warning',
                allowOutsideClick: false
            });
            return;
        }
        if (this.state.date === '') {
            Swal.fire({
                text: 'Select the Date',
                showCloseButton: true,
                confirmButtonText: "Ok",
                icon: 'warning',
                allowOutsideClick: false
            });
            return;
        }
        if (this.state.item === '') {
            Swal.fire({
                text: 'Enter the Description',
                showCloseButton: true,
                confirmButtonText: "Ok",
                icon: 'warning',
                allowOutsideClick: false
            });
            return;
        }
        let params = {
            billing_reg_id: this.props.regId,
            offline_payment_method: this.state.paymentMethod,
            billing_amount: this.state.amount,
            billing_his_id: this.props.editVal.billing_id,
            billing_desc: this.state.item,
            paydate: this.state.date
        }
        this.setState({ loading: "loading" });
        updateUserBillingHistory(params).then(res => this.handleAddResponse(res));
    }

    // Handle Edit Api Response
    handleAddResponse = res => {
        if (res.data.code === 200) {
            Swal.fire({
                title: 'Billing History Updated',
                showCloseButton: true,
                confirmButtonText: "Ok",
                icon: 'success',
                allowOutsideClick: false
            });
            this.props.refreshData();
            this.closeModal();

        } else {
            this.setState({ backendErrors: res.data.message, loading: "" });
        }
    }

    // Close edit modal
    closeModal = () => {
        this.setState({
            backendErrors: null,
            loading: '',
            paymentMethod: "",
            amount: "",
            item: "",
            date: ""
        });
        this.props.toggle();
    }

    // Get max date function
    getMaxDate = () => {
        return moment().format('YYYY-MM-DD');
    }

    render() {
        return (
            <div>
                <div className="mb-3">
                    <label>Payment method</label>
                    <select name="offline_payment_method" id="offline_payment_method" className="form-control" value={this.state.paymentMethod}
                        onChange={e => this.setState({
                            paymentMethod: e.target.value
                        })}>
                        <option value="">Select Payment Method</option>
                        <option value="check">check</option>
                        <option value="ACH/wire transfer">ACH/wire transfer</option>
                        <option value="Stripe CC">Stripe CC</option>
                        <option value="Quickbooks CC">Quickbooks CC</option>
                        <option value="PayPal CC">PayPal CC</option>
                    </select>
                </div>

                <div className="mb-3">
                    <label>Amount</label>
                    <input
                        name="amount"
                        type="number"
                        className="form-control"
                        value={this.state.amount}
                        onChange={e => this.setState({
                            amount: e.target.value
                        })}
                    />
                </div>

                <div className="mb-3">
                    <label>Date</label>
                    <input type="date" name="bill_date" id="bill_date" className="form-control" max={this.getMaxDate()} value={this.state.date}
                        onChange={e => this.setState({
                            date: e.target.value
                        })} />
                </div>

                <div className="mb-3">
                    <label>Item</label>
                    <textarea className="form-control text-area" id="description" rows="3" placeholder="Description" value={this.state.item}
                        onChange={e => this.setState({
                            item: e.target.value
                        })}></textarea>
                </div>

                {
                    this.state.backendErrors ? (
                        <div
                            className="alert alert-danger"
                            role="alert"
                            dangerouslySetInnerHTML={{
                                __html: this.state.backendErrors
                            }}
                        ></div>
                    ) : null
                }

                <div className="mt-4" style={{ textAlign: "right" }}>
                    <button type="button" className={"btn btn-primary backColor " + this.state.loading} onClick={this.handleSubmit}>Save</button>&nbsp;
                    <button type="button" className="btn btn-secondary" onClick={this.closeModal}>Cancel</button>
                </div>
            </div>
        );
    }
};


// Export
export default EditBillingForm;
