// Vendor Imports
import React from "react";
import Swal from 'sweetalert2';
import $ from "jquery";

// Local Imports
import { addUserCreditHistory } from "../../../../service/admin";

// Add Credit Form
class AddCreditForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            backendErrors: null,
            loading: '',
            creditType: "CR",
            credits: "",
            desc: ''
        };
    }

    // Form Submit
    handleSubmit = () => {
        if (this.state.credits === '') {
            Swal.fire({
                text: 'Enter the Credits',
                showCloseButton: true,
                confirmButtonText: "Ok",
                icon: 'warning',
                allowOutsideClick: false
            });
            return;
        }
        if (this.state.desc === '') {
            Swal.fire({
                text: 'Enter the Description',
                showCloseButton: true,
                confirmButtonText: "Ok",
                icon: 'warning',
                allowOutsideClick: false
            });
            return;
        }
        let params = {
            trans_reg_id: this.props.regId,
            transaction_type: this.state.creditType,
            credits: this.state.credits,
            transaction_desc: this.state.desc,
        }
        this.setState({ loading: "loading" });
        addUserCreditHistory(params).then(res => this.handleAddResponse(res));
    }

    // Handle add api response
    handleAddResponse = res => {
        if (res.data.code === 200) {
            Swal.fire({
                title: 'Credit History Added',
                showCloseButton: true,
                confirmButtonText: "Ok",
                icon: 'success',
                allowOutsideClick: false
            });
            this.props.refreshData();
            this.closeModal();

        } else {
            this.setState({ backendErrors: res.data.message, loading: "" });
        }
    }

    // Close modal
    closeModal = () => {
        this.setState({
            backendErrors: null,
            loading: '',
            creditType: "CR",
            credits: "",
            desc: ''
        });
        this.props.toggle();
    }

    render() {
        return (
            <div>
                <div className="mb-3">
                    <label>Credit Type</label>
                    <select name="transaction_type" id="transaction_type" className="form-control" value={this.state.creditType}
                        onChange={e => this.setState({
                            creditType: e.target.value
                        })}>
                        <option value="CR">+</option>
                        <option value="DR">-</option>
                    </select>
                </div>

                <div className="mb-3">
                    <label>Credits</label>
                    <input
                        name="credits"
                        type="number"
                        className="form-control"
                        value={this.state.credits}
                        onChange={e => this.setState({
                            credits: e.target.value
                        })}
                    />
                </div>

                <div className="mb-3">
                    <label>Description</label>
                    <textarea className="form-control text-area" id="exampleFormControlTextarea1" rows="3" placeholder="Description" value={this.state.desc}
                        onChange={e => this.setState({
                            desc: e.target.value
                        })}></textarea>
                </div>

                {
                    this.state.backendErrors ? (
                        <div
                            className="alert alert-danger"
                            role="alert"
                            dangerouslySetInnerHTML={{
                                __html: this.state.backendErrors
                            }}
                        ></div>
                    ) : null
                }

                <div className="mt-4" style={{ textAlign: "right" }}>
                    <button type="button" className={"btn btn-primary backColor " + this.state.loading} onClick={this.handleSubmit}>Add</button>&nbsp;
                    <button type="button" className="btn btn-secondary" onClick={this.closeModal}>Cancel</button>
                </div>
            </div>
        );
    }
};


// Export
export default AddCreditForm;
