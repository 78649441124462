// Vendor Imports
import React from "react";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Swal from 'sweetalert2';

// Local Imports
import { getUserSubscriptionHistory , getSubscriptionList , updateUserEndSubscription } from "../../../../service/admin";
import AddForm from './AddForm';
import EditForm from './EditForm';

class SubscriptionHistoryComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tableDetails: null,
            addModal: false,
            editModal: false,
            editValues: {},
            subDetails: null
        };
    }

    componentDidMount() {
        this.getSubscriptions();
        this.getSubscriptionLists();
    }

    getSubscriptions = () => {
        // Api call to get subscription history
        getUserSubscriptionHistory(this.props.userId).then(res => this.handleResponse(res));
    }

    getSubscriptionLists = () => {
        getSubscriptionList().then(res => this.handleResponseDt(res));
    }

    // Handle response
    handleResponse = res => {
        if (res.data.code === 200) {
            this.setState({
                tableDetails: res.data.data,
            });
        }
    };

    // Handle response
    handleResponseDt = res => {
 
        if (res.data.code === 200) {
            this.setState({
                subDetails: res.data.data.items,
            });
        }
    };

    //end Subscription
    /*endSubscription = val => {
        let params = {
            sh_id: val.SUBSC_ID,
            reg_id: val.REG_ID
        }
        this.setState({ loading: "loading" });
        updateUserEndSubscription(params).then(res => this.handleEndResponse(res));
    }*/

    // Hanlde edit response
    /*handleEndResponse = res => {
        if (res.data.code === 200) {
            Swal.fire({
                title: 'Subscription History Updated',
                showCloseButton: true,
                confirmButtonText: "Ok",
                icon: 'success',
                allowOutsideClick: false
            });
            this.setState({
                subDetails: res.data.data.items,
            });
            this.getSubscriptions();
            this.getSubscriptionLists();
            this.setState({ loading: "" });
        } else {
            this.setState({ backendErrors: res.data.message, loading: "" });
        }
    }*/

    // Add toggle
    toggle = () => {
        this.setState({ addModal: !this.state.addModal });
    }

    // Open edit modal
    openEditModal = val => {
        this.setState({ editModal: true, editValues: val });
    }

    // Edit modal toggle
    editToggle = () => {
        this.setState({ editModal: false, editValues: {} });
    }

    
    render() {
        return (
            <section className="admin-table">
                {this.state.tableDetails ? <div>
                    <div className="row">
                        <div className="col-md-12">
                            <button type="button" className="btn add-btn" onClick={this.toggle}>+ Add New</button>
                        </div>
                    </div>

                    <div className="table-responsive">
                        <table className="table table-bordered ">
                            <thead>
                                <tr>
                                    <th scope="col">Subscription ID</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Subscription Date Start</th>
                                    <th scope="col">Subscription Date End</th>
                                    <th scope="col">Subscription Date Status</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.tableDetails.length === 0 ?
                                    <tr>
                                        <td colSpan="7" align="center">No Subscription History Found</td>
                                    </tr> :
                                    this.state.tableDetails.map((listValue, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{listValue.SUBSC_ID}</td>
                                                <td>{listValue.NAME}</td>
                                                <td>{listValue.DESCRIPTION}</td>
                                                <td>{listValue.SUBSCRIPTION_DATE_START}</td>
                                                <td>{listValue.SUBSCRIPTION_DATE_END}</td>
                                                <td>{listValue['SUBSCRIPTION STATUS']}</td>
                                                {
                                                    listValue.Edit === 1 ?
                                                        <td><a href="#" className="edit-btn" onClick={() => this.openEditModal(listValue)}>Edit</a></td> : <td></td>
                                                }
                                                
                                            </tr>
                                        );
                                    })
                                }

                            </tbody>
                        </table>
                    </div>
                </div>
                    : <div className="text-center">
                        <div
                            className="spinner-border text-primary mt-3"
                            style={{ width: "3rem", height: "3rem", marginLeft: "auto" }}
                            role="status"
                        >

                            <span className="sr-only">Loading...</span>
                        </div></div>
                }

                {/* Add subscription history */}
                <Modal isOpen={this.state.addModal} backdrop="static" centered={true} >
                    <ModalHeader toggle={this.toggle} className="backColor">Add Subscription History</ModalHeader>
                    <ModalBody>
                        <AddForm toggle={this.toggle} subData={this.state.subDetails} refreshData={this.getSubscriptions} regId={this.props.userId} />
                    </ModalBody>
                </Modal >

                {/* Edit subscription history */}
                <Modal isOpen={this.state.editModal} backdrop="static" centered={true} >
                    <ModalHeader toggle={this.editToggle} className="backColor">Edit Subscription History</ModalHeader>
                    <ModalBody>
                        <EditForm toggle={this.editToggle} subData={this.state.subDetails} refreshData={this.getSubscriptions} regId={this.props.userId} editVal={this.state.editValues} />
                    </ModalBody>
                </Modal >
            </section>

        );
    }

}

// Export
export default SubscriptionHistoryComponent;
