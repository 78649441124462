// password change validation 
const validate = values => {
  const errors = {};
  if (!values.oldPassWord) {
    errors.oldPassWord = "Passwords enter your current password";
  }
  if (!values.newPassWord) {
    errors.newPassWord = "Passwords enter a new password";
  } else if (values.newPassWord.length < 8) {
    errors.newPassWord = "Your password must have at least 8 characters, including at least 1 uppercase, 1 lowercase, 1 digit, and 1 special character";
  } else if (!/[A-Z]+/g.test(values.newPassWord)) {
    errors.newPassWord = "Your password must have at least 8 characters, including at least 1 uppercase, 1 lowercase, 1 digit, and 1 special character";
  } else if (!/[a-z]+/g.test(values.newPassWord)) {
    errors.newPassWord = "Your password must have at least 8 characters, including at least 1 uppercase, 1 lowercase, 1 digit, and 1 special character";
  } else if (!/[#?!@$%^&*-]+/.test(values.newPassWord)) {
    errors.newPassWord = "Your password must have at least 8 characters, including at least 1 uppercase, 1 lowercase, 1 digit, and 1 special character";
  } else if (!/[0-9]+/g.test(values.newPassWord)) {
    errors.newPassWord = "Your password must have at least 8 characters, including at least 1 uppercase, 1 lowercase, 1 digit, and 1 special character";
  }
  if (!values.confirmPassWord) {
    errors.confirmPassWord = "Passwords enter confirm your new password";
  } else if (values.newPassWord !== values.confirmPassWord) {
    errors.confirmPassWord = "Passwords do not match";
  }
  return errors;
};

export default validate;
