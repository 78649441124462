import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from "react-redux";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import PlaidComponent from './Plaid';
import CheckoutForm from './CheckoutCardForm';
import { setMyTransactions, refreshUserDetails, setLinkToken } from "../../../../reducers/client/actions";
import { GetLinkToken, getMyTransactions, getMyAccount } from '../../../../service/client';

// Stripe payment component with credit card and ACH based checkout option
function StripeModal(props) {
  const {
    toggle,
    modal,
  } = props;
  
  let linkToken = useSelector(store => store.client.plaidLink);
  
  useEffect(() => {
      let params = {
        redirect_uri: window.location.origin + '/checkout/oauth',
      }
      GetLinkToken(params).then(res =>
        handlePlaidLinkTokenResponse(res.data.token),
      )
  },[modal]);

  const handlePlaidLinkTokenResponse = (token) => {
    if (token.link_token) {
      dispatch(setLinkToken(token.link_token));
    }
  }

  const [title, setTitle] = useState('Stripe Payment Option');
  let [stripeError, setStripeError] = useState('');
  let [stripeSuccess, setStripeSuccess] = useState('');
  const dispatch = useDispatch();

  const setStripe = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_API_KEY);
  // redirect to download or my account page after successfull payment
  const HandleClose = () => {

    dispatch(setLinkToken(''));
    if (stripeSuccess === "") {
      props.toggle();
    } else {
      if (props.checkout.type === "download") {
        if (props.currentSearch.expired === true) {
          props.history.push('/confirm-expire-download/' + props.currentSearch.id);
        } else {
          props.history.push('/search/download');
        }

      } else {
        props.history.push('/my-account');
      }
    }
  }
  // function to display payment related success or error message
  const handlePaymentMessage = (response) => {
    if (response.data.code === 200) {
      setTitle("Payment Success");
      if (props.checkout.type === "download") {
        setStripeSuccess(response.data.data.reponseMessage);
      } else {
        setStripeSuccess(response.data.data.reponseMessage);
      }
      getMyAccount().then(res =>
        dispatch(refreshUserDetails(res.data.data))
      );
      getMyTransactions().then(res =>
        dispatch(setMyTransactions(res.data.data)),
      )
    } else {
      setStripeError({ error: { message: "Payment Failed! Try again" } });

    }
  }
  return (
    <div>

      <Modal
        size="lg"
        isOpen={modal}
        toggle={toggle}
        backdrop={false}
        centered={true}
        className="modal-dialog"
      >
        <ModalHeader id="stripe_modal_header_wrap" toggle={HandleClose}>{title}</ModalHeader>
        <ModalBody id="stripe_modal_body_wrap">
          {stripeError !== '' ?
            <div className="alert alert-danger mt-3" role="alert">
              {stripeError.error.message}
              <span
                dangerouslySetInnerHTML={{
                  __html: stripeError.error.message
                }}
              />
            </div>
            :
            ""
          }
          {stripeSuccess !== "" ?
            <div className="alert alert-success mt-3" role="alert">
              <span
                dangerouslySetInnerHTML={{
                  __html: stripeSuccess
                }}
              />
            </div>
            :
            <>

              <Elements stripe={setStripe}>
                <CheckoutForm callBack={handlePaymentMessage} />
              </Elements>

              <div className="row">
                <div className="col-12 mt-3 text-center">
                  {linkToken !== '' ?
                    <PlaidComponent callBack={handlePaymentMessage} />
                    :
                    ""
                  }
                </div>
              </div>
              <div className="row">
                <div className="col-12 mt-3 text-center">
                  <img alt="stripe" className="w-100" src="/images/secure-stripe-payment-logo.png" />
                </div>
              </div>
            </>
          }
        </ModalBody>
      </Modal>
    </div>
  )
}


function mapStateToProps(store, props) {
  return {
    checkout: store.client.checkout ? store.client.checkout : [],
    currentSearch: store.client.currentSearch ? store.client.currentSearch : [],
  }
}

export default connect(mapStateToProps, {})(StripeModal);

