import React, {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setNewUserServeyForm } from '../../../../reducers/client/actions';

// My first search  - Location filter
function LocataionFilter() {
  const dispatch = useDispatch();
  const NewUserServeyFormData = useSelector((store) => store.client.NewUserServeyForm);
  
  const newSurveyResponse = useSelector((store) => store.client.info.survey_response ? JSON.parse( store.client.info.survey_response ): "");
  
  useEffect(() => {
   if(newSurveyResponse!==""){
    let NewUserServeyForm = NewUserServeyFormData;
    NewUserServeyForm.where = newSurveyResponse.where;
    NewUserServeyForm.locations = newSurveyResponse.locations;
  
    dispatch(setNewUserServeyForm(NewUserServeyForm));
   }
  },[]);
  // list of state and values
  let formFields = [
    { label: "Alabama", name: "Alabama", value: "Alabama" },
    { label: "Alaska", name: "Alaska", value: "Alaska" },
    { label: "Arizona", name: "Arizona", value: "Arizona" },
    { label: "Arkansas", name: "Arkansas", value: "Arkansas" },
    { label: "California", name: "California", value: "California" },
    { label: "Colorado", name: "Colorado", value: "Colorado" },
    { label: "Connecticut", name: "Connecticut", value: "Connecticut" },
    { label: "Delaware", name: "Delaware", value: "Delaware" },
    { label: "District Of Columbia", name: "District+Of+Columbia", value: "District+Of+Columbia" },
    { label: "Florida", name: "Florida", value: "Florida" },
    { label: "Georgia", name: "Georgia", value: "Georgia" },
    { label: "Hawaii", name: "Hawaii", value: "Hawaii" },
    { label: "Idaho", name: "Idaho", value: "Idaho" },
    { label: "Illinois", name: "Illinois", value: "Illinois" },
    { label: "Indiana", name: "Indiana", value: "Indiana" },
    { label: "Iowa", name: "Iowa", value: "Iowa" },
    { label: "Kansas", name: "Kansas", value: "Kansas" },
    { label: "Kentucky", name: "Kentucky", value: "Kentucky" },
    { label: "Louisiana", name: "Louisiana", value: "Louisiana" },
    { label: "Maine", name: "Maine", value: "Maine" },
    { label: "Maryland", name: "Maryland", value: "Maryland" },
    { label: "Massachusetts", name: "Massachusetts", value: "Massachusetts" },
    { label: "Michigan", name: "Michigan", value: "Michigan" },
    { label: "Minnesota", name: "Minnesota", value: "Minnesota" },
    { label: "Mississippi", name: "Mississippi", value: "Mississippi" },
    { label: "Missouri", name: "Missouri", value: "Missouri" },
    { label: "Montana", name: "Montana", value: "Montana" },
    { label: "Nebraska", name: "Nebraska", value: "Nebraska" },
    { label: "Nevada", name: "Nevada", value: "Nevada" },
    { label: "New Hampshire", name: "New+Hampshire", value: "New+Hampshire" },
    { label: "New Jersey", name: "New+Jersey", value: "New+Jersey" },
    { label: "New Mexico", name: "New+Mexico", value: "New+Mexico" },
    { label: "New York", name: "New+York", value: "New+York" },
    { label: "North Carolina", name: "North+Carolina", value: "North+Carolina" },
    { label: "North Dakota", name: "North+Dakota", value: "North+Dakota" },
    { label: "Ohio", name: "Ohio", value: "Ohio" },
    { label: "Oklahoma", name: "Oklahoma", value: "Oklahoma" },
    { label: "Oregon", name: "Oregon", value: "Oregon" },
    { label: "Pennsylvania", name: "Pennsylvania", value: "Pennsylvania" },
    { label: "Rhode Island", name: "Rhode+Island", value: "Rhode+Island" },
    { label: "South Carolina", name: "South+Carolina", value: "South+Carolina" },
    { label: "South Dakota", name: "South+Dakota", value: "South+Dakota" },
    { label: "Tennessee", name: "Tennessee", value: "Tennessee" },
    { label: "Texas", name: "Texas", value: "Texas" },
    { label: "Utah", name: "Utah", value: "Utah" },
    { label: "Vermont", name: "Vermont", value: "Vermont" },
    { label: "Virginia", name: "Virginia", value: "Virginia" },
    { label: "Washington", name: "Washington", value: "Washington" },
    { label: "West Virginia", name: "West+Virginia", value: "West+Virginia" },
    { label: "Wisconsin", name: "Wisconsin", value: "Wisconsin" },
    { label: "Wyoming", name: "Wyoming", value: "Wyoming" }
  ];

  // funcation to capture location filter values
  const handleChange = (event) => {
    let newObject = {};
    let locations = [];
    if (event.target.value !== '') {
      newObject[event.target.value] = event.target.value;
      locations.push(event.target.value);
    }
    let NewUserServeyForm = NewUserServeyFormData;
    NewUserServeyForm.where = locations;
    NewUserServeyForm.locations = newObject;
    dispatch(setNewUserServeyForm(NewUserServeyForm));
  };

  return (
        <div className="row  pt-2 pr-5 pl-5 ">
          <select onChange={handleChange} name="where" id="usWhere" className="form-control">
            <option value="">Select State</option>
            {
              formFields.map((field, index) => {
                let selected = "";
                if (newSurveyResponse !== "") {
                  selected = newSurveyResponse.where.includes(field.value) ? "selected" : "";
                }
                return (
                  <option key={index} selected={selected}  value={field.value}>{field.label}</option>
                );
              })
            }
          </select>
        </div>
  )
}

export default LocataionFilter;
