import React from 'react';
import { connect, useSelector } from 'react-redux';
import { renderCount, renderEmailPercent } from "../../../../utils";

// location based search stats
function ByLocation(props) {
    let tamMode = useSelector(store => store.client.tamMode);
    let Summary = 'JSON_SUMMARY' in props.searchResult ? JSON.parse(props.searchResult.JSON_SUMMARY) : {};
    let Governments = 'JSON_GOVERNMENTS' in props.searchResult ? JSON.parse(props.searchResult.JSON_GOVERNMENTS) : {};
    let Officials = 'JSON_OFFICIALS' in props.searchResult ? JSON.parse(props.searchResult.JSON_OFFICIALS) : {};
    const locations = [
        { label: "Alabama", name: "AL", govCount: "locationALGov", emailCount: "locationALEmail" },
        { label: "Alaska", name: "AK", govCount: "locationAKGov", emailCount: "locationAKEmail" },
        { label: "Arizona", name: "AZ", govCount: "locationAZGov", emailCount: "locationAZEmail" },
        { label: "Arkansas", name: "AR", govCount: "locationARGov", emailCount: "locationAREmail" },
        { label: "California", name: "CA", govCount: "locationCAGov", emailCount: "locationCAEmail" },
        { label: "Colorado", name: "CO", govCount: "locationCOGov", emailCount: "locationCOEmail" },
        { label: "Connecticut", name: "CT", govCount: "locationCTGov", emailCount: "locationCTEmail" },
        { label: "Delaware", name: "DE", govCount: "locationDEGov", emailCount: "locationDEEmail" },
        { label: "District of Columbia", name: "DC", govCount: "locationDCGov", emailCount: "locationDCEmail" },
        { label: "Florida", name: "FL", govCount: "locationFLGov", emailCount: "locationFLEmail" },
        { label: "Georgia", name: "GA", govCount: "locationGAGov", emailCount: "locationGAEmail" },
        { label: "Hawaii", name: "HI", govCount: "locationHIGov", emailCount: "locationHIEmail" },
        { label: "Idaho", name: "ID", govCount: "locationIDGov", emailCount: "locationIDEmail" },
        { label: "Illinois", name: "IL", govCount: "locationILGov", emailCount: "locationILEmail" },
        { label: "Indiana", name: "IN", govCount: "locationINGov", emailCount: "locationINEmail" },
        { label: "Iowa", name: "IA", govCount: "locationIAGov", emailCount: "locationIAEmail" },
        { label: "Kansas", name: "KS", govCount: "locationKSGov", emailCount: "locationKSEmail" },
        { label: "Kentucky", name: "KY", govCount: "locationKYGov", emailCount: "locationKYEmail" },
        { label: "Louisiana", name: "LA", govCount: "locationLAGov", emailCount: "locationLAEmail" },
        { label: "Maine", name: "ME", govCount: "locationMEGov", emailCount: "locationMEEmail" },
        { label: "Maryland", name: "MD", govCount: "locationMDGov", emailCount: "locationMDEmail" },
        { label: "Massachusetts", name: "MA", govCount: "locationMAGov", emailCount: "locationMAEmail" },
        { label: "Michigan", name: "MI", govCount: "locationMIGov", emailCount: "locationMIEmail" },
        { label: "Minnesota", name: "MN", govCount: "locationMNGov", emailCount: "locationMNEmail" },
        { label: "Mississippi", name: "MS", govCount: "locationMSGov", emailCount: "locationMSEmail" },
        { label: "Missouri", name: "MO", govCount: "locationMOGov", emailCount: "locationMOEmail" },
        { label: "Montana", name: "MT", govCount: "locationMTGov", emailCount: "locationMTEmail" },
        { label: "Nebraska", name: "NE", govCount: "locationNEGov", emailCount: "locationNEEmail" },
        { label: "Nevada", name: "NV", govCount: "locationNVGov", emailCount: "locationNVEmail" },
        { label: "New Hampshire", name: "NH", govCount: "locationNHGov", emailCount: "locationNHEmail" },
        { label: "New Jersey", name: "NJ", govCount: "locationNJGov", emailCount: "locationNJEmail" },
        { label: "New Mexico", name: "NM", govCount: "locationNMGov", emailCount: "locationNMEmail" },
        { label: "New York", name: "NY", govCount: "locationNYGov", emailCount: "locationNYEmail" },
        { label: "North Carolina", name: "NC", govCount: "locationNCGov", emailCount: "locationNCEmail" },
        { label: "North Dakota", name: "ND", govCount: "locationNDGov", emailCount: "locationNDEmail" },
        { label: "Ohio", name: "OH", govCount: "locationOHGov", emailCount: "locationOHEmail" },
        { label: "Oklahoma", name: "OK", govCount: "locationOKGov", emailCount: "locationOKEmail" },
        { label: "Oregon", name: "OR", govCount: "locationORGov", emailCount: "locationOREmail" },
        { label: "Pennsylvania", name: "PA", govCount: "locationPAGov", emailCount: "locationPAEmail" },
        { label: "Rhode Island", name: "RI", govCount: "locationRIGov", emailCount: "locationRIEmail" },
        { label: "South Carolina", name: "SC", govCount: "locationSCGov", emailCount: "locationSCEmail" },
        { label: "South Dakota", name: "SD", govCount: "locationSDGov", emailCount: "locationSDEmail" },
        { label: "Tennessee", name: "TN", govCount: "locationTNGov", emailCount: "locationTNEmail" },
        { label: "Texas", name: "TX", govCount: "locationTXGov", emailCount: "locationTXEmail" },
        { label: "Utah", name: "UT", govCount: "locationUTGov", emailCount: "locationUTEmail" },
        { label: "Vermont", name: "VT", govCount: "locationVTGov", emailCount: "locationVTEmail" },
        { label: "Virginia", name: "VA", govCount: "locationVAGov", emailCount: "locationVAEmail" },
        { label: "Washington", name: "WA", govCount: "locationWAGov", emailCount: "locationWAEmail" },
        { label: "West Virginia", name: "WV", govCount: "locationWVGov", emailCount: "locationWVEmail" },
        { label: "Wisconsin", name: "WI", govCount: "locationWIGov", emailCount: "locationWIEmail" },
        { label: "Wyoming", name: "WY", govCount: "locationWYGov", emailCount: "locationWYEmail" }
    ];

    return (
        <>
            <tbody id="searchResultsGovType">
                {
                    locations.map((row, index) => {
                        return (
                            <tr key={index}>
                                <td> <span dangerouslySetInnerHTML={{ __html: row.label }} /> </td>
                                <td>
                                    <span className="setToZero">{renderCount(Officials[row.name])} </span>
                                </td>
                                {
                                    tamMode ?
                                        <>
                                            <td>
                                                <span className="setToZero">{renderCount(Governments[row.govCount])}</span>
                                            </td>
                                            <td> {renderEmailPercent(Summary.TamGovs, Governments[row.govCount])}%</td>
                                        </> :
                                        <>
                                            <td>
                                                <span className="setToZero">{renderCount(Officials[row.emailCount])}</span>
                                            </td>
                                            <td> {renderEmailPercent(Officials[row.name], Officials[row.emailCount])}%</td>
                                        </>
                                }
                            </tr>
                        );
                    })
                }
            </tbody>

        </>

    )
}


function mapStateToProps(store, props) {
    return {
        searchResult: store.client.searchResult ? store.client.searchResult : []
    }
}

export default connect(mapStateToProps, {})(ByLocation);
