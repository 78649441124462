// Vendor Imports
import React from "react";
import { connect } from "react-redux";
import { Helmet } from 'react-helmet';
import { withRouter } from "react-router-dom";
// Local Imports
import LoginForm from "./Form";
import { adminLogin } from "../../../service/admin";
import {
    setAdminUser
} from "../../../reducers/admin/actions";

// Admin Login Page
class AdminLoginComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            backendErrors: null,
            loading: ""
        };
    }

    componentDidMount() {
        if (this.props.adminAuthToken !== null) {
            this.props.history.push("/admin/dashboard");
        }
    }
    // Form Submit
    handleSubmit = values => {
        let params = {
            username: values.email,
            password: values.password
        };
        this.setState({ loading: "loading" });
        adminLogin(params).then(res => this.handleResponse(res));
    };

    // Handling login response
    handleResponse = res => {
        if (res.data.code === 200) {
            this.setState({
                backendErrors: null,
                loading: ""
            });
            this.props.dispatch(setAdminUser(res.data.data));
            this.props.history.push("/admin/dashboard");
        } else {
            this.setState({ backendErrors: res.data.message, loading: "" });
        }
    };

    render() {
        return (
            <>
                <Helmet>
                    <title> PA Admin Log In </title>
                </Helmet>
                <section style={{ marginBottom: '10px' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="admin-login-bg">
                                    <LoginForm
                                        onSubmit={this.handleSubmit}
                                        loading={this.state.loading}
                                        backendErrors={this.state.backendErrors} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );

    }
}

function mapStateToProps(store, props) {
    return {
        adminAuthToken: store.admin.authToken ? store.admin.authToken : null
    }
}

// Export
export default withRouter(connect(mapStateToProps)(AdminLoginComponent));
