import moment from 'moment';
import { createBrowserHistory } from 'history';
import NumberFormat from 'react-number-format';
export const history = createBrowserHistory();


export function timestampToDate(timestampdate) {
	const date = moment(timestampdate);
	return date.format('MMM DD, YYYY');
}

export function dateFormatted(d) {
	return moment(d).format('ll');
}

export function dateToTimeStamp(date) {
	return moment(date).unix()
}

export function renderCount(count) {
	if (typeof count === 'undefined') {
		return 0;
	}
	return (
		<NumberFormat value={count} displayType={'text'} thousandSeparator={true} />
	);
}
export function renderCurrency(count) {
	if (typeof count === 'undefined') {
		return 0;
	}
	return (
		<NumberFormat fixedDecimalScale={true} decimalScale="2" value={count} displayType={'text'} thousandSeparator={true} />
	);
}

export function renderEmailPercent(value1, value2) {
	let val1 = 0;
	let val2 = 0;

	if (typeof value1 !== 'undefined' || value1 === 0) {
		val1 = value1;
	}
	if (typeof value2 !== 'undefined' || value2 === 0) {
		val2 = value2;
	}
	if (val2 === 0) {
		return 0;
	}
	let percent = (parseFloat(val2) / parseFloat(val1)) * 100;
	if (percent > 99 && percent < 100) {
		return percent.toString().slice(0, (percent.toString().indexOf(".")) + (1 + 1));
	}
	if (isNaN(percent)) {
		return 0;
	} else {
		return percent.toFixed(0);
	}

}

export function objectToArray(object) {
	var result = [];
	for (var key in object) {
		if (object.hasOwnProperty(key)) {
			result[key] = object[key];

		}
	}
	return result;
}



export const findIndex = function (array, cb) {
	if (array) {
		for (let i = 0; i < array.length; i++) {
			if (true === cb(array[i]))
				return i;
		}
	}
	return -1;
}


export function loadSavedSearchName(currentSearch, formFields) {
	var pairs = currentSearch.split('&');
	var result = [];
	formFields.map((field, index) => {
		pairs.forEach(function (pair) {
			pair = pair.split('=');

			if (pair[0] === field.name) {
				result.push(field.name);
			}
		})
	});
	var isCustomSearch = 0;
	let applied = [];
	let checked = 0;
	formFields.map((field, index) => {
		if (result.includes(field.name)) {
			field.checked = true;
			applied.push(field);
			checked++;
		} else {
			field.checked = false;
			applied.push(field);
			isCustomSearch = 1;
		}
	});

	return { checkedcount: checked, isCustomSearch: isCustomSearch, appliedFilters: applied }
}

export function loadSavedSearchNameArrayType(currentSearch, formFields) {

	var pairs = currentSearch.split('&');
	var result = [];
	var isCustomSearch = 0;
	formFields.map((field, index) => {
		pairs.forEach(function (pair) {
			pair = pair.split('=');
			if (pair[0] === field.name && pair[1] === field.value) {
				result.push(field.value);
			}
		})
	});

	let applied = [];
	let checked = 0;
	formFields.map((field, index) => {
		if (result.includes(field.value)) {
			field.checked = true;
			applied.push(field);
			checked++;
		} else {
			field.checked = false;
			applied.push(field);
			isCustomSearch = 1;
		}
	});

	return { checkedcount: checked, isCustomSearch: isCustomSearch, appliedFilters: applied }
}

export function getAppliedGovSpendCategory(currentSearch, formFields) {
	var pairs = currentSearch.split('&');
	var result = [];
	formFields.map((field, index) => {
		pairs.forEach(function (pair) {

			pair = pair.split('=');
			if (pair[0] === 'expType' && pair[1] !== "") {
				result = { "category": pair[0], "subcategory": pair[1] };
			}
			else if (pair[0] === field) {
				result = { "category": field, "subcategory": pair[1] };
			}
		})
	});

	return result;
}


export function getAppliedGovFTECategory(currentSearch, formFields) {
	var pairs = currentSearch.split('&');
	var result = [];
	formFields.map((field, index) => {
		pairs.forEach(function (pair) {
			pair = pair.split('=');
			if (pair[0] === 'totalFTE') {
				result = { "category": pair[0], "subcategory": pair[1] };
			}
			else if (pair[0] === field) {
				result = { "category": field, "subcategory": pair[1] };
			}
		})
	});

	return result;
}
export function getAppliedZipcode(currentSearch, formFields) {
	var pairs = currentSearch.split('&');
	var result = [];
	var isCustomSearch = 0;
	formFields.map((field, index) => {
		pairs.forEach(function (pair) {
			pair = pair.split('=');
			if (pair[0] === field) {
				result = { "zipcode": pair[1] };
			}
		})
	});

	return result;
}

export function getAppliedTitle(currentSearch, formFields) {
	var pairs = currentSearch.split('&');
	var result = [];
	var isCustomSearch = 0;
	formFields.map((field, index) => {
		pairs.forEach(function (pair) {
			pair = pair.split('=');
			if (pair[0] === field) {
				result = { "bytitle": pair[1] };
			}
		})
	});

	return result;
}

export function getAppliedName(currentSearch, formFields) {
	var pairs = currentSearch.split('&');
	var result = [];
	var isCustomSearch = 0;
	formFields.map((field, index) => {
		pairs.forEach(function (pair) {
			pair = pair.split('=');
			if (pair[0] === field) {
				result = { "byname": pair[1] };
			}
		})
	});

	return result;
}

export function getAppliedRadius(currentSearch, formFields) {
	var pairs = currentSearch.split('&');
	var result = [];
	var isCustomSearch = 0;
	formFields.map((field, index) => {
		pairs.forEach(function (pair) {
			pair = pair.split('=');
			if (pair[0] === field) {
				result = { "radius": pair[1] };
			}
		})
	});

	return result;
}

export function getAppliedPopulation(currentSearch) {
	var pairs = currentSearch.split('&');
	var result = [];
	var isCustomSearch = 0;

	["populationMoreThan", "populationThan"].map((field, index) => {
		pairs.forEach(function (pair) {
			pair = pair.split('=');
			if (pair[0] === field) {
				result.push(parseInt(pair[1]));
			}
		})
	});

	return result;
}

export function validateSearchFilters(filters) {
	let validation = { proceed: true, div: "" };
	if (filters.governmentRoles === 0) {

		validation = { proceed: false, div: "governmentRoles" };
	}
	else if (filters.locations === 0) {

		validation = { proceed: false, div: "locationByState" };
	}

	else if (filters.governmentType === 0) {

		validation = { proceed: false, div: "governmentType" };
	} else if (filters.governmentSpend === 0) {

		validation = { proceed: false, div: "governmentSpend" };
	}
	else if (filters.governmentFTE === 0) {

		validation = { proceed: false, div: "governmentFTE" };
	}
	else if (filters.fiscalYearEnding === 0) {

		validation = { proceed: false, div: "fiscalFilter" };
	}
	else if (filters.monthOfEelections === 0) {

		validation = { proceed: false, div: "monthOfElection" };
	}

	if (filters.locations === 0 && (filters.radius === "" || filters.zipcode === "" || filters.zipcode === null || filters.zipcode.length !== 5)) {
		validation = { proceed: false, div: "locationByState" };
	} else if (filters.locations === 0 && (filters.radius !== "" && filters.zipcode !== "")) {
		validation = { proceed: true, div: "" };
	}
	return validation;
}