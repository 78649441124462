import {
    SET_DMS_USER,
    REMOVE_DMS_USER
} from "./constants";

export function setDmsUser(payload) {
    return {
        type: SET_DMS_USER,
        payload
    };
}

export function removeDmsUser() {
    return {
        type: REMOVE_DMS_USER
    };
}