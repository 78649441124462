import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import { loadSavedSearchName,getAppliedTitle } from '../../../../utils';
import { setAppliedFilters,setValidateFilters } from '../../../../reducers/client/actions';

// Government type filter component
function Governmenttitle(props) {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = React.useState('');
  const handleChangeAccordian = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const [title, setTitle] = React.useState('');



  let rolesFilter = useSelector(store => store.client.validFilters);
  
  let [PreviousParams, setPreviousParams] = React.useState({});

  // set initial checked inputs or loaded saved search parameters
  useEffect(() => {
  if (Object.keys(props.currentSearch).length !== 0 && PreviousParams !== props.currentSearch) {
    let loadParams = "";
    let loadedTitle='';
    if (props.currentSearch.id !== "0" || props.currentSearch.id !== "") {
      loadParams = props.currentSearch.search_params;
    } else {
      loadParams = props.currentSearch.params;
    }
    loadedTitle = getAppliedTitle(loadParams, ['bytitle']);
    if(loadedTitle.bytitle!=''){
      setExpanded('govTitle');
      setTitle(loadedTitle.bytitle);
    } else {
      setExpanded('');
      setTitle('');
    }
  }
  },[props.currentSearch]);
  //function to capture changed inputs and trigger search api
  const handleChange = (event) => {

    let value = event.target.value;
    setTitle(value);

    let  allSearchFilterdzf = {
      rolesf: rolesFilter.rolesf,
      statesf: rolesFilter.statesf,
      gtypef: rolesFilter.gtypef,
      gftef: rolesFilter.gftef,
      gspendf: rolesFilter.gspendf,
      fydf: rolesFilter.fydf,
      molf: rolesFilter.molf
    }
    dispatch(setValidateFilters(allSearchFilterdzf));
    props.triggerSearch('', false,allSearchFilterdzf);

  };

  const searchClear = ( value ) => {
    setTitle('');

    if ( value ) {
        let  allSearchFilterdzf = {
        rolesf: rolesFilter.rolesf,
        statesf: rolesFilter.statesf,
        gtypef: rolesFilter.gtypef,
        gftef: rolesFilter.gftef,
        gspendf: rolesFilter.gspendf,
        fydf: rolesFilter.fydf,
        molf: rolesFilter.molf
      }
      dispatch(setValidateFilters(allSearchFilterdzf));
      props.triggerSearch('', false,allSearchFilterdzf);
    }
    
  }
  
 

  return (

    <div id="governmentTitle" className="row">
      <div className="col ">
        <Accordion className="accordianLine" expanded={expanded === 'govTitle'} onChange={handleChangeAccordian('govTitle')}>
          <AccordionSummary

            expandIcon={<ExpandMoreIcon />}
            aria-controls="govTitlea-content"
            id="govTitlea-header"
          >
            <Typography className=""> Search by Title</Typography>
          </AccordionSummary>
          <AccordionDetails>
          <Typography className="w-100">
            <div className="row" style={{width : '100%'}}>
                <div className="col-12">
                  <input placeholder="Search by Title" value={title!=='' ? title : ''} className="form-control " name="bytitle" id="searchbytitle"  onChange={handleChange} />
                  <span className='inputClearBTN' onClick={() => searchClear(title)}>X</span>
                </div>
            </div>
            <p id="zipfocusHelptxt1">This will search the contact's title. Titles can be very unique. We recommend using the Role filter for best results.</p>
          </Typography>
          </AccordionDetails>
        </Accordion>

      </div>
    </div >

  )
}


function mapStateToProps(store, props) {
  return {
    currentSearch: store.client.currentSearch ? store.client.currentSearch : [],
    appliedFilters: store.client.appliedFilters ? store.client.appliedFilters : [],
  }
}

export default connect(mapStateToProps, {})(Governmenttitle);