// Vendor Imports
import React from "react";
import { Route, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
// Local Imports
import DmsLayout from "../../layouts/DmsLayout";

// Client Route
const componentWithLayout = (DmsComponent, props) => {
    if (props.dmsUser) {
        return (
            <DmsLayout>
                <DmsComponent {...props} />
            </DmsLayout>
        );
    } else {
        props.history.push("/dms");
    }
};

class DmsRoute extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    render() {
        const { menu, component: component, ...rest } = this.props;
        return (
            <Route
                {...rest}
                render={() => componentWithLayout(component, this.props)}
            />
        );
    }
}

// Export

function mapStateToProps(store, props) {
    return {
        dmsUser: store.dms.dmsUser ? store.dms.dmsUser : null,
        authToken: store.dms.authToken ? store.dms.authToken : null
    }
}

export default withRouter(connect(mapStateToProps, {})(DmsRoute));
