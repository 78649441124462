// Vendor Imports
import React from "react";
import Swal from 'sweetalert2';
import moment from 'moment';
import { updateSubscription } from "../../../service/admin";

class EditSubscriptionFormComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            backendErrors: null,
            loading: '',
            sub_name: "",
            sub_reserves: "",
            sub_fees: "",
            sub_cost_per_dl: ""
        };
    }

    componentDidMount() {
        this.setState({
            sub_monthly_renewal: this.props.editVal.monthly_renewal,
            sub_name: this.props.editVal.Sub_Name,
            sub_reserves: this.props.editVal.Sub_NumReserves,
            sub_fees: this.props.editVal.Sub_AnnualFees,
            sub_cost_per_dl: this.props.editVal.Sub_CostPerDL
        })
    }


    handleSubmit = () => {

        if (this.state.sub_name === "") {
            Swal.fire({
                text: 'Enter the Subscription Name',
                showCloseButton: true,
                confirmButtonText: "Ok",
                icon: 'warning',
                allowOutsideClick: false
            });
            return;
        }
        if (this.state.sub_reserves === "") {
            Swal.fire({
                text: 'Enter the Credits on Registration',
                showCloseButton: true,
                confirmButtonText: "Ok",
                icon: 'warning',
                allowOutsideClick: false
            });
            return;
        }
        if (this.props.editVal.Sub_Level==="9" && this.state.sub_monthly_renewal === "") {
            Swal.fire({
                text: 'Enter the Subscription Monthly Renewal Credits',
                showCloseButton: true,
                confirmButtonText: "Ok",
                icon: 'warning',
                allowOutsideClick: false
            });
            return;
        }
        if (this.state.sub_fees === "") {
            Swal.fire({
                text: 'Enter the Subscription Fees',
                showCloseButton: true,
                confirmButtonText: "Ok",
                icon: 'warning',
                allowOutsideClick: false
            });
            return;
        }
        if (this.state.sub_cost_per_dl === "") {
            Swal.fire({
                text: 'Enter the Subscription Cost Per DL',
                showCloseButton: true,
                confirmButtonText: "Ok",
                icon: 'warning',
                allowOutsideClick: false
            });
            return;
        }


        let params = {
            subscription_id: this.props.editVal.Subscriptions_ID,
            sub_name: this.state.sub_name,
            sub_numreserves: this.state.sub_reserves,
            sub_annualfees: this.state.sub_fees,
            sub_costperdl: this.state.sub_cost_per_dl,
            sub_monthly_renewal: this.state.sub_monthly_renewal
        }
        this.setState({ loading: "loading" });
        updateSubscription(params).then(res => this.handleUpdateResponse(res));
    }

    // Hanlde edit response
    handleUpdateResponse = res => {
        if (res.data.code === 200) {
            Swal.fire({
                title: 'Subscription Updated',
                showCloseButton: true,
                confirmButtonText: "Ok",
                icon: 'success',
                allowOutsideClick: false
            });
            this.props.refreshData();
            this.closeModal();

        } else {
            this.setState({ backendErrors: res.data.message, loading: "" });
        }
    }

    // Close edit modal
    closeModal = () => {
        this.setState({
            backendErrors: null,
            loading: '',
            sub_name: "",
            sub_reserves: "",
            sub_fees: "",
            sub_cost_per_dl: ""
        });
        this.props.toggle();
    }

    render() {
        let readOnly = false;
        if (this.props.editVal.Sub_Level === "10") {
            readOnly = true;
        }
        
        return (
            <div>
                <div className="mb-3">
                    <label>Subscription Name</label>
                    <input type="text" name="sub_name" id="sub_name" className="form-control" defaultValue={this.state.sub_name}
                        onChange={e => this.setState({
                            sub_name: e.target.value
                        })} />
                </div>

                <div className="mb-3">
                    <label>Credits on Registration</label>
                    <input readOnly={readOnly} type="text" name="sub_reserves" id="sub_reserves" className="form-control" defaultValue={this.state.sub_reserves}
                        onChange={e => this.setState({
                            sub_reserves: e.target.value
                        })} />
                </div>

                {this.props.editVal.Sub_Level=='9' ? 
                <div className="mb-3"><label>Credits on Renewal</label>
                <input type="text" name="sub_monthly_renewal" id="sub_monthly_renewal" className="form-control" defaultValue={this.state.sub_monthly_renewal} onChange={e => this.setState({ sub_monthly_renewal: e.target.value})} />
                </div>
                : ""}
                

                <div className="mb-3">
                    <label>Subscription Annual Fees</label>
                    <input type="text" readOnly={this.props.editVal.Sub_Level === "11" || this.props.editVal.Sub_Level === "9" ? true : ""} name="sub_fees" id="sub_fees" className="form-control" defaultValue={this.state.sub_fees}
                        onChange={e => this.setState({
                            sub_fees: e.target.value
                        })} />
                </div>

                <div className="mb-3">
                    <label>Subscription Cost Per DL</label>
                    <input readOnly={readOnly} type="text" name="sub_cost" id="sub_cost" className="form-control" defaultValue={this.state.sub_cost_per_dl}
                        onChange={e => this.setState({
                            sub_cost_per_dl: e.target.value
                        })} />
                </div>

                <div className="mb-3">
                    <input type="button" className="btn btn-primary" onClick={this.handleSubmit} value="Update"
                    />&nbsp;
                    <button type="button" className="btn btn-secondary" onClick={this.closeModal}>Cancel</button>
                </div>

            </div>
        );
    }
};

// Export
export default EditSubscriptionFormComponent;