import NumberFormat from 'react-number-format';
import { Link } from "react-router-dom";

// Power15 card component
function PowerFifiteen(props) {
  const plan = props.data;
  const currentPlan = props.currentPlan;

  // card action button functionality to perform action subscription either sign up / upgrade
  const CardButton = () => {
    const Sub_Level = plan.Sub_Level;
    const canSignUp = currentPlan === "8" || currentPlan === "9" || currentPlan === "11" ? true : false;
    const isUnAvailable = (currentPlan === "10" || currentPlan === "13") && currentPlan !== "12" ? true : false;
    if (isUnAvailable) {
      return <span className="btn btn-pa btn-danger w-100 rounded text-truncate unavailable" >Unavailable</span>
    }
    else if (canSignUp) {
      return <Link to={"/checkout/subscription/" + plan.Subscriptions_ID} className="btn  w-100 rounded get-started text-white" >
        Sign Up </Link>
    }
    else if (currentPlan === Sub_Level) {
      return <span className="btn currentPlan w-100 text-truncate rounded current-plan"> Current Plan </span>
    }
  }
  return (
    <div className="col-sm-6 col-lg-3 mb-4  p-0">
      <section className="card custom-card  border-top-0  border-left-0">
       
        <div className="card-header custom-card-header record-plan ">
          <h3
            className="m-0 text-center record-title"
            style={{ textTransform: "uppercase" }}
          >
            {plan.Sub_Name} {" "}
          </h3>
          <p className="m-0 text-center ">
          <i><NumberFormat value={plan.Sub_NumReserves} displayType={'text'} thousandSeparator={true} /> Records </i></p>
        </div>
        <div className="card-header pricing">
          <p className="m-0 text-center ">
            <span className="title">{plan.Sub_CostPerDL * 100}&cent; </span><br/>
            <span className="title"> / Record </span>{" "}
            <span className="title-sub">
                <NumberFormat value={plan.Sub_AnnualFees} displayType={'text'} thousandSeparator={true} prefix="$" /> / Year
            </span>
          </p>
        </div>
        <div className="card-body p-0">
          <ul className="list-group list-group-flush et_pb_pricing ">
            <li className="m-0">
            <span>  {plan.Sub_CostPerDL * 100}&cent; for Each Additional Record Over 15,000 </span> 
            </li>
            <li className="m-0">
              <span> Unlimited Users </span>
            </li>
            <li className="m-0">
              <span>
                Free Updates for Downloaded Records
               </span>
            </li>
          </ul>
        </div>
        <div className="card-footer border-top-0 text-center ">
          {CardButton()}
        </div>
      </section>
    </div>

  );
}

export default PowerFifiteen;