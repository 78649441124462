// Vendor Imports
import React from "react";
import { connect } from "react-redux";

// Local Imports
import { ResendActivation } from "../../../service/client";
import loginImage from "../../../images/login.jpg";
import { Link } from "react-router-dom";

// EmailActivation Component
class ResendActivationMail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      message: "",
      code: ""
    };
  }

  componentDidMount() {
    let params = {
      eMail: this.props.computedMatch.params.email
    };
    // Resent activation api call
    ResendActivation(params).then(res => this.handleResponse(res));
  }

  // Response handler
  handleResponse = res => {
    this.setState({
      isLoading: false,
      message: res.data.data.message,
      code: res.data.code
    });
  };

  render() {
    return (
      <div style={{ marginTop: "5%" }}>
        <section className="container login-section forgotpw-block">
          <div className="row login-bg">
            <div className="col-sm-12 col-md-6">
              <img
                src={loginImage}
                alt="Placeholder"
                className="img-responsive"
              />
            </div>
            <div
              className="col-sm-12 col-md-6"
              style={{ paddingTop: "15px", paddingRight: "20px" }}
            >
              {/* <h1>Account Activation</h1> */}
              {this.state.isLoading ? (
                <div
                  className="spinner-border mt-3"
                  style={{ width: "3rem", height: "3rem", marginLeft: "auto" }}
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              ) : this.state.code === 200 ? (
                <div
                  className="alert alert-success mt-3"
                >
                  <h3>Resend Activation Code</h3>
                  <p>An email has been sent to: {this.props.computedMatch.params.email} <br /><br />
                    Please check your email and click on the activation URL to enable your account. (Not there?  Be sure to check your spam/junk folders too.)
                     <br /> <br /> After you activate your account, you can <Link to="/login">log in here</Link>
                  </p>
                </div>
              ) : (
                    <div
                      className="alert alert-danger mt-3"
                      role="alert"
                      dangerouslySetInnerHTML={{
                        __html: this.state.message
                      }}
                    ></div>
                  )}
            </div>
          </div>
        </section>
      </div>
    );
  }
}

// Export
export default connect()(ResendActivationMail);
