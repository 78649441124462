// Vendor Imports
import React from "react";
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

// Local Imports
import Form from "./Form";
import { changePassword } from "../../../../service/client";

// Change Password Component
class ChangePasswordModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backendErrors: null,
      loading: "",
      successMessage: false,
    };
  }

  // Handlers
  handleSubmit = (values) => {
    this.setState({ loading: "loading" });
    changePassword(values).then((res) => this.handleResponse(res));
  };

  handleResponse = (res) => {
    if (res.data.code === 200) {
      this.setState({
        backendErrors: null,
        loading: "",
        successMessage: true,
      });
    } else if (res.data.code === 422) {
      this.setState({ backendErrors: res.data.data.message[0], loading: "" });
    } else {
      this.setState({ backendErrors: res.data.message, loading: "" });
    }
  };

  render() {
    return (
      <div>
        <Modal
          size="lg"
          isOpen={this.props.modal}
          toggle={this.props.toggle}
          backdrop={false}
          centered={true}
          className="modal-dialog"
        >
          <ModalHeader toggle={this.props.toggle}>Change Password</ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col">
                <p className="mb-3">
                  Change your password, make sure to choose something strong and
                  memorable
                </p>
              </div>
            </div>
            {this.state.backendErrors ? (
              <div
                className="alert alert-danger"
                role="alert"
                dangerouslySetInnerHTML={{
                  __html: this.state.backendErrors,
                }}
              ></div>
            ) : null}
            {this.state.successMessage ? (
              <div className="alert alert-success">Password updated</div>
            ) : (
              <Form
                onSubmit={this.handleSubmit}
                loading={this.state.loading}
                toggle={this.props.toggle}
              ></Form>
            )}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

// Export
export default connect()(ChangePasswordModal);
