import React, { useEffect } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import { connect, useDispatch, useSelector } from 'react-redux';
import { loadSavedSearchNameArrayType, getAppliedGovFTECategory } from '../../../../utils';
import { setAppliedFilters,setValidateFilters } from '../../../../reducers/client/actions';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
// government FTE filter component
function FteToolTip(){
  const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
      color: theme.palette.common.black,
    },
    tooltip: {
      backgroundColor: theme.palette.common.black,
      fontSize: "1rem"
    },
  }));
  const classes = useStylesBootstrap();
  return (
    <Tooltip  classes={classes} placement="top" arrow title="Based on US Census Bureau Data for 2007 fiscal year released in 2009.">
      <sup><i className=' fa fa-info-circle'> </i>    </sup>
    </Tooltip>
  )
}
function GovernmentFTE(props) {

  let [expanded, setExpanded] = React.useState('');
  let dispatch = useDispatch();
  let [category, setCategory] = React.useState('');
  let [subcategory, setSubcategory] = React.useState({});
  //categorie of FTE filter
  const categories = ["ftePS", "ftePW", "fteH", "fteU", "fteT", "fteL", "fteF", "fteM"];
  let rolesFilter = useSelector(store => store.client.validFilters)

  // government FTE filter and values
  let formFields = [
    { checked: true, label: "Largest Employers (4th Quartile)", name: "FTEQuartileSel[]", value: "4" },
    { checked: true, label: "High-Medium (3rd Quartile)", name: "FTEQuartileSel[]", value: "3" },
    { checked: true, label: "Low-Medium (2nd Quartile)", name: "FTEQuartileSel[]", value: "2" },
    { checked: true, label: "Smallest Employers (1st Quartile)", name: "FTEQuartileSel[]", value: "1" },
    { checked: true, label: "No employees or data not avlb", name: "FTEQuartileSel[]", value: "5" }
  ];


  let [CheckedCount, setCheckedCount] = React.useState(formFields.length);

  let [Fields, setCheckedFields] = React.useState(formFields)

  let [PreviousParams, setPreviousParams] = React.useState('');
  // set initial checked inputs or loaded saved search parameters
  useEffect(() => {

    if (Object.keys(props.currentSearch).length !== 0 && PreviousParams !== props.currentSearch) {
      setPreviousParams(props.currentSearch);
      let loadParams = "";
      let loadSavedSearch = [];
      let appliedCategories = {};
      setCategory('');
      setSubcategory('');
      if (props.currentSearch.id !== "0" || props.currentSearch.id !== "") {
        loadParams = props.currentSearch.search_params;
        loadSavedSearch = loadSavedSearchNameArrayType(loadParams, formFields);
        appliedCategories = getAppliedGovFTECategory(loadParams, categories);
      } else if (props.currentSearch.id === "0" || props.currentSearch.id === "") {
        loadParams = props.currentSearch.params;
        loadSavedSearch = loadSavedSearchNameArrayType(loadParams, formFields);
        appliedCategories = getAppliedGovFTECategory(loadParams, categories);
      }

      if (Fields !== loadSavedSearch && appliedCategories.length !== 0) {
        if (appliedCategories.category === "totalFTE" && appliedCategories.subcategory === "1") {
          setCategory(appliedCategories.subcategory);
          setSubcategory(appliedCategories.subcategory);
        } else {
          setCategory(appliedCategories.category);
          setSubcategory(appliedCategories.subcategory);
        }
        setExpanded("govFTE");
      }

      if (Fields !== loadSavedSearch && loadSavedSearch.length !== 0) {
        setPreviousParams(props.currentSearch);
        setCheckedFields(loadSavedSearch.appliedFilters);
        setCheckedCount(loadSavedSearch.checkedcount);
        let appliedFilters = props.appliedFilters;
        appliedFilters.governmentFTE = loadSavedSearch.checkedcount;
        //dispatch(setAppliedFilters(appliedFilters));
        if (loadSavedSearch.checkedcount !== 0 && loadSavedSearch.checkedcount !== formFields.length) {
          setExpanded("govFTE");
        } else {
          if (expanded !== "") {

          } else {
            setExpanded("");
          }
        }

      }
    }

  });
  // funcation to capture filter values and trigger search api
  const handleChange = (event) => {
    setSubcategory(event.target.value);
    let allSearchFilterTT = {
        rolesf: rolesFilter.rolesf,
        statesf: rolesFilter.statesf,
        gtypef: rolesFilter.gtypef,
        gftef: true,
        gspendf: rolesFilter.gspendf,
        fydf: rolesFilter.fydf,
        molf: rolesFilter.molf
      }
    props.triggerSearch('','',allSearchFilterTT);
  };
  // function to capture changed quartile values
  const handleQuartileChange = (event) => {
    let MinimumChecked = 0;
    let uncheckedInputs = [];
    Fields.map((field, index) => {
      if (event.target.value === field.value) {
        field.checked = event.target.checked;
      }
      if (field.checked === true) {
        MinimumChecked++;
      }
      uncheckedInputs.push(field);
    })
    let  allSearchFilter = {};
    if(MinimumChecked===0)
    {
      allSearchFilter = {
        rolesf: rolesFilter.rolesf,
        statesf: rolesFilter.statesf,
        gtypef: rolesFilter.gtypef,
        gftef: false,
        gspendf: rolesFilter.gspendf,
        fydf: rolesFilter.fydf,
        molf: rolesFilter.molf
      }
      dispatch(setValidateFilters(allSearchFilter));
    } else {
      allSearchFilter = {
        rolesf: rolesFilter.rolesf,
        statesf: rolesFilter.statesf,
        gtypef: rolesFilter.gtypef,
        gftef: true,
        gspendf: rolesFilter.gspendf,
        fydf: rolesFilter.fydf,
        molf: rolesFilter.molf
      }
      dispatch(setValidateFilters(allSearchFilter));
    }
    setCheckedFields(uncheckedInputs);
    setCheckedCount(MinimumChecked);
    let appliedFilters = props.appliedFilters;
    appliedFilters.governmentFTE = MinimumChecked;
    dispatch(setAppliedFilters(appliedFilters));
    props.triggerSearch(uncheckedInputs, true, allSearchFilter);
  };

  // function to handle parent categories
  const handleParentCategoryChange = (event) => {
    setCategory(event.target.value);
    let allSearchFilterT = {
        rolesf: rolesFilter.rolesf,
        statesf: rolesFilter.statesf,
        gtypef: rolesFilter.gtypef,
        gftef: true,
        gspendf: rolesFilter.gspendf,
        fydf: rolesFilter.fydf,
        molf: rolesFilter.molf
      }
    props.triggerSearch('', false, allSearchFilterT);
  };

  // function set opened or closed state of filter
  const handleChangeAccordian = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (

    <div id="governmentFTE" className="row">
      <div className="col ">
        <Accordion className="accordianLine" expanded={expanded === 'govFTE'} onChange={handleChangeAccordian('govFTE')}>
          <AccordionSummary

            expandIcon={<ExpandMoreIcon />}
            aria-controls="govFTEa-content"
            id="govFTEa-header"
          >
            <Typography className=""> Government FTEs <FteToolTip/></Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="row">
              {CheckedCount === 0 ?
                <div className="text-danger text-center mb-2 text-small w-100" >Select 1 or more options</div>
                :
                ""
              }

              {(category === "1" || category === "") ? <input type="hidden" name="totalFTE" value="1" /> : ""}


              {
                Fields.map((field, index) => {
                  return (
                    <div key={index} className="col-12">
                      <FormGroup>
                        <FormControlLabel
                          className="form-check-label  roleLabel"
                          control={<Checkbox checked={field.checked} className="roleCheckbox" onChange={handleQuartileChange} name={field.name} value={field.value} />}
                          label={<Typography className="text-small" dangerouslySetInnerHTML={{ __html: field.label }} />}
                        />
                      </FormGroup>
                    </div>
                  );

                })
              }

              <div className="col-12">
                <div className="form-group form-inline">
                  <label htmlFor="FTERangeCategory" className="text-small ">On</label>

                  <select value={category} onChange={handleParentCategoryChange} id="FTERangeCategory" className="form-control col-10 ml-3">
                    <option value="1" >Total FTEs</option>
                    <option value="ftePS" >Public Safety</option>
                    <option value="ftePW" >Public Welfare</option>
                    <option value="fteH" >Health</option>
                    <option value="fteU" >Utilities</option>
                    <option value="fteT" >Transportation</option>
                    <option value="fteL" >Leisure</option>
                    <option value="fteF" >Administration</option>
                    <option value="fteM" >Miscellaneous</option>
                  </select>

                </div>
                <div className="form-group" id="FTERangeSubCategories">

                  {category === "ftePS" ?
                    <select value={subcategory} onChange={handleChange} name="ftePS" id="ftePS" className="form-control">
                      <option value="All">Total Public Safety</option>
                      <option value="Corrections">Corrections</option>
                      <option value="FireFighters">Firefighters</option>
                      <option value="OtherFireProtection">Other Fire Protection</option>
                      <option value="JudicialandLegal">Judicial and Legal</option>
                      <option value="PolicePersonsWithPowerofArrest">Police Persons with Power of Arrest</option>
                      <option value="OtherPolice">Other Police</option>
                    </select>
                    :
                    ""
                  }

                  {category === "ftePW" ?

                    <select value={subcategory} onChange={handleChange} name="ftePW" id="ftePW" className=" form-control">
                      <option value="All">Total Public Welfare</option>
                      <option value="PublicWelfareServices">Public Welfare Services</option>
                      <option value="SocialInsuranceAdministration">Social Insurance Administration</option>
                    </select>
                    :
                    ""
                  }

                  {category == "fteH" ?

                    <select value={subcategory} onChange={handleChange} name="fteH" id="fteH" className=" form-control">
                      <option value="All">Total Health</option>
                      <option value="HealthServices">Health Services</option>
                      <option value="Hospitals">Hospitals</option>
                    </select>
                    :
                    ""
                  }

                  {category === "fteU" ?
                    <select value={subcategory} onChange={handleChange} name="fteU" id="fteU" className=" form-control">
                      <option value="All">Total Utilities</option>
                      <option value="Electricity">Electricity</option>
                      <option value="Gas">Gas</option>
                      <option value="Sewerage">Sewerage</option>
                      <option value="SolidWasteManagement">Solid Waste Management</option>
                      <option value="Water">Water</option>
                    </select>
                    :
                    ""
                  }

                  {category === "fteT" ?
                    <select value={subcategory} onChange={handleChange} name="fteT" id="fteT" className=" form-control">
                      <option value="All">Total Transportation</option>
                      <option value="Airports">Airports</option>
                      <option value="PublicTransit">Public Transit</option>
                      <option value="RoadsandHighways">Roads and Highways</option>
                      <option value="SeaandInlandPortFacilities ">Sea and Inland Port Facilities </option>
                    </select>
                    :
                    ""
                  }

                  {category === "fteL" ?
                    <select value={subcategory} onChange={handleChange} name="fteL" id="fteL" className=" form-control">
                      <option value="All">Total Leisure</option>
                      <option value="Libraries">Libraries</option>
                      <option value="ParksRecreation">Parks and Recreation</option>
                    </select>
                    :
                    ""
                  }

                  {category === "fteF" ?
                    <select value={subcategory} onChange={handleChange} name="fteF" id="fteF" className=" form-control">
                      <option value="All">Total Administration</option>
                      <option value="FinancialAdministration">Financial Administration</option>
                      <option value="OtherGovernmentAdministration">Other Government Administration</option>
                    </select>
                    :
                    ""
                  }

                  {category === "fteM" ?
                    <select value={subcategory} onChange={handleChange} name="fteM" id="fteM" className=" form-control">
                      <option value="Education">Education</option>
                      <option value="HousingCommunityDevelopment">Housing & Community Development</option>
                      <option value="NaturalResources">Natural Resources</option>
                      <option value="StateLiquorStores">State Liquor Stores</option>
                      <option value="Other">Other</option>
                    </select>

                    :
                    ""
                  }




                </div>
              </div>

            </div>
          </AccordionDetails>
        </Accordion>

      </div>
    </div >

  )
}


function mapStateToProps(store, props) {
  return {
    currentSearch: store.client.currentSearch ? store.client.currentSearch : [],
    appliedFilters: store.client.appliedFilters ? store.client.appliedFilters : [],
  }
}

export default connect(mapStateToProps, {})(GovernmentFTE);