import React from "react";
import { useDispatch } from "react-redux";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import Button from "@material-ui/core/Button";
import { updateSubUser } from "../../../service/client";
import { refreshUserDetails } from "../../../reducers/client/actions";
import { getMyAccount } from "../../../service/client";

// Edit and Update sub user detail component
export default function ManageSubUserModal(props) {
  const { toggle, modal } = props;

  let User_Subscription = props.data.User_Subscription;
  const dispatch = useDispatch();
  const maxLimit = props.myaccount.User_DL_Reserves;
  const [state, setState] = React.useState({});
  const [loading, setLoading] = React.useState("");
  const [response, setResponse] = React.useState("");
  const [validation, setValidation] = React.useState("");
  // subuser edit and validation function
  const validate = () => {
    if (User_Subscription !== 10) {
      if (state.dlLimits === "") {
        setValidation(" You must assign a credit limit. It may be “0” ");
        return false;
      } else if (state.dlLimits > maxLimit) {
        setValidation(
          " You dont have  " + state.dlLimits + "  credit in your account"
        );
        return false;
      }
    }

    return true;
  };

  // function to call subuser update api
  const handleSubmit = e => {
    e.preventDefault();
    if (!validate()) {
      return false;
    }
    setLoading(" loading");
    let params = {
      eMail: props.data.User_Email,
      credit_type: state.credit_type,
      firstName: state.firstName ? state.firstName : props.data.User_FirstName,
      lastName: state.lastName ? state.lastName : props.data.User_LastName,
      newDLLimits: state.newDLLimits
    };

    updateSubUser(params).then(res => setResponse(res.data));
  };
  // function capture changes in edit subuser form
  const handleChange = event => {

    let oldState = state;
    oldState[event.target.name] = event.target.value;
    setState(oldState);
  };
  // clear validation and error message and refreshing my account page
  const clearStates = () => {

    if (response !== "" && response.code === 200) {
      setState({});
      setLoading("");
      setResponse("");
      getMyAccount().then(res =>
        dispatch(refreshUserDetails(res.data.data))
      );
      props.toggle(false);
    }
    if (response !== "") {
      setLoading("");
      setResponse("");
    }
  };
  setTimeout(() => clearStates(), 3000);

  return (
    <div>
      <Modal
        size="lg"
        isOpen={modal}
        toggle={() => toggle(false)}
        backdrop={false}
        className="modal-dialog"
      >
        <ModalHeader toggle={() => toggle(false)}>Manage Sub-User</ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit}>
            <p>Sub User email addresses cannot be changed</p>

            {response !== "" && response.code === 200 ? (
              <div className="alert alert-success">
                Sub user has been updated!!
              </div>
            ) : (
              ""
            )}
            {validation !== "" ? (
              <div className="alert alert-danger"> {validation}</div>
            ) : (
              ""
            )}

            {response !== "" && response.code === 400 ? (
              <div className="alert alert-danger">{response.message}</div>
            ) : (
              ""
            )}

            <div className=" row">
              <div className="col">
                <div className="form-group">
                  <input
                    required="required"
                    onChange={handleChange}
                    defaultValue={props.data.User_FirstName}
                    type="text"
                    name="firstName"
                    id="subUserFirstName"
                    placeholder="First Name"
                    className="form-control"
                    maxLength="255"
                  />
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <input
                    defaultValue={props.data.User_LastName}
                    required="required"
                    onChange={handleChange}
                    type="text"
                    name="lastName"
                    id="subUserLastName"
                    className="form-control"
                    placeholder="Last Name"
                    maxLength="255"
                  />
                </div>
              </div>
            </div>
            <div className=" row">
              <div className="col col-md-6">
                <div className="form-group">
                  <input
                    defaultValue={props.data.User_Email}
                    required="required"
                    onChange={handleChange}
                    type="email"
                    name="eMail"
                    readOnly="readonly"
                    id="subUserEmail"
                    className="form-control"
                    maxLength="255"
                    placeholder="Email Address"
                  />
                </div>
              </div>
              {User_Subscription !== "10" ? (
                <div className="col col-md-6">
                  <div className="form-group">
                    <input
                      defaultValue={props.data.User_DL_Reserves}
                      maxLength={maxLimit}
                      required="required"
                      onChange={handleChange}
                      type="number"
                      placeholder="Credit Limit"
                      name="dlLimits"
                      readOnly="readonly"
                      id="subUserCreditLimit"
                      className="form-control"
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            {User_Subscription !== "10" ? (
              <div className="hide-on-success row">
                <div className="col col-md-6">
                  <div className="form-group">
                    <select
                      onChange={handleChange}
                      required="required"
                      className="form-control"
                      name="credit_type"
                      id="credit_type"
                    >
                      <option value="">Select One</option>
                      <option value="CR">Add Credits</option>
                      <option value="DR">Subtract Credits</option>
                    </select>
                  </div>
                </div>
                <div className="col">
                  <div className="form-group">
                    <input
                      onChange={handleChange}
                      required="required"
                      type="number"
                      name="newDLLimits"
                      placeholder="# of credits to add or subtract"
                      id="manageSubUserCreditLimit"
                      className="form-control"
                      maxlength="15"
                    />
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            <div className=" row">
              <div className="col text-center">
                <div className="manage-sub-btn">
                  <Button variant="contained" type="submit" className={"btn btn-pa" + loading}>
                    Submit
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => toggle(false)}
                    className="backbtn close-modal"
                  >
                    Back
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
}
