// Vendor Imports
import React from "react";
import { connect } from "react-redux";

// Local Imports
import { getMyActiveTasks } from "../../../../service/dms";

// My Active Tasks Table
class MyActiveTaskTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tableDetails: null
        };
    }

    componentDidMount() {
        // Api call to get data
        getMyActiveTasks().then(res => this.handleResponse(res));
    }

    // Response Handler
    handleResponse = res => {
        if (res.data.code === 200) {
            this.setState({
                tableDetails: res.data.data
            });
        }
    };


    render() {
        return (
            <div>
                {this.state.tableDetails ? <div>
                    <section className="dmstable mt-3">
                        <div className="table-responsive">
                            <table className="table table-bordered ">
                                <thead>
                                    <tr>
                                        <th scope="col">Active Task Status</th>
                                        <th scope="col">Total # Governments</th>
                                        <th scope="col">% of Total Active</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Voice Agent</td>
                                        <td>{this.state.tableDetails.va_count}</td>
                                        <td>{this.state.tableDetails.va_total}%</td>
                                    </tr>
                                    <tr>
                                        <td>Quality Checker</td>
                                        <td>{this.state.tableDetails.qc_count}</td>
                                        <td>{this.state.tableDetails.qc_total}%</td>
                                    </tr>
                                    <tr>
                                        <td>Final Approver</td>
                                        <td>{this.state.tableDetails.fa_count}</td>
                                        <td>{this.state.tableDetails.fa_total}%</td>
                                    </tr>
                                    <tr>
                                        <td>Done Updating</td>
                                        <td>{this.state.tableDetails.done_count}</td>
                                        <td>{this.state.tableDetails.done_total}%</td>
                                    </tr>
                                    <tr className="mt-1">
                                        <th scope="col">Government Database</th>
                                        <th scope="col">Total # Governments</th>
                                        <th scope="col">% of Total</th>
                                    </tr>
                                    <tr>
                                        <td>Allocated Governments</td>
                                        <td>{this.state.tableDetails.allocated_government_total}</td>
                                        <td>{this.state.tableDetails.allocated_government_percent}%</td>
                                    </tr>
                                    <tr>
                                        <td>UnAllocated Governments</td>
                                        <td>{this.state.tableDetails.unallocated_government_total}</td>
                                        <td>{this.state.tableDetails.unallocated_government_percent}%</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </section>
                    <section className="dmstable mt-3">
                        <div className="row voicetitle">
                            <div className="col-md-12"><h2>Voice Agents Snapshot</h2></div>

                        </div>
                        <div className="table-responsive">
                            <table className="table table-bordered ">
                                <thead>
                                    <tr>
                                        <th scope="col">Voice Agent</th>
                                        <th scope="col"># of Governments in VA Status</th>
                                        <th scope="col">% of Total in VA Status</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.tableDetails.snap.map((listValue, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{listValue.Voice_Agent}</td>
                                                <td>{listValue.numJobsVAActiveFMT}</td>
                                                <td>{listValue.PCTofTotal}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </section>
                </div> : <div className="text-center">
                        <div
                            className="spinner-border text-primary mt-3"
                            style={{ width: "3rem", height: "3rem", marginLeft: "auto" }}
                            role="status"
                        >

                            <span className="sr-only">Loading...</span>
                        </div></div>}
            </div>
        );
    }
}

// Export
export default connect()(MyActiveTaskTable);
