import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import { loadSavedSearchName } from '../../../../utils';
// refined filter component
function RefinedFilter(props) {
  let tamMode = useSelector(store => store.client.tamMode);
  let IsSearching = useSelector(store => store.client.searchLoading);
  let rolesFilter = useSelector(store => store.client.validFilters);

  const [expanded, setExpanded] = React.useState('');
  const handleChangeAccordian = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  // initial refined filters and values
  let formFields = [
    // { checked: false, label: "Only include records with an EMAIL address", name: "off_email", value: "govtOff_email" },
    { checked: false, label: "Only include records that I've previously downloaded during my current subscription term", name: "purchasedOnly", value: "1" },
    { checked: false, label: "EXCLUDE records that I've previously downloaded during my current subscription term", name: "NotpurchasedOnly", value: "1" },
  ];

  const [Fields, setCheckedFields] = React.useState(formFields);
  let [PreviousParams, setPreviousParams] = React.useState({});

  // set initial or loaded saved search filter parameters
  useEffect(() => {
    if (Object.keys(props.currentSearch).length !== 0 && PreviousParams !== props.currentSearch) {

      let loadParams = "";
      let loadSavedSearch = [];
      if (props.currentSearch.id !== "0" || props.currentSearch.id !== "") {
        loadParams = props.currentSearch.search_params;
        loadSavedSearch = loadSavedSearchName(loadParams, formFields);
      } else if (props.currentSearch.id === "0" || props.currentSearch.id === "") {
        loadParams = props.currentSearch.params;
        loadSavedSearch = loadSavedSearchName(loadParams, formFields);
      }

      if (Fields !== loadSavedSearch && loadSavedSearch.length !== 0) {
        setPreviousParams(props.currentSearch);
        setCheckedFields(loadSavedSearch.appliedFilters);
        if (loadSavedSearch.checkedcount !== 0 && loadSavedSearch.checkedcount !== formFields.length) {
          setExpanded("refineFilter");
        } else {
          if (expanded !== "") {

          } else {
            setExpanded("");
          }
        }
      }
    }
  });
  // useEffect(() => {
  //   if (tamMode && !IsSearching) {
  //     props.triggerSearch('', false);
  //   }
  // }, [tamMode])
  // caputre changes on refined filter and trigger search api
  const handleChange = (event) => {
    let uncheckedInputs = [];
    var name = event.target.name;

    Fields.map((field, index) => {
      if (field.name === name) {
        field.checked = event.target.checked;
      }

      if (name === "NotpurchasedOnly" && field.name === "purchasedOnly") {
        field.checked = false;
      } else if (name === "purchasedOnly" && field.name === "NotpurchasedOnly") {
        field.checked = false;
      }
      uncheckedInputs.push(field);
    });
    setCheckedFields(uncheckedInputs);
    props.triggerSearch('', false, rolesFilter);
  };
  let disableFilter = tamMode ? "disableFilter" : "";

  return (

    <div className={"row " + disableFilter}>
      <div className="col ">
        <Accordion className="accordianLine" expanded={expanded === 'refineFilter'} onChange={handleChangeAccordian('refineFilter')}>
          <AccordionSummary

            expandIcon={<ExpandMoreIcon />}
            aria-controls="refineFiltera-content"
            id="refineFiltera-header"
          >
            <Typography className=""> Refine Results</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="row">

              {
                Fields.map((field, index) => {
                  let ischecked = field.checked

                  if (tamMode) {
                    ischecked = false;
                  }
                  return (
                    <div key={index} className="col-12">
                      <FormGroup>
                        <FormControlLabel
                          className="form-check-label roleLabel"
                          control={<Checkbox checked={ischecked} className="roleCheckbox" onChange={handleChange} id={field.name} name={field.name} value={field.value} />}
                          label={<Typography className="text-small" dangerouslySetInnerHTML={{ __html: field.label }} />}
                        />
                      </FormGroup>
                    </div>
                  );
                })
              }

            </div>
          </AccordionDetails>
        </Accordion>

      </div>
    </div >

  )
}

function mapStateToProps(store, props) {
  return {
    currentSearch: store.client.currentSearch ? store.client.currentSearch : [],
  }
}

export default connect(mapStateToProps, {})(RefinedFilter);
