// Vendor Imports
import React, { useState, useEffect } from "react";
import {  withRouter } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { useDispatch } from "react-redux";
import { setCheckout } from '../../../../reducers/client/actions';
import { getCheckout } from '../../../../service/client';
// Local Imports
import OnlinePayment from '../PaymentMedhod/OnlinePayment'
import OfflinePayment from '../PaymentMedhod/OfflinePayment'
import { renderCount, renderCurrency } from '../../../../utils'

// Checkout Component for purchasing credits only
function CheckoutPurchaseCredit(props) {
  const [InVoiceData, setInVoiceData] = useState([]);
  const dispatch = useDispatch();
  const queryString = props.computedMatch.params;
  useEffect(() => {
      let request_params = { type: "credits", id: queryString.credits };
      getCheckout(request_params).then(res =>
        handleCheckoutAPI(res),
      )
  },[]);
  // function to call checkout api
  const handleCheckoutAPI = (response) => {
    if (response.data.code === 200) {
      dispatch(setCheckout(response.data.data));
      setInVoiceData(response.data.data.invoice);
    }
  }

  return (
    <>
      <Helmet>
        <title> Checkout - Power Almanac </title>
      </Helmet>
      <div >
        <section className="my-search">
          <div className="container">
            <div classNameCheckoutComponent="row">
              <div className="col">
                <h1 className="display-4 text-uppercase">
                  SELECT A PAYMENT OPTION
              </h1>
              </div>
            </div>
          </div>
        </section>

        <div className="container">
          <div className="row pt-5">
            <div className="col-lg-6">

              <table className="table payoption-table">
                <thead>
                  <tr>
                    <th scope="row" className="border-0">Item Ordered</th>
                    <th scope="row" className="border-0">Credits Included</th>
                    <th scope="row" className="border-bottom border-top-0 text-left" >Unit Price</th>
                    <th scope="row" className="border-bottom border-top-0 text-right" >Total Price</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    InVoiceData.length !== 0 ?

                      InVoiceData.purchases.map(function (item, i) {
                        return (<tr key={i}>
                          <td className="border-bottom">
                            {item.name}
                          </td>
                          <td className="border-bottom">
                            {renderCount(item.no_of_records)}
                          </td>
                          <td>{item.unit_price}</td>
                          <td className="text-right">${renderCurrency(item.cost)}</td>
                        </tr>)
                      })
                      :
                      <div className="spinner-grow" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                  }
                  <tr>
                    <td> <strong>Total Purchase </strong></td>
                    <td ><strong>{InVoiceData.totalPurchase ? renderCount(InVoiceData.totalPurchase) : renderCount(InVoiceData.creditsIncluded)} </strong></td>
                    <td> </td>
                    <td className="text-right" >
                      <strong> ${renderCurrency(InVoiceData.totalPrice)}  </strong>
                    </td>
                  </tr>


                </tbody>
              </table>
            </div>


            <div className="col-lg-6 pay-col">
              <OnlinePayment history={props.history} data={InVoiceData} checkoutType="subscription" />
              <OfflinePayment checkoutType="subscription" />
            </div>

          </div>

        </div>
      </div>
    </>
  );

}

export default withRouter(CheckoutPurchaseCredit);
