// Vendor Imports
import React from "react";
import Swal from 'sweetalert2';
import $ from "jquery";

// Local Imports
import { getAllUsers, addBillingTrans } from "../../../service/admin";

// Add Transaction Form
class AddTransactionForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            backendErrors: null,
            loading: '',
            allUsers: null,
            checkNum: "",
            itemPur: "",
            amount: ''
        };
    }

    componentDidMount() {
        // Api call for gettings the users
        getAllUsers().then(res => this.handleResponse(res));
    }

    // Handling response 
    handleResponse = res => {
        if (res.data.code === 200) {
            this.setState({
                allUsers: res.data.data.items,
            });
        }
    };

    // Formatted name for dropdown
    getDropDownName = data => {
        return data.User_FirstName + ", " + data.User_LastName + " <" + data.User_Email + "> #" + data.RegUser_ID;
    }

    // Form submit
    handleSubmit = () => {
        if (this.state.checkNum === '') {
            Swal.fire({
                text: 'Please Enter Cheque / Transaction Number',
                showCloseButton: true,
                confirmButtonText: "Ok",
                icon: 'warning',
                allowOutsideClick: false
            });
            return;
        }
        if (this.state.itemPur === '') {
            Swal.fire({
                text: 'Please Enter Item Purchased',
                showCloseButton: true,
                confirmButtonText: "Ok",
                icon: 'warning',
                allowOutsideClick: false
            });
            return;
        }
        if (this.state.amount === '') {
            Swal.fire({
                text: 'Please Enter Payment Amount',
                showCloseButton: true,
                confirmButtonText: "Ok",
                icon: 'warning',
                allowOutsideClick: false
            });
            return;
        }
        let params = {
            RegUser_ID: $('#reg_user').val(),
            transaction_number: this.state.checkNum,
            payment_method: $("#payment_type").val(),
            item: this.state.itemPur,
            payment_amount: this.state.amount,
            transaction_date: $("#date_time").val()
        }
        this.setState({ loading: "loading" });
        addBillingTrans(params).then(res => this.handleAddResponse(res));
    }

    // Handling add transaction response
    handleAddResponse = res => {
        if (res.data.code === 200) {
            Swal.fire({
                title: 'Transaction Added',
                showCloseButton: true,
                confirmButtonText: "Ok",
                icon: 'success',
                allowOutsideClick: false
            });
            this.props.refreshData();
            this.closeModal();

        } else {
            this.setState({ backendErrors: res.data.message, loading: "" });
        }
    }

    // Closing the popup modal
    closeModal = () => {
        this.setState({
            backendErrors: null,
            loading: '',
            allUsers: null,
            checkNum: "",
            itemPur: "",
            amount: ''
        });
        this.props.toggle();
    }

    render() {
        return (
            <div>
                {this.state.allUsers ? <div>
                    <div className="mb-3">
                        <label>Select USer</label>
                        <select name="reg_user" id="reg_user" className="form-control">
                            {this.state.allUsers.map((data) => {
                                return (
                                    <option value={data.RegUser_ID}>{this.getDropDownName(data)}</option>
                                )
                            })}
                        </select>
                    </div>

                    <div className="mb-3">
                        <label>Cheque / Transaction Number</label>
                        <input
                            name="trans_num"
                            type="text"
                            placeholder="Cheque / Transaction Number"
                            className="form-control"
                            value={this.state.checkNum}
                            onChange={e => this.setState({
                                checkNum: e.target.value
                            })}
                        />
                    </div>

                    <div className="mb-3">
                        <label>Item Purchased</label>
                        <input
                            name="items"
                            type="text"
                            placeholder="Item Purchased"
                            className="form-control"
                            value={this.state.itemPur}
                            onChange={e => this.setState({
                                itemPur: e.target.value
                            })}
                        />
                    </div>

                    <div className="mb-3">
                        <label>Payment Type</label>
                        <select name="payment_type" id="payment_type" className="form-control">
                            <option value="Offline Payment">Offline Payment</option>
                        </select>
                    </div>

                    <div className="mb-3">
                        <label>Payment Amount</label>
                        <input
                            name="pay_amount"
                            type="number"
                            placeholder="Payment Amount"
                            className="form-control"
                            value={this.state.amount}
                            onChange={e => this.setState({
                                amount: e.target.value
                            })}
                        />
                    </div>

                    <div className="mb-3">
                        <label>Date</label>
                        <input type="datetime-local" name="date_time" id="date_time" className="form-control" />
                    </div>

                    {
                        this.state.backendErrors ? (
                            <div
                                className="alert alert-danger"
                                role="alert"
                                dangerouslySetInnerHTML={{
                                    __html: this.state.backendErrors
                                }}
                            ></div>
                        ) : null
                    }

                    <div className="mt-4" style={{ textAlign: "right" }}>
                        <button type="button" className={"btn btn-primary backColor " + this.state.loading} onClick={this.handleSubmit}>Add Transaction</button>&nbsp;
                        <button type="button" className="btn btn-secondary" onClick={this.props.toggle}>Cancel</button>
                    </div>
                </div> : <div className="text-center">
                        <div
                            className="spinner-border text-primary mt-3"
                            style={{ width: "3rem", height: "3rem", marginLeft: "auto" }}
                            role="status"
                        >
                            <span className="sr-only">Loading...</span>
                        </div></div>
                }
            </div>
        );
    }
};


// Export
export default AddTransactionForm;
