const TermsOfServiceContent = (props) => {
  return (
    <div>
      <div className="et_pb_module et_pb_text et_pb_text_1 et_pb_bg_layout_light  et_pb_text_align_left">


        <div className="et_pb_text_inner">
        

<p >Thank you for
using Power Almanac’s websites, search
tools, data, records, and information services (individually and collectively the “<strong>Services</strong>”) which
may be accessed via our website at <a
href="https://poweralmanac.com"> https://poweralmanac.com</a> (the “<strong>Site</strong>”)
or provided to you by Power Almanac or its representatives.
Power Almanac and the Services are owned and operated by Government Executive
Media Group LLC (“<strong>GovExec</strong>”). </p>

<p >These Terms of Service (“<strong>Terms</strong>“) govern your
use of the Services and the Site, and you indicate your agreement to
these Terms by clicking or tapping on a button indicating your acceptance of
these Terms, by executing a document that references them, by signing
up for a Power Almanac subscription plan, or by using the
Services. If you will be using the Services on behalf of an
organization, you agree to these Terms on behalf of that organization and you
represent that you have the authority to do so. In such case, “you” and “your”
will refer to that organization.</p>

<p ><strong>1. License to Use Power
Almanac.</strong></p>

<p >Provided you abide
by these Terms, and satisfy all registration, payment and other
requirements applicable to the subscription level you choose, as described on
the Site and in these Terms, GovExec grants you a
non-exclusive, non-assignable, revocable license (“<strong>License</strong>“) to access
and use the Services during the Subscription Period (as defined
below). If you received the Services from Power Almanac or its
representatives without signing up for a subscription, then the License is
granted for a period of three months starting with the day you receive the
Services, provided that you satisfy all registration, payment and other
requirements applicable to your receiving the Services. The License allows you,
during your Subscription Period only, to download, access,
print, save, store in a searchable database (such as a CRM or marketing
automation system), or otherwise make use of the Services for
your use in conducting research and/or engaging in marketing or other
promotional activities. The License is subject to the limitations set forth
in these Terms.</p>

<p ><strong>2. Subscriptions.</strong></p>

<p >By accepting the License to
use the Services, you agree to pay the fee, if any, associated with the
subscription level you select (examples of subscription levels
are Power Entry, Power 15, Power 75, or Power
Max). The “<strong>Subscription Period</strong>” has
a start date and end date, with the start date being the day you
subscribe, the end date being the last day of
your Subscription Period, and the time from start date until end
date being the length of time associated with the subscription level
you selected (or the length of time agreed to in writing between you
and a GovExec representative). Before the
end of the Subscription Period, you will be notified by email and/or phone and
given the option to renew your subscription. Any
renewal Subscription Period will have its own start date and end
date. Your “<strong>Current
Subscription Period</strong>” refers to
the Subscription Period that you are in at that moment,
and does NOT include any prior or future subscription periods. It is
your responsibility to determine if you would like to use the
Services BEFORE subscribing or renewing your subscription. Once you
have paid a subscription fee, GovExec will not refund
the fee.</p>

<p ><strong>2.1 Credits for Downloading
Records.</strong></p>

<p >A certain number of download
credits (“<strong>Credits</strong>“) may
be included with your subscription. When you download records
from the Site, your Power Almanac account will be deducted one
Credit for each record you download. For Power 15, Power 75, and Power Max
subscriptions, once you download a certain record during your Current
Subscription Period, you may access and re-download that
record as many times as you’d like during the same Current
Subscription Period without using any additional Credits, even if that
record has been updated or changed. If you use all your Credits
during the Subscription Period, you may purchase additional Credits for an
additional fee. Once you have paid for additional Credits, a refund cannot be
given, whether or not you use the Credits. From time to time GovExec may offer certain special subscriptions that
require GovExec to download records into your account
prior to your having access to your account (“<strong>Special Subscription</strong>”). If you
purchase a Special Subscription, then as of the first time you log-in to your
account (i) you will have access to the records that GovExec has pre-downloaded for you, and (ii) you may or may
not have a balance of download Credits.</p>

<p ><strong>2.2 Establishing Sub-User
Accounts.</strong></p>

<p >Your subscription entitles
you to add, at no additional cost, sub-user accounts that are
connected to your account, provided that each
such sub-user account is for use only by individuals who are employed
by the same company or organization employing you (“<strong>Sub-Users</strong>“). Each
Sub-User account must be established with an email address sharing the same
domain name as your email address (for example, if your email
address is abc@pa.local, then someone with the email domain @pa.local may be added as a Sub-User), and that
domain name must be owned and operated by the company or organization that
employs you and the Sub-User. For the purposes of these Terms,
a parent, subsidiary, or other related company is not considered part
of your company or organization. Any individual added as a Sub-User
must read and accept these Terms to use the Services. Any provision or
restriction directed to “you” and “your” under these Terms applies
equally to any Sub-User, except that a Sub-User may not add other sub-users. </p>

<p ><strong>2.3 Expiration
of Subscription Period.</strong></p>

<p >When your Subscription
Period ends, you forfeit any remaining Credits in your account. If you renew
the subscription, you will begin a new Subscription Period (with a
new start date and end date) and at the start of that new (renewal)
Subscription Period your Power Almanac account will have
the number of Credits associated with your subscription level. 
For example, if your subscription level is Power 15, then your account will
have 15,000 Credits at the start of the new Subscription Period.</p>

<p ><strong>2.4. Termination of
Subscription.</strong></p>

<p >GovExec
may, in its sole discretion, terminate or suspend your subscription for any or
no reason and with no prior notice to you; provided, however, if GovExec terminates for a reason other than your misconduct
or breach of these Terms, and you have prepaid for your subscription and/or
additional Credits, GovExec will compensate you on a
pro rata basis for the prepaid amount(s) (based on the number of Credits
you purchased and the number of Credits you have remaining), unless you
have a Power Max subscription, in which case your compensation will
be equal to 1% of your most recently paid subscription fee for each month
remaining in your subscription term. Upon termination of the subscription for
any reason, including non-renewal, the License will likewise
terminate and you and your Sub-Users and anyone else at
your organization with access to the Services shall immediately
cease using the Services, including Power Almanac records
downloaded from the Site or given to you by a Power Almanac
representative. All such Power Almanac records must
also be purged from your databases (including CRM and marketing
automation systems) and from your soft- and hard-copy files within
14 days of termination, except that you may retain and use Power
Almanac records for people who, prior to the termination of
your Power Almanac subscription, have reached out or responded
to you in writing, in person, or by phone (“<strong>Responders</strong>”). 
Responders do NOT include people who have not responded to your
outreach efforts or from whom you received
an “auto-response” email such
as an “out-of-office” or “on vacation” notification. GovExec reserves the right to investigate your use
of the Services either during your Subscription Period or after
termination of your subscription in the event GovExec,
in its sole and absolute discretion, believes you have violated these Terms.</p>

<p ><strong>2.5
Subscription Fee Changes.</strong></p>

<p >GovExec
reserves the right to change at any time the fees it charges
for Power Almanac subscriptions; however, no such change will apply
to a Subscription Period for which you have already paid.</p>

<p ><strong>3. Your Responsibilities.</strong></p>

<p ><strong>3.1 Account Information.</strong></p>

<p >You agree to provide true,
accurate, current, and complete information as prompted by the registration
form, and maintain and update such information to keep it true, accurate,
current, and complete at all times.</p>

<p ><strong>3.2 Password and
Security.</strong></p>

<p >Unless otherwise agreed
to in writing, you will access the Services by logging
into your Power Almanac account at the Site. You are
responsible for maintaining the confidentiality of your Power Almanac password,
and you are solely responsible for all activities that occur with the use of
your password. You agree to immediately notify GovExec
of any unauthorized use of your password or any other breach of security of
which you become aware. GovExec reserves the right to
require you to change your password if GovExec
believes that your password is no longer secure. YOU AGREE THAT GovExec WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE ARISING
FROM YOUR FAILURE TO ADEQUATELY SAFEGUARD YOUR PASSWORD. </p>

<p ><strong>3.3 Compliance with
Applicable Law.</strong></p>

<p >In addition, you agree not
to: (i) use the Services to violate any local, state,
national, or international law or regulation, including by using the Services in
a manner that violates the U.S. CAN-SPAM Act of 2003, as such statute may be
amended from time to time; (ii) transmit any material that is abusive,
harassing, tortious, defamatory, vulgar, pornographic, obscene, libelous,
invasive of another’s privacy, hateful, or racially, ethnically, or otherwise
objectionable; (iii) transmit any material that contains adware, malware,
spyware, software viruses, or any other computer code, files, or programs
designed to interrupt, destroy, or limit the functionality of any computer
software or hardware or telecommunications equipment; (iv) stalk, harass, or
otherwise harm another individual; or (v) impersonate any person, or entity, or
otherwise misrepresent your affiliation with a person or entity.</p>

<p ><strong>4. No Sharing Data with Third Parties.</strong></p>

<p >The Services, including all
Power Almanac records, are for your exclusive use and
benefit. You may not give, share, or otherwise distribute
the Services or any portion of the Services to third
parties, including your organization’s parent company, subsidiaries, or
other companies related to your organization. You may not use any robot,
spider, scraper, deep link or other similar automated data gathering
or extraction tools, program, algorithm or methodology to access, acquire,
copy, monitor, download, store, publish, transmit, retransmit, transfer,
distribute, disseminate, broadcast, circulate, sell or otherwise use
the Services, or any portion of the Services, in any form or by any
means, except as expressly permitted by these Terms. You may not sell, license,
sublicense or distribute all or any portion of the Services to third
parties or use the Services as a component of or as a basis for any
material offered for sale, license or distribution.</p>

<p ><strong>5. Proprietary
Rights.</strong></p>

<p >The format, layout,
appearance and data structures related to the Services and
Site are proprietary. You may not reverse engineer or otherwise attempt to
discern such proprietary architecture. Power Almanac, the Services, and
Site are and will remain the exclusive property of GovExec. Except for the License granted in these Terms, all
rights, title and interest in the Services, in all languages, formats and
media throughout the world, are and will continue to be the exclusive property
of GovExec.</p>

<p ><strong>6. Copyrights.</strong> </p>

<p >The content on the Site (“<strong>Content</strong>“),
including but not limited to text and graphics, is protected under United
States and international copyright laws, is subject to other intellectual
property and proprietary rights and laws, and is owned by GovExec.
The Content may not be copied, modified, reproduced, posted, transmitted, sold,
offered for sale, or redistributed in any way without the prior written
permission of GovExec.</p>

<p ><strong>7. Trademarks.</strong></p>

<p >POWER ALMANAC and the Power
Almanac logo are trademarks of GovExec. Other names
and logos displayed on the Site may be the trademarks of their respective
owners. You may not use, frame or utilize framing techniques to display any GovExec trademarks without the prior written permission of GovExec.</p>

<p ><strong>8. Privacy.</strong>  </p>

<p >Your use of the Services is
subject to the Privacy Policy described at<a
href=" https://www.govexec.com/about/privacy-policy/" target="_blank">  https://www.govexec.com/about/privacy-policy/</a>, the
provisions of which are incorporated into these Terms by reference. By
using the Services, you acknowledge and consent to the Privacy Policy,
including Power Almanac’s collection, use and disclosure of information as
described in the Privacy Policy.</p>

<p ><strong>9. Modification
of these Terms of Service.</strong></p>

<p >GovExec may
modify these Terms at any time for any reason, and the most current
version of these Terms will always be posted on the
Site. If any changes to these
Terms are material, as determined in GovExec’s
sole discretion, then Power Almanac will notify you by
email. Any changes to these Terms will become
effective and binding on you after your first use of the
Services following the change(s). If at any time you find these
Terms unacceptable and do not agree with them, you thereafter will have no
right to use or access the Services.</p>

<p ><strong>10. Modifications to Power
Almanac; Continuity of Service.</strong></p>

<p >GovExec
reserves the right to upgrade, revise, or otherwise modify the
Services and Site with or without notice to you. GovExec
will use reasonable commercial efforts to protect the integrity of data saved
by you and stored in connection with your account on the Site, but GovExec shall not be liable for any loss or damage
resulting from total or partial loss of your data, or from any corruption of
your data. Data can get lost or become corrupt as a result of a number of
causes, including hardware failures, software failures or bugs, or
communications failures. GovExec recommends that you
periodically back up your data to media not associated with GovExec.
GovExec will use reasonable commercial efforts to
provide access to the Services on a 24/7 basis, but it shall not be
responsible for any disruption in the Services, regardless of length.</p>

<p ><strong>11. Disclaimer of
Warranties.</strong></p>

<p >The information in the Power
Almanac databases has been compiled from public records and proprietary sources
for the purpose of providing general background information about local
governments. Neither GovExec nor any of our data
suppliers represents or warrants that the Services are current,
complete or accurate. GovExec HEREBY DISCLAIMS ALL
REPRESENTATIONS AND WARRANTIES REGARDING THE PERFORMANCE OF THE SERVICES AND
THE ACCURACY, CURRENCY, OR COMPLETENESS OF THE Services, INCLUDING
(WITHOUT LIMITATION) ALL WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A
PARTICULAR PURPOSE. ADDITIONALLY, UNDER NO CIRCUMSTANCES SHALL GovExec BE LIABLE TO YOU FOR ANY DAMAGES WHATSOEVER,
INCLUDING (WITHOUT LIMITATION) ANY DIRECT, SPECIAL, INCIDENTAL, EXEMPLARY OR
CONSEQUENTIAL DAMAGES, LOST PROFITS, OR ANY OTHER CLAIMS OF YOURS OR THIRD
PARTIES, EVEN IF GovExec HAS BEEN ADVISED OF THE
POSSIBILITY OF SUCH DAMAGES. You assume all risks associated with the use of
the databases and the Services. </p>

<p ><strong>12. Limitation of Liability.</strong></p>

<p >YOU UNDERSTAND THAT TO THE
EXTENT PERMITTED UNDER APPLICABLE LAW, IN NO EVENT WILL GOVEXEC OR ITS
OFFICERS, EMPLOYEES, DIRECTORS, SHAREHOLDERS, PARENTS, SUBSIDIARIES,
AFFILIATES, AGENTS, OR LICENSORS BE LIABLE UNDER ANY THEORY OF LIABILITY
(WHETHER IN CONTRACT, TORT, STATUTORY, OR OTHERWISE) FOR ANY DIRECT, INDIRECT,
INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING BUT NOT
LIMITED TO DAMAGES FOR LOSS OF REVENUES, PROFITS, GOODWILL, USE, DATA OR OTHER
INTANGIBLE LOSSES (EVEN IF SUCH PARTIES WERE ADVISED OF, KNEW OF OR SHOULD HAVE
KNOWN OF THE POSSIBILITY OF SUCH DAMAGES), RESULTING FROM YOUR USE OF THE
SERVICES.</p>

<p ><strong>13. Indemnification.</strong></p>

<p >You agree to indemnify,
defend, and hold harmless GovExec, its subsidiaries
and related companies, affiliates, officers, directors, employees, consultants
and agents from and against any and all claims, liabilities, damages, losses,
costs, expenses, fees (including reasonable attorneys’ fees) that may arise as
a result of your violation of these Terms. GovExec
reserves the right, at its own expense, to assume the exclusive defense and
control of any matter otherwise subject to indemnification by you, and in such
case, you agree to cooperate with GovExec’s defense
of such claim.</p>

<p ><strong>14. Digital Millenium Copyright Act.</strong></p>

<p >If you believe the Site
contains content that infringes your copyright, please provide the following
information to Power Almanac, Attn: General Counsel, Government Executive Media Group, 600 New Hampshire Avenue NW, Suite 201, Washington, DC 20037, Phone: 202-739-8501, <a
href="mailto:DMCA@govexec.com"><strong>DMCA@govexec.com</strong></a> </p>

<p >• Your postal address,
telephone number, and email address; </p>

<p >• A description of the
copyrighted work that you claim has been infringed;</p>

<p >• A description of
where the alleged infringing material is located;</p>

<p >• A statement by you
that you have a good faith belief that the disputed use is not authorized by
you, the copyright owner, its agent, or the law; </p>

<p >• An electronic or
physical signature of the person authorized to act on behalf of the owner of
the copyright interest; and </p>

<p >• A statement by you,
made under penalty of perjury, that the above information in your notice is
accurate and that you are the copyright owner or authorized to act on the
copyright owner’s behalf.</p>

<p ><strong>15. Other Terms.</strong></p>

<p >These Terms, together with
any additional terms to which you agree when using the Services (for
example, terms relating to the payment of fees for Power Almanac
subscriptions), constitute the entire agreement between you and GovExec with respect to the subject matter hereof, and
govern your use of the Services, superseding any prior agreements or
negotiations between you and GovExec with respect to
the subject matter hereof. These Terms and the relationship between you and GovExec shall be governed by the laws of the State of
California as applied to agreements made, entered into, and performed entirely
in California by California residents, notwithstanding your actual place of
residence. All lawsuits arising from or relating to these Terms or your use of the
Services shall be brought in the Federal or State courts located in San
Francisco County, California, and you hereby irrevocably submit to the
exclusive personal jurisdiction of such courts for such purpose. The failure of
GovExec to exercise or enforce any right or provision
of these Terms shall not constitute a waiver of such right or provision. If any
provision of these Terms is found by a court of competent jurisdiction to be
invalid, you nevertheless agree that the court should endeavor to give effect
to the intentions of GovExec and you as reflected in
the provision, and that the other provisions of these Terms remain in full
force and effect. You agree that regardless of any statute or law to the
contrary, any claim or cause of action arising out of or related to use
of the Services or these Terms must be filed within one (1) year
after such claim or cause of action arose or be forever barred. The section
titles in these Terms are for convenience only and have no legal or contractual
effect. Sections 2.4, 5 – 7 and 11 – 13 of these Terms, as
well as any other limitations on liability explicitly set forth herein, shall
survive the termination or expiration of your Power Almanac subscription.</p>

        </div>
      </div>
    </div>
  );
}

export default TermsOfServiceContent;