// Vendor Imports
import { handleActions } from "redux-actions";

const initialState = {
  currentUser: null,
  errors: undefined
};

export default handleActions(
  {
    SET_CURRENT_USER: (state, action) => ({
      ...state,
      currentUser: action.payload
    }),
    SET_LOGIN_ERRORS: (state, action) => ({
      ...state,
      errors: action.payload
    })
  },
  initialState
);
