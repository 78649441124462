// Vendor Imports
import React from "react";

// Local Imports
import PublicRoute from "./PublicRoute";
import LoginComponent from "../../components/guest/Login";
import ProcessList from "../../components/guest/ProcessList";
import RegistrationComponent from "../../components/guest/Registration";
import ForgotPasswordComponent from "../../components/guest/ForgotPassword";
import ForgotPasswordSuccessComponent from "../../components/guest/ForgotPasswordSuccess";
import EmailActivationComponent from "../../components/guest/EmailActivation";
import resendActivationMail from "../../components/guest/resendActivationMail";
import ResetPasswordComponent from "../../components/guest/ResetPassword";
import RenewSubscriptionComponent from "../../components/guest/RenewSubscription";
import ChangeSubUserPasswordComponent from "../../components/client/ChangeSubUserPassword";
import DmsLogin from "../../components/dms/Login";
import AdminLogin from "../../components/admin/Login";

const RedirectToWordpressApp = () => {
  window.location = 'https://poweralmanac.com';
}
export default (
  <React.Fragment>
    <PublicRoute exact path="/" component={RedirectToWordpressApp} />

    <PublicRoute exact path="/processlist" component={ProcessList} />

    <PublicRoute exact path="/login" component={LoginComponent} />
    <PublicRoute
      exact
      path="/get-my-free-100-leads"
      component={RegistrationComponent}
    />

    <PublicRoute
      exact
      path="/forgot-password"
      component={ForgotPasswordComponent}
    />
    <PublicRoute
      exact
      path="/forgot-password-success"
      component={ForgotPasswordSuccessComponent}
    />
    <PublicRoute
      exact
      path="/reset-password/:id1/:id2/:token"
      component={ResetPasswordComponent}
    />
    <PublicRoute
      exact
      path="/emailactivation/:token/:email"
      component={EmailActivationComponent}
    />
    <PublicRoute
      exact
      path="/resend-activation-mail/:email"
      component={resendActivationMail}
    />
    <PublicRoute
      exact
      path="/renew-subscription/:id"
      component={RenewSubscriptionComponent}
    />
    <PublicRoute
      exact
      path="/change-password"
      component={ChangeSubUserPasswordComponent}
    />
    <PublicRoute
      exact
      path="/dms"
      component={DmsLogin}
    />
    <PublicRoute
      exact
      path="/admin"
      component={AdminLogin}
    />
  </React.Fragment>
);
