// Vendor Imports
import React from "react";
import Swal from 'sweetalert2';
import $ from "jquery";
import moment from 'moment';

// Local Imports
import { addUserSubscriptionHistory } from "../../../../service/admin";

// Add Subscription Form
class AddSubscriptionForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            backendErrors: null,
            loading: '',
            item: "",
            desc: "",
            startDate: "",
            subscriptionDetails: this.props.subData
        };
    }

    // Form Submit
    handleSubmit = () => {
        if (this.state.item === '') {
            Swal.fire({
                text: 'Select the subscription',
                showCloseButton: true,
                confirmButtonText: "Ok",
                icon: 'warning',
                allowOutsideClick: false
            });
            return;
        }
        if ($("#date_start").val() === '') {
            Swal.fire({
                text: 'Select the Startdate',
                showCloseButton: true,
                confirmButtonText: "Ok",
                icon: 'warning',
                allowOutsideClick: false
            });
            return;
        }
        if ($("#date_end").val() === '') {
            Swal.fire({
                text: 'Select the Enddate',
                showCloseButton: true,
                confirmButtonText: "Ok",
                icon: 'warning',
                allowOutsideClick: false
            });
            return;
        }
        if (this.state.desc === '') {
            Swal.fire({
                text: 'Enter the Description',
                showCloseButton: true,
                confirmButtonText: "Ok",
                icon: 'warning',
                allowOutsideClick: false
            });
            return;
        }
        if ($("#date_start").val() > $("#date_end").val()) {
            Swal.fire({
                text: 'End date cannot be earlier than start date',
                showCloseButton: true,
                confirmButtonText: "Ok",
                icon: 'warning',
                allowOutsideClick: false
            });
            return;
        }
        let params = {
            sub_reg_id: this.props.regId,
            subscription: this.state.item,
            subscription_desc: this.state.desc,
            datestart: $("#date_start").val(),
            dateend: $("#date_end").val(),
        }
        this.setState({ loading: "loading" });
        addUserSubscriptionHistory(params).then(res => this.handleAddResponse(res));
    }

    // Handle add response
    handleAddResponse = res => {
        if (res.data.code === 200) {
            Swal.fire({
                title: 'Subscription History Added',
                showCloseButton: true,
                confirmButtonText: "Ok",
                icon: 'success',
                allowOutsideClick: false
            });
            this.props.refreshData();
            this.closeModal();

        } else {
            this.setState({ backendErrors: res.data.message, loading: "" });
        }
    }

    // Close modal
    closeModal = () => {
        this.setState({
            backendErrors: null,
            loading: '',
            item: "",
            desc: "",
            startDate: ""
        });
        this.props.toggle();
    }

    // Get the min date
    getMinDate = date => {
        if (date === "") {
            return moment().format('YYYY-MM-DD');
        } else {
            return moment(date).format('YYYY-MM-DD');
        }
    }

    render() {
        return (
            <div>
                <div className="mb-3">
                    <label>Item</label>
                    <select name="subscription" id="subscription" className="form-control" value={this.state.item}
                        onChange={e => this.setState({
                            item: e.target.value
                        })}>
                        <option value="">Select Plan</option>
                        {this.state.subscriptionDetails.map((listValue, index) => {
                            return (
                                <option value={listValue.Sub_Level}>{listValue.Sub_Name  }   ({listValue.Sub_SKU})</option>
                            );
                        })
                        }
                    </select>
                </div>

                <div className="mb-3">
                    <label>Start Date</label>
                    <input type="date" name="date_start" id="date_start" className="form-control" value={this.state.startDate}
                        onChange={e => this.setState({
                            startDate: e.target.value
                        })} />
                </div>

                <div className="mb-3">
                    <label>End Date</label>
                    <input type="date" name="date_end" id="date_end" className="form-control" min={this.getMinDate(this.state.startDate)} />
                </div>

                <div className="mb-3">
                    <label>Description</label>
                    <textarea className="form-control text-area" id="exampleFormControlTextarea1" rows="3" placeholder="Description" value={this.state.desc}
                        onChange={e => this.setState({
                            desc: e.target.value
                        })}></textarea>
                </div>

                {
                    this.state.backendErrors ? (
                        <div
                            className="alert alert-danger"
                            role="alert"
                            dangerouslySetInnerHTML={{
                                __html: this.state.backendErrors
                            }}
                        ></div>
                    ) : null
                }

                <div className="mt-4" style={{ textAlign: "right" }}>
                    <button type="button" className={"btn btn-primary backColor " + this.state.loading} onClick={this.handleSubmit}>Add</button>&nbsp;
                    <button type="button" className="btn btn-secondary" onClick={this.closeModal}>Cancel</button>
                </div>
            </div>
        );
    }
};


// Export
export default AddSubscriptionForm;
