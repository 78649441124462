import axios from "axios";
import { setError, clearError } from "../../reducers/error/actions";
import { removeDmsUser } from "../../reducers/dms/actions";
import { removeAdminUser } from "../../reducers/admin/actions";
import { SignOff } from '../../reducers/client/actions';
import store from "../../store";
import Swal from 'sweetalert2';

async function getToken(userType) {
  if (userType === 'Client') {
    let client = store.getState().client;
    return client.authToken;
  }
  if (userType === "Dms") {
    let dms = store.getState().dms;
    return dms.authToken;
  }
  if (userType === "Admin") {
    let admin = store.getState().admin;
    return admin.authToken;
  }
}
async function dispatchSetError(error) {
  if (error) {
    let errorResponse = {};
    if (error.message === "Network Error") {
      errorResponse.status = true;
      errorResponse.statusCode = "unknown";
      errorResponse.data = "";
      errorResponse.message = "Network Error";
      errorResponse.statusText = "Network Error";
    } else if (error.response.status >= 400 && error.response.status <= 499) {
      errorResponse.status = true;
      errorResponse.statusCode = error.response.status;
      errorResponse.data = error.response.data;
      errorResponse.type = "warn";
      errorResponse.message = error.response.data.message;
      errorResponse.statusText = error.response.statusText;
    } else if (error.response.status >= 500 && error.response.status <= 599) {
      errorResponse.status = true;
      errorResponse.type = "error";
      errorResponse.statusCode = error.response.status;
      errorResponse.data = error.response.data;
      errorResponse.message = error.response.data.message;
      errorResponse.statusText = error.response.statusText;
    }

    return store.dispatch(
      setError({
        error: errorResponse
      })
    );
  } else {
    return store.dispatch(clearError({}));
  }
}

//  process.env.REACT_APP_API_URL
const getInstance = async (anonymous, userType) => {

  if (anonymous) {
    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_API_URL
    });
    dispatchSetError(null);
    axiosInstance.interceptors.response.use(
      response => response,
      error => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error

        //console.log("unauthorized", error.message);
        if (error.message === "Network Error") {
          dispatchSetError(error);
        } else if (error.response.status === 401) {
          //console.log("unauthorized");
        } else {
          dispatchSetError(error);
        }
        return Promise.reject(error);
      }
    );
    return axiosInstance;
  } else {

    const token = await getToken(userType);

    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: {
        // 'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${token}`
        // Why do you need to SEND this header, I think it is returned by the server
        // Why? 'Access-Control-Allow-Origin': 'http://localhost:4000'
      }
    });
    dispatchSetError(null);
    axiosInstance.interceptors.response.use(
      response => response,
      error => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error

        //console.log("error", error.message);
        if (error.message === "Network Error") {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            text: "ERROR: " + error.response.statusText,
            title: "Network Error",
            showConfirmButton: true
          });
        } else if (error.response.status === 401) {
          Swal.fire({
            iconHtml: '<img width="100" src="/icons/clock.png">',
            customClass: {
              icon: 'no-border'
            },
            text: "",
            title: "Your session has expired due to inactivity.",
            showConfirmButton: true,
            allowOutsideClick: false,
            confirmButtonText: 'Login',
          }).then((result) => {
            if (result.isConfirmed) {
              //console.log("User", userType);
              if (userType === 'Dms') {
                store.dispatch(removeDmsUser());
              }
              else if (userType === 'Admin') {
                store.dispatch(removeAdminUser());
              }
              else if (userType === 'Client') {
                store.dispatch(SignOff());
              }
            }
          })
        } else if (error.response.status === 500 && error.response.config.url.indexOf('stripe') !== -1 ) {
          //console.log("error.response", error.response)
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            text: "ERROR: " + error.response.data.message,
            title: "",
            showConfirmButton: true
          });
        } else {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            text: "ERROR: " + error.response.statusText,
            title: "Oops! Something went to wrong...",
            showConfirmButton: true
          });
        }
        return Promise.reject(error);
      }
    );

    return axiosInstance;
  }

};

export default getInstance;
