// Vendor Imports
import React from "react";
import MaterialTable from "material-table";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Swal from 'sweetalert2'

// Local Imports
import { dialerAdminDash, daDownloadGovernment } from "../../../service/dms";

// Dialer Admin data List
class ListTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tableDetails: null,
            month: null,
            currentPage: 1,
            columns: [
                {
                    title: "Government ID",
                    field: "Government_ID"
                },
                { title: "Government Name", field: "User_Email", render: rowData => rowData.Government_Place_Name + " " + rowData.County_Name },
                { title: "Government Type Name", field: "Government_Type_Name" },
                { title: "Government Type", field: "Government_Type" },
                { title: "Election Month", field: "Election_month", render: rowData => this.getMonthName(rowData.Election_month) },
                { title: "Verification Month", field: "VerificationMonth", render: rowData => this.getMonthName(rowData.VerificationMonth) },
                { title: "Last Confirmed Date", field: "last_verified_date" }
            ],
            loading: ''
        };
    }

    // Get Month Name
    getMonthName = val => {
        const monthNames = ["", "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        return monthNames[val];
    }

    componentDidMount() {
        // Api cal to get list details
        const d = new Date();
        let month = d.getMonth()+1;
        this.setState({month: month});
        dialerAdminDash({ currentPage: 1, perPage: 10000,month: month }).then(res => this.handleResponse(res));
    }

    // Handle api response
    handleResponse = res => {
        if (res.data.code === 200) {
            this.setState({
                loading: '' ,
                tableDetails: res.data.data.dialer
            });
        }
    };

    // Handle Pagination
    paginationChange = (event, page) => {
        this.setState({ currentPage: page });
        dialerAdminDash({ currentPage: page, perPage: 10,month: this.state.month }).then(res => this.handleResponse(res));
    }

    // To get pagination count
    getPaginateCount = () => {
        let mod = this.state.tableDetails.total % 10;
        let div = Math.round(this.state.tableDetails.total / 10);
        let modVal = mod !== 0 ? 1 : 0;
        return div + modVal;
    }

    // Get current month value
    getCurrentMonth = () => {
        const monthNames = ["", "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        const d = new Date();
        return monthNames[this.state.month];
    }


    getDialerData = (month)=>{
      
        this.setState({month: month, loading: 'loading' });
        dialerAdminDash({ currentPage: this.state.currentPage, perPage: 10,month: month }).then(res => this.handleResponse(res));
    }

    // Handle download
    download = () => {
        this.setState({ loading: 'loading' });
        let param = {month: this.state.month};
        daDownloadGovernment(param).then(res => {
            if (res.data.code === 200) {
                this.setState({ loading: '' });
                window.open(res.data.data.path, '_blank');
            } else {
                Swal.fire({
                    text: res.data.message,
                    showCloseButton: true,
                    confirmButtonText: "Ok",
                    icon: 'error',
                    allowOutsideClick: false
                });
                this.setState({ loading: '' });
                return;
            }
        })
    }


    render() {
        return (
            <div>
                {this.state.tableDetails ?
                    <div className="mb-4 mt-3">
                      
                        <div className="mb-4 text-center">
                            <div className="row">
                            <div className="col-6">
                                <select defaultValue={this.state.month} onChange={(e)=>this.getDialerData(e.target.value)} className="form-control"  >
                                    <option  value="1"> January</option>
                                    <option value="2"> February </option>
                                    <option value="3"> March</option>
                                    <option value="4"> April</option>
                                    <option value="5"> May</option>
                                    <option value="6"> June</option>
                                    <option value="7"> July</option>
                                    <option value="8"> August</option>
                                    <option value="9"> September</option>
                                    <option value="10"> October</option>
                                    <option value="11"> November</option>
                                    <option value="12"> December</option>
                                </select> 
                            </div>
                            <div className="col-6">
                                <button className={'btn btn-info backColor ' + this.state.loading} onClick={this.download}>Download {this.state.tableDetails.total} Government IDs in {this.getCurrentMonth()} <img alt="" src="/images/button-download.png" /></button>
                            </div>
                            
                         </div>
                        
                          
                        </div>
                        <MaterialTable
                            className="table display search-table table-responsive"
                            columns={this.state.columns}
                            data={this.state.tableDetails.items}
                            title=""
                            options={{
                                sorting: true,
                                actionsColumnIndex: -1,
                                pageSize: 10,
                                emptyRowsWhenPaging: false
                            }}
                        />
                        {/* <section className="dmstable">
                            <div className="table-responsive">
                                <div className="d-flex justify-content-center mb-2">
                                    <Pagination
                                        count={this.getPaginateCount()}
                                        onChange={this.paginationChange}
                                        showFirstButton={true}
                                        showLastButton={true}
                                        page={this.state.currentPage}
                                    />
                                </div>
                                <table className="table table-bordered ">
                                    <thead>
                                        <tr>
                                            <th scope="col">Government ID</th>
                                            <th scope="col">Government Name</th>
                                            <th scope="col">Government Type Name</th>
                                            <th scope="col">Government Type</th>
                                            <th scope="col">Election Month</th>
                                            <th scope="col">Verification Month</th>
                                            <th scope="col">Last Confirmed Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.tableDetails.items.map((listValue, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{listValue.Government_ID}</td>
                                                    <td>{listValue.Government_Place_Name}({listValue.County_Name})</td>
                                                    <td>{listValue.Government_Type_Name}</td>
                                                    <td>{listValue.Government_Type}</td>
                                                    <td>{listValue.Election_month}</td>
                                                    <td>{listValue.VerificationMonth}</td>
                                                    <td>{listValue.last_verified_date}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                                <div className="d-flex justify-content-center mt-2">
                                    <Pagination
                                        count={this.getPaginateCount()}
                                        onChange={this.paginationChange}
                                        showFirstButton={true}
                                        showLastButton={true}
                                        page={this.state.currentPage}
                                    />
                                </div>
                            </div>
                        </section> */}
                    </div> : <div className="text-center">
                        <div
                            className="spinner-border text-primary mt-3"
                            style={{ width: "3rem", height: "3rem", marginLeft: "auto" }}
                            role="status"
                        >
                            <span className="sr-only">Loading...</span>
                        </div></div>
                }

            </div>
        );
    }
}

// Export
export default withRouter(connect()(ListTable));
