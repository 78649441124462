// Vendor Imports
import React from "react";

// Local Imports
import MaintenRoute from "./MaintenRoute";
import MaintenanceComponent from "../../components/guest/Maintenance";

export default (
    <React.Fragment>
        <MaintenRoute exact path="/under-maintenance" component={MaintenanceComponent} />
    </React.Fragment>
);
