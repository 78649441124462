import React, { useState, useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import { Link, withRouter } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { setTamMode, setAppliedFilters, SignOff, setCurrentSearch, setSearchResult, setBestPricing, setSearchLoading } from '../../reducers/client/actions';
import { doLogOff } from '../../service/client';
import SaveNewSearch from '../../components/client/Search/SaveNewSearch'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';

import DialogTitle from '@material-ui/core/DialogTitle';
import { renderCount } from '../../utils';
import { useIdleTimer } from 'react-idle-timer';
import $ from 'jquery';
import Cookies from 'js-cookie';
function Header(props) {
  useEffect(()=>{
    if(!props.adminToken){
      window.heap.identify(props.myaccount.User_Email);
      window.heap.addUserProperties({Account_ID: props.myaccount.RegUser_ID, Subscription: props.plan.Sub_Name, Email: props.myaccount.User_Email,   FirstName: props.myaccount.User_FirstName, LastName: props.myaccount.User_LastName,Registration_Date: props.myaccount.DateTime_Registered});
    }
  }, []);
  const dispatch = useDispatch();

  let [redirectTo, setRedirectTo] = React.useState('');

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const [saveSearchModal, setSaveSearchModal] = useState(false);
  const toggleSaveSearchModal = () => {
    setSaveSearchModal(!saveSearchModal);
    setDialogOpen(false);
  }
  const [openDialog, setDialogOpen] = React.useState(false);
  const [openlogOffDialog, setLogOffDialogOpen] = React.useState(false);
  const toggleLogOff = () => setLogOffDialogOpen(!openlogOffDialog);

  const handleDialogClickOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {

    setDialogOpen(false);
    if (redirectTo === 'signoff') {
      toggleLogOff();
      if (window.analytics) {
        window.analytics.identify('', { // internal user ID
          // TODO: add more info
          company_name: '',
          subscription_type: '', // power_15 // power_75 // 'power_max' // power_custom
          email: '',
          name: '',
          registration_date:'',
          subscription_start_date:'',
          subscription_end_date:'',
          user_type:'',
          is_internal: false // set to true if this is a test account, Power Alamanc employee etc 
        })
      }
      doLogOff().then(res =>
        dispatch(SignOff({}))
        
      )
      return false;
    }
    if (redirectTo === "/search") {
      //dispatch(setTamMode(false));
      dispatch(setCurrentSearch([]));
      dispatch(setSearchResult([]));
    } else {
      dispatch(setCurrentSearch([]));
      //dispatch(setTamMode(false));
      props.history.push(redirectTo);
    }


  };

  const handleSignOff = () => {
    Cookies.set('notification', false);

    if ((props.history.location.pathname === "/" || props.history.location.pathname === "/search") && Object.keys(props.currentSearch).length !== 0 & props.currentSearch.id === "") {
      handleDialogClickOpen();
      setRedirectTo('signoff');
      return false;
    }
    toggleLogOff();
    doLogOff().then(res => {
      //console.log("console.log", res)
    })
    dispatch(SignOff({}));

  }

  const CheckSearchFilterIsSaved = (to) => {
    if ((props.history.location.pathname !== "/" && props.history.location.pathname !== "/search")) {
      props.history.push(to);
      return false;
    }
    else if ((props.history.location.pathname === "/" || props.history.location.pathname === "/search") && props.currentSearch.id === "") {
      handleDialogClickOpen();
      setRedirectTo(to);
      return false;
    } else {
      //debugger;
      dispatch(setCurrentSearch([]));
      dispatch(setSearchResult([]));
      dispatch(setBestPricing([]));
      dispatch(setAppliedFilters([]));

      props.history.push(to);
    }
  }
  const callNewSearch = () => {

    setRedirectTo('/search');
    if (Object.keys(props.currentSearch).length !== 0 && props.currentSearch.id === "") {
      setDialogOpen(true);
      return false;
    }
    // dispatch(setTamMode(false));
    dispatch(setCurrentSearch([]));
    dispatch(setSearchResult([]));
    dispatch(setBestPricing([]));
    dispatch(setAppliedFilters([]));
    dispatch(setSearchLoading(false));
    setTimeout(function () {
      props.history.push('/search')
    }, 500);
  }
  const handleOnIdle = event => {
    doLogOff().then(res =>
      dispatch(SignOff({}))
    )
  }

  const handleOnAction = event => {
    //console.log('user did something');
   
    if(!props.adminToken){
      window.heap.identify(props.myaccount.User_Email);
      window.heap.addUserProperties({Account_ID: props.myaccount.RegUser_ID, Subscription: props.plan.Sub_Name, Email: props.myaccount.User_Email,   FirstName: props.myaccount.User_FirstName, LastName: props.myaccount.User_LastName,Registration_Date: props.myaccount.DateTime_Registered});
    }
   
    if (!props.authToken) {
      dispatch(SignOff({}));
    }
  }
  const { } = useIdleTimer({
    timeout: 1000 * 60 * 60,
    onIdle: handleOnIdle,
    onAction: handleOnAction,
    debounce: 500
  });


  $(window).bind('resize', function () {
    var $header = $('#header');
    var $footer = $('#footer');
    var h = window.innerHeight;
    h -= ($header.outerHeight() + parseInt($header.css('margin-bottom')));
    h -= ($footer.outerHeight() + parseInt($footer.css('margin-top')));
    $('.wrapper').css('min-height', h + 'px');

  }).trigger('resize');

 
  return (
    <>
      <div>


        <header id="header" className="site-header shadow-sm">
          <Navbar color="white" light expand="lg">
            <NavbarBrand tag={Link} to="/">
              <img src="/images/logo/poweralmanac.svg" alt="Power Almanac" className="logo" width="132" height="63" />
            </NavbarBrand>
            {
              props.plan.Sub_Level !== "11" ?
                <a className="navbar-brand checkunsavedsearch" rel="noreferrer" target="_blank" href="https://poweralmanac.com/refer-a-friend/" >
                  <img className="refbtn " src="/images/referalbtn.png" alt="Power Almanac" />
                </a>
                :
                ""
            }

            <NavbarToggler onClick={toggle} />
            <Collapse isOpen={isOpen} navbar>
              <Nav className="ml-auto" navbar>
                <NavItem className="mt-2">
                  <NavLink onClick={callNewSearch} className="nav-link btn mt-2 my-sm-0 top-btn new-top-btn " >NEW Search</NavLink>
                </NavItem>
                <NavItem>
                  <UncontrolledDropdown className=" profile-object" nav inNavbar>

                    <DropdownToggle className="profile-object" nav caret >
                      <span className="profile-name">{props.myaccount.User_FirstName} {props.myaccount.User_LastName} </span><br />
                      <span className="profile-company">
                        {props.myaccount.User_CompanyName} </span>
                      <br />
                      <span className="profile-subscription ">
                        {props.plan.Sub_Level === "10" ? "Unlimited" : renderCount(props.info.creditsRemaining ? props.info.creditsRemaining : props.myaccount.User_DL_Reserves)} credits | {props.plan.Sub_Name} </span>
                    </DropdownToggle>

                    <DropdownMenu className="header-drop-menu" right>
                    
                    <DropdownItem>
                        <NavLink onClick={(e) => CheckSearchFilterIsSaved("/local-gov-account-file")} className="nav-link btn my-2 my-sm-0  new-top-btn checkunsavedsearch" >
                          Local Government Account File</NavLink>
                      </DropdownItem>

                      <DropdownItem>
                        <NavLink onClick={(e) => CheckSearchFilterIsSaved("/my-saved-searches")} className="nav-link btn my-2 my-sm-0  new-top-btn checkunsavedsearch" >
                          My SAVED Searches</NavLink>
                      </DropdownItem>
                      <DropdownItem>
                        <NavLink onClick={(e) => callNewSearch("/search")} className="nav-link btn my-2 my-sm-0  new-top-btn checkunsavedsearch" >
                          Start NEW Search</NavLink>
                      </DropdownItem>
                      <DropdownItem>
                        <NavLink onClick={(e) => CheckSearchFilterIsSaved("/my-account")} className="nav-link btn my-2 my-sm-0  new-top-btn checkunsavedsearch" >
                          My Account</NavLink>
                      </DropdownItem>

                      {props.info.isParent === true ?
                        <DropdownItem>
                          <NavLink onClick={(e) => CheckSearchFilterIsSaved("/my-transactions")} className="nav-link btn my-2 my-sm-0  new-top-btn checkunsavedsearch" >
                            My Transaction History</NavLink>
                        </DropdownItem>
                        :
                        ""
                      }
                      {props.info.isParent === true && props.plan.Sub_Level !== "10" ?
                        <DropdownItem>
                          <NavLink onClick={(e) => CheckSearchFilterIsSaved("/upgrade-subscription")} className="nav-link btn my-2 my-sm-0  new-top-btn checkunsavedsearch" >
                            Upgrade Subscription</NavLink>
                        </DropdownItem>
                        :
                        ""
                      }
                      <DropdownItem divider />
                      <DropdownItem>
                        <DropdownItem>
                          <NavLink onClick={handleSignOff} className="nav-link btn my-2 my-sm-0  new-top-btn checkunsavedsearch" >
                            Sign Off</NavLink>
                        </DropdownItem>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </NavItem>
              </Nav>
            </Collapse>
          </Navbar >

        </header >
      </div >
      <Dialog
        disableBackdropClick={true}
        open={openDialog}
        disableEscapeKeyDown={true}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Would you like to save the current search?</DialogTitle>

        <DialogActions>
          <Button variant="contained" onClick={toggleSaveSearchModal} color="primary" className="btn confirm-save-btn">
            Yes
          </Button>
          <Button variant="contained" onClick={handleDialogClose} color="secondary" className="btn confirm-cancel" autoFocus>
            No Thanks
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openlogOffDialog}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        onClose={toggleLogOff}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Signing Off.. Please wait
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </DialogTitle>
      </Dialog>


      <SaveNewSearch history={props.history} data={props.searchResult} title="Save this Search" modal={saveSearchModal} redirect={redirectTo} action="save-redirect" toggle={toggleSaveSearchModal} />
    </>
  );
}

function mapStateToProps(store, props) {
  return {
    SearchResult: store.client.searchResult ? store.client.searchResult : [],
    myaccount: store.client.myaccount ? store.client.myaccount : [],
    plan: store.client.plan ? store.client.plan : [],
    info: store.client.info ? store.client.info : [],
    authToken: store.client.authToken ? store.client.authToken : null,
    adminToken: store.admin.authToken ? store.admin.authToken : null,
    currentSearch: store.client.currentSearch ? store.client.currentSearch : [],
  }
}

export default withRouter(connect(mapStateToProps, {})(Header));
