// Vendor Imports
import React from "react";

// Local Imports
import AdminRoute from "./AdminRoute";
import AdminDashboard from "../../components/admin/Dashboard";
import RegisteredAccounts from "../../components/admin/RegisteredAccounts";
import PaypalTransactions from "../../components/admin/PaypalTransactions";
import BillingHistory from "../../components/admin/BillingHistory";
import ActiveAccStats from "../../components/admin/ActiveAccStats";
import UserEdit from "../../components/admin/UserEdit";
import OverAllStats from "../../components/admin/OverAllStats";
import Subscription from "../../components/admin/Subscription";
import APIUsers from "../../components/admin/APIUsers";

export default (
    <React.Fragment>
        <AdminRoute exact path="/admin/dashboard" component={AdminDashboard} />
        <AdminRoute exact path="/admin/registered-accounts" component={RegisteredAccounts} />
        <AdminRoute exact path="/admin/paypal-transactions" component={PaypalTransactions} />
        <AdminRoute exact path="/admin/view-billing-history" component={BillingHistory} />
        <AdminRoute exact path="/admin/active-acc-stats" component={ActiveAccStats} />
        <AdminRoute exact path="/admin/edit-user-details/:userType/:id" component={UserEdit} />
        <AdminRoute exact path="/admin/over-all-stats" component={OverAllStats} />
        <AdminRoute exact path="/admin/subscription" component={Subscription} />
        <AdminRoute exact path="/admin/api_users" component={APIUsers} />
    </React.Fragment>
);
