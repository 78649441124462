// Vendor Imports
import React from "react";
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { withRouter } from "react-router-dom";

// Local Imports
import { qcSendBackVa } from "../../../../../service/dms";
// import Form from "./Form";

// Add va notes modal
class AddNotesModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            backendErrors: null,
            loading: '',
            notes: '',
            error: false
        };
    }

    // On keyup Handler
    onKeyUp = () => {
        this.setState({ error: false });
    }

    // Submit Handler
    handleSubmit = () => {
        if (this.state.notes.trim() === '') {
            this.setState({ error: true });
            return;
        }
        this.setState({ error: false });
        this.setState({ loading: "loading" });
        let params = {
            jid: this.props.jid,
            gid: this.props.gid,
            note: this.state.notes,
        }
        qcSendBackVa(params).then(res => this.handleAddResponse(res));
    }

    // Add response handler
    handleAddResponse = res => {
        if (res.data.code === 200) {
            this.closeModal();
            this.props.history.push("/dms/supervisor/qc-dashboard");
        } else {
            this.setState({ backendErrors: res.data.message, loading: "" });
        }
    }

    // Close modal handler
    closeModal = () => {
        this.setState({
            backendErrors: null,
            loading: '',
            error: false
        });
        this.props.toggle();
    }

    render() {
        return (
            <div>
                {/* Add Modal */}
                < Modal isOpen={this.props.modal} backdrop="static" centered={true}>
                    <ModalHeader toggle={this.closeModal} className="backColor"><h4>Add Notes</h4></ModalHeader>
                    <ModalBody>
                        {this.state.backendErrors ? (
                            <div
                                className="alert alert-danger"
                                role="alert"
                                dangerouslySetInnerHTML={{
                                    __html: this.state.backendErrors
                                }}
                            ></div>
                        ) : null}
                        <div>
                            <div className="form-group">
                                <label htmlFor="notes" style={{ fontSize: "14px" }}>Add Notes to Voice Agent</label>
                                <textarea id="notes" name="notes" rows="4" cols="50" value={this.state.notes} onChange={e => this.setState({
                                    notes: e.target.value
                                })} onKeyUp={this.onKeyUp} />
                                {this.state.error ? <span className="text-danger">Please enter notes</span> : <span></span>}
                            </div>
                            <div className="text-right">
                                <button type="button" className={"btn btn-primary backColor  " + this.state.loading} onClick={this.handleSubmit}>Add Notes</button>&nbsp;
                                <button type="button" className="btn btn-secondary" onClick={this.closeModal}>Cancel</button>
                            </div>
                        </div>
                    </ModalBody>
                </Modal >
            </div>
        );
    }
}

export default withRouter(connect()(AddNotesModal));
