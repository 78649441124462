// Vendor Imports
import React from "react";
import { Helmet } from 'react-helmet';

// Local Imports
import Table from './Table';

// Paypal Transaction
class PayPalTransactionsComponent extends React.Component {

    render() {
        return (
            <>
                <Helmet>
                    <title> PA Admin - PayPal Transactions </title>
                </Helmet>
                <section>
                    <div className="container admin-container">
                        <div className="row">
                            <div className="col-12">
                                <section className="admin-title">
                                    <h1>PayPal Transactions</h1>
                                </section>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <Table />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );

    }
}

// Export
export default PayPalTransactionsComponent;
