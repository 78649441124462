// Vendor Imports
import React, { useState } from "react";
import {
  Navbar,
  NavbarToggler,
  NavbarBrand,
} from 'reactstrap';

// Header Component
function Header(props) {

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div>
      <header id="header" className="site-header shadow-sm">
        <Navbar color="light" light expand="md">
          <NavbarBrand href="https://poweralmanac.com">
            <img
              src="/images/logo/poweralmanac.svg"
              alt="Power Almanac"
              className="logo"
              width="132"
              height="63"
            />
          </NavbarBrand>
          <NavbarToggler onClick={toggle} />
          {/*<Collapse isOpen={isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                {window.location.pathname !== '/dms' ? <NavLink tag={Link} className="nav-link btn my-2 my-sm-0 top-btn" to="/login">Log in</NavLink> : ""}
              </NavItem>
            </Nav>
          </Collapse>*/}
        </Navbar>
      </header>
    </div>
  );
}


export default Header;
