// Vendor Imports
import React, {useState, useEffect} from "react";
import { useDispatch, connect, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
// Local Imports
import { renderCount } from '../../../utils'
import { setMySavedSearches,  refreshUserDetails,
 setCurrentSearch, setSearchResult, setBestPricing,  setCheckout } from '../../../reducers/client/actions';
import { searchCheckout, savedDownloadExpired,
downloadExpired, getMySavedSearches, getMyAccount , getbestPricing } from '../../../service/client';
import AWS from 'aws-sdk';
import s3ParseUrl from 's3-url-parser';

// Download expired existing saved search file. 
function DownloadExpired(props) {

 const [loading, setLoading] = useState('');
 const [openDialog, setDialogOpen] = useState(false);
 const [downloadDetails, setDownloadDetail] = useState('');
 const [noData, setNoData] = useState('');
 const params = props.computedMatch.params;
 const [apicallaback, setApiCallBack] = useState(false);
 const dispatch = useDispatch();
 const currentUser = useSelector((store) => store.client.myaccount);
 const mySavedSearches = useSelector((store) => store.client.mySavedSearches
);
 
 
  // get and set expired download file details
  useEffect(() => {
    if (downloadDetails=== '' && apicallaback===false) {
        savedDownloadExpired(params.id).then(res => {
          handleDownloadDetails(res);
        })
    } 
  });
// function to download expired file
  const handleDownloadDetails = (res) =>{
    setApiCallBack(true);
    let newArr=[];
    let roleData=[];
    let populationData=[];
    let populationDataMore=[];
    let ftestrTotal=[];
    let criteriastrTotal=[];
    let exptypestrTotal=[];
    let allState=[];
    let govTypeData=[];
    let elecMonth=[];
    let fiscalMnth=[];
    let expQrtSel=[];
    let fteQrt=[];
    let zipcodeData=[];
    let offEmailData=[];
    let newOfficialData=[];
    let refinFilterData=[];
    let ftequartileData=[];
    let finSpendData=[];

    let roleValue=[];
    let roleHeadofReplace='';
    let roleHead_ofReplace='';
    let populationValue='';
    let populationValueData='';
    let allStateValue=[];
    let elecMonthValue=[];
    let fiscalMnthValue='';
    let expQrtSelValue=[];
    let fteQrtValue=[];
    let govTypeDataValue=[];
    let contact_zipcode='';
    let contact_zipcode_readius='';
    let contact_refine_result_filter_include='No';
    let contact_refine_result_filter_exclude='No';

    let contact_FTE_category='';
    let contact_FTE_subcategory='';
    let contact_spend_category='';
    let contact_spend_subcategory='';
    let contact_newoffical_filter='No';
    let contact_recordwithemail_filter='No';

    {props.currentSearch.search_params.split("&").map((item, index) => {
      if(item.search('role_'))
      {

        if(item.search('populationMoreThan'))
        {

          if(item.search('populationThan'))
          {

            if(item.search('govLoc_byState'))
            {

              if(item.search('FTEQuartileSel'))
              {

                if(item.search('expenseBudgetQuartileSel'))
                {

                  if(item.search('fiscalYearEnd_Month'))
                  {

                    if(item.search('electionMonth'))
                    {

                      if(item.search('govType_'))
                      {

                        if(item.search('totalFTE'))
                        {

                          if(item.search('expType'))
                          {

                            if(item.search('criteria'))
                            {

                              if(item.search('byZipcode'))
                              {
                                contact_zipcode='NA';
                                contact_zipcode_readius='NA';
                                

                                if(item.search('off_email'))
                                {
                                  if(item.search('purchasedOnly'))
                                  {

                                    if(item.search('OnlyHotLeads'))
                                    {
                                      contact_newoffical_filter='No';
                                      

                                      if(item.search('fte'))
                                      {
                                          contact_FTE_category="";
                                          contact_FTE_subcategory="";
                                          
                                          if(item.search('fin'))
                                          {
                                            contact_spend_category="";;
                                            contact_spend_subcategory=""
                                            
                                          } else {
                                            finSpendData=item.split("=");
                                            if(finSpendData[0]=='finPS')
                                            {
                                              contact_spend_category="Public Safety";
                                              contact_spend_subcategory=finSpendData[1];
                                            }

                                            if(finSpendData[0]=='finPW')
                                            {
                                              contact_spend_category="Public Welfare";
                                              contact_spend_subcategory=finSpendData[1];
                                            }

                                            if(finSpendData[0]=='finH')
                                            {
                                              contact_spend_category="Health";
                                              contact_spend_subcategory=finSpendData[1];
                                            }

                                            if(finSpendData[0]=='finU')
                                            {
                                              contact_spend_category="Utilities";
                                              contact_spend_subcategory=finSpendData[1];
                                            }

                                            if(finSpendData[0]=='finT')
                                            {
                                              contact_spend_category="Transportation";
                                              contact_spend_subcategory=finSpendData[1];
                                            }

                                            if(finSpendData[0]=='finL')
                                            {
                                              contact_spend_category="Leisure";
                                              contact_spend_subcategory=finSpendData[1];
                                            }

                                            if(finSpendData[0]=='finF')
                                            {
                                              contact_spend_category="Finance";
                                              contact_spend_subcategory=finSpendData[1];
                                            }

                                            if(finSpendData[0]=='finM')
                                            {
                                              contact_spend_category="Miscellaneous";
                                              contact_spend_subcategory=finSpendData[1];
                                            }
                                          }


                                      } else {
                                          ftequartileData=item.split("=");
                                          if(ftequartileData[0]=='ftePS')
                                          {
                                            contact_FTE_category="Public Safety";
                                            contact_FTE_subcategory=ftequartileData[1];
                                          }

                                          if(ftequartileData[0]=='fteH')
                                          {
                                            contact_FTE_category="Health";
                                            contact_FTE_subcategory=ftequartileData[1];
                                          }

                                          if(ftequartileData[0]=='ftePW')
                                          {
                                            contact_FTE_category="Public Welfare";
                                            contact_FTE_subcategory=ftequartileData[1];
                                          }

                                          if(ftequartileData[0]=='fteU')
                                          {
                                            contact_FTE_category="Utilities";
                                            contact_FTE_subcategory=ftequartileData[1];
                                          }

                                          if(ftequartileData[0]=='fteT')
                                          {
                                            contact_FTE_category="Transport";
                                            contact_FTE_subcategory=ftequartileData[1];
                                          }

                                          if(ftequartileData[0]=='fteL')
                                          {
                                            contact_FTE_category="Leisure";
                                            contact_FTE_subcategory=ftequartileData[1];
                                          }

                                          if(ftequartileData[0]=='fteF')
                                          {
                                            contact_FTE_category="Administration";
                                            contact_FTE_subcategory=ftequartileData[1];
                                          }

                                          if(ftequartileData[0]=='fteM')
                                          {
                                            contact_FTE_category="Miscellanous";
                                            contact_FTE_subcategory=ftequartileData[1];
                                          }

                                      }

                                    } else {
                                      newOfficialData=item.split("=");
                                      if(newOfficialData[0]=='OnlyHotLeads')
                                      {
                                        contact_newoffical_filter='Yes';
                                      } else {
                                        contact_newoffical_filter = 'No';
                                      }
                                    }

                                  } else {
                                    refinFilterData=item.split("=");
                                    if(refinFilterData[0]=='purchasedOnly')
                                    {
                                      contact_refine_result_filter_include = 'Yes';
                                    } else {
                                      contact_refine_result_filter_include = 'No';
                                    }

                                    if(refinFilterData[0]=='NotpurchasedOnly')
                                    {
                                      contact_refine_result_filter_exclude = 'Yes';
                                    } else {
                                      contact_refine_result_filter_exclude = 'No';
                                    }

                                  }


                                } else {
                                  offEmailData=item.split("=");
                                  if(offEmailData[1]=='govtOff_email')
                                  {
                                    contact_recordwithemail_filter = 'Yes';
                                  } else {
                                    contact_recordwithemail_filter = 'No';
                                  }

                                }


                              } else {

                                zipcodeData=item.split("=");
                                if(zipcodeData[0]=='byZipcode')
                                {
                                  contact_zipcode=zipcodeData[1];
                                }
                                if(zipcodeData[0]=='byZipCodeDistance')
                                {
                                  contact_zipcode_readius=zipcodeData[1];
                                }
                              }

                            } else {
                              criteriastrTotal=item.split("=");
                              //console.log('Criteria =',criteriastrTotal[1]);
                            }


                          } else {
                            exptypestrTotal=item.split("=");
                            if(exptypestrTotal[0]==2)
                            {
                              contact_spend_category="Special Categories";
                              contact_spend_subcategory= "Only Capital Expenditures";
                            }
                            if(exptypestrTotal[0]==3)
                            {
                              contact_spend_category="Special Categories";
                              contact_spend_subcategory= "Only Operating Expenditures";
                            }
                            if(exptypestrTotal[0]==4)
                            {
                              contact_spend_category="Special Categories";
                              contact_spend_subcategory= "Only Salaries & Wages";
                            }
                            if(exptypestrTotal[0]==5)
                            {
                              contact_spend_category="Special Categories";
                              contact_spend_subcategory= "Only Expenditures on Suppliers";
                            }
                          }

                        } else {
                          ftestrTotal=item.split("=");
                          //console.log('Total FTE =',ftestrTotal[1]);
                        }

                      } else {
                        govTypeData=item.split("=");
                        //govTypeDataValue+='"'+govTypeData[1].charAt(0).toUpperCase()+ govTypeData[1].slice(1)+'",';
                        govTypeDataValue.push(govTypeData[1].charAt(0).toUpperCase()+ govTypeData[1].slice(1));
                      }

                    } else {
                      elecMonth=item.split("=");
                      if(elecMonth[1]==13)
                      {
                        elecMonth[1]='Not Avlb';
                      }
                      elecMonthValue.push(elecMonth[1]);
                      
                    }

                  } else {
                    fiscalMnth=item.split("=");
                    if(fiscalMnth[1]=='ot+Known')
                    {
                        fiscalMnth[1]='Not Avlb';
                    }
                    //fiscalMnthValue.push(fiscalMnth[1]);
                    fiscalMnthValue+=fiscalMnth[1]+',';
                    
                  }

                } else {
                  expQrtSel=item.split("=");
                  if(expQrtSel[1]==5)
                  {
                    expQrtSel[1]='Others';
                  }
                  expQrtSelValue.push(expQrtSel[1]);
                  
                }

              } else {
                fteQrt=item.split("=");
                if(fteQrt[1]==5)
                {
                  fteQrt[1]='Others';
                }
                fteQrtValue.push(fteQrt[1]);
                
              }


            } else {
              allState=item.split("=");
              allStateValue.push(allState[1].replaceAll('+', " "));
              //='"'+allState[1].replaceAll('+', " ")+'",';
              
            }

          } else {
            populationData=item.split("=");
          }

        } else {
          populationDataMore=item.split("=");
        }
      } else {
        roleData=item.split("=");
        roleHeadofReplace = roleData[1].replaceAll('Headof', "");
        roleHead_ofReplace = roleHeadofReplace.replaceAll('+', " ");
        if(roleHead_ofReplace=='Zoning'){
          roleHead_ofReplace='Planning/Zoning';
        } 
        if(roleHead_ofReplace=='Head of Finance/Budgeting'){
          roleHead_ofReplace='Finance';
        }  
        if(roleHead_ofReplace=='Head of Purchasing/Procurement'){
          roleHead_ofReplace='Purchasing';
        }  
        if(roleHead_ofReplace=='Head of Fire Protection Services'){
          roleHead_ofReplace="Fire";
        }  
        if(roleHead_ofReplace=='Head Building Official'){
          roleHead_ofReplace="Buildings";
        }  
        if(roleHead_ofReplace=='Clerk'){
          roleHead_ofReplace="Clerk's Office";
        }
        roleValue.push(roleHead_ofReplace.replaceAll('Head of ', ""));
      }
      
    })}
    /*let fteQuartileArrayVal=fteQrtValue.substring(0, fteQrtValue.length-1).split(',').sort((a, b) => a-b);
    let spendQuartileArrayVal=expQrtSelValue.substring(0, expQrtSelValue.length-1).split(',').sort((a, b) => a-b);
    const order = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    const sort = (data) =>data.split(',').sort((a, b) => order.indexOf(a) - order.indexOf(b)).join();
    let electionMonthArrayVal=elecMonthValue.substring(0, elecMonthValue.length-1).split(',').sort((a, b) => a-b);*/
     
    const order = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    const sort1 = (data) =>data.split(',').sort((a, b) => order.indexOf(a) - order.indexOf(b)).join();
    const fiscalData=sort1(fiscalMnthValue.substring(0, fiscalMnthValue.length-1));
    if(res.data.code===200){
        if (window.analytics) {
              window.analytics.track('Update Records Downloaded', {
                search_name:props.currentSearch.name,
                search_id:props.currentSearch.id,
                total: 'yes',
                expire:'yes',
                contact_role : roleValue,
                contact_population_min : populationDataMore[1],
                contact_population_max : populationData[1],
                contact_state : allStateValue,
                contact_zipcode : contact_zipcode,
                contact_zipcode_radius : contact_zipcode_readius,
                contact_government_type : govTypeDataValue,
                contact_FTE_quartile : fteQrtValue,
                contact_FTE_category : contact_FTE_category,
                contact_FTE_subcategory : contact_FTE_subcategory,
                contact_spend_quartile : expQrtSelValue,
                contact_spend_category : contact_spend_category,
                contact_spend_subcategory : contact_spend_subcategory,
                contact_fiscal_ending : fiscalData.split(','),
                contact_election_month : elecMonthValue.sort((a, b) => a-b),
                contact_recordwithemail_filter : contact_recordwithemail_filter,
                contact_newoffical_filter : contact_newoffical_filter,
                contact_refine_result_filter_include : contact_refine_result_filter_include,
                contact_refine_result_filter_exclude : contact_refine_result_filter_exclude,
              });
          }

      setDownloadDetail(res.data.data)
    }
    if(res.data.code===400){
      setNoData(res.data.message);
    }
  }
  //close download process dialog
  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  // function to call download file api
  const handleProceedDownload = () => {
      //return false;
    setLoading('loading');
    var element = document.getElementById("body");
    element.classList.add("downloadloading");
    let params = {
      unpaid: downloadDetails.unpaid,
      id: props.currentSearch.id,
      
    }
    setDialogOpen(true);
    downloadExpired(params).then(res =>
        DownloadFile(res),
    )  
   
  }
  
  // function to save csv file in user local folder
  const DownloadFile = (response) => {
    /*const AWSS3 = new AWS.S3();
    AWS.config.update({
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
        AWS_SDK_LOAD_CONFIG:1,
    });*/
    const AWSS3 = new AWS.S3({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY
    });
    setLoading('');
    var element = document.getElementById("body");
    element.classList.remove("downloadloading");
    if (response.data.code===200) {
      setDialogOpen(false);
      let data = response.data.data;
      //let file = data.file;
      const mainURL = data.file;
      const { bucket, key } = s3ParseUrl(mainURL);
      let dwnUrl = AWSS3.getSignedUrl('getObject',
            {Bucket : bucket, Key : key, Expires : 60 * 5}
      );
      let file = dwnUrl;
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = file;
      a.download = data.file_name;
      document.body.appendChild(a);
      a.click();
      dispatch(setCurrentSearch([])); dispatch(setMySavedSearches(data));
      dispatch(setSearchResult([]));
      getMySavedSearches().then(res =>
          dispatch(setMySavedSearches(res.data.data.savedsearch)),
        )
      props.history.push('/my-saved-searches');
      
    } else {
      setDialogOpen(false);
      alert("Your Download is Not Successful. Please Try Again");
    }
  }
  const handleCheckout = () => {
      let bestParams = {
        checkout_paid_records: downloadDetails.paid,
        checkout_unpaid_records: parseInt(downloadDetails.Total_records_in_this_file),
      }
      let checkoutparams = {
        paid_records: downloadDetails.paid,
        no_ofRecords: parseInt(downloadDetails.Total_records_in_this_file)
      }
      searchCheckout(checkoutparams).then(res =>
        dispatch(setCheckout(res.data.data)),
      )
     
      getbestPricing(bestParams).then(response =>
        handleRedirect(response),
      )
  }
  const handleRedirect = (response) =>{
    getMyAccount().then(res =>
      dispatch(refreshUserDetails(res.data.data))
    );
    dispatch(setBestPricing(response.data.data));
    props.history.push("/checkout/expired/download");
  }
  
    return (
      <>
      <div style={{ marginBottom: "5%" }}>
        <section className="my-search">
          <div className="container">
            <div className="row">
              <div className="col">
                <ul className="breadcrumb">
                  <li>
                    <Link to="/my-saved-searches">My Saved Search</Link>
                  </li>
                  <li>Download Records</li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <div className="container">
          <div className="col-12">
            <div className="panel panel-primary download-trans pt-5">
              <div className="panel-heading account-test text-center">
                DOWNLOAD CONFIRMATION
              </div>
              <div className="panel-body background-grey add-subscribe ">
                <div className="row ">
                
                {
                  downloadDetails!== "" && props.plan.Sub_Level!=="10" ?

                  <div className="col-sm-12 ">
                    <div className="col-sm-6 float-left text-left download-records">
                      <div className="row">
                        <div className="col-md-10 text-right">
                          <b>Total records in this file:</b>
                        </div>
                        <div className="col-md-2 text-right">
                                <b>{renderCount(downloadDetails.Total_records_in_this_file)}</b>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-10 text-right">
                          Total credits required to download the file:
                        </div>
                              <div className="col-md-2 text-right">{renderCount(downloadDetails.Total_credits_required_to_download_the_file)}</div>
                      </div>
                      <div className="row">
                        <div className="col-md-10 text-right">
                          Total credits available PRIOR TO this purchase:
                        </div>
                              <div className="col-md-2 text-right">{renderCount(downloadDetails.Total_credits_available_PRIOR_TO_this_purchase)}</div>
                      </div>
                      <div className="row">
                        <div className="col-md-10 text-right">
                          Credits you must purchase:
                        </div>
                              <div className="col-md-2 text-right">{downloadDetails.Credits_you_must_purchase}</div>
                      </div>

                      <div className="row">
                        <div className="col-md-10 text-right">
                          Credit balance after you purchase and download:
                        </div>
                              <div className="col-md-2 text-right">{downloadDetails.Credit_balance_after_you_purchase_and_download}</div>
                      </div>
                    </div>

                    <div className="col-sm-6 float-left text-left download-records">
                      <p>
                        <strong>
                                {downloadDetails.text}
                        </strong>
                      </p>
                      {
                        downloadDetails && downloadDetails.referrer==="proceed" ?
                        <p>
                         <Button onClick={handleProceedDownload} variant="contained" className={"  btn btn-pa button download-btn " + loading}> 
                       Proceed to Start the Download 
                          
                        <i className="ml-2 fa fa-download" aria-hidden="true"></i>
                        </Button>
                      </p>
                        :
                        
                          downloadDetails && downloadDetails.referrer!=="proceed" ?
                           <p>
                             <strong>
                              The total cost for these additional credits is $ {downloadDetails.cost_of_additional_cost}
                              </strong>
                               <Button onClick={handleCheckout} variant="contained" className={" mt-2 btn btn-pa button download-btn " + loading}> 
                                <strong> PURCHASE {downloadDetails.Credits_you_must_purchase} CREDITS </strong> 
                              </Button>
                            </p>  
                          :
                          ""
                        
                                           
                    }
                      
                    </div>
                  </div>
                  :
                  downloadDetails!=="" ? 
                  <div className="col-sm-12 text-center">
                  <p>
                       <strong>
                         Good News! You have enough credits for this download.
                       </strong>
                      </p>
                      <p>
                       <Button onClick={handleProceedDownload} variant="contained" className={"  btn btn-pa button download-btn " + loading}> 
                       Proceed to Start the Download 
                          
                        <i className="ml-2 fa fa-download" aria-hidden="true"></i>
                        </Button>
                        
                      </p>{" "}
                  </div>
                  :
                   <div className="col-sm-12 text-center">
                  <p> Data not Found! </p>
                  </div>
                }

                  <div className="col-12  text-center">
                    <Link to="/my-saved-searches" className="back_btn">
                      <span>
                        <i
                          className="fa fa-angle-double-left"
                          aria-hidden="true"
                        ></i>
                      </span>
                      Go Back 
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
       <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Downloading</DialogTitle>
        <DialogContent>
          <DialogContentText className="text-center" id="alert-dialog-description">
           Please be patient, we are putting together your file now, it may take 30 to 45 seconds.
           <p><img src="/images/loading.gif"/> </p>
          </DialogContentText>
        </DialogContent>
        
      </Dialog>
</>
    );
  
}

function mapStateToProps(store, props) {
  return {
    bestPricing: store.client.bestPricing ? store.client.bestPricing : [],
    plan: store.client.plan ? store.client.plan : [],
    info: store.client.info ? store.client.info : [],
    searchResult: store.client.searchResult ? store.client.searchResult : [],
    currentSearch: store.client.currentSearch ? store.client.currentSearch : [],
  }
}

export default withRouter(connect(mapStateToProps, {})(DownloadExpired));
