// Vendor Imports
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { Helmet } from 'react-helmet'
import { setSubscriptions, setCheckout } from '../../../reducers/client/actions';
import { getSubscriptions } from '../../../service/client';

import PowerEntry from "./PowerEntry";
//import Freemium from "./Freemium";
import Power15 from './Power15';
import Power75 from './Power75';
import PowerMax from './PowerMax';


// Upgrade subscription page component

function UpgradeSubscriptionComponent(props) {
  const [subscriptions, setSubs] = useState([]);
  const dispatch = useDispatch();

  // get and set available subscription plan
  useEffect(() => {
    dispatch(setCheckout([]));

    if (props.plan.Sub_Level === "10" || props.info.isParent === false) {
      props.history.push('/search');
    }
    getSubscriptions().then(res =>
      setSubscriptionsData(res)
    )
  },[]);

  const setSubscriptionsData = (res)=>{
    setSubs(res.data.data.subscription);
    dispatch(setSubscriptions(res.data.data.subscription));
  }
  // set subscription plan cards and current plan and avaiable plan to user
  const Card = (item) => {
    let currentPlan = props.myaccount.User_Subscription;

    // if (item.Sub_Level === "9") {
    //   return <Freemium data={item} currentPlan={currentPlan} />
    // }
    if (item.Sub_Level === "9") {
      return <PowerEntry data={item} currentPlan={currentPlan} />
    }
    if (item.Sub_Level === "12") {
      return <Power15 data={item} currentPlan={currentPlan} />
    }
    if (item.Sub_Level === "13") {
      return <Power75 data={item} currentPlan={currentPlan} />
    }
    if (item.Sub_Level === "10") {
      return <PowerMax data={item} currentPlan={currentPlan} />
    }

  }

  return (
    <>
      <Helmet>
        <title> Upgrade Subscriptions - Power Almanac </title>
      </Helmet>
      <div >
        <section className="my-search">
          <div className="container">
            <div className="row">
              <div className="col">
                <ul className="breadcrumb">
                  <li>Upgrade Subscription</li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <div className="container upgrade-sec">
          <div className="row ">
          <div className="col-3  mb-4">
            </div>
            <div className="col-6  mb-4">
              <br></br>
              <h4 className="text-center" style={{ fontWeight: "bold" }}>
                ALL Subscription Plans Include:{" "}
              </h4>
              <br></br>
              <ul>
                <li style={{ lineHeight: "2" }}>
                  Unlimited use of contact data during the subscription&nbsp;
                </li>
                <li style={{ lineHeight: "2" }}>
                  Unlimited users in your organization&nbsp;
                </li>
                <li style={{ lineHeight: "2" }}>
                  Easy, fast download in Excel/CVS … IT support not required
                </li>
                <li style={{ lineHeight: "2" }}>
                  All information is 100% phone verified
                </li>
                <li style={{ lineHeight: "2" }}>Free updates</li>
              </ul>
            </div>
            <div className="col-3  mb-4">
            </div>
          </div>

                
          <div id="credits" className="tabbed credits">
            <div className="row">
              {
                subscriptions && subscriptions.length > 0 ?
                  subscriptions.map(function (item, i) {
                    return Card(item)
                  })
                  :
                  ""
              }

            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <p>
                <strong>Notes:</strong> Full payment is due when your subscription starts. Use all of your credits on day-1 or use them throughout the year.
              </p>
            </div>
          </div>

          {/* <PurchaseForm plan={props.plan} /> */}

          <div className="row">
            <div className="col mt-4 mb-4">
              <h3
                className="text-center qust-upgrade"
                style={{ fontWeight: "bold" }}
              >
                {" "}
                Have Questions about Upgrading? Contact our sales team now:
              </h3>

              <div className="col-md-4 col-md-offset-4 column-in-center mt-4">
                <div className="card card-primary card-outline">
                  <div className="card-body box-profile">
                    <h3 className="text-muted text-center">Sales</h3>
                    <h3 className="text-muted text-center">
                      650.539.2340 ext 2
                    </h3>

                    <p className="text-muted text-center">
                      <a href="mailto:sales@poweralmanac.com">
                        sales@poweralmanac.com
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  );
}

function mapStateToProps(store, props) {
  return {
    subscriptions: store.client.subscriptions ? store.client.subscriptions : [],
    myaccount: store.client.myaccount ? store.client.myaccount : [],
    plan: store.client.plan ? store.client.plan : [],
    info: store.client.info ? store.client.info : []
  }
}

export default withRouter(connect(mapStateToProps, {})(UpgradeSubscriptionComponent));
