import { SET_ERROR, CLEAR_ERROR } from './constants';

const initialState = {};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ERROR:
      return {
        ...state,
        ...action.payload,
      };
    case CLEAR_ERROR:
      return {};
    default:
      return state;
  }
};

export default userReducer;