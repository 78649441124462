import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import BillingList from './billingList.jsx'
import SubscriptionList from './subscriptionList.jsx'
import CreditList from './creditList.jsx'
import { setMyTransactions } from '../../../reducers/client/actions';
import { getMyTransactions } from '../../../service/client';
// My Transactions page component
function MyTransactionsComponent(props) {
  const info = useSelector((store) => store.client.info);
  const dispatch = useDispatch();
  // get and set initial billing, subscription and credit history data
  useEffect(() => {
    if (info.isParent === false) {
      props.history.push('/search');
    }
    if (window.analytics) {
        window.analytics.page();
    }
    getMyTransactions().then(res =>
      updateStateAndStore(res.data.data),
    )
    
  }, []);

  const updateStateAndStore = (data) => {
    dispatch(setMyTransactions(data));
  }

  return (
    <>
      <Helmet>
        <title> My Transactions - Power Almanac </title>
      </Helmet>
      <section className="my-search">
        <div className="container">
          <div className="row">
            <div className="col">
              <ul className="breadcrumb">
                <li>My Transaction History*</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="my-table-container">
        <div className="row">
          <div className="col">
            <div className=" mt-3 search-list-table">
              <h2 className="display-5 text-uppercase sub-user">Billing History </h2>
              <BillingList />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className=" mt-3 search-list-table">
              <h2 className="display-5 text-uppercase sub-user">SUBSCRIPTION HISTORY </h2>
              <SubscriptionList />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className=" mt-3 search-list-table">
              <h2 className="display-5 text-uppercase sub-user">CREDITS HISTORY </h2>
              <CreditList />
            </div>
          </div>
        </div>
      </section>

    </>
  );

}


export default MyTransactionsComponent;
