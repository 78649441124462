// Vendor Imports
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Helmet } from 'react-helmet';

// Local Imports
import ListTable from "./Table";

// RRT Main Component
class RRT extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dmsUser: null
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.dmsUser !== null) {
            return {
                dmsUser: props.dmsUser
            };
        }
        return null;
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>Dashboard - Upload Bad Emails</title>
                </Helmet>
                <section className="titlebar">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h1>Dashboard</h1>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="white-bg">
                                    <section className="dmstable">

                                        <div className="table-responsive">
                                            <table className="table table-bordered ">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Logged In As</th>
                                                        <th scope="col">Access Level</th>
                                                        <th scope="col">Primary Role</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Rapid Response Team	</td>
                                                        <td>Rapid Response Team	</td>
                                                        <td>Rapid Response Team	</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </section>
                                    <ListTable />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

const mapStateToProps = (store, props) => {
    return {
        dmsUser: store.dms.dmsUser
    };
};

// Export
export default withRouter(connect(mapStateToProps)(RRT));
