import React, { useEffect } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import { connect, useSelector } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import { loadSavedSearchName } from '../../../../utils';

// new official filter component
function NewOfficials(props) {
  let tamMode = useSelector(store => store.client.tamMode);
  let rolesFilter = useSelector(store => store.client.validFilters);
  const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
      color: theme.palette.common.black,
    },
    tooltip: {
      backgroundColor: theme.palette.common.black,
      fontSize: "1rem"
    },
  }));

  const classes = useStylesBootstrap();
  const [expanded, setExpanded] = React.useState('');
  // initial filter and values
  let formFields = [
    { label: " Only include records that are NEW OFFICIALS*  <p class='newofficiashelptext2'> <i>* Officials NEW in their role (for 6 months, on average) </i></p>", name: "OnlyHotLeads", value: "1", checked: false }
  ];

  let [Fields, setCheckedFields] = React.useState(formFields);
  let [PreviousParams, setPreviousParams] = React.useState({});
  // set initial or loaded saved search filter parameter
  useEffect(() => {
    if (Object.keys(props.currentSearch).length !== 0 && PreviousParams !== props.currentSearch) {
      let loadParams = "";
      let loadSavedSearch = [];
      if (props.currentSearch.id !== "0" || props.currentSearch.id !== "") {
        loadParams = props.currentSearch.search_params;
        loadSavedSearch = loadSavedSearchName(loadParams, formFields);
      } else if (props.currentSearch.id === "0" || props.currentSearch.id === "") {
        loadParams = props.currentSearch.params;
        loadSavedSearch = loadSavedSearchName(loadParams, formFields);
      }

      if (Fields !== loadSavedSearch && loadSavedSearch.length !== 0) {
        setPreviousParams(props.currentSearch);
        setCheckedFields(loadSavedSearch.appliedFilters);
        if (loadSavedSearch.checkedcount !== 0 && loadSavedSearch.checkedcount !== formFields.length) {
          setExpanded("newOfficials");
        } else {
          if (expanded !== "") {

          } else {
            setExpanded("");
          }
        }

      }
    }
  });

  // useEffect(() => {
  //   props.triggerSearch('', false);
  // }, [tamMode])

  // function to capture new official value and trigger search api
  const handleChange = (event) => {

    let uncheckedInputs = [];
    Fields.map((field, index) => {
      if (event.target.name === field.name) {
        field.checked = event.target.checked;
      }

      uncheckedInputs.push(field);
    })
    setCheckedFields(uncheckedInputs);
    props.triggerSearch('', false,rolesFilter);
  };
  const handleChangeAccordian = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  let disableFilter = tamMode ? "disableFilter" : "";

  return (

    <div className={"row " + disableFilter}>
      <div className="col ">
        <Accordion className="accordianLine" expanded={expanded === 'newOfficials'} onChange={handleChangeAccordian('newOfficials')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="newOfficialsa-content"
            id="newOfficialsa-header"
          >
            <Typography className="">
              New Officials
              <a className="newofficiashelptext">(for Power Max users only)</a>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>

              {
                Fields.map((field, index) => {
                  let disabled = props.plan.Sub_Level !== "10" ? true : false;
                  let ischecked = field.checked
                  if (tamMode) {
                    ischecked = false;
                  }
                  return (
                    <FormGroup key={index}>
                      <FormControlLabel
                        className="form-check-label newOfficial roleLabel"
                        control={<Checkbox disabled={disabled} checked={ischecked} id={field.name} className="roleCheckbox" onChange={handleChange} name={field.name} value={field.value} />}
                        label={<Tooltip classes={classes} placement="top" arrow title="This filter is for Power Max subscribers only" >
                          <Typography className="text-small" dangerouslySetInnerHTML={{ __html: field.label }} />
                        </Tooltip>}
                      />
                    </FormGroup>
                  );

                })
              }
            </Typography>
          </AccordionDetails>
        </Accordion>

      </div>
    </div>

  )
}

function mapStateToProps(store, props) {
  return {
    plan: store.client.plan ? store.client.plan : [],
    currentSearch: store.client.currentSearch ? store.client.currentSearch : [],
  }
}

export default connect(mapStateToProps, {})(NewOfficials);
