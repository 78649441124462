import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import { Link } from "react-router-dom";
import UpgrdeRequestModal from './UpgrdeRequestModal';
// Power75 subscription card component
function Power75(props) {
  const plan = props.data;
  const currentPlan = props.currentPlan;
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  // card action button functionality to perform action subscription either sign up / upgrade
  const CardButton = () => {
    const Sub_Level = plan.Sub_Level;
    const canUpgrade = currentPlan === "12" ? true : false;
    const canSignUp = currentPlan === "8" || currentPlan === "9" || currentPlan === "11" ? true : false;
    const isUnAvailable = currentPlan === "10" ? true : false;
    if (isUnAvailable) {
      return <span className="btn btn-pa btn-danger w-100  rounded text-truncate unavailable" >Unavailable</span>
    }
    else if (canSignUp) {
      return <Link to={"/checkout/subscription/" + plan.Subscriptions_ID} className="btn w-100 get-started rounded text-white"
      > Sign Up </Link>
    }
    else if (canUpgrade) {
      return <Link onClick={toggle} className="btn w-100 get-started rounded text-white upgrade-plan-button"
      > Upgrade </Link>
    }
    else if (currentPlan === Sub_Level) {
      return <span className="btn currentPlan w-100 text-truncate rounded current-plan" style={{ backgroundColor: "#50c5a9 !important" }}> Current Plan </span>
    }
  }
  return (
    <>
      <div className="col-sm-6 col-lg-3 mb-4  p-0">
        <section className="card custom-card border-top-0  border-left-0">
          
          <div className="card-header custom-card-header record-plan">
            <h3
              className="m-0 text-center record-title"
              style={{ textTransform: "uppercase" }}
            >
              {plan.Sub_Name} {" "}
            </h3>
            <p className="m-0 text-center">
                  <i><NumberFormat value={plan.Sub_NumReserves} displayType={'text'} thousandSeparator={true} /> Records </i>
              </p>
          </div>
          <div className="card-header pricing">
            <p className="m-0 text-center ">
              <span className="title"> {Math.round(plan.Sub_CostPerDL * 100)}&cent; </span> <br/>
              <span className="title"> / Record </span>{" "}
              <span className=" title-sub">
                             <NumberFormat value={plan.Sub_AnnualFees} displayType={'text'} thousandSeparator={true} prefix="$" /> / Year
                        </span>
            </p>
          </div>
          <div className="card-body p-0">
            <ul className="list-group list-group-flush et_pb_pricing ">
            <li className="m-0">
                <span>
                  {Math.round(plan.Sub_CostPerDL * 100)}&cent; for Each Additional Record Over 75,000
               </span>
              </li>
              <li className="m-0">
                <span>Unlimited Users</span>
              </li>
              <li className="m-0">
                <span>Free Updates for Downloaded Records</span>
              </li>
            </ul>
          </div>
          <div className="card-footer border-top-0">
            {CardButton()}
          </div>
        </section>
      </div>
      <UpgrdeRequestModal
        modal={modal}
        toggle={toggle}
      />
    </>
  );
}

export default Power75;