// Vendor Imports
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Swal from 'sweetalert2';

// Local Imports
import {
    removeDmsUser
} from "../../reducers/dms/actions";
import { logoutDMS } from "../../service/dms";

// Header Component
class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dmsUser: null,
            loading: ''
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.dmsUser !== null) {
            return {
                dmsUser: props.dmsUser
            };
        }
        return null;
    }

    logout = () => {
        this.setState({ loading: 'loading' });
        logoutDMS().then(res => {
            if (res.data.code === 200) {
                this.setState({
                    loading: '',
                });
                this.props.dispatch(removeDmsUser());
                this.props.history.push("/dms");
            } else {
                this.setState({
                    loading: '',
                });
                Swal.fire({
                    text: res.data.message,
                    showCloseButton: true,
                    confirmButtonText: "Ok",
                    icon: 'error',
                    allowOutsideClick: false
                });
                return;
            }
        })
    }

    render() {
        return (
            <div>
                <header id="header" className="site-header pinavbar shadow-sm">
                    <nav className="navbar navbar-expand-lg navbar-light bg-white ">

                        <img
                            src="/images/logo/poweralmanac.svg"
                            alt="Power Almanac"
                            className="logo"
                            width="132"
                            height="63"
                        />

                        <button
                            className="navbar-toggler navbar-toggler-right"
                            type="button"
                            data-toggle="collapse"
                            data-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div
                            className="collapse navbar-collapse"
                            id="navbarSupportedContent"
                            role="navigation"
                        >
                            <ul className="navbar-nav navbar-right ml-auto">
                                {this.state.dmsUser.primary_role === 'QualityChecker' ? <li className="nav-item ">
                                    <Link
                                        className="nav-link btn my-2 my-sm-0 "
                                        to="/dms/supervisor/qc-dashboard" >
                                        My Active Tasks
                  </Link>
                                </li> : <span></span>}
                                {this.state.dmsUser.primary_role === 'FinalApprover' ? <li className="nav-item ">
                                    <Link
                                        className="nav-link btn my-2 my-sm-0 "
                                        to="/dms/supervisor/fa-dashboard" >
                                        My Active Tasks
                  </Link>
                                </li> : <span></span>}
                                {this.state.dmsUser.primary_role === 'ProcessInitiator' ? <li className="nav-item ">
                                    <Link
                                        className="nav-link btn my-2 my-sm-0 "
                                        to="/dms/supervisor/pi-dashboard" >
                                        My Active Tasks
                  </Link>
                                </li> : <span></span>}
                                {this.state.dmsUser.primary_role === 'VoiceAgent' ? <li className="nav-item ">
                                    <Link
                                        className="nav-link btn my-2 my-sm-0 "
                                        to="/dms/voice-agent/dashboard" >
                                        Dashboard
                  </Link>
                                </li> : this.state.dmsUser.primary_role === 'DialerAdmin' ? <li className="nav-item ">
                                    <Link
                                        className="nav-link btn my-2 my-sm-0 "
                                        to="/dms/dialer-admin/dashboard" >
                                        Dashboard
                  </Link>
                                </li> : this.state.dmsUser.primary_role === 'RecencyManagementTeam' ? <li className="nav-item ">
                                    <Link
                                        className="nav-link btn my-2 my-sm-0 "
                                        to="/dms/rrt/dashboard" >
                                        Dashboard
                  </Link>
                                </li> : <span>
                                                <li className="nav-item ">
                                                    <Link
                                                        className="nav-link btn my-2 my-sm-0 "
                                                        to="/dms/supervisor/voice-agents" >
                                                        View Voice Agents
                  </Link>
                                                </li>
                                                <li className="nav-item ">
                                                    <Link
                                                        className="nav-link btn my-2 my-sm-0 "
                                                        to="/dms/supervisor/view-productivity" >
                                                        View Productivity
                  </Link>
                                                </li>
                                            </span>
                                }
                                {this.state.dmsUser.primary_role === 'ProcessInitiator' ? <li className="nav-item ">
                                    <Link
                                        className="nav-link btn my-2 my-sm-0 "
                                        to="/dms/supervisor/ageing-mis" >
                                        Ageing MIS
                  </Link>
                                </li> : <span></span>}

                                {this.state.dmsUser.primary_role === 'ProcessInitiator' ? <li className="nav-item ">
                                    <Link
                                        className="nav-link btn my-2 my-sm-0 "
                                        to="/dms/supervisor/role-movement-mis" >
                                        Role Movement MIS
                  </Link>
                                </li> : <span></span>}

                                {this.state.dmsUser.primary_role === 'ProcessInitiator' ? <li className="nav-item ">
                                    <Link
                                        className="nav-link btn my-2 my-sm-0 "
                                        to="/dms/supervisor/add-new-officials" >
                                        Add New Officials
                  </Link>
                                </li> : <span></span>}

                                {this.state.dmsUser.primary_role === 'ProcessInitiator' ? <li className="nav-item ">
                                    <Link
                                        className="nav-link btn my-2 my-sm-0 "
                                        to="/dms/supervisor/unlock-governments" >
                                        Unlock Governments
                  </Link>
                                </li> : <span></span>}

                                {/* {this.state.dmsUser.primary_role === 'ProcessInitiator' ? <li className="nav-item ">
                                    <Link
                                        className="nav-link btn my-2 my-sm-0 "
                                        to="/dms/supervisor/qc-mis" >
                                        QC MIS
                  </Link>
                                </li> : <span></span>} */}

                                <li className="nav-item ">
                                    <Link
                                        className="nav-link btn my-2 my-sm-0"
                                        to="/dms/help" >
                                        Help
                  </Link>
                                </li>
                                <li className="nav-item ">
                                    <span
                                        onClick={this.logout}
                                        className={"nav-link btn my-2 my-sm-0 " + this.state.loading}
                                    >
                                        Log Out
                  </span>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </header>
            </div>
        );
    }
}

const mapStateToProps = (store, props) => {
    return {
        dmsUser: store.dms.dmsUser
    };
};

export default withRouter(connect(mapStateToProps)(Header));
