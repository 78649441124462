// Vendor Imports
import React from "react";

// Local Imports
import Header from "./Header";
import Footer from "./Footer";

// Guest Layout
const GuestLayout = props => {
  return (
    <div>
      <Header></Header>
      <main className="wrapper">
        <div >
          {props.children}
        </div>
      </main>
      <Footer></Footer>
    </div>
  );
};

// Export
export default GuestLayout;
