// Vendor Imports
import React from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import Cookies from 'js-cookie';
import AES from "crypto-js/aes";
import Utf8 from "crypto-js/enc-utf8";

// Local Imports
import Form from "./Form";
import Protector from "./Protector";

// Login Component
import {
  setLoginUser,
  subUserChangePass
} from "../../../reducers/client/actions";
import { doLogin } from "../../../service/client";
import $ from "jquery";
class LoginComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: null,
      loading: "",
      eMail: "",
      rememberMe: false,
    };
  }

  componentDidMount() {
    $("#header").show();
    Cookies.set('notification', false);
    if (this.props.authToken !== null) {
      this.props.history.push("/search");
    }
    let cookie = Cookies.getJSON('user-details');
    if (cookie) {
      this.setState({ rememberMe: cookie.rememberMe })
    }
  }

  // RememberMe Handler
  checkRemember = () => {
    this.setState({ rememberMe: !this.state.rememberMe });
  }

  // Login response handler
  setLoginResponse = result => {
    Cookies.set('notification', false);
    if (result.data.code === 200 && "subregister" in result.data.data) {
      if (result.data.message === "SUCCESS") {
        this.setState({ response: result.data, errors: null, loading: "" });
      } else {
        this.setState({ response: result.data, errors: result.data.message, loading: "" });
      }

      this.props.dispatch(subUserChangePass(result.data.data.subregister));
      this.props.history.push("change-password");
      return;
    } else if (result.data.code === 200) {
      //condition for hard loading search page #kannadhasan
      if (result.data.message === "SUCCESS") {
        this.setState({ response: result.data, errors: null, loading: "" });
      } else {
        this.setState({ response: result.data, errors: result.data.message, loading: "" });
      }

      this.props.dispatch(setLoginUser(result.data.data));
      let is_substart_date = new Date(result.data.data.user.DateTime_SubscriptionStart);
      let is_reg_date = new Date(result.data.data.user.DateTime_Registered);
      let is_subend_date = new Date(result.data.data.user.DateTime_SubscriptionEnd);
      if (window.analytics) {
          window.analytics.identify(result.data.data.user.RegUser_ID, { // internal user ID
          // TODO: add more info
          company_name: result.data.data.user.User_CompanyName,
          subscription_type: result.data.data.plan.Sub_Name, // power_15 // power_75 // 'power_max' // power_custom
          email: result.data.data.user.User_Email,
          name: result.data.data.user.User_FirstName+' '+result.data.data.user.User_LastName,
          registration_date:result.data.data.user.DateTime_Registered,
          subscription_start_date:result.data.data.user.DateTime_SubscriptionStart,
          subscription_end_date:result.data.data.user.DateTime_SubscriptionEnd,
          user_type:result.data.data.user.User_Parent==="0" ? 'Primary User' : 'Sub User' ,
          is_internal: result.data.data.user.is_internal==="0" ? 'true' : 'false' , // set to true if this is a test account, Power Alamanc employee etc 
        });
        window.analytics.track('User Logged In', {
            //Current login date time in ISO format string
        })
      }

      this.props.history.push("/search");
      //window.location = "/search";
    } else if (result.data.code === 400 && "status" in result.data.data) {
      if (result.data.data.status === "renewal") {
        this.setState({ response: result.data, errors: null, loading: "" });
        this.props.history.push(
          `/renew-subscription/${result.data.data.RegUser_ID}`
        );
        return;
      }
    } else {
      this.setState({ response: result.data, errors: result.data.message, loading: "" });
    }
  };

  // Handlers
  handleSubmit = values => {
    let cookie = Cookies.getJSON('user-details');
    if (cookie) {
      if (this.decrypt(cookie.email, 'poweralmanac') === values.email) {
        if (!this.state.rememberMe) {
          Cookies.remove('user-details');
        }
      } else {
        if (this.state.rememberMe) {
          Cookies.set('user-details', {
            email: this.encrypt(values.email, 'poweralmanac'),
            password: this.encrypt(values.password, 'poweralmanac'),
            rememberMe: this.state.rememberMe
          });
        }
      }
    } else {
      if (this.state.rememberMe) {
        Cookies.set('user-details', {
          email: this.encrypt(values.email, 'poweralmanac'),
          password: this.encrypt(values.password, 'poweralmanac'),
          rememberMe: this.state.rememberMe
        });
      }
    }
    let params = {
      eMail: values.email,
      passWord: values.password,
      rememberMe: "0"
    };
    this.setState({ loading: "loading" });
    doLogin(params).then(res => this.setLoginResponse(res));
  };

  encrypt = (val, passphrase) => {
    return AES.encrypt(val, passphrase).toString();
  }

  decrypt = (val, passphrase) => {
    const bytes = AES.decrypt(val, passphrase);
    const originalText = bytes.toString(Utf8);
    return originalText;
  }

  render() {
    const ErrorMessage = () => {
      if (this.state.errors) {
        if (this.state.response.code === 400 && this.state.response.data.message === "ACCOUNT_NOT_ACTIVATED") {
          return <div
            className="alert alert-danger"
            role="alert"
          >
            ERROR: Account is NOT activated yet. <br />To re-send the activation email, <Link to={"/resend-activation-mail/" + this.state.response.data.eMail}>click here</Link>
          </div>
        } else {
          return <div
            className="alert alert-danger"
            role="alert"
            dangerouslySetInnerHTML={{
              __html: this.state.errors
            }}
          ></div>
        }
      } else {
        return <></>
      }
    }

    return (
      <>
        <Helmet>
          <title> Log in - Power Almanac </title>
        </Helmet>
        <section
          className="container login-section login-block"

        >
          <div className="row login-bg">
            <div className="col-sm-12 col-md-6">
              <img
                src="images/login.jpg"
                alt="Placeholder"
                className="img-responsive"
              />
            </div>
            <div className="col-sm-12 col-md-6 login-right">
              <h1>Please Log in</h1>
              {ErrorMessage()}
              <Form
                loading={this.state.loading}
                onSubmit={this.handleSubmit}
                rememberMeFun={this.checkRemember}
                rememberMeVal={this.state.rememberMe}
              ></Form>
            </div>
          </div>
        </section>
        {
          process.env.REACT_APP_ENV === "STAGING" && this.props.protector && this.props.protector.success !== true ?
            <Protector />
            :
            ""
        }

      </>
    );
  }
}

function mapStateToProps(store, props) {
  return {
    errors: store.errors,
    authToken: store.client.authToken ? store.client.authToken : null,
    protector: store.client.protector

  }
}

// Export
export default withRouter(connect(mapStateToProps)(LoginComponent));
