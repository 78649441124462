import React, { useState } from 'react';
import { withRouter } from "react-router-dom";
import StripeModal from './StripeModal'
import { useSelector } from "react-redux";

// Stripe base online payment modal
function PaymentMedhod(props) {
  let [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const myaccount = useSelector((store) => store.client.myaccount);
  const plan = useSelector((store) => store.client.plan);
  const checkout = useSelector((store) => store.client.checkout);
  return (
    <>
      <div className="row">
        <div className="col ">
          <h3><strong>Pay Online</strong></h3>
          {
            props.checkoutType === "subscription" ?
              <p>
                For immediate access to the item you've ordered, pay ONLINE NOW with a credit card or ACH payment. Your payment will be handled securely by STRIPE. Click PAY ONLINE.
                            </p>
              :
              <p>To download your file now, you must pay ONLINE NOW with a credit card or ACH payment.  Your payment will be handled securely by STRIPE.   Click PAY ONLINE.</p>
          }


          <div className="text-right">
            <button
              type="submit"
              className="btn btn-pa text-white online-btn"
              data-toggle="modal"
              data-target="#stripe-modal"
              onClick={toggle}
            >
              <span className="text-uppercase">PAY ONLINE</span>
              {
                props.checkoutType !== "subscription" ?
                  <span className="text-lowercase">
                    {" "}
                        and proceed to download
                      </span>
                  :
                  ""
              }
            </button>
          </div>
        </div>
      </div>
      {
        checkout.length !== 0 ?
          <StripeModal history={props.history} modal={modal} toggle={toggle} />
          :
          ""
      }


    </>
  )
}

// function mapStateToProps(store, props) {
//   return {
//     myaccount: store.client.myaccount ? store.client.myaccount : [],
//     plan: store.client.plan ? store.client.plan : [],
//     checkout: store.client.checkout ? store.client.checkout : [],
//   }
// }

export default withRouter(PaymentMedhod);
