import React from "react";
import { useDispatch } from "react-redux";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import Button from "@material-ui/core/Button";
import { addSubUser } from "../../../service/client";
import { refreshUserDetails } from "../../../reducers/client/actions";
import { getMyAccount } from "../../../service/client";
// Add sub user modal
export default function SubUserModal(props) {
  const { modal } = props;
  let User_Subscription = props.data.User_Subscription;
  const dispatch = useDispatch();
  const maxLimit = parseInt(props.data.User_DL_Reserves);
  const [state, setState] = React.useState({});
  const [loading, setLoading] = React.useState("");
  const [response, setResponse] = React.useState("");
  const [validation, setValidation] = React.useState("");

  // function to validate firstname, lastname, email
  const validate = () => {
    if (state.firstName === "") {
      setValidation("Enter the First Name");
      return false;
    }
    if (state.lastName === "") {
      setValidation("Enter the Last Name");
      return false;
    }
    if (state.eMail === "") {
      setValidation("Enter the Email");
      return false;
    }

    var subuserEmailDomain = state.eMail.split("@")[1];
    var parentEmailDomain = props.data.User_Email.split("@")[1];

    if(props.data.RegUser_ID!==8459){
      if (subuserEmailDomain !== parentEmailDomain) {
        setValidation("Email must be from the same domain");
        return false;
      }
    }
    if (User_Subscription !== "10") {
      if (state.dlLimits === "") {
        setValidation(" You must assign a credit limit. It may be “0” ");
        return false;
      } else if (state.dlLimits > maxLimit) {
        setValidation(
          " You dont have  " + state.dlLimits + "  credit in your account"
        );
        return false;
      }
    }

    let oldState = state;
    if (typeof oldState.dlLimits === "undefined") {
      oldState['dlLimits'] = 0;
      setState(oldState);
    }

    return true;
  };

  //function to call new subuser api
  const handleSubmit = e => {
    e.preventDefault();
    if (!validate()) {
      return false;
    }
    setLoading(" loading");
    addSubUser(state).then(res => clearStates(res.data));
  };

  // capture form changes and save into state
  const handleChange = event => {
    setValidation("");
    setResponse("");
    let oldState = state;
    oldState[event.target.name] = event.target.value;
    setState(oldState);
  };
  const onClose = () => {
    setState({});
    setLoading("");
    setResponse("");
    props.toggle();
  }
  // clear validation and close modal and refresh my account details
  const clearStates = (response) => {
    setLoading("");

    if (response.code === 200) {
      setState({});
      setResponse(response);
      getMyAccount().then(res =>
        dispatch(refreshUserDetails(res.data.data))
      );
      props.toggle();
    }
    else {
      setResponse(response);
    }
  };
  //setTimeout(() => clearStates(), 3000);
  return (
    <div>
      <Modal
        size="lg"
        isOpen={modal}
        toggle={onClose}
        backdrop={false}
        centered={true}
        className="modal-dialog"
      >
        <ModalHeader toggle={onClose}>Add Sub-User</ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col">
                <p className="mb-3">
                  Sub-users must work in the same organization as you, with an
                  email address that shares the same domain name (e.g.
                  @poweralmanac.com).
                </p>
                <p className="mb-3">
                  All fields below are required. The credit limit assigned to
                  this sub-user may be any number, including “0”, but not
                  exceeding the total number of credits remaining in your
                  account.
                </p>
              </div>
            </div>
            {response !== "" && response.code === 200 ? (
              <div className="alert alert-success">
                Sub user has been added!!
              </div>
            ) : (
              ""
            )}
            {validation !== "" ? (
              <div className="alert alert-danger"> {validation}</div>
            ) : (
              ""
            )}

            {response !== "" && response.code === 400 ? (
              <div className="alert alert-danger">{response.message}</div>
            ) : (
              ""
            )}

            <div className=" row">
              <div className="col">
                <div className="form-group">
                  <input
                    required="required"
                    onChange={handleChange}
                    type="text"
                    name="firstName"
                    id="subUserFirstName"
                    placeholder="First Name"
                    className="form-control"
                    maxLength="255"
                  />
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <input
                    required="required"
                    onChange={handleChange}
                    type="text"
                    name="lastName"
                    id="subUserLastName"
                    className="form-control"
                    placeholder="Last Name"
                    maxLength="255"
                  />
                </div>
              </div>
            </div>
            <div className=" row">
              <div className="col col-md-6">
                <div className="form-group">
                  <input
                    required="required"
                    onChange={handleChange}
                    type="email"
                    name="eMail"
                    id="subUserEmail"
                    className="form-control"
                    maxLength="255"
                    placeholder="Email Address"
                  />
                </div>
              </div>
              {User_Subscription === "10" ? (
                ""
              ) : (
                <div className="col col-md-6">
                  <div className="form-group">
                    <input
                      maxLength={maxLimit}
                      required="required"
                      onChange={handleChange}
                      type="number"
                      placeholder="Credit Limit"
                      name="dlLimits"
                      id="subUserCreditLimit"
                      className="form-control"
                    />
                  </div>
                </div>
              )}
            </div>
            <div className=" row">
              <div className="col">
                <p>
                  When you click SUBMIT this sub-user will receive an email from
                  Power Almanac stating that they’ve been added as a sub-user to
                  your Power Almanac account, and providing them with their own
                  login credentials
                </p>
              </div>
            </div>
            <div className=" row">
              <div className="col text-center">
                <div className="manage-sub-btn">
                  <Button
                    variant="contained" type="submit" className={"btn btn-pa" + loading}>
                    Submit
                  </Button>

                  <Button
                    variant="contained"
                    onClick={onClose}
                    className=" backbtn close-modal"
                  >
                    Back
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
}
