import React, { useState, useEffect ,useMemo,useCallback } from 'react';
import { connect,useSelector } from 'react-redux';
import SaveNewSearch from './SaveNewSearch'
import UpgrdeRequestModal from '../../client/UpgradeSubscription/UpgrdeRequestModal'
import { Link, withRouter } from "react-router-dom";
import { renderCount } from '../../../utils';
import Button from '@material-ui/core/Button';
import CreditRequestModal from './CreditRequest'
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
// search info component with download option
function SearchInfo(props) {
  let [bestPricing, setPricing] = React.useState([]);
  const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
      color: theme.palette.common.black,
    },
    tooltip: {
      backgroundColor: theme.palette.common.black,
      fontSize: "1rem"
    },
  }));

  const classes = useStylesBootstrap();
  const [filterClass, setFilterClass] = useState('');
  const rolesFilter = useSelector(store => store.client.validFilters);
  useEffect(() => {
    if (bestPricing !== props.bestPricing) {
      setPricing(props.bestPricing);
    }
  });

  useEffect(() => { 
    if(rolesFilter.rolesf==false || rolesFilter.statesf==false || rolesFilter.gtypef==false || rolesFilter.gftef==false || rolesFilter.gspendf==false || rolesFilter.fydf==false || rolesFilter.molf==false)
    {
      setFilterClass('invalidFilter');
    } else {
      setFilterClass('');
    }     
  }, [rolesFilter]);

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [upgradeModal, setUpgradeModal] = useState(false);
  const toggleUpgrade = () => {
    setUpgradeModal(!upgradeModal)
  };

  const [creditModal, setCreditModal] = useState(false);
  const toggleCreditRequest = () => {
    setCreditModal(!creditModal)
  };
  // function to take decision to save search before download or checkout or credit / upgrade request
  const DownloadButton = () => {
    if (props.searchLoading === true) {
      return (
        <Button variant="container" className="btn text-white com-btn text-uppercase off-on-search btn-secondary"><strong> PLEASE WAIT </strong> </Button>
      )
    } else if (props.currentSearch.id === "" || props.currentSearch.id === "0") {
      return (
        <Button onClick={SaveAndCheckout} variant="container" className="btn text-white com-btn text-uppercase off-on-search btn-pa">  <strong> Download{" "}  <span className="blinking"> {renderCount(props.searchResult.lastSearchNumMatched)} </span>
          {" "} Records </strong>
        </Button>);
    } else if (props.currentSearch.id !== "" && props.currentSearch.id !== "0") {
      if (props.info.isParent === true) {
        if (bestPricing.option === "Download") {
          return (
            <Link to="/search/download" className="btn text-white com-btn text-uppercase off-on-search btn-pa">
              <strong> Download{" "}  <span className="blinking"> {renderCount(props.searchResult.lastSearchNumMatched)} </span>
                {" "} Records   </strong>
            </Link>);
        } else if (bestPricing.option === "Upgrade" && (props.plan.Sub_Level === "11" || props.plan.Sub_Level === "9")) {
          return (
            <Link to="/search/download/upgrade-plan" className="btn text-white com-btn text-uppercase off-on-search btn-pa">  <strong> Download{" "}  <span className="blinking"> {renderCount(props.searchResult.lastSearchNumMatched)} </span>
              {" "} Records  </strong>
            </Link>);
        } else if (bestPricing.option === "Upgrade" && (props.plan.Sub_Level !== "10" && props.plan.Sub_Level !== "11" && props.plan.Sub_Level !== "9")) {
          return (
            <Button onClick={toggleUpgrade} variant="container" className="btn text-white com-btn text-uppercase off-on-search btn-pa">  <strong> Download{" "}  <span className="blinking"> {renderCount(props.searchResult.lastSearchNumMatched)} </span>
              {" "} Records   </strong>
            </Button>
          );
        } else if (bestPricing.option === "Purchase" && (props.plan.Sub_Level !== "10")) {
          return (
            <Link to="/search/download/purchase-credits" className="btn text-white com-btn text-uppercase off-on-search btn-pa">  <strong> Download{" "}  <span className="blinking"> {renderCount(props.searchResult.lastSearchNumMatched)} </span>
              {" "} Records  </strong>
            </Link>);
        } else {
          return (
            <Button variant="container" className="btn text-white com-btn text-uppercase off-on-search btn-pa">  <strong> Download{" "}  <span className="blinking"> 0 </span>
              {" "} Records   </strong>
            </Button>);
        }

      } else if (props.info.isParent === false) {
        if (bestPricing.option === "Download") {
          return (
            <Link to="/search/download" className="btn text-white com-btn text-uppercase off-on-search btn-pa">
              <strong> Download{" "}  <span className="blinking"> {renderCount(props.searchResult.lastSearchNumMatched)} </span>
                {" "} Records    </strong>
            </Link>);
        } else if (bestPricing.option === "Upgrade" && (props.plan.Sub_Level === "11" || props.plan.Sub_Level === "9")) {
          return (
            <Button onClick={toggleCreditRequest} variant="container" className="btn text-white com-btn text-uppercase off-on-search btn-pa">  <strong> Download{" "}  <span className="blinking"> {renderCount(props.searchResult.lastSearchNumMatched)} </span>
              {" "} Records   </strong>
            </Button>
          );
        } else if (bestPricing.option === "Upgrade" && (props.plan.Sub_Level !== "10" && props.plan.Sub_Level !== "11" && props.plan.Sub_Level !== "9")) {
          return (
            <Button onClick={toggleCreditRequest} variant="container" className="btn text-white com-btn text-uppercase off-on-search btn-pa">  <strong> Download{" "}  <span className="blinking"> {renderCount(props.searchResult.lastSearchNumMatched)} </span>
              {" "} Records   </strong>
            </Button>
          );
        } else if (bestPricing.option === "Purchase" && (props.plan.Sub_Level !== "10")) {
          return (
            <Button onClick={toggleCreditRequest} variant="container" className="btn text-white com-btn text-uppercase off-on-search btn-pa">  <strong> Download{" "}  <span className="blinking"> {renderCount(props.searchResult.lastSearchNumMatched)} </span>
              {" "} Records   </strong>
            </Button>);
        } else {
          return (
            <Button variant="container" className="btn text-white com-btn text-uppercase off-on-search btn-pa">  <strong> Download{" "}  <span className="blinking"> 0 </span>
              {" "} Records   </strong>
            </Button>);
        }
      }

    }

  }



  // funtion to show purchase information to user suggested by system
  const PuchaseInfo = () => {

    if (props.currentSearch.id === "" || props.currentSearch.id === "0") {
      return (
        <Link onClick={SaveAndCheckout} variant="container">
          <strong className="RequiredPurchase">
            <span dangerouslySetInnerHTML={{ __html: bestPricing.pricing_option }} />
          </strong>
        </Link>);
    } else if (props.currentSearch.id !== "" && props.currentSearch.id !== "0") {

      if (props.info.isParent === true) {

        if (bestPricing.option === "Download") {
          return (
            <Link to="/search/download" >
              <strong> Download{" "}  <span className="blinking"> {renderCount(props.searchResult.lastSearchNumMatched)} </span>
                {" "} Records  </strong>
            </Link>);
        } else if (bestPricing.option === "Upgrade" && (props.plan.Sub_Level === "11" || props.plan.Sub_Level === "9")) {
          return (
            <Link to="/search/download/upgrade-plan" >
              <strong> {bestPricing.pricing_option} </strong>
            </Link>);
        } else if (bestPricing.option === "Upgrade" && (props.plan.Sub_Level !== "10" && props.plan.Sub_Level !== "11" && props.plan.Sub_Level !== "9")) {
          return (
            <Link onClick={toggleUpgrade} >
              <strong> {bestPricing.pricing_option} </strong>
            </Link>);
        } else if (bestPricing.option === "Purchase" && (props.plan.Sub_Level !== "10")) {
          return (
            <Link to="/search/download/purchase-credits" >   <strong> {bestPricing.pricing_option} </strong>
            </Link>);
        } else {
          return (<Link onClick={toggleCreditRequest} variant="container" >
            <strong> {bestPricing.pricing_option} </strong>
          </Link>);
        }

      } else if (props.info.isParent === false) {
        if (bestPricing.option === "Download") {
          return (
            <Link to="/search/download" >
              <strong> Download{" "}  <span className="blinking"> {renderCount(props.searchResult.lastSearchNumMatched)} </span>
                {" "} Records    </strong>
            </Link>);
        }
        else if (bestPricing.option !== "Download") {
          return (
            <Link onClick={toggleCreditRequest} variant="container" >
              <strong> {bestPricing.pricing_option} </strong>
            </Link>
          );
        }
      }

    }

  }
  const SaveAndCheckout = () => {
    toggle();
  }
  let creditBalance = props.info.creditsRemaining ? props.info.creditsRemaining : props.myaccount.User_DL_Reserves;

  return (
    <>
      <div className="row">
        <div className="col-12 text-center">
          <h2>Your Search RESULTS</h2>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="border rounded p-3 left-box">
            <div className="row">
              <div className={filterClass + "  col-12 text-center"}>
                <DownloadButton />
              </div>
            </div>
            <div className="row pt-2">
              <div className="col-5 text-center">
                <p className="lead mb-0"><strong className="creditRequired">{props.searchLoading === true ? 0 : renderCount(props.searchResult.credit_required)}</strong></p>
                <p className="text-small">Credits Required</p>
              </div>
              <div className="col-2 text-center">
                <p className="lead">/</p>
              </div>
              <div className="col-5 text-center">
                <p className="lead mb-0">
                  {
                    props.plan.Sub_Level === "10" ?
                      <strong> Unlimited  </strong>
                      :
                      <strong> {renderCount(creditBalance)}  </strong>
                  }
                </p>
                <p className="text-small">Credits Available</p>
              </div>
            </div>

            {
              bestPricing.option === "Download" ?
                <div id="DownloadInfo" className="row pt-1 ">
                  <div className="col-12  text-center">
                    <p>  <strong> You have enough credits </strong>  to download these records </p>
                  </div>
                </div>
                :
                bestPricing.length !== 0 && props.searchLoading === false ?
                  <div id="PurchaseInfo" className="row pt-1 ">
                    {
                      props.plan.Sub_Level === "11" || props.plan.Sub_Level === "9" ?
                        <div className="col-12 text-center">
                          {
                            creditBalance !== "0" ?
                              <>
                                <strong>Use the search filters below to reduce your search to {"  "}
                                  {renderCount(creditBalance)} or fewer records
                                </strong>
                                <br />
                                or
                              </>
                              :
                              <>
                                <strong> You’ve used all the credits in your trial account

                                  (or they’ve expired).  <br /> To download this search:
                                </strong>
                              </>
                          }
                         

                        </div>
                        :
                        <div className="col-12 text-center">

                          <strong>Required to download these records </strong>
                          <sup>

                            <Tooltip classes={classes} placement="top" arrow title="A purchase is required to download these records because the credits required to download is more than the credits you have available." >
                              <i data-toggle="tooltip" title="" className="fa fa-info-circle" aria-hidden="true">
                              </i>
                            </Tooltip>
                          </sup>
                        </div>
                    }
                    <div className="col-12  text-center">
                      <PuchaseInfo />
                    </div>
                  </div>
                  :
                  ""
            }


          </div>
        </div>
      </div>
      <SaveNewSearch history={props.history} data={props.searchResult} title="You Must Save this Search Before Downloading" modal={modal} action="save-continue" toggle={toggle} />
      <UpgrdeRequestModal modal={upgradeModal} toggle={toggleUpgrade} />
      {
        props.info.isParent === false ?
          <CreditRequestModal modal={creditModal} toggle={toggleCreditRequest} />
          :
          ""
      }

    </>
  )
}



function mapStateToProps(store, props) {
  return {
    searchResult: store.client.searchResult ? store.client.searchResult : [],
    searchLoading: store.client.searchLoading,
    info: store.client.info ? store.client.info : [],
    myaccount: store.client.myaccount ? store.client.myaccount : [],
    plan: store.client.plan ? store.client.plan : [],
    bestPricing: store.client.bestPricing ? store.client.bestPricing : [],
    currentSearch: store.client.currentSearch ? store.client.currentSearch : [],
    validFilters: store.client.validFilters,
    
  }
}

export default withRouter(connect(mapStateToProps, {})(SearchInfo));