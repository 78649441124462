import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import Nouislider from 'nouislider-react';
import { setNewUserServeyForm } from '../../../../reducers/client/actions';

// My first search - Population filter
class Population extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
            color: 'rgb(127, 127, 127)',
            textValue: null,
            percent: null,
            value: 0,
            disabled: false,
            start: [0, 20000000],
            range: {
                min: 0,
                max: 100,
            },
            ref: null,
        };
    }


    componentDidMount() {
        if (this.props.NewUserServeyForm.length === 0) {
            let NewUserServeyForm = this.props.NewUserServeyForm;
            NewUserServeyForm.size = { gt: 0, lt: 20000000 };
            NewUserServeyForm.population = { populationMoreThan: 0, populationThan: 20000000 };
            this.props.dispatch(setNewUserServeyForm(NewUserServeyForm));
        }
        if(this.props.newSurveyResponse!==""){
          
            let populations = JSON.parse(this.props.newSurveyResponse).population;
            this.setState({
                start: [populations.populationMoreThan, populations.populationThan],
            });
            let NewUserServeyForm = this.props.NewUserServeyForm;
            NewUserServeyForm.size = { gt: populations.populationMoreThan, lt: populations.populationThan };
            NewUserServeyForm.population = { populationMoreThan: populations.populationMoreThan, populationThan: populations.populationThan };
            this.props.dispatch(setNewUserServeyForm(NewUserServeyForm));
           
        }
        
        this.updateSliderStepsText();
    }
    // funtion to set label to population pips
    updateSliderStepsText() {
        var steps = document.getElementsByClassName("noUi-value");
        for (let index = 0; index < steps.length; index++) {
            const element = steps[index];
            let value = element.innerText
            if (value >= 10000000) {
                value /= 1000000;
                element.innerText = value + ' mil';
            }
            if (value >= 10000) {
                value /= 1000;
                element.innerText = value + 'k';
            }
            if (value < 10000 && value > 999) {
                element.innerText = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
        }
    }

    // function ot capture population values
    onSlide = (value) => {
        if(parseInt(value[0])!==parseInt(value[1])){
            this.setState({
                start: [parseInt(value[0]), parseInt(value[1])],
            });
            let NewUserServeyForm = this.props.NewUserServeyForm;
            NewUserServeyForm.size = { gt: parseInt(value[0]), lt: parseInt(value[1]) };
            NewUserServeyForm.population = { populationMoreThan: parseInt(value[0]), populationThan: parseInt(value[1]) };
            this.props.dispatch(setNewUserServeyForm(NewUserServeyForm));
        }else{
            if(parseInt(value[0])!==0){
                value[1]=20000000;
            }
            this.setState({
                start: [parseInt(value[0]), parseInt(value[1])],
            });
        }
        

    };


    handleClick = () => {
        this.setState(prevState => ({ value: prevState.value + 10 }));
    };

    changeDisabled = () => {
        this.setState(prevState => ({ disabled: !prevState.disabled }));
    };

    changeRange = () => {
        this.setState({
            range: {
                min: 0,
                max: 200,
            },
        });
    };

    changeByRef = () => {
        const { ref } = this.state;
        if (ref && ref.noUiSlider) {
            ref.noUiSlider.set(20);
        }
    };
    handleChangeAccordian = (panel) => (event, newExpanded) => {
        if (newExpanded === false) {
            this.setState({ expanded: false })
        } else {
            this.setState({ expanded: "panel1" })
        }
    };
    // function to set lable to slider
    onUpdate = index => (render, handle, value, un, percent) => {
        this.updateSliderStepsText();
        return true;
    };


    render() {
        return (
            <div className="row  pt-2 pr-5 pl-5">
                <div className="col ">
                    <section className="PopulationSlider">

                        <div className="noUiSlider">

                            <Nouislider
                                connect={true}
                                snap
                                start={this.state.start}
                                behaviour="tap"
                                pips={{
                                    mode: 'steps', density: (9), values: 9, stepped: true
                                }}

                                range={{
                                    min: 0,
                                    "12.5%": [1000, 2500],
                                    "25%": [2500, 5000],
                                    "37.5%": [5000, 10000],
                                    "47%": [10000, 25000],
                                    "57.5%": [25000, 50000],
                                    "68%": [50000, 100000],
                                    "77.5%": [100000, 500000],
                                    "88%": [500000, 20000000],
                                    max: 20000000
                                }}
                                onSlide={this.onSlide}
                                onUpdate={this.onUpdate()}
                            />

                        </div>
                    </section>
                </div>
            </div >
        );
    }
}



function mapStateToProps(store, props) {
    return {
        NewUserServeyForm: store.client.NewUserServeyForm ? store.client.NewUserServeyForm : [],
        newSurveyResponse: store.client.info.survey_response ? store.client.info.survey_response : ""
    }
}

export default withRouter(connect(mapStateToProps)(Population));
