// Vendor Imports
import React from "react";

// Local Imports
import ClientRoute from "./ClientRoute";
import Search from "../../components/client/Search";
import MySavedSearches from "../../components/client/MySavedSearches";
import MyAccount from "../../components/client/MyAccount";
import MyTransactions from "../../components/client/MyTransactions";
import ConfirmDownload from "../../components/client/MySavedSearches/confirm-download.jsx";
import ConfirmExpireDownload from "../../components/client/MySavedSearches/confirm-expire-download.jsx";
import UpgradeSubscriptionComponent from "../../components/client/UpgradeSubscription";
import CheckoutSubscriptionComponent from "../../components/client/Checkout/Subscription";
import CheckoutCreditsComponent from "../../components/client/Checkout/CreditPurchase";

import SearchPurchaseCredits from "../../components/client/Checkout/SearchCreditPurchase";
import SearchUpgradPlanDownload from "../../components/client/Checkout/SearchUpgradePlan";
import CheckoutExpiredDownload from "../../components/client/Checkout/CheckoutExpiredDownload";
import SearchDownload from "../../components/client/Search/SearchDownloadConfirm";
import OAuthBasedPayment from "../../components/client/Checkout/PaymentMedhod/OAuthBasedPayment";
import LocalGovAccountFile from "../../components/client/LocalGovAccountFile";

export default (
  <React.Fragment>
    <ClientRoute exact path="/" component={Search} />
    <ClientRoute exact path="/search" component={Search} />
    <ClientRoute exact path="/local-gov-account-file" component={LocalGovAccountFile} />
    <ClientRoute exact path="/my-saved-searches" component={MySavedSearches} />
    <ClientRoute
      exact
      path="/confirm-download/:type/download/:id"
      component={ConfirmDownload}
    />
    <ClientRoute
      exact
      path="/confirm-expire-download/:id"
      component={ConfirmExpireDownload}
    />
    <ClientRoute exact path="/my-transactions" component={MyTransactions} />

    <ClientRoute exact path="/my-account" component={MyAccount} />
    <ClientRoute
      exact
      path="/upgrade-subscription"
      component={UpgradeSubscriptionComponent}
    />
    <ClientRoute exact path="/checkout/subscription/:id" component={CheckoutSubscriptionComponent} />
    <ClientRoute exact path="/checkout/purchase/credits/:credits" component={CheckoutCreditsComponent} />
    <ClientRoute exact path="/search/download/upgrade-plan" component={SearchUpgradPlanDownload} />
    <ClientRoute exact path="/search/download/purchase-credits" component={SearchPurchaseCredits} />

    <ClientRoute exact path="/checkout/expired/download" component={CheckoutExpiredDownload} />

    <ClientRoute exact path="/checkout/oauth" component={OAuthBasedPayment} />


    <ClientRoute
      exact
      path="/search/download"
      component={SearchDownload}
    />
  </React.Fragment>
);
