// Vendor Imports
import React from "react";

// Local Imports
import DmsRoute from "./DmsRoute";
import VARoute from "./VARoute";
import DARoute from "./DARoute";
import RRTRoute from "./RRTRoute";
import PIRoute from "./PIRoute";
import QCRoute from "./QCRoute";
import FARoute from "./FARoute";
import PIQCFARoute from "./PIQCFARoute";
import MyActiveTasks from "../../components/dms/Supervisor/MyActiveTasks"
import VoiceAgents from "../../components/dms/Supervisor/ViewVoiceAgents"
import AgeingMis from "../../components/dms/Supervisor/AgeingMis"
import RoleMovementMis from "../../components/dms/Supervisor/RoleMovementMis"
import AddNewOfficials from "../../components/dms/Supervisor/AddNewOfficials"
import Help from "../../components/dms/Supervisor/Help"
import ViewProductivity from "../../components/dms/Supervisor/ViewProductivity"
import FaDashboard from "../../components/dms/Supervisor/FaDashboard"
import QcDashboard from "../../components/dms/Supervisor/QcDashboard"
import PiCreateTask from "../../components/dms/Supervisor/PiCreateTask"
import VoiceAgentDash from "../../components/dms/VoiceAgent/Dashboard"
import EditVoiceAgent from "../../components/dms/VoiceAgent/EditVoiceAgent"
import EditQc from "../../components/dms/Supervisor/EditQc"
import EditFa from "../../components/dms/Supervisor/EditFa"
import DialerAdmin from "../../components/dms/DialerAdmin"
import RRT from "../../components/dms/RRT"
import UnlockGovernments from "../../components/dms/Supervisor/UnlockGovernments"
import QcMis from "../../components/dms/Supervisor/QcMis"

export default (
    <React.Fragment>
        {/* All DMS routes */}
        <DmsRoute exact path="/dms/supervisor/qc-mis" component={QcMis} />
        <DmsRoute exact path="/dms/help" component={Help} />

        {/* PI, QC and FA common routes */}
        <PIQCFARoute exact path="/dms/supervisor/voice-agents" component={VoiceAgents} />
        <PIQCFARoute exact path="/dms/supervisor/view-productivity" component={ViewProductivity} />

        {/* VA routes only */}
        <VARoute exact path="/dms/voice-agent/dashboard" component={VoiceAgentDash} />
        <VARoute exact path="/dms/voice-agent/edit/:gid/:jid/:mode/:ge_ID" component={EditVoiceAgent} />

        {/* DA routes only */}
        <DARoute exact path="/dms/dialer-admin/dashboard" component={DialerAdmin} />

        {/* RMT routes only */}
        <RRTRoute exact path="/dms/rrt/dashboard" component={RRT} />

        {/* PI routes only */}
        <PIRoute exact path="/dms/supervisor/pi-dashboard" component={MyActiveTasks} />
        <PIRoute exact path="/dms/supervisor/unlock-governments" component={UnlockGovernments} />
        <PIRoute exact path="/dms/supervisor/ageing-mis" component={AgeingMis} />
        <PIRoute exact path="/dms/supervisor/role-movement-mis" component={RoleMovementMis} />
        <PIRoute exact path="/dms/supervisor/add-new-officials" component={AddNewOfficials} />
        <PIRoute exact path="/dms/supervisor/create-task" component={PiCreateTask} />

        {/* QC routes only */}
        <QCRoute exact path="/dms/supervisor/qc-dashboard" component={QcDashboard} />
        <QCRoute exact path="/dms/supervisor/qc-edit/:gid/:jid/:mode/:ge_ID" component={EditQc} />

        {/* FA routes only */}
        <FARoute exact path="/dms/supervisor/fa-dashboard" component={FaDashboard} />
        <FARoute exact path="/dms/supervisor/fa-edit/:gid/:jid/:mode/:ge_ID" component={EditFa} /> 
    </React.Fragment>
);
