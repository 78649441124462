// Vendor Imports
import React from "react";
import { connect } from "react-redux";
import Swal from 'sweetalert2'

// Local Imports
import { getNewOfficials, updateStatusOfficial } from "../../../../service/dms";
import AddNew from "./Add";
import EditNew from "./Edit";

class AddNewOfficialsTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tableDetails: null,
            addModal: false,
            editModal: false,
            official: null
        };
    }

    componentDidMount() {
        this.getDetails();
    }

    getDetails = () => {
        this.setState({
            tableDetails: null
        });
        // Api to get officials details
        getNewOfficials().then(res => this.handleResponse(res));
    }

    // Api response handler
    handleResponse = res => {
        if (res.data.code === 200) {
            this.setState({
                tableDetails: res.data.data.items
            });
        }
    };

    // Add modal handler
    toggle = () => {
        this.setState({ addModal: !this.state.addModal })
    }

    // Edit model handler
    editToggle = () => {
        this.setState({ editModal: !this.state.editModal })
    }

    // Open edit modal Handler
    openEditModal = (data) => {
        this.setState({ official: data });

        this.editToggle();
    }

    // CLose edit modal handler
    closeEditModal = () => {
        this.setState({ official: null });
        this.editToggle();
    }

    // Status change Handler
    handleStatusChange = (e, value) => {
        Swal.fire({
            text: 'Are you sure you want change role status?',
            showCancelButton: true,
            confirmButtonText: 'OK',
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                updateStatusOfficial({ id: value.id, status: e.target.value }).then(res => {
                    if (res.data.code === 200) {
                        this.getDetails();
                    } else {
                        Swal.fire({
                            text: res.data.message,
                            showCloseButton: true,
                            confirmButtonText: "Ok",
                            icon: 'error',
                            allowOutsideClick: false
                        });
                    }
                });
            }
        })
    }

    render() {
        return (
            <div>
                <section className="pi-subtitle mt-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <h2>New Officials</h2>
                            </div>
                            <div className="col-md-6">
                                <button type="submit" className="btn pi-btn" onClick={this.toggle}>+ Add Role</button>
                            </div>
                        </div>
                    </div>
                </section>
                {this.state.tableDetails ? <div>
                    <section className="dmstable">
                        <div className="table-responsive">
                            <table className="table table-bordered ">
                                <thead>
                                    <tr>
                                        <th scope="col">ID</th>
                                        <th scope="col">Role</th>
                                        <th scope="col">Number of Govs</th>
                                        <th scope="col">Operation Model</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.tableDetails.map((listValue, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{listValue.id}</td>
                                                <td>{listValue.role_name}</td>
                                                <td>{listValue.cnt}</td>
                                                <td>{listValue.etype}</td>

                                                {listValue.status === "draft" ? <td>
                                                    <select className="form-select"
                                                        aria-label="Default select example"
                                                        onChange={(e) => this.handleStatusChange(e, listValue)}>
                                                        <option value="Draft">Draft</option>
                                                        <option value="active">Active</option>
                                                    </select></td> : <td>{listValue.status}</td>}
                                                <td><a href="#" onClick={() => this.openEditModal(listValue)}>Edit</a></td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </section>
                </div> : <div className="text-center">
                    <div
                        className="spinner-border text-primary mt-3"
                        style={{ width: "3rem", height: "3rem", marginLeft: "auto" }}
                        role="status"
                    >

                        <span className="sr-only">Loading...</span>
                    </div></div>}

                {/* Add Official Modal */}
                <AddNew modal={this.state.addModal} toggle={this.toggle} refreshPage={this.getDetails} />
                {/* Edit Official Modal */}
                {this.state.editModal ? <EditNew modal={this.state.editModal} toggle={this.closeEditModal} official={this.state.official} refreshPage={this.getDetails} /> : <span></span>}
            </div>
        );
    }
}

// Export
export default connect()(AddNewOfficialsTable);
