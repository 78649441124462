// Vendor Imports
import React from "react";
import { Helmet } from 'react-helmet';

// Help component
class HelpComponent extends React.Component {

    render() {
        return (
            <>
                <Helmet>
                    <title>Help</title>
                </Helmet>
                <section>
                    <section className="titlebar">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <h1>Help</h1>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>

                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="white-bg">
                                    <section className="dmstable">
                                        <div className="table-responsive">
                                            <table className="table table-bordered ">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Process Initiator Role</th>
                                                        <th scope="col">Voice Agent Role</th>
                                                        <th scope="col">Quality Checker Role</th>
                                                        <th scope="col">Final Approver Role</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td width="25%">
                                                            <li>Log in.</li>
                                                            <li>Create a new TASK.</li>
                                                            <li>Select a Voice Agent, Quality Checker, and Final Approver for the TASK.</li>
                                                            <li>Select Government IDs (up to 25 at a time) for the Voice Agent to work on.</li>
                                                            <li>Activate by making the TASK available to the Voice Agent.</li>

                                                        </td>
                                                        <td width="25%"><li>Log in.</li>
                                                            <li>Start by editing a TASK assigned to you by the Process Initator.</li>
                                                            <li>Update or confirm each Government Officials for the selected Government ID.</li>
                                                            <li>When you are done with all the Government Officials for a specific Government ID, it will automatically be moved to the Quality Checker for verification.</li>
                                                            <p>The Quality Checker or Final Approver may send the Government ID (and all associated Government Officials) back for more edits/updates. Repeat the process but be sure to read the Notes section for additional instructions.</p>
                                                        </td>
                                                        <td width="25%" ><li>Log in.</li>
                                                            <li>Start by editing a TASK completed by the Voice Agent.</li>
                                                            <li>Confirm the Government ID and pass it to the Final Approver if clean - it will automatically be moved to the Final Approver for verification.</li>
                                                            <li>Otherwise send it back to the Voice Agent for further updates - remember to add instructions to the Notes section for the Voice Agent.
                  </li>
                                                        </td>
                                                        <td width="25%"><li>Log in.</li>
                                                            <li>Start by editing a TASK completed by the Quality Checker.</li>
                                                            <li>Confirm the Government ID and pass it to the Data Uploader if clean - it will automatically be moved to the Data Uploader for verification.</li>
                                                            <li>Otherwise send it back to the Voice Agent or Quality Checker for further updates - remember to add instructions to the Notes section for the Voice Agent or Quality Checker.</li>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </section>
                                    <section className="help">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-12">
                                                    <h3>Productivity</h3>
                                                    <h5>Applies to Voice Agent Roles only.</h5>
                                                    <p> Productivity is defined to be the AVERAGE per first confirm (of any Government official record). This is calculated according to the deltas (elapsed time) from confirm to confirm in SECONDS.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );

    }
}

// Export
export default HelpComponent;
