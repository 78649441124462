import React, {useEffect, useState} from 'react';
import { useDispatch } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Swal from "sweetalert2";
// import UserInformation from "./UserInformation";
import {
    getUserDetails,
    completeActivation
  } from "../../../service/admin";
  
function AccountActivationStep(props){
    const [user, setUser]=useState([]);
    const [loading, setLoading]=useState(false);
    const [loadingBtn, setLoadingBtn]=useState('');
    let activated = user.Account_Activated!=="1" ? true : false;
    let subscriptionAdded = user.Account_Activated!=="1" ? true : false;
    let surveyform= user.newusersurvey===null ? true : false;
    let Phone = user.User_NewPhone==="" || user.User_NewPhone===null ? true : false;
    let verfied = user.Verified_Date==="N/A" || user.Verified_Date===1 ? true : false;

    useEffect(()=>{
        fetchUserDetails();
    },[])
    const fetchUserDetails = async ()=>{
        setLoading(true)
       await getUserDetails(props.Account.RegUser_ID).then((res) =>
             handleUserDetailResponse(res.data.data),
        );
    }

const handleUserDetailResponse = (response)=>{
    setUser(response);
    setLoading(false)
}

const handleComplete = ()=>{
  setLoadingBtn('loading');
  completeActivation(user.RegUser_ID).then((res) =>
  handleActivationResponse(res),
);
}
const handleActivationResponse = (res)=>{
  setLoadingBtn('');
  if (res.data.code === 200) {
    fetchUserDetails();
  }else{
    Swal.fire({
      html: res.data.message,
      showCloseButton: true,
      confirmButtonText: "Ok",
      icon: "error",
      allowOutsideClick: false,
    });
  }
}


return(
    <Modal
    isOpen={props.modal}
    backdrop="static"
    centered={true}
  >
    <ModalHeader
      toggle={()=>props.toggle()}
      className="backColor"
    >
    Account Activation Wizard
    </ModalHeader>
        <ModalBody>
            {loading || user.length==0 ?
            <>
                <div class="spinner-border text-primary"> </div> 
                <p className='text-center'>Fetching user details...</p>
                
            </>
            :
          
            <table class="table table-striped admin-table">
                <thead>
                <tr>
                    <th>Steps</th>
                    <th>Status</th>
                  
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>Email Verified</td>
                    <td>
                        {activated ?   <i className="fa fa-times-circle-o text-danger activation-fa" aria-hidden="true"></i> :   <i className="fa fa-check-circle-o text-success activation-fa"  aria-hidden="true"></i>
                        
                    }</td>
                  
                </tr>
                <tr>
                <td>Subscription Added</td>
                <td>
                        {subscriptionAdded ?   <i className="fa fa-times-circle-o text-danger activation-fa" aria-hidden="true"></i> :   <i className="fa fa-check-circle-o text-success activation-fa"  aria-hidden="true"></i>
                        
                     }
                    </td>
                   
                </tr>
                
                <tr>
                <td>Phone Number Entered</td>
                <td>
                        { Phone ?   <i className="fa fa-times-circle-o text-danger activation-fa" aria-hidden="true"></i> :   <i className="fa fa-check-circle-o text-success activation-fa"  aria-hidden="true"></i>
                        
                         }</td>
                   
                </tr>
                
                <tr>
                <td>Account Activated</td>
                     <td>
                    {verfied ?   <i className="fa fa-times-circle-o text-danger activation-fa" aria-hidden="true"></i> :     <i className="fa fa-check-circle-o text-success activation-fa"  aria-hidden="true"></i>
                    
                        }
                    </td>
                   
                </tr>
                
                </tbody>
            </table>
           
            }
            {
              (activated || surveyform || Phone || verfied ) && user.length!==0 ?
              <div className='text-right'>
              <button onClick={()=>handleComplete()}  className={"btn btn-danger "+ loadingBtn}>Complete</button>
           </div>
           :
           ""
            }
          
        </ModalBody>
    <ModalFooter>
      <button
        type="button"
        className="btn btn-secondary"
        onClick={()=>props.toggle()}
      >
        Close
      </button>
    </ModalFooter>
  </Modal>

)
}
export default AccountActivationStep;