// Vendor Imports
import React from "react";
import { Route, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
// Local Imports
import DmsLayout from "../../layouts/DmsLayout";

// Client Route
const componentWithLayout = (DmsComponent, props) => {
    if (props.dmsUser) {
        if(props.dmsUser.access_level === "Supervisor"){
            if (props.dmsUser.primary_role === 'QualityChecker') {
                return (
                    <DmsLayout>
                        <DmsComponent {...props} />
                    </DmsLayout>
                );
            }
            if (props.dmsUser.primary_role === 'ProcessInitiator') {
                props.history.push("/dms/supervisor/pi-dashboard");
            }
            if (props.dmsUser.primary_role === 'FinalApprover') {
                props.history.push("/dms/supervisor/fa-dashboard");
            }
        }
        else{
            if (props.dmsUser.access_level === 'VoiceAgent') {
                props.history.push("/dms/voice-agent/dashboard");
            }
            else if (props.dmsUser.access_level === 'DialerAdmin') {
                props.history.push("/dms/dialer-admin/dashboard");
            }
            else if (props.dmsUser.access_level === 'RecencyManagementTeam') {
                props.history.push("/dms/rrt/dashboard");
            }
        }
    } else {
        props.history.push("/dms");
    }
};

class QCRoute extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    render() {
        const { menu, component: component, ...rest } = this.props;
        return (
            <Route
                {...rest}
                render={() => componentWithLayout(component, this.props)}
            />
        );
    }
}

// Export

function mapStateToProps(store, props) {
    return {
        dmsUser: store.dms.dmsUser ? store.dms.dmsUser : null,
        authToken: store.dms.authToken ? store.dms.authToken : null
    }
}

export default withRouter(connect(mapStateToProps, {})(QCRoute));
