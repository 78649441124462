// Vendor Imports
import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { Helmet } from 'react-helmet'
import { setCheckout } from '../../../../reducers/client/actions';
import { getCheckout } from '../../../../service/client';
// Local Imports
import OnlinePayment from '../PaymentMedhod/OnlinePayment'
import OfflinePayment from '../PaymentMedhod/OfflinePayment'
import { renderCount, renderCurrency } from '../../../../utils'

// Subscription upgrade package checkout page
function CheckoutSubscriptionComponent(props) {
  const [InVoiceData, setInVoiceData] = useState([]);
  const checkout = useSelector((store) => store.client.checkout);
  const dispatch = useDispatch();
  const queryString = props.computedMatch.params;
  // get and set package details
  useEffect(() => {
    if(queryString.id){
      callCheckoutAPI();
    }
  },[]);
 
  // function to call checkout api to upgrade subscription plan
  const callCheckoutAPI = () => {
    let params = { type: "subscription", id: queryString.id };
    getCheckout(params).then(res =>
      setCheckoutData(res)
    )
  }
  const setCheckoutData = (res)=>{
    dispatch(setCheckout(res.data.data));
    setInVoiceData(res.data.data.invoice);

  }
  if(checkout.length==0){
    return (
        <div className="row pt-5 text-center">
          <p>Payment information not available</p>
        </div>
    )
  }else{
    return (
      <>
        <Helmet>
          <title> Checkout - Power Almanac </title>
        </Helmet>
        <div >
          <section className="my-search">
            <div className="container">
              <div classNameCheckoutComponent="row">
                <div className="col">
                  <h1 className="display-4 text-uppercase">
                    SELECT A PAYMENT OPTION
                </h1>
                </div>
              </div>
            </div>
          </section>

          <div className="container">
            {
              InVoiceData.length === 0 ?
                <div className="row pt-5 text-center">
                  <p>Payment information not available</p>
                </div>
                :
                <div className="row pt-5">
                  <div className="col-lg-6">

                    <table className="table payoption-table">
                      <thead>
                        <tr>
                          <th scope="row" className="border-0">Item Ordered</th>
                          <th scope="row" className="border-0">Credits Included</th>
                          <th scope="row" className="border-bottom border-top-0 text-left" >Unit Price</th>
                          <th scope="row" className="border-bottom border-top-0 text-right" >Total Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          InVoiceData.length !== 0 ?

                            InVoiceData.purchases.map(function (item, i) {
                              return (<tr key={i}>
                                <td className="border-bottom">
                                  {item.name}
                                </td>
                                <td className="border-bottom">
                                  {item.no_of_records === "Unlimited" ? "Unlimited" : renderCount(item.no_of_records)}
                                </td>
                                <td>${renderCurrency(item.unit_price)}</td>
                                <td className="text-right">${renderCurrency(item.unit_price)}</td>
                              </tr>)
                            })
                            :
                            <div className="spinner-grow" role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                        }


                        <tr>
                          <td> <strong>Total Purchase </strong></td>
                          <td ><strong>{InVoiceData.creditsIncluded} </strong></td>
                          <td> </td>
                          <td className="text-right" >
                            <strong> ${renderCurrency(InVoiceData.totalPrice)}  </strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <div className="row">
                      <div className="col-md-5 text-right">Subscription Start Date: </div>
                      <div className="col-md-7 text-left">{InVoiceData.SubscriptionStartDate} </div>
                    </div>
                    <div className="row">
                      <div className="col-md-5 text-right">Subscription End Date: </div>
                      <div className="col-md-7 text-left">{InVoiceData.SubscriptionEndDate}</div>
                    </div>
                  </div>

                  <div className="col-lg-6 pay-col">
                    <OnlinePayment history={props.history} data={InVoiceData} checkoutType="subscription" />
                    <OfflinePayment checkoutType="subscription" />
                  </div>

                </div>
            }

          </div>
        </div>
      </>
    );
  }

}


export default withRouter(CheckoutSubscriptionComponent);
