// Vendor Imports
import React from "react";
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { withRouter } from "react-router-dom";
import Swal from 'sweetalert2'

// Local Imports
import { addDispostion } from "../../../../../service/dms";
// import Form from "./Form";

// Add Disposition Modal
class AddDispositionModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            backendErrors: null,
            loading: '',
            disposition: 'Cant Connect or No Answer'
        };
    }

    // Submit Handler
    handleSubmit = () => {
        let params = {
            jid: this.props.jid,
            gid: this.props.gid,
            note: this.state.disposition,
            confirmed: this.getConfirmMarked(),
            etype: this.props.tableDetails.government_info.verification_type,
            total: this.getTotalMarked(),
            page: 'voiceagent'
        };
        if (this.state.disposition.toLowerCase() === "confirm completed") {
            if (this.getEditedNotConfirmed()) {
                Swal.fire({
                    title: "Confirm Edited Officials",
                    text: '1 or more roles have been edited but not confirmed. Are you sure to proceed and discard the changes to this role?',
                    showCancelButton: true,
                    showCloseButton: true,
                    showConfirmButton: false,
                    cancelButtonText: 'Go Back',
                    icon: 'question',
                    allowOutsideClick: false
                });
                return;
            } else if (this.getUnconfirmMarked() !== 0) {
                Swal.fire({
                    title: "Confirm Edited Officials",
                    text: 'Are you sure you want to dispose the government "Confirmed Completed". You still have few functions for edit/add Notes.',
                    showCancelButton: true,
                    showCloseButton: true,
                    showConfirmButton: false,
                    cancelButtonText: 'Go Back',
                    icon: 'question',
                    allowOutsideClick: false
                });
                return;
            } else if (this.props.ePlusStatus) {
                Swal.fire({
                    title: "Confirm Edited Officials",
                    text: 'Are you sure you want to dispose the government "Confirmed Completed". You still have few functions for edit/add Notes.',
                    showCancelButton: true,
                    showCloseButton: true,
                    showConfirmButton: false,
                    cancelButtonText: 'Go Back',
                    icon: 'question',
                    allowOutsideClick: false
                });
                return;
            } else if (this.props.tableDetails.government_info.verification_type !== "" && this.props.tableDetails.dual_roles !== null) {
                Swal.fire({
                    title: "Confirm Edited Officials",
                    text: `Please check and confirm ${this.props.tableDetails.dual_roles} which has the same person from the roles already confirmed`,
                    showCancelButton: true,
                    showCloseButton: true,
                    showConfirmButton: false,
                    cancelButtonText: 'Go Back',
                    icon: 'question',
                    allowOutsideClick: false
                });
                return;
            }
            else {
                this.setState({ loading: "loading" });
                addDispostion(params).then(res => this.handleResponse(res))
            }
        } else if (this.state.disposition.toLowerCase() !== "partial completed. to be called back") {
            Swal.fire({
                title: "Dispose Government",
                text: 'This govenment is being editing by you. Do you want to dispose?',
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonText: "Proceed",
                cancelButtonText: 'Go Back',
                icon: 'question',
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    this.setState({ loading: "loading" });
                    addDispostion(params).then(res => this.handleResponse(res))
                }
            })
        } else {
            this.setState({ loading: "loading" });
            addDispostion(params).then(res => this.handleResponse(res))
        }
    }

    // Response Handler
    handleResponse = res => {
        if (res.data.code === 200) {
            this.setState({
                backendErrors: null,
                loading: '',
            });
            this.props.toggle();
            this.props.history.push("/dms/voice-agent/dashboard");
        } else {
            this.setState({ backendErrors: res.data.message, loading: "" });
        }
    }

    // Get confirm gov handler
    getConfirmMarked = () => {
        let data = 'government_confirmed' in this.props.tableDetails ? this.props.tableDetails.government_confirmed.filter(g => g.mark === "marked") : [];
        return data.length;
    }

    // Get unconfirm gov handler
    getUnconfirmMarked = () => {
        let members = this.getAllMembers();
        let data = members.filter(g => g.mark === "marked");
        return data.length;
    }

    // Get total gov handler
    getTotalMarked = () => {
        let unconfirm = this.getUnconfirmMarked();
        let confirm = this.getConfirmMarked();
        return unconfirm + confirm;
    }

    // Get all members handler
    getAllMembers = () => {
        let members = [this.props.tableDetails.top_elected_official];
        members.push(this.props.tableDetails.governing_board_member)
        members.push(this.props.tableDetails.top_appointed_executive)
        members.push(this.props.tableDetails.other_government_officials)
        let flatMembers = members.flat();
        let removeUndef = flatMembers.filter(f => f !== undefined)
        return removeUndef;
    }

    // Get edited but not confirmed gov handler
    getEditedNotConfirmed = () => {
        let data = this.getAllMembers();
        let data2 = data.map(d => {
            let val = [];
            val.push(d.Email_Address_notes);
            val.push(d.First_Name_notes);
            val.push(d.Government_Title_notes);
            val.push(d.Last_Name_notes);
            val.push(d.Mailing_City_notes);
            val.push(d.Mailing_State_notes);
            val.push(d.Mailing_Street_Box_notes);
            val.push(d.Mailing_Suite_Number_notes);
            val.push(d.Mailng_Zip_Code_notes);
            val.push(d.Person_ID_notes);
            val.push(d.Phone_Number_Ext_notes);
            val.push(d.Phone_Number_notes);
            return val;
        });
        let flatMembers = data2.flat();
        return flatMembers.includes("1");
    }

    // Close modal handler
    closeModal = () => {
        this.setState({
            backendErrors: null,
            loading: '',
        });
        this.props.toggle();
    }

    render() {
        return (
            <div>
                {/* Add Modal */}
                < Modal isOpen={this.props.modal} backdrop="static" centered={true}>
                    <ModalHeader toggle={this.closeModal} className="backColor"><h4>Add Disposition</h4></ModalHeader>
                    <ModalBody>
                        {this.state.backendErrors ? (
                            <div
                                className="alert alert-danger"
                                role="alert"
                                dangerouslySetInnerHTML={{
                                    __html: this.state.backendErrors
                                }}
                            ></div>
                        ) : null}
                        <div>
                            <div className="form-group">
                                <label htmlFor="dispositionSelect" style={{ fontSize: "14px" }}>Add Disposition to this Government</label>
                                <select className="form-control" id="dispositionSelect" value={this.state.disposition} onChange={e => this.setState({
                                    disposition: e.target.value
                                })}>
                                    <option value='Cant Connect or No Answer'>Cant Connect or No Answer</option>
                                    <option value='Answering Machine or Left Voice mail'>Answering Machine or Left Voicemail</option>
                                    <option value='Refused to Participate'>Refused to Participate</option>
                                    <option value='Partial Completed. To Be Called Back'>Partial Completed. To Be Called Back</option>
                                    <option value='Confirm Completed'>Confirm Completed</option>
                                </select>
                            </div>
                            <div className="text-right">
                                <button type="button" className={"btn btn-primary backColor  " + this.state.loading} onClick={this.handleSubmit}>Add Disposition</button>&nbsp;
                                <button type="button" className="btn btn-secondary" onClick={this.closeModal}>Cancel</button>
                            </div>
                        </div>
                    </ModalBody>
                </Modal >
            </div>
        );
    }
}

export default withRouter(connect()(AddDispositionModal));
