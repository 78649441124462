// Vendor Imports
import React from "react";
import MaterialTable from "material-table";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Swal from 'sweetalert2';

// Local Imports
import { getPaypalTransactions, getPaypalDetails } from "../../../service/admin";
import { dateFormatted } from "../../../utils";

// Paypal Transaction Table
class PaypalTransactionComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tableDetails: null,
            detailModal: false,
            transDetails: null
        };
    }

    componentDidMount() {
        // Api call to get paypal transaction data
        getPaypalTransactions().then(res => this.handleResponse(res))
    }

    // Handle popup modal
    onDetailsClick = id => {
        this.setState({ detailModal: true })
        getPaypalDetails(id).then(res => {
            if (res.data.code === 200) {
                this.setState({
                    transDetails: res.data.data.values,
                });
            } else {
                Swal.fire({
                    text: res.data.message,
                    showCloseButton: true,
                    confirmButtonText: "Ok",
                    icon: 'error',
                    allowOutsideClick: false
                });
                return;
            }
        })
    }

    // Handle api response
    handleResponse = res => {
        if (res.data.code === 200) {
            this.setState({
                tableDetails: res.data.data,
            });
        }
    };

    // Toggle popup modal
    toggle = () => {
        this.setState({ detailModal: !this.state.detailModal, transDetails: null });
    }

    render() {
        const columns = [
            {
                title: "ID",
                field: "id"
            },
            { title: "Trans SKU", field: "Trans SKU" },
            { title: "Trans Date", field: "Trans Date_ft", render: rowData => dateFormatted(rowData['Trans Date_ft']) },
            { title: "Trans ID", field: "Trans ID" },
            { title: "Trans Amount", field: "Trans Amount", render: rowData => "$" + rowData['Trans Amount'] },
            { title: "Trans Credit", field: "Trans Credit" },
            { title: "User Email", field: "User Email" },
            { title: "Actions", render: rowData => <button type="button" className="btn btn-primary edit-btn" onClick={() => this.onDetailsClick(rowData.id)}>Details</button> }
        ];
        return (
            <section className="admin-table" >
                {this.state.tableDetails ?
                    <div>
                        <MaterialTable
                            className="table display search-table table-responsive"
                            columns={columns}
                            data={this.state.tableDetails}
                            title=""
                            options={{
                                sorting: true,
                                actionsColumnIndex: -1,
                                paging: true,
                                pageSize: 20,
                                pageSizeOptions: [20, 50, 100],
                                emptyRowsWhenPaging: false,
                                search: true,
                                toolbar: true
                            }}
                            localization={{
                                toolbar: {
                                    nRowsSelected: '',
                                }
                            }}
                        />
                    </div>
                    : <div className="text-center">
                        <div
                            className="spinner-border text-primary mt-3"
                            style={{ width: "3rem", height: "3rem", marginLeft: "auto" }}
                            role="status"
                        >

                            <span className="sr-only">Loading...</span>
                        </div></div>
                }

                <Modal isOpen={this.state.detailModal} backdrop="static" centered={true} >
                    <ModalHeader toggle={this.toggle} className="backColor">PayPal Transaction Details</ModalHeader>
                    <ModalBody>
                        {this.state.transDetails ?
                            this.state.transDetails.map((listValue, index) => {
                                return (
                                    <li>{listValue}</li>
                                );
                            })
                            : <div className="text-center">
                                <div
                                    className="spinner-border text-primary mt-3"
                                    style={{ width: "3rem", height: "3rem", marginLeft: "auto" }}
                                    role="status"
                                >

                                    <span className="sr-only">Loading...</span>
                                </div></div>}
                    </ModalBody>
                    <ModalFooter>
                        <button type="button" className="btn btn-secondary" onClick={this.toggle}>Close</button>
                    </ModalFooter>
                </Modal >
            </section >

        );
    }
}

// Export
export default PaypalTransactionComponent;
