import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import { loadSavedSearchName } from '../../../../utils';
import { setAppliedFilters,setValidateFilters } from '../../../../reducers/client/actions';

// Government type filter component
function GovernmentType(props) {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = React.useState('');
  const handleChangeAccordian = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  // Government type filter and values
  let formFields = [
    { checked: true, label: "Cities / Municipalities", name: "govType_municipalities", value: "municipality" },
    { checked: true, label: "Townships", name: "govType_townships", value: "township" },
    { checked: true, label: "Counties", name: "govType_counties", value: "county" },
  ];


  let rolesFilter = useSelector(store => store.client.validFilters);
  const [CheckedCount, setCheckedCount] = React.useState(formFields.length);

  const [Fields, setCheckedFields] = React.useState(formFields);
  let [PreviousParams, setPreviousParams] = React.useState({});

  // set initial checked inputs or loaded saved search parameters
  useEffect(() => {
    if (Object.keys(props.currentSearch).length !== 0 && PreviousParams !== props.currentSearch) {

      let loadParams = "";
      let loadSavedSearch = [];
      if (props.currentSearch.id !== "0" || props.currentSearch.id !== "") {
        loadParams = props.currentSearch.search_params;
        loadSavedSearch = loadSavedSearchName(loadParams, formFields);
      } else if (props.currentSearch.id === "0" || props.currentSearch.id === "") {
        loadParams = props.currentSearch.params;
        loadSavedSearch = loadSavedSearchName(loadParams, formFields);
      }

      if (Fields !== loadSavedSearch && loadSavedSearch.length !== 0) {
        setPreviousParams(props.currentSearch);
        setCheckedFields(loadSavedSearch.appliedFilters);
        setCheckedCount(loadSavedSearch.checkedcount);
        let appliedFilters = props.appliedFilters;
        appliedFilters.governmentType = loadSavedSearch.checkedcount;
        dispatch(setAppliedFilters(appliedFilters));
        if (loadSavedSearch.checkedcount !== 0 && loadSavedSearch.checkedcount !== formFields.length) {
          setExpanded("govType");
        } else {
          if (expanded !== "") {

          } else {
            setExpanded("");
          }
        }
      }
    }
  });
  //function to capture changed inputs and trigger search api
  const handleChange = (event) => {
    let MinimumChecked = 0;
    let uncheckedInputs = [];
    Fields.map((field, index) => {
      if (event.target.value === field.value) {
        field.checked = event.target.checked;
      }
      if (field.checked === true) {
        MinimumChecked++;
      }
      uncheckedInputs.push(field);
    })
    let  allSearchFilter = {};
    if(MinimumChecked===0)
    {
      
      allSearchFilter = {
        rolesf: rolesFilter.rolesf,
        statesf: rolesFilter.statesf,
        gtypef: false,
        gftef: rolesFilter.gftef,
        gspendf: rolesFilter.gspendf,
        fydf: rolesFilter.fydf,
        molf: rolesFilter.molf
      }
      dispatch(setValidateFilters(allSearchFilter));
    } else {
      allSearchFilter = {
        rolesf: rolesFilter.rolesf,
        statesf: rolesFilter.statesf,
        gtypef: true,
        gftef: rolesFilter.gftef,
        gspendf: rolesFilter.gspendf,
        fydf: rolesFilter.fydf,
        molf: rolesFilter.molf
      }
      dispatch(setValidateFilters(allSearchFilter));
    }
    setCheckedFields(uncheckedInputs);
    setCheckedCount(MinimumChecked);
    let appliedFilters = props.appliedFilters;
    appliedFilters.governmentType = MinimumChecked;
    dispatch(setAppliedFilters(appliedFilters));
    props.triggerSearch(uncheckedInputs, true, allSearchFilter);
    
  };
  // function to select all types checkboxes
  const handleSelectAll = () => {
    let uncheckedInputs = [];
    Fields.map((field, index) => {
      field.checked = true;
      uncheckedInputs.push(field);
    })
    let  allSearchFilterdt = {
        rolesf: rolesFilter.rolesf,
        statesf: rolesFilter.statesf,
        gtypef: true,
        gftef: rolesFilter.gftef,
        gspendf: rolesFilter.gspendf,
        fydf: rolesFilter.fydf,
        molf: rolesFilter.molf
    }
    dispatch(setValidateFilters(allSearchFilterdt));
    setCheckedFields(uncheckedInputs);
    setCheckedCount(Fields.length);
    let appliedFilters = props.appliedFilters;
    appliedFilters.governmentType = 3;
    dispatch(setAppliedFilters(appliedFilters));
    props.triggerSearch(uncheckedInputs, true, allSearchFilterdt);
  };
  // function to select all types checkboxes
  const handleDeSelectAll = () => {
    let uncheckedInputs = [];
    Fields.map((field, index) => {
      field.checked = false;
      uncheckedInputs.push(field);
    })
    setCheckedFields(uncheckedInputs);
    setCheckedCount(0);
    let appliedFilters = props.appliedFilters;
    appliedFilters.governmentType = 0;
    dispatch(setAppliedFilters(appliedFilters));
    let  allSearchFilterdf = {
        rolesf: rolesFilter.rolesf,
        statesf: rolesFilter.statesf,
        gtypef: false,
        gftef: rolesFilter.gftef,
        gspendf: rolesFilter.gspendf,
        fydf: rolesFilter.fydf,
        molf: rolesFilter.molf
    }
    dispatch(setValidateFilters(allSearchFilterdf));
  };

  return (

    <div id="governmentType" className="row">
      <div className="col ">
        <Accordion className="accordianLine" expanded={expanded === 'govType'} onChange={handleChangeAccordian('govType')}>
          <AccordionSummary

            expandIcon={<ExpandMoreIcon />}
            aria-controls="govTypea-content"
            id="govTypea-header"
          >
            <Typography className=""> Government Type</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="row">
              {
                Fields.map((field, index) => {
                  return (
                    <div key={index} className="col-12">
                      <FormGroup >
                        <FormControlLabel
                          className="form-check-label roleLabel"
                          control={<Checkbox checked={field.checked} className="roleCheckbox" onChange={handleChange} name={field.name} value={field.value} />}
                          label={<Typography className="text-small" dangerouslySetInnerHTML={{ __html: field.label }} />}
                        />
                      </FormGroup>
                    </div>
                  );

                })
              }

              <div className="col-12 text-small text-center">
                {CheckedCount === 0 ?
                  <div className="text-danger mb-2 w-100" >Select 1 or more government types</div>
                  :
                  ""
                }

                <a onClick={handleSelectAll} href="javascript:void(0)" className="select-all" >Select All</a> | {" "}
                <a onClick={handleDeSelectAll} href="javascript:void(0)" className="deselect-all" >Deselect All</a>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>

      </div>
    </div >

  )
}


function mapStateToProps(store, props) {
  return {
    currentSearch: store.client.currentSearch ? store.client.currentSearch : [],
    appliedFilters: store.client.appliedFilters ? store.client.appliedFilters : [],
  }
}

export default connect(mapStateToProps, {})(GovernmentType);