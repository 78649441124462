import React from "react";
import NumberFormat from 'react-number-format';
// Power entry subscription card component
function PowerEntry(props) {
  const plan = props.data;
  const currentPlan = props.currentPlan;
  return (
    <div className="col-sm-6 col-lg-3 mb-4 p-0  border-left-0  ">
      <section className="card custom-card border-top-0 border-left-0">
        
        <div className="card-header custom-card-header record-plan" >
          <h3
            className="m-0 text-center record-title"
            style={{ textTransform: "uppercase" }}
          > POWER ENTRY
          
          </h3>
         
          <p className="m-0 text-center">
          <i> Get Stared </i></p>
          
          
        </div>
        <div className="card-header pricing">
          <p className="m-0 text-center">
            <span className="title">FREE <br/>PLAN</span>
          </p>
          <p className="m-0  text-center">
            <span className="title-sub">$0</span>
          </p>
          
        </div>
        <div className="card-body p-0">
          <ul className="list-group list-group-flush et_pb_pricing">
          
            <li className="m-0">
              <span>100 Credits per Month</span>
            </li>
            <li className="m-0">
              <span>90¢ per Additional Record</span>
              
            </li>
            <li className="m-0">
              <span>
              Use Credits to Download Record Updates
                {/* {plan.Sub_CostPerDL * 100}&cent; for Each Additional Record Over 100 */}
              </span>
            </li>
            <li className="m-0">
              <span>
              No Credit Card Needed
              </span>
            </li>
          </ul>
        </div>
        <div className="card-footer border-top-0">
          {currentPlan === plan.Sub_Level ?
            <span className="btn currentPlan w-100 text-truncate rounded current-plan" style={{ backgroundColor: "#50c5a9 !important" }}>
              Current Plan
            </span>
            :
            <span className="btn btn-pa btn-danger w-100  rounded text-truncate unavailable" >Unavailable</span>
          }
        </div>
      </section>
    </div>
  );
}

export default PowerEntry;
