import React from 'react';
import { connect, useSelector } from 'react-redux';
import { renderCount, renderEmailPercent } from "../../../../utils";

// Election month search stats
function ByElectionMonth(props) {
  let tamMode = useSelector(store => store.client.tamMode);
  let Summary = 'JSON_SUMMARY' in props.searchResult ? JSON.parse(props.searchResult.JSON_SUMMARY) : {};
  let Governments = 'JSON_GOVERNMENTS' in props.searchResult ? JSON.parse(props.searchResult.JSON_GOVERNMENTS) : {};
  let Officials = 'JSON_OFFICIALS' in props.searchResult ? JSON.parse(props.searchResult.JSON_OFFICIALS) : {};
  let fiscal = [{ label: "January", name: "ElectionJanuary", govCount: "ElectionJanuaryGov", emailCount: "ElectionJanuaryEmail", count: 0 },
  { label: "February", name: "ElectionFebruary", govCount: "ElectionFebruaryGov", emailCount: "ElectionFebruaryEmail", count: 0 },
  { label: "March", name: "ElectionMarch", govCount: "ElectionMarchGov", emailCount: "ElectionMarchEmail", count: 0 },
  { label: "April", name: "ElectionApril", govCount: "ElectionAprilGov", emailCount: "ElectionAprilEmail", count: 0 },
  { label: "May", name: "ElectionMay", govCount: "ElectionMayGov", emailCount: "ElectionMayEmail", count: 0 },
  { label: "June", name: "ElectionJune", govCount: "ElectionJuneGov", emailCount: "ElectionJuneEmail", count: 0 },
  { label: "July", name: "ElectionJuly", govCount: "ElectionJulyGov", emailCount: "ElectionJulyEmail", count: 0 },
  { label: "August", name: "ElectionAugust", govCount: "ElectionAugustGov", emailCount: "ElectionAugustEmail", count: 0 },
  { label: "September", name: "ElectionSeptember", govCount: "ElectionSeptemberGov", emailCount: "ElectionSeptemberEmail", count: 0 },
  { label: "October", name: "ElectionOctober", govCount: "ElectionOctoberGov", emailCount: "ElectionOctoberEmail", count: 0 },
  { label: "November", name: "ElectionNovember", govCount: "ElectionNovemberGov", emailCount: "ElectionNovemberEmail", count: 0 },
  { label: "December", name: "ElectionDecember", govCount: "ElectionDecemberGov", emailCount: "ElectionDecemberEmail", count: 0 },
  { label: "Not Avlb", name: "ElectionNot_Known", govCount: "ElectionNot_KnownGov", emailCount: "ElectionNot_KnownEmail", count: 0 }];

  return (
    <>
      <tbody id="ByElectionMonth">
        {
          fiscal.map((month, index) => {
            return (
              <tr key={index}>
                <td> <span dangerouslySetInnerHTML={{ __html: month.label }} /> </td>
                <td>
                  <span className="setToZero">{renderCount(Officials[month.name])} </span>
                </td>
                {
                  tamMode ?
                    <>
                      <td>
                        <span className="setToZero">{renderCount(Governments[month.govCount])}</span>
                      </td>
                      <td> {renderEmailPercent(Summary.TamGovs, Governments[month.govCount])}%</td>
                    </> :
                    <>
                      <td>
                        <span className="setToZero">{renderCount(Officials[month.emailCount])}</span>
                      </td>
                      <td> {renderEmailPercent(Officials[month.name], Officials[month.emailCount])}%</td>
                    </>
                }


              </tr>
            );
          })
        }
      </tbody>

    </>

  )
}


function mapStateToProps(store, props) {
  return {
    searchResult: store.client.searchResult ? store.client.searchResult : []
  }
}

export default connect(mapStateToProps, {})(ByElectionMonth);
