import React from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

// Page not found
function PageNotFound(props) {
  return (
    <>
      <Helmet>
        <title>Power Almanac - 404 Page</title>
      </Helmet>
      <section className="container mb-5 login-section login-block" >
        <div className="row login-bg">
          <div className="col-sm-12 col-md-6">
            <img
              src="/images/login.jpg"
              alt="Placeholder"
              className="img-responsive"
            />
          </div>
          <div className="col-sm-12 col-md-6 text-center mt-5 login-right">
            <h1 className="error-title">404  - Page Not Found </h1>
            <p>
              The page you requested could not be found. Try refining your search, or use the navigation above to locate the post.
            </p>
            <div className="text-center">
              {props.authToken !== null ?
                <Link to="/search" className="ml-2 back_btn">
                  <span>
                    <i className="fa fa-home" aria-hidden="true"></i>
                  </span> Back to Search
                </Link>
                :
                <Link to="/login" className="ml-2 back_btn">
                  <span>
                    <i className="fa fa-home" aria-hidden="true"></i>
                  </span> Back to Login
                </Link>
              }

            </div>
          </div>
        </div>
      </section>
    </>
  )
}

function mapStateToProps(store, props) {
  return {
    authToken: store.client.authToken
  }
}

export default withRouter(connect(mapStateToProps, {})(PageNotFound));