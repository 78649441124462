// Vendor Imports
import React from "react";

import { connect } from "react-redux";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

// Local Imports
import { rrtUploadBadEmails } from "../../../../service/dms";

// Bad Emails Upload Component
class AddModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            backendErrors: null,
            loading: '',
            success: false,
            uploadFile: null
        };
    }

    // Close modal
    closeModal = () => {
        this.setState({
            backendErrors: null,
            loading: '',
            success: false,
            uploadFile: null
        });
        this.props.toggle();
    }

    // Handle file change
    fileChange = event => {
        // Update the state
        this.setState({ uploadFile: event.target.files[0], backendErrors: null });
    };

    // Handle Form Submit
    handleSubmit = () => {
        if (this.state.uploadFile === null) {
            this.setState({ backendErrors: "Please select upload file" });
            return;
        }
        this.setState({ backendErrors: null, loading: 'loading' });
        const formData = new FormData();
        formData.append(
            "file",
            this.state.uploadFile,
            this.state.uploadFile.name
        );
        formData.append("target", "bad_emails")
        rrtUploadBadEmails(formData).then(res => {
            if (res.data.code === 200) {
                this.setState({
                    backendErrors: null,
                    loading: '',
                    success: true
                });
                this.props.refreshPage();
            } else {
                this.setState({ backendErrors: res.data.message, loading: "" });
            }
        })
    }

    render() {
        return (
            <div>
                {/* Add Modal */}
                < Modal isOpen={this.props.modal} size="lg" backdrop="static" >
                    <ModalHeader toggle={this.closeModal}>Upload Bad Email CSV File</ModalHeader>
                    <ModalBody>
                        {this.state.success ? <div className="alert alert-success">
                            File Uploaded Successfully!!
                         </div> :
                            <div>
                                <div className="form-group" >
                                    <label>Bad Emails</label>
                                    <input style={{ padding: "4px" }} type="file" name="file" id="file" accept=".csv" placeholder="file" className="form-control" required="required" onChange={this.fileChange} />
                                </div>
                                {this.state.backendErrors ? (
                                    <div
                                        className="alert alert-danger"
                                        role="alert"
                                        dangerouslySetInnerHTML={{
                                            __html: this.state.backendErrors
                                        }}
                                    ></div>
                                ) : null}
                                <div className="text-right">
                                    <button type="submit" className={"btn btn-primary " + this.state.loading} onClick={this.handleSubmit}>Upload</button>
                                    <button type="button" className="btn btn-secondary ml-2" onClick={this.closeModal}>Cancel</button>
                                </div>
                            </div>}
                    </ModalBody>
                </Modal >
            </div>
        );
    }
}

export default connect()(AddModal);
