// Vendor Imports
import React from "react";
import { connect } from "react-redux";


// Local Imports
import { getVoiceAgents } from "../../../../service/dms";
import AddNew from "./Add";

// View Voice Agent Table
class ViewVoiceAgentTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tableDetails: null,
            addModal: false,
        };
    }

    componentDidMount() {
        // Api call to get voice agent data
        getVoiceAgents().then(res => this.handleResponse(res));
    }

    // Toggle handler
    toggle = () => {
        this.setState({ addModal: !this.state.addModal })
    }

    // Response Handler
    handleResponse = res => {
        if (res.data.code === 200) {
            this.setState({
                tableDetails: res.data.data.employee.items
            });
        }
    };

    // Add new voice  agent
    addNewVoiceAgent = value => {
        this.state.tableDetails.push(value)
    }


    render() {
        return (
            <div>
                <section className="pi-subtitle">
                    <div className="row">
                        <div className="col-md-6"><h2>Voice Agents</h2></div>
                        <div className="col-md-6">
                            <button type="submit" className="btn pi-btn" onClick={this.toggle}>+ Create New Voice Agent</button>
                        </div>
                    </div>
                </section>
                {this.state.tableDetails ? <div>
                    <section className="dmstable mt-3">
                        <div className="table-responsive">
                            <table className="table table-bordered ">
                                <thead>
                                    <tr>
                                        <th scope="col">Employee ID</th>
                                        <th scope="col">First Name</th>
                                        <th scope="col">Last Name</th>
                                        <th scope="col">User ID</th>
                                        {/*<th scope="col">Password</th>*/}
                                        <th scope="col">Access Level</th>
                                        <th scope="col">Primary Role</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.tableDetails.map((listValue, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{listValue.emp_id}</td>
                                                <td>{listValue.first_name}</td>
                                                <td>{listValue.last_name}</td>
                                                <td>{listValue.user_id}</td>
                                                {/*<td>{listValue.password}</td>*/}
                                                <td>{listValue.access_level}</td>
                                                <td>{listValue.primary_role}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </section>
                </div> : <div className="text-center">
                        <div
                            className="spinner-border text-primary mt-3"
                            style={{ width: "3rem", height: "3rem", marginLeft: "auto" }}
                            role="status"
                        >

                            <span className="sr-only">Loading...</span>
                        </div></div>}
                <AddNew modal={this.state.addModal} toggle={this.toggle} addAgent={this.addNewVoiceAgent} />
            </div>
        );
    }
}

// Export
export default connect()(ViewVoiceAgentTable);
