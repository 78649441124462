import React, { useState, useEffect } from 'react';
import { connect, useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import ByRole from './ByRole'
import ByPopulation from './ByPopulation'
import ByElection from './ByElection'
import ByFiscal from './ByFiscal'
import ByGovType from './ByGovType'
import ByLocation from './ByLocation'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import Swal from 'sweetalert2';
import { renderCount, getAppliedPopulation, renderEmailPercent } from "../../../../utils";
import { setTamMode } from '../../../../reducers/client/actions';
import Badge from '@material-ui/core/Badge';
import { Progress } from 'reactstrap'
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import Cookies from 'js-cookie';

// Search stats main component
function SearchInfo(props) {
  const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
      color: theme.palette.common.black,
    },
    tooltip: {
      backgroundColor: theme.palette.common.black,
      fontSize: "1rem",
      width: "500px",
      maxWidth: "500px"
    },
  }));

  const classes = useStylesBootstrap();
  const [state, setState] = useState("Position");
  let [tamModeCheckBox, setTamModeCheckBox] = React.useState(false);
  const dispatch = useDispatch();
  let tamMode = useSelector(store => store.client.tamMode);
  let currentSearch = useSelector(store => store.client.currentSearch);

  useEffect(() => {
    setTamModeCheckBox(tamMode);
    if (tamMode && state == "Position") {
      setState('Population');
    }
  }, [tamMode]);

  useEffect(() => {
    if ('search_params' in currentSearch) {
      //console.log("tamMode", currentSearch.search_params.includes('tamMode=true'))
      if ((currentSearch && currentSearch.search_params.includes('tamMode=true') || tamMode)) {
        setTamModeCheckBox(true);
        dispatch(setTamMode(true));

      }
    }
  }, [currentSearch]);


  const handleChange = (event) => {
    setState(event.target.value);
  };
  const handleTAM = (e) => {
    let tamEnabled = e.target.checked;
    setTamModeCheckBox(tamEnabled);
    let appliedVal = getAppliedPopulation(props.currentSearch.search_params);

    if ('search_params' in currentSearch && tamEnabled) {
      if (!Cookies.get('TAMVisited')) {
        Swal.fire({
          position: 'center',
          allowOutsideClick: false,
          allowEscapeKey: false,
          icon: '',
          html: '<p className="text-center"> For TAM Mode, the maximum market size includes roughly 21,000 local governments representing a population of 1,000 or more  (i.e.governments representing less than 1,000 are excluded).</p>        <p className="text-center"> As you apply the filters, the TAM (Total Available Market) for your selected market segments may be less than or equal to the maximum market size.  </p>',
          title: "",
          showConfirmButton: true
        }).then((result) => {
          if (result.isConfirmed) {
            Cookies.set('TAMVisited', true);
          } else {
            return false;
          }
        });
      }

      if (currentSearch && (currentSearch.search_params.includes('purchasedOnly=1') || currentSearch.search_params.includes('NotpurchasedOnly=1')) || currentSearch.search_params.includes('OnlyHotLeads=1') || (appliedVal[0] === 0 && appliedVal[1] === 1000)) {
        let message0 = "<h2 class='tam-new-text-big'>TAM Mode</h2> <br/> Filter(s) listed below are currently selected and cannot be used in TAM Mode.  ";
        let message2 = "";
        let message3 = "";
        let message4 = "";
        let message5 = "";

        if (currentSearch.search_params.includes('purchasedOnly=1') && !currentSearch.search_params.includes('NotpurchasedOnly=1')) {
          message2 = "<div class='alert alert-warning'>Only include records that I've previously downloaded during my current subscription term</div>";
        }
        if (currentSearch.search_params.includes('NotpurchasedOnly=1')) {
          message3 = "<div class='alert alert-warning'>EXCLUDE records that I've previously downloaded during my current subscription term</div>";
        }
        if (currentSearch.search_params.includes('OnlyHotLeads=1')) {
          message4 = "<div class='alert alert-warning'>Only include records that are NEW OFFICIALS</div>";
        }

        if (appliedVal[0] === 0 && appliedVal[1] === 1000) {
          message5 = "<div class='alert alert-warning'>Population range can not be 0-1000</div>";
        }

        let msg = message0 + message2 + message3 + message4 + message5 + "Please click “Proceed” to switch to TAM Mode and automatically de-select above filter(s).";

        Swal.fire({
          allowOutsideClick: false,
          allowEscapeKey: false,
          html: msg,
          showCancelButton: true,
          showCloseButton: true,
          confirmButtonText: 'Proceed',
          cancelButtonText: 'Cancel',
          icon: ''
        }).then((result) => {
          if (result.isConfirmed) {
            dispatch(setTamMode(tamEnabled));
          } else {
            return false;
          }
        })

      } else {
        dispatch(setTamMode(tamEnabled));
      }
    } else {

      if (appliedVal[0] === 1000) {
        Swal.fire({
          position: 'center',
          allowOutsideClick: false,
          allowEscapeKey: false,
          icon: '',
          html: "Please remember that local governments with a population of less than 1,000 are excluded in TAM Mode. <br/>To include them again, adjust the Population Filter when you switch out of TAM Mode.",
          title: "",
          showConfirmButton: true
        });
      }
      dispatch(setTamMode(tamEnabled));
    }



  }

  let showResult = state;
  //console.log("props.SearchResult", props.SearchResult.JSON_GOVERNMENTS)
  let Summary = 'JSON_SUMMARY' in props.SearchResult ? JSON.parse(props.SearchResult.JSON_SUMMARY) : {}

  let total = parseInt(Summary.TotalGovs);
  let included = parseInt(Summary.TamGovs);
  let excluded = parseInt(Summary.TotalGovs) - (parseInt(Summary.TamGovs));
  // console.log("data", data)

  const tamToolTip = () => {
    return (
      <div>
        <strong><u>Know Your TAM (Total Available Market)</u> </strong>
        <p>
          TAM Mode makes it easy for you to know how many local governments (and decision makers) are in the segment(s) of the market you’re targeting (or thinking about targeting).
        </p>

      </div>
    )
  }

  //console.log("showResult", state)
  return (
    <>
      <FormGroup className="TAMcontent">
        <FormControlLabel
          className="form-check-label roleLabel"
          control={<Badge anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}  color="error">
            <button className="TAMButton btn"> {tamMode ? <><span className='TAM-button-small-text'>You are in</span> TAM Mode </> : <><span className='TAM-button-small-text'>Switch to </span>TAM Mode</>} <sup>
              {/*badgeContent={<span >NEW!</span>}*/}
                 {/* <Tooltip classes={classes} placement="top" arrow title={tamToolTip()} >
                 <a target="_blank" href="https://poweralmanac.com/blog/calculating-the-total-addressable-market-for-your-local-government-offering/"><i data-toggle="tooltip" title="" className="fa fa-info-circle" aria-hidden="true">
                </i></a>
              </Tooltip> */}

<a target="_blank" href="https://poweralmanac.com/blog/calculating-the-total-addressable-market-for-your-local-government-offering/"><i data-toggle="tooltip" title="" className="fa fa-info-circle" aria-hidden="true">
                </i></a>              
            </sup> <Checkbox disabled={props.searchLoading} checked={tamModeCheckBox} className="roleCheckbox" onChange={handleTAM} name="showTAM" value="1" /> </button>
          </Badge>}
          label=""
          labelPlacement="start"
        />
      </FormGroup>
      <div className="row">
        <div className="col-6  text-left">
          <h2>Your Search STATS</h2>
        </div>

        <div className="col-6 text-right">

        </div>
      </div>
      {
        props.searchLoading === true ?
          <div className="col" id="loader">
            <div className="p-5 text-center">
              <p className="display-4">Searching</p>
              <div className="spinner-border text-pa searchloader" role="status"></div>
            </div>
          </div>
          :

          <div className="row searchResults">
            <div className="col-md-12">
              <table className="table  result-table searchStats table-striped ">
                <thead>
                  {tamMode ?
                    <tr>
                      <th style={{ width: "24%" }}>
                        <select value={showResult} id="searchResultsViewFilter" onChange={(e) => handleChange(e)} className="form-control">
                          <option value="Position">By Role</option>
                          <option value="Population">By Population</option>
                          <option value="Location">By Location</option>
                          <option value="GovType">By Government Type</option>
                          <option value="FiscalYearEnd">By Fiscal Year Ending</option>
                          <option value="ElectionMonth">By Election Month</option>
                        </select>
                      </th>
                      <th style={{ width: "13%" }}>Records</th>

                      <th colSpan="2" style={{ width: "25%", border: "1px solid white" }}>Governments In Your Target Market
                        <table border="0" style={{ width: "100%" }}>
                          <tr>
                            <th colSpan="1" style={{ width: "70%", borderRight: "1px solid white", borderBottom: "0px" }}> #</th>
                            <th colSpan="1" style={{ width: "30%", borderBottom: "0px" }}> %</th>
                          </tr>
                        </table>
                      </th>
                    </tr> :
                    <tr>
                      <th style={{ width: "37%" }}>
                        <select value={showResult} id="searchResultsViewFilter" onChange={(e) => handleChange(e)} className="form-control">
                          <option value="Position">By Role</option>
                          <option value="Population">By Population</option>
                          <option value="Location">By Location</option>
                          <option value="GovType">By Government Type</option>
                          <option value="FiscalYearEnd">By Fiscal Year Ending</option>
                          <option value="ElectionMonth">By Election Month</option>
                        </select>
                      </th>
                      <th style={{ width: "13%" }}>Records</th>
                      <th style={{ width: "24%" }}>Email #</th>
                      <th style={{ width: "11%" }}>Email %</th>


                    </tr>
                  }


                </thead>

                {showResult === "Position" ? <ByRole /> : ""}

                {showResult === "Population" ? <ByPopulation /> : ""}

                {showResult === "Location" ? <ByLocation /> : ""}

                {showResult === "GovType" ? <ByGovType /> : ""}

                {showResult === "FiscalYearEnd" ? <ByFiscal /> : ""}

                {showResult === "ElectionMonth" ? <ByElection /> : ""}


                <tbody id="searchResultsTotals">
                  {
                    tamMode ?
                      <tr>
                        <td colSpan="2" style={{ paddingRight: "3em" }} align="right">
                          <strong>Total Records:</strong>
                          <span className="setToZero">  {renderCount(Summary.Num_Matched_Officials)}</span>
                        </td>
                        <td>
                          <strong>Total Governments: </strong>
                          <span className="setToZero"> {renderCount(Summary.TamGovs)}</span>
                        </td>
                        <td>{Summary.Num_Matched_Officials !== "0" ?
                          renderEmailPercent(Summary.TamGovs, Summary.TamGovs) : 0} %</td>
                      </tr>
                      :
                      <tr>

                        <td colSpan="2" align="right">
                          <strong>Total Records:</strong>
                          <span className="setToZero">  {renderCount(Summary.Num_Matched_Officials)}</span>
                        </td>

                        <td>
                          <strong>Total Emails: </strong>
                          <span className="setToZero"> {renderCount(Summary.Num_Matched_Emails)}</span>
                        </td>
                        <td>{Summary.Num_Matched_Officials !== "0" ? renderEmailPercent(Summary.Num_Matched_Officials, Summary.Num_Matched_Emails) : 0} %</td>
                      </tr>

                  }

                </tbody>
              </table >
            </div >

            {
              tamMode ?

                <div className="col-lg-12 num-gov  ">
                  < div className='col-9 m-auto mr-0 white-bg' >
                    <p className="text-center">
                      <strong >
                        # and % of Local Governments
                        INCLUDED or EXCLUDED from YOUR TAM*</strong></p>
                    <div className="row ">
                      <div className='col-2'>
                        <label>Included</label>
                      </div>
                      <div className='col-8'>
                        <Progress max={total} color="info" value={included}>
                          {renderCount(included)}
                        </Progress>
                      </div>
                      <div className='col-2'>
                        <label> {renderEmailPercent(Summary.TotalGovs, included)}%</label>
                      </div>
                    </div>
                    <div className="row pt-3">
                      <div className='col-2'>
                        <label>Excluded</label>
                      </div>
                      <div className='col-8'>
                        <Progress max={total} color="danger" value={excluded}>
                          {renderCount(excluded)}
                        </Progress>
                        <p className='TAMExlucdetxt'>*The maximum market size includes 20,610 local governments.</p>
                      </div>
                      <div className='col-2'>
                        <label> {renderEmailPercent(Summary.TotalGovs, excluded)}%</label>
                      </div>
                    </div>
                  </div >

                </div >
                :
                <div className="col-lg-10 num-gov" align="right">
                  <strong>Total Number of Governments: </strong>
                  <span id="summaryGovernmentsCount" className="setToZero">
                    {renderCount(Summary.Num_Matched_Governments)}
                  </span>
                </div>
            }

            <div className="col-lg-2 ">
            </div>
          </div >
      }


    </>
  )
}


function mapStateToProps(store, props) {
  return {
    currentSearch: store.client.currentSearch ? store.client.currentSearch : [],
    SearchResult: store.client.searchResult ? store.client.searchResult : [],
    searchLoading: store.client.searchLoading,
  }
}
export default withRouter(connect(mapStateToProps, {})(SearchInfo));
