// Vendor Imports
import React from "react";
import { Route, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
// Local Imports
import AdminLayout from "../../layouts/AdminLayout";

// Client Route
const componentWithLayout = (AdminComponent, props) => {
    if (props.adminUser) {
        return (
            <AdminLayout>
                <AdminComponent {...props} />
            </AdminLayout>
        );
    } else {
        props.history.push("/admin");
    }
};

class AdminRoute extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    render() {
        const { menu, component: component, ...rest } = this.props;
        return (
            <Route
                {...rest}
                render={() => componentWithLayout(component, this.props)}
            />
        );
    }
}

// Export

function mapStateToProps(store, props) {
    return {
        adminUser: store.admin.adminUser ? store.admin.adminUser : null,
        authToken: store.admin.authToken ? store.admin.authToken : null
    }
}

export default withRouter(connect(mapStateToProps, {})(AdminRoute));
