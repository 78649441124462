// Vendor Imports
import React from "react";
import { connect } from "react-redux";

// Local Imports
import subscripImage from "../../../images/login-1.jpg";
import Form from "./Form";
import { getRenewUser, renewalLogin } from "../../../service/client";
import { setLoginUser } from "../../../reducers/client/actions";

// Renew Subscription Component
class RenewSubscriptionComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toggle: false,
      error: false,
      backendErrors: null,
      loading: "",
      isLoading: true,
      userDetails: {}
    };
  }

  componentDidMount() {
    getRenewUser(this.props.computedMatch.params.id).then(res => {
      if (res.data.code === 200) {
        this.setState({
          isLoading: false,
          userDetails: res.data.data.user_details
        });
      } else {
        this.setState({ isLoading: false });
      }
    });
  }

  // Handlers
  handleSubmit = values => {
    if (!this.state.toggle) {
      this.setState({ error: true });
      return;
    } else {
      this.setState({ error: false });
    }
    let params = {
      RegUser_ID: this.props.computedMatch.params.id
    };
    this.setState({ loading: "loading" });
    renewalLogin(params).then(res => this.handleResponse(res));
  };

  // Response handler
  handleResponse = res => {
    if (res.data.code === 200) {
      this.setState({
        backendErrors: null,
        loading: ""
      });
      this.props.dispatch(setLoginUser(res.data.data));
      this.props.history.push("/search");
    } else {
      this.setState({ backendErrors: res.data.message, loading: "" });
    }
  };

  // Check toggle handler
  checkToggle = () => {
    this.setState({ toggle: !this.state.toggle });
    if (!this.state.toggle === true) {
      this.setState({ error: false });
    }
  };

  render() {
    return (
      <div style={{ marginTop: "3%" }}>
        <section className="container login-section">
          <div className="row login-bg">
            <div className="col-sm-12 col-md-6">
              <img
                src={subscripImage}
                alt="Placeholder"
                className="img-responsive"
              />
            </div>
            <div className="col-sm-12 col-md-6 login-right">
              <h1>Re-activate your Power Almanac Account</h1>
              <div
                className="alert alert-danger text-center"
                role="alert"
                style={{ color: "#FF0000" }}
              >
                Your Free Power Almanac account will renew every 30 days with 100 fresh download credits.
                <br />
              </div>
              {this.state.backendErrors ? (
                <div
                  className="alert alert-danger"
                  role="alert"
                  dangerouslySetInnerHTML={{
                    __html: this.state.backendErrors
                  }}
                ></div>
              ) : null}
              {this.state.isLoading ? (
                <div
                  className="spinner-border mt-3"
                  style={{ width: "3rem", height: "3rem" }}
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                  <Form
                    termsChange={this.checkToggle}
                    onSubmit={this.handleSubmit}
                    errorMessage={this.state.error}
                    loading={this.state.loading}
                    userDetails={this.state.userDetails}
                  ></Form>
                )}
            </div>
          </div>
        </section>
      </div>
    );
  }
}

// Export
export default connect()(RenewSubscriptionComponent);
