// Vendor Imports
import React from "react";
import { Field, reduxForm } from "redux-form";


// Local Imports

// Input Field
const renderInputField = ({
    input,
    label,
    type,
    meta: { touched, error, warning }
}) => (
    <>
        <span className="edit-gov-text">Government ID:</span>
        <input
            {...input}
            placeholder={label}
            type={type}
            className="form-control rounded col-6"
        />
        {touched &&
            ((error && <span className="text-danger">{error}</span>) ||
                (warning && <span className="text-danger">{warning}</span>))}
    </>
);

// Edit Gov Form
const EditGovForm = props => {
    const { handleSubmit } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="row" style={{ margin: "auto 5%" }}>
                <div className="col-1"></div>
                <Field
                    name="gov_id"
                    type="text"
                    component={renderInputField}
                    label="Search"
                />
                <button type="submit" className={"btn edit-gov-button col-3 ml-2  " + props.loading} onClick={handleSubmit}>Edit Government</button>
            </div>
        </form>
    );
};

// Redux Form
const reduxEditGovForm = reduxForm({
    // a unique name for the form
    form: "editGovForm"
})(EditGovForm);

// Export
export default reduxEditGovForm;
