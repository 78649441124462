import React from "react";
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import SubUserList from './SubUser';
import AccountInfo from './AccountInfo';
// My account page component
function MyAccountComponent(props) {
  return (

    <>
      <Helmet>
        <title> My Account - Power Almanac </title>
      </Helmet>
      <section className="my-search">
        <div className="container">
          <div className="row">
            <div className="col">
              <ul className="breadcrumb">
                <li>My Account</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="my-table-container mb-5">
        <AccountInfo />
        {props.info.isParent === true ?
          <SubUserList />
          :
          ""
        }
      </section>
    </>
  );
}

function mapStateToProps(store, props) {
  return {
    info: store.client.info ? store.client.info : []
  }
}

export default connect(mapStateToProps, {})(MyAccountComponent);
