// Vendor Imports
import React from "react";
import { Field, reduxForm } from "redux-form";

// Local Imports
import validate from "./validate";

// Input Field
const renderInputField = ({
    input,
    label,
    type,
    meta: { touched, error, warning }
}) => (
    <div>
        <input
            {...input}
            placeholder={label}
            type={type}
            className="form-control"
        />
        {touched &&
            ((error && <span className="text-danger">{error}</span>) ||
                (warning && <span className="text-danger">{warning}</span>))}
    </div>
);

// Dms Login Form
const DmsLoginForm = props => {
    const { handleSubmit } = props;
    return (
        <section>
            <div className="dms-login">
                <form onSubmit={handleSubmit}>
                    <h1>Login</h1>
                    {props.backendErrors ? (
                        <div
                            className="alert alert-danger"
                            role="alert"
                            dangerouslySetInnerHTML={{
                                __html: props.backendErrors
                            }}
                        ></div>
                    ) : null}
                    <div className="form-group">
                        <label htmlFor="email">Username</label>
                        <Field
                            name="email"
                            type="text"
                            label="Username"
                            component={renderInputField}
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <Field
                            name="password"
                            type="password"
                            label="Password"
                            component={renderInputField}
                        />
                    </div>

                    <button type="submit" className={"btn btn-primary " + props.loading}>Submit</button>
                </form >
            </div></section>
    );
};

// Redux Form
const reduxDmsLoginForm = reduxForm({
    // a unique name for the form
    form: "dmsLogin",
    validate
})(DmsLoginForm);

// Export
export default reduxDmsLoginForm;

