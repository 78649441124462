import React from "react";
import { Field, reduxForm } from "redux-form";
import validate from "./validate";

// Input Field
const renderInputField = ({
  input,
  label,
  type,
  meta: { touched, error, warning }
}) => (
  <div>
    <input
      {...input}
      placeholder={label}
      type={type}
      className="form-control"
    />
    {touched &&
      ((error && <span className="text-danger">{error}</span>) ||
        (warning && <span className="text-danger">{warning}</span>))}
  </div>
);

// ChangePassword Form
const ChangePasswordForm = props => {
  const { handleSubmit } = props;
  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group loginInput">
        <label htmlFor="newpass">New Password:</label>
        <Field
          name="newpass"
          type="password"
          label="New Password"
          component={renderInputField}
          minlength="8"
        />
      </div>

      <div className="form-group loginInput">
        <label htmlFor="confirmpass">Confirm New Password:</label>
        <Field
          name="confirmpass"
          type="password"
          label="Confirm Password"
          component={renderInputField}
          minlength="8"
        />
      </div>

      <div className="checkbox mb-2">
        <label>
          <input
            type="checkbox"
            name="terms_of_services"
            id="terms_of_services"
            onClick={props.termsChange}
          />{" "}
          <strong>
            {" "}
            You must agree to the below terms of service before accessing your
            account{" "}
          </strong>
          <a
            href="https://poweralmanac.com/terms-of-service#et-main-area"
            target="_blank"
          >
            Terms of Service
          </a>
        </label>
        <br />
        {props.errorMessage ? (
          <span className="text-danger">
            You must agree to the TERMS OF SERVICE
          </span>
        ) : (
          <span></span>
        )}
      </div>

      <div id="login-or-register" style={{ textAlign: "right" }}>
        <input
          type="submit"
          className={"btn btn-pa text-uppercase w-100  " + props.loading}
          value="Set Password"
          style={{ float: "none" }}
        />
      </div>
    </form>
  );
};

// Redux Form
const reduxChangePasswordForm = reduxForm({
  // a unique name for the form
  form: "changePassword",
  validate
})(ChangePasswordForm);

// Export
export default reduxChangePasswordForm;
