import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody , ModalHeader} from 'reactstrap';
import { Link, withRouter } from "react-router-dom";
import { refreshUserDetails } from "../../../reducers/client/actions";
import { UpdateNotificationPopup, getMyAccount } from '../../../service/client';
import Cookies from 'js-cookie';

// powerentry alert pop modal component
function NotificationAlert(props) {

  let myaccount = useSelector((state) => state.client.myaccount);

  const {
    modal
  } = props;
  const dispatch = useDispatch();
  // function to renew power entry subscription
  const handleNotification = (todo) => {
    let param = {
      ft_popup: parseInt(myaccount.ft_pop_up) + 1
    }
    UpdateNotificationPopup(param).then(response =>
      handleResponse(response, todo),
    )
  }

  const handleResponse = (response, todo) => {
    Cookies.set('notification', true);

    if (response.data.code === 200) {
      getMyAccount().then(res =>
        dispatch(refreshUserDetails(res.data.data))
      );
      if (todo === "upgrade") {
        props.history.push('/upgrade-subscription');
      }
    }

  }
  // return modal template
  return (
    <div>

      <Modal
        size="lg"
        isOpen={modal}
        toggle={handleNotification}
        backdropClick={()=>handleNotification('')}
        centered={false}
        id="NotificationModal"
        className="modal-dialog">
        <ModalHeader  toggle={(e) => handleNotification('')}><strong className='new-text-big text-center' style={{ letterSpacing: "1.5px" }}> UPDATE </strong></ModalHeader>
        <ModalBody>
          {/* <h5 className="text-center">
            <p>  <strong className='new-text-big' style={{ letterSpacing: "1.5px" }}>ROLE Intelligence <sup> TM</sup> IN ACTION </strong>
              <br />
              <strong>Deputy Top Appointed Executive is here!</strong>
            </p>
          </h5>
          <p className="text-center">
            <strong>Power Max Subscribers:</strong>
            <p> Congratulations! You've been granted <br /><strong className='new-text-big'>EARLY ACCESS </strong>  to Deputy Top Appointed Executive</p>
          </p>
          <p className="text-center">
            <strong>All Other Subscribers:</strong>
            <p> Access this new role starting Monday, May 2<sup>nd</sup> </p>
          </p>
          <p className="text-center">
            Want early access for ALL new roles?
            <br />
            <Link onClick={(e) => handleNotification('upgrade')} >
              <u>Upgrade to Power Max and unlock early access NOW</u>
            </Link>
          </p>

          <p className="text-center">
            <Link className="btn btn-pa " onClick={(e) => handleNotification('')}>Take me to the SEARCH page</Link>
          </p> */}

          {/* <h5 className="text-center">
            <p>  <strong className='new-text-big' style={{ letterSpacing: "0px" }}>We have added a new role so you can expand your reach. </strong>

            </p>
          </h5>
          <p className="text-center">
            <strong> You now have access to more than <br />
              3,000+ Deputy Top Appointed Executive!</strong>
          </p>


          <p className="text-center"> For TAM Mode, the maximum market size includes roughly 21,800 local governments representing a population of 1,000 or more
            (i.e. governments representing less than 1,000 are excluded).</p>

          <p className="text-center"> As you apply the filters, the TAM (Total Available Market) for your selected market segments may be less than or equal to the maximum market size.
          </p>

          <p className="text-center">
            <button className="btn btn-pa " onClick={(e) => handleNotification('')}>Take me to the SEARCH page</button>
          </p>  */}

          {/* <h5 className="text-center">
            <p>  <strong className='new-text-big' style={{ letterSpacing: "1.5px" }}> UPDATE </strong>
            </p>
          </h5> */}

          {/*<p className="text-left">  04/03/22 - New role : Head of Corrections - Power Max access begins now. All subscriber access begins 05/02/23 </p>*/}
          <p className="text-left">  05/02/23 - New role : Head of Corrections </p>
          <p className="text-left">  12/07/22 – New role: Head of Geographic Information System </p>
          <p className="text-left">  10/05/22 – New role: Head of Health Services </p>
             <p className="text-left"> 7/10/22 - 486 New Governments Added </p>
          <p className="text-left"> 07/04/22 – New role: Head of Facilities Management </p>
          <p className="text-left"> 06/06/22 – New download files make CRM integration easier
          <a target="_blank" href="https://poweralmanac.com/blog/major-upgrade-to-power-almanac-download-files-for-ease-of-crm-integration/"> Learn More. </a> </p>
  
          <p className="text-left"> 05/09/22 – Latest Census Bureau population information now incorporated</p>


          {/* <p className="text-center">
            <button className="btn btn-pa " onClick={(e) => handleNotification('')}>Take me to the SEARCH page</button>
          </p>  */}
        </ModalBody>

      </Modal>
    </div >
  );
}
export default withRouter(NotificationAlert);
//export default NotificationAlert;