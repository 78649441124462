import {
  SET_USER,
  SET_SUBSCRIPTIONS,
  SIGN_OFF,
  GET_SEARCH,
  GET_BEST_PRICE,
  GET_ZIPCODES,
  SET_MY_SAVED_SEARCHES,
  SET_MY_ACCOUNT,
  SET_MY_TRANSACTIONS,
  REMOVE_SUB_USER,
  UPDATE_MY_SAVED_SEARCH,
  DELETE_MY_SAVED_SEARCH,
  SUB_USER_CHANGE_PASS,
  NEW_USER_SERVEY,
  PHONE_VERIFICATION,
  REFRESH_MY_ACCOUNT,
  CHECKOUT,
  CURRENT_SEARCH,
  SET_SEARCH_RESULT,
  SET_BEST_PRICING,
  SET_ZIPCODES,
  SET_SEARCHLOADING,
  SET_USER_INFO,
  SET_USER_DETAILS,
  SET_PROTECTOR,
  APPLIED_FILTERS,
  SET_LINK_TOKEN,
  SET_TAM_MODE,
  SET_STEP,
  SET_VALID_FILTERS,
} from "./constants";
import * as Cookies from 'js-cookie';
export function setLoginUser(payload) {
  return {
    type: SET_USER,
    payload
  };
}
export function setPasswordProtector(payload) {
  return {
    type: SET_PROTECTOR,
    payload
  };
}
export function setAppliedFilters(payload) {
  return {
    type: APPLIED_FILTERS,
    payload
  };
}

export function setValidateFilters(payload) {
  return {
    type: SET_VALID_FILTERS,
    payload
  };
}

export function refreshUserDetails(payload) {
  return {
    type: SET_USER_DETAILS,
    payload
  };
}

export function SignOff(payload) {
  Cookies.remove('powerentryalert');
  return {
    type: SIGN_OFF,
    payload
  };
}

export function getSearch(payload) {
  return {
    type: GET_SEARCH,
    payload
  };
}

export function getBestBrice(payload) {
  return {
    type: GET_BEST_PRICE,
    payload
  };
}

export function getZipCode(payload) {
  return {
    type: GET_ZIPCODES,
    payload
  };
}
export function saveSearch(payload) {
  return {
    type: GET_ZIPCODES,
    payload
  };
}

export function setMySavedSearches(payload) {
  return {
    type: SET_MY_SAVED_SEARCHES,
    payload
  };
}

export function setMyAccount(payload) {
  return {
    type: SET_MY_ACCOUNT,
    payload
  };
}

export function setMyTransactions(payload) {
  return {
    type: SET_MY_TRANSACTIONS,
    payload
  };
}

export function setSubscriptions(payload) {
  return {
    type: SET_SUBSCRIPTIONS,
    payload
  };
}

export function removeSubUser(payload) {
  return {
    type: REMOVE_SUB_USER,
    payload
  };
}

export function setUpdatedMySavedSearch(payload) {
  return {
    type: UPDATE_MY_SAVED_SEARCH,
    payload
  };
}

export function setDeleteMySavedSearch(payload) {
  return {
    type: DELETE_MY_SAVED_SEARCH,
    payload
  };
}

export function subUserChangePass(payload) {
  return {
    type: SUB_USER_CHANGE_PASS,
    payload
  };
}

export function setNewUserServeyForm(payload) {
  return {
    type: NEW_USER_SERVEY,
    payload
  };
}

export function setPhoneVerificationFrom(payload) {
  return {
    type: PHONE_VERIFICATION,
    payload
  };
}

export function refreshMyAccount(payload) {
  return {
    type: REFRESH_MY_ACCOUNT,
    payload
  };
}


export function setCheckout(payload) {
  return {
    type: CHECKOUT,
    payload
  };
}

export function setCurrentSearch(payload) {
  return {
    type: CURRENT_SEARCH,
    payload
  };
}

export function setSearchResult(payload) {
  return {
    type: SET_SEARCH_RESULT,
    payload
  };
}

export function setBestPricing(payload) {

  return {
    type: SET_BEST_PRICING,
    payload
  };
}

export function setZipCodes(payload) {
  return {
    type: SET_ZIPCODES,
    payload
  };
}

export function setSearchLoading(payload) {
  return {
    type: SET_SEARCHLOADING,
    payload
  };
}
export function setUserInfo(payload) {
  return {
    type: SET_USER_INFO,
    payload
  };
}

export function setLinkToken(payload) {
  return {
    type: SET_LINK_TOKEN,
    payload
  };
}

export function setTamMode(payload) {
  return {
    type: SET_TAM_MODE,
    payload
  };
}
export function setSurveyformStep(payload) {
  return {
    type: SET_STEP,
    payload
  };
}