// Vendor Imports
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// Local Imports
import { emailActivation } from "../../../service/client";
import loginImage from "../../../images/login.jpg";

// EmailActivation Component
class EmailActivationComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      message: "",
      code: ""
    };
  }

  componentDidMount() {
    let params = {
      token: this.props.computedMatch.params.token,
      email: this.props.computedMatch.params.email
    };
    emailActivation(params).then(res => this.handleResponse(res));
  }

  // Response handler
  handleResponse = res => {
    this.setState({
      isLoading: false,
      message: res.data.message,
      code: res.data.code
    });
  };

  render() {
    return (
      <div style={{ marginTop: "5%" }}>
        <section className="container login-section forgotpw-block">
          <div className="row login-bg">
            <div className="col-sm-12 col-md-6">
              <img
                src={loginImage}
                alt="Placeholder"
                className="img-responsive"
              />
            </div>
            <div
              className="col-sm-12 col-md-6"
              style={{ paddingTop: "15px", paddingRight: "20px" }}
            >
              <h1>Account Activation</h1>
              {this.state.isLoading ? (
                <div
                  className="spinner-border mt-3"
                  style={{ width: "3rem", height: "3rem", marginLeft: "auto" }}
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              ) : this.state.code === 200 ? (
                <>
                <div
                  className="alert alert-success mt-3"
                  dangerouslySetInnerHTML={{
                    __html: this.state.message
                  }}
                ></div>
                <div className="row align-items-center mb-3 login-link">
                  <div className="col-sm-4 login-btn">
                    <Link to="/login" className="btn btn-pa text-uppercase ">
                      Log in
                    </Link>
                  </div>
                </div>
                </>
              ) : (
                    <div
                      className="alert alert-danger mt-3"
                      role="alert"
                      dangerouslySetInnerHTML={{
                        __html: this.state.message
                      }}
                    ></div>
                  )}
            </div>
          </div>
        </section>
      </div>
    );
  }
}

// Export
export default connect()(EmailActivationComponent);
