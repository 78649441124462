const PrivacyPloicyContent = (props) => {
  return (
    <div>
      <div className="et_pb_section et_pb_section_1 et_section_regular">
        <div className="et_pb_module et_pb_text et_pb_text_1 et_pb_bg_layout_light  et_pb_text_align_left">
          <div className="et_pb_text_inner">
            <p>Your privacy in important to us, and we have prepared this Privacy Policy to explain to you how we collect, use and share information we obtain through your use of the Power Almanac website (https://www.poweralmanac.com) (the “Site”).Your privacy in important to us, and we have prepared this Privacy Policy to explain to you how we collect, use and share information we obtain through your use of the Power Almanac website (https://www.poweralmanac.com) (the <strong>“Site”</strong>).</p>
            <p>By using this Site, you explicitly consent to such use of your information and agree to the terms of this Privacy Policy. This Privacy Policy is effective as of June 16, 2011.</p>
            <p><strong>1. Our Information Sharing Practices.</strong></p>
            <p style={{ paddingLeft: "30px" }}>A. <strong>General.&nbsp;</strong>Except as otherwise described in this section of our Privacy Policy, we will not share your Usage Data (See Section 4, below) or Personal Information (See Section 2, below) (which we will refer to in this Privacy Policy collectively as “User Information”) with any third party without your permission.</p>
            <p style={{ paddingLeft: "30px" }}>B. <strong>Aggregated Data.&nbsp;</strong>We don’t sell or rent your personal information to third parties for their marketing purposes without your explicit consent. From time to time, we may share aggregated User Information with third parties. We will not share any aggregated data, however, in a manner that would enable the recipient to personally identify you.</p>
            <p style={{ paddingLeft: "30px" }}>C. <strong>Publicly Available Information.</strong><em>&nbsp;</em>As part of the Services, we provide data that is lawfully collected from federal, state or local governments; widely available sources that do not prohibit onward transfer or use; and disclosures to the general public that are required to be made by federal, state or local law (“Publicly Available Information”). If you are employed by or elected to serve with a federal, state or local government, Publicly Available Information about your employment or elected office may be included in the data that users can access when they use the Services. We will not, however, treat the Personal Information that you provide to us for your user account any differently than the Personal Information of a user who is not employed by or elected to serve with a federal, state or local government.</p>
            <p style={{ paddingLeft: "30px" }}>D. <strong>Other Disclosure Scenarios.</strong><em>&nbsp;</em>Notwithstanding anything in this Privacy Policy to the contrary, we reserve the right, and you hereby expressly authorize us, to share any User Information:</p>
            <p style={{ paddingLeft: "60px" }}>⦁ in response to subpoenas, court orders, or legal process, or to establish, protect, or exercise our legal rights or defend against legal claims;</p>
            <p style={{ paddingLeft: "60px" }}>⦁ if we believe it is necessary in order to investigate, prevent, or take action regarding illegal activities, fraud, or situations involving potential threats to the safety of any person or property;</p>
            <p style={{ paddingLeft: "60px" }}>⦁ if we believe it is necessary to investigate, prevent, or take action regarding abuse of the Site’s infrastructure or the Internet in general (such as voluminous spamming, denial of service attacks, or attempts to compromise the security of information);</p>
            <p style={{ paddingLeft: "60px" }}>⦁ with subsidiaries, joint ventures, or other companies under common control with us (in which case we will require such entities to honor this Privacy Policy);</p>
            <p style={{ paddingLeft: "60px" }}>⦁ if we are acquired by or merge with another company; or</p>
            <p style={{ paddingLeft: "60px" }}>⦁ with third parties who provide services to us (e.g., data management and storage services), provided, however, that in those circumstances we disclose User Information that is necessary for such service providers to perform those services, and request that they not share your User Information in a manner inconsistent with this Privacy Policy.</p>
            <p><strong>2. Information You Choose To Submit.&nbsp;&nbsp;</strong>You can visit the Site without telling us who you are or revealing any information through which someone could personally identify you (which we will refer to in this Privacy Policy collectively as “Personal Information”). We do not consider Personal Information to include information that has been anonymized so that it does not identify a specific user. If, however, you wish to create an account and use the Site’s services (“Services”), you are required to provide certain Personal Information (e.g., your name, your e-mail address), and we ask that you also provide a user name and password. We use your Personal Information to fulfill your requests for products and services, to improve our services, and to contact you from time to time about us, our Site, and our products and services. We do not collect any information regarding payment or financial information related to our Site and Services, as all payment transactions are made through third-party payment services (such as PayPal or Stripe) and are subject to their privacy policies.</p>
            <p><strong>3. Information We Automatically Collect.&nbsp;&nbsp;</strong>We automatically collect certain information to help us understand how our users use the Site (which we will refer to in this Privacy Policy collectively as “Usage Data”) and in order to tailor the site to your preferences and to improve the quality of the Site. For example, each time you visit the Site we may automatically collect your IP address, browser and computer type, access time, the Web page from which you came, and the Web page(s) that you access during your visit, and your search history. We collect and store the information through a third-party web hosting service (Amazon Web Services), and we take steps to ensure that your Usage Data is treated securely and in accordance with this Privacy Policy.</p>
            <p><strong>4. Security.&nbsp;&nbsp;</strong>We have implemented reasonable measures to help protect your User Information from loss, misuse, or unauthorized access or disclosure. Unfortunately, however, no data transmission over the Internet can be guaranteed to be 100% secure. As a result, while we strive to protect your User Information, we cannot guarantee its security. If you choose to provide us with Personal Information, you consent to the transfer and storage of that information on our service provider’s servers. Because the Internet is global, however, information about you that we collect or that you submit may be transferred to, processed, and held in other countries, where laws regarding processing of your Personal Information may be less stringent than the laws in the United States.</p>
            <p><strong>5. Changes.&nbsp;&nbsp;</strong>We may change this Privacy Policy from time to time. If under any such update we make any material change to the way in which we treat your User Information, we will inform you of such change via e-mail. Such changes shall be effective immediately upon our posting the revised Privacy Policy on the Site. Your continued use of the Site after such modifications will constitute your acknowledgment of the modified Privacy Policy and agreement to abide by and be bound by any modified Privacy Policy.</p>
            <p><strong>Questions</strong>.</p>
            <p>If you have any questions about this Privacy Policy, please feel free to contact us:</p>
            <p>Power Almanac Information Privacy Site Coordinator</p>
            <p>205 De Anza Blvd #59</p>
            <p>San Mateo, CA 94402</p>
            <p>Phone: 650-539-2340</p>
            <p>Email: <a href="mailto:privacy@poweralmanac.com">privacy@poweralmanac.com</a></p>
            <p>&nbsp;</p>
          </div>
        </div>
      </div>
    </div>

  );
}

export default PrivacyPloicyContent;