import React, {useEffect} from 'react';
import {  useDispatch, useSelector } from 'react-redux';

import { setNewUserServeyForm } from '../../../../reducers/client/actions';
// my first search Government role filter
function GovernmentRoles(props) {
  const NewUserServeyFormData = useSelector((store) => store.client.NewUserServeyForm);
  
  const newSurveyResponse = useSelector((store) => store.client.info.survey_response ? JSON.parse( store.client.info.survey_response ): "");
  useEffect(() => {
   if(newSurveyResponse!==""){
    let NewUserServeyForm = NewUserServeyFormData;
    NewUserServeyForm.position = newSurveyResponse.position;
    NewUserServeyForm.roles = newSurveyResponse.roles;;
    dispatch(setNewUserServeyForm(NewUserServeyForm));
   }
  },[]);

  const formFields = [
    { label: "Top Elected Official   (e.g. the Mayor)", name: "role_1", value: "Top+Elected+Official", checked: false },
    { label: "Top Appointed Executive (e.g. the City Manager)", name: "role_2", value: "Top+Appointed+Official", checked: false },
    { label: "Deputy Top Appointed Executive  (e.g. the Assistant City Manager)", name: "role_18", value: "DeputyTopAppointedExecutive", checked: false },
    { label: "Head of Finance", name: "role_4", value: "Head+of+Finance/Budgeting", checked: false },
    { label: "Head of Purchasing", name: "role_5", value: "Head+of+Purchasing/Procurement", checked: false },
    { label: "Head of Public Works", name: "role_6", value: "Head+of+Public+Works", checked: false },
    { label: "Head of Police", name: "role_7", value: "Head+of+Law+Enforcement", checked: false },
    { label: "Head of Fire", name: "role_8", value: "Head+of+Fire+Protection+Services", checked: false },
    { label: "Head of Clerk's Office", name: "role_9", value: "Clerk", checked: false },
    { label: "Head of IT", name: "role_10", value: "Head+of+IT", checked: false },
    { label: "Head of HR", name: "role_13", value: "Head+of+HR", checked: false },
    { label: "Head of Communications", name: "role_12", value: "Head+of+Communications", checked: false },
    { label: "Head of Buildings", name: "role_11", value: "Head+Building+Official", checked: false },
    { label: "Head of Economic Development", name: "role_17", value: "EconomicDevelopment", checked: false },
    { label: "Head of Water Supply", name: "role_14", value: "Head+of+Water+Supply", checked: false },
    { label: "Head of Parks and Recreation", name: "role_15", value: "ParksandRecreation", checked: false },
    { label: "Head of Planning/Zoning ", name: "role_16", value: "Zoning", checked: false },
    { label: "Head of Facilities Management ", name: "role_19", value: "HeadofFacilitiesManagement", checked: false },
    { label: "Head of Health Services", name: "role_20", value: "HeadofHealthServices", checked: false },
    { label: "Head of Geographic Information System", name: "role_21", value: "HeadofGeographicInformationSystem", checked: false },
    { label: "Head of Corrections", name: "role_22", value: "HeadofCorrections", checked: false },
    
  ];

  const dispatch = useDispatch();

  // funcation to capture location filter values
  const handleChange = (event) => {
    let newObject = {};
    let govRoles = [];
    let value = event.target.value.split("-");
    if (event.target.value !== '') {
      newObject[value[0]] = value[1];
      govRoles.push(value[0]);
    }
    let NewUserServeyForm = NewUserServeyFormData;
    NewUserServeyForm.position = govRoles;
    NewUserServeyForm.roles = newObject;
    dispatch(setNewUserServeyForm(NewUserServeyForm));
  }

  
  return (
    <div className="row  pt-2 pr-5 pl-5 ">
      <select onChange={handleChange} name="roles" id="roles" className="form-control">
        <option value="">Select Role</option>
        {
          formFields.map((field, index) => {
            let selected = "";
            if (newSurveyResponse !== "") {
              selected = newSurveyResponse.position.includes(field.name) ? "selected" : "";
            }
            return (
              <option key={index} selected={selected} disabled={field.disabled}  value={field.name+"-"+field.value}>{field.label}</option>
            );
          })
        }
      </select>
    </div>
  )
}


export default GovernmentRoles;
