import React from "react";
import GuestLayout from "../../layouts/GuestLayout";
import ClientLayout from "../../layouts/ClientLayout";
import DmsLayout from "../../layouts/DmsLayout";

import { Route, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
// Client Route
const componentWithLayout = (Component, props) => {

  if (props.authToken === null) {
    return (
      <GuestLayout>
        <Component {...props} />
      </GuestLayout>
    );
  } else if (props.authToken !== null) {
    return (
      <ClientLayout>
        <Component {...props} />
      </ClientLayout>
    );
  }
  else if (props.dmsAdmin.authToken !== null) {
    return (
      <DmsLayout>
        <Component {...props} />
      </DmsLayout>
    );
  } else {
    props.history.push("/search");
  }
};
class PublicRoute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }
  render() {
    const { menu, component: component, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={() => componentWithLayout(component, this.props)}
      />
    );
  }
}

function mapStateToProps(store, props) {

  return {
    authToken: store.client.authToken ? store.client.authToken : null,
    dmsAdmin: store.dms ? store.dms : null
  }
}

export default withRouter(connect(mapStateToProps, {})(PublicRoute));

