// Vendor Imports
import moment from "moment";
import React from "react";
import Swal from 'sweetalert2';

// Local Imports
import {
    getOverallActiveStats,
    getOverAllDownloadFiles, getOverAllDownloadRecords, getOverAllActiveSubusers, getOverAllOtherCounts
} from "../../../service/admin";

// Overall Stats Table
class OverallStatsComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeAccs: null,
            downloadedFiles: null,
            downloadedRecords: null,
            subUsers: null,
            others: null
        };
    }

    componentDidMount() {
        this.handleActiveAcc();
    }

    // Handle error response
    handleError = res => {
        Swal.fire({
            text: res.data.message,
            showCloseButton: true,
            confirmButtonText: "Ok",
            icon: 'error',
            allowOutsideClick: false
        });
        return;
    }

    // get active accounts
    handleActiveAcc = () => {
        getOverallActiveStats().then(res => {
            if (res.data.code === 200) {
                this.setState({
                    activeAccs: res.data.data
                });
                this.handleDownloadedFiles();
            } else {
                this.handleError(res);
            }
        });
    }

    // get downloaded files
    handleDownloadedFiles = () => {
        getOverAllDownloadFiles().then(res => {
            if (res.data.code === 200) {
                this.setState({
                    downloadedFiles: res.data.data
                });
                this.handleDownloadedRecords();
            } else {
                this.handleError(res);
            }
        });
    }

    // get downloaded records
    handleDownloadedRecords = () => {
        getOverAllDownloadRecords().then(res => {
            if (res.data.code === 200) {
                this.setState({
                    downloadedRecords: res.data.data
                });
                this.handleSubUsers();
            } else {
                this.handleError(res);
            }
        });
    }

    // get sub users data
    handleSubUsers = () => {
        getOverAllActiveSubusers().then(res => {
            if (res.data.code === 200) {
                this.setState({
                    subUsers: res.data.data
                });
                this.handleOthers();
            } else {
                this.handleError(res);
            }
        });
    }

    // get other data
    handleOthers = () => {
        getOverAllOtherCounts().then(res => {
            if (res.data.code === 200) {
                this.setState({
                    others: res.data.data
                });
            } else {
                this.handleError(res);
            }
        });
    }

    // Handle active acc sum
    handleActiveAccSum = val => {
        let val1 = val in this.state.activeAccs.power_entry ? this.state.activeAccs.power_entry[val.toString()] : 0;
        let val2 = val in this.state.activeAccs.power_15 ? this.state.activeAccs.power_15[val.toString()] : 0;
        let val3 = val in this.state.activeAccs.power_75 ? this.state.activeAccs.power_75[val.toString()] : 0;
        let val4 = val in this.state.activeAccs.power_max ? this.state.activeAccs.power_max[val.toString()] : 0;
        let val5 = val in this.state.activeAccs.power_custom ? this.state.activeAccs.power_custom[val.toString()] : 0;
        return val1 + val2 + val3 + val4 + val5;
    }

    // Handle download files sum
    handleDownloadedFilesSum = val => {
        let val1 = val in this.state.downloadedFiles.new_files ? this.state.downloadedFiles.new_files[val.toString()] : 0;
        let val2 = val in this.state.downloadedFiles.updated_files_all_records ? this.state.downloadedFiles.updated_files_all_records[val.toString()] : 0;
        let val3 = val in this.state.downloadedFiles.updated_files_update_records ? this.state.downloadedFiles.updated_files_update_records[val.toString()] : 0;
        return val1 + val2 + val3;
    }

    // Handle download records sum
    handleDownloadedRecordsSum = val => {
        let val1 = val in this.state.downloadedRecords.power_entry ? this.state.downloadedRecords.power_entry[val.toString()] : 0;
        let val2 = val in this.state.downloadedRecords.power_15 ? this.state.downloadedRecords.power_15[val.toString()] : 0;
        let val3 = val in this.state.downloadedRecords.power_75 ? this.state.downloadedRecords.power_75[val.toString()] : 0;
        let val4 = val in this.state.downloadedRecords.power_max ? this.state.downloadedRecords.power_max[val.toString()] : 0;
        let val5 = val in this.state.downloadedRecords.power_custom ? this.state.downloadedRecords.power_custom[val.toString()] : 0;
        return val1 + val2 + val3 + val4 + val5;
    }

    // Handle sub users sum
    handleSubusersSum = val => {
        let val1 = val in this.state.subUsers.power_entry ? this.state.subUsers.power_entry[val.toString()] : 0;
        let val2 = val in this.state.subUsers.power_15 ? this.state.subUsers.power_15[val.toString()] : 0;
        let val3 = val in this.state.subUsers.power_75 ? this.state.subUsers.power_75[val.toString()] : 0;
        let val4 = val in this.state.subUsers.power_max ? this.state.subUsers.power_max[val.toString()] : 0;
        let val5 = val in this.state.subUsers.power_custom ? this.state.subUsers.power_custom[val.toString()] : 0;
        return val1 + val2 + val3 + val4+ val5;
    }

    render() {
        return (
            <section className="admin-table overall">
                <div className="table-responsive">
                    <h1>{moment().format('YYYY')}</h1>
                    <table className="table table-bordered table-striped">
                        <thead>
                            <tr>
                                <th scope="col"> </th>
                                <th scope="col">January</th>
                                <th scope="col">February</th>
                                <th scope="col">March</th>
                                <th scope="col">April</th>
                                <th scope="col">May</th>
                                <th scope="col">June</th>
                                <th scope="col">July</th>
                                <th scope="col">August</th>
                                <th scope="col">September</th>
                                <th scope="col">October</th>
                                <th scope="col">November</th>
                                <th scope="col">December</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.activeAccs ? <>
                                <tr className="center-td">
                                    <td colSpan="13" align="center"><strong># of Active Accounts</strong></td>
                                </tr>
                                <tr>
                                    <td>Power Custom</td>
                                    <td>{1 in this.state.activeAccs.power_custom ? this.state.activeAccs.power_custom['1'] : 0}</td>
                                    <td>{2 in this.state.activeAccs.power_custom ? this.state.activeAccs.power_custom['2'] : 0}</td>
                                    <td>{3 in this.state.activeAccs.power_custom ? this.state.activeAccs.power_custom['3'] : 0}</td>
                                    <td>{4 in this.state.activeAccs.power_custom ? this.state.activeAccs.power_custom['4'] : 0}</td>
                                    <td>{5 in this.state.activeAccs.power_custom ? this.state.activeAccs.power_custom['5'] : 0}</td>
                                    <td>{6 in this.state.activeAccs.power_custom ? this.state.activeAccs.power_custom['6'] : 0}</td>
                                    <td>{7 in this.state.activeAccs.power_custom ? this.state.activeAccs.power_custom['7'] : 0}</td>
                                    <td>{8 in this.state.activeAccs.power_custom ? this.state.activeAccs.power_custom['8'] : 0}</td>
                                    <td>{9 in this.state.activeAccs.power_custom ? this.state.activeAccs.power_custom['9'] : 0}</td>
                                    <td>{10 in this.state.activeAccs.power_custom ? this.state.activeAccs.power_custom['10'] : 0}</td>
                                    <td>{11 in this.state.activeAccs.power_custom ? this.state.activeAccs.power_custom['11'] : 0}</td>
                                    <td>{12 in this.state.activeAccs.power_custom ? this.state.activeAccs.power_custom['12'] : 0}</td>
                                </tr>
                                <tr>
                                    <td>Power Entry</td>
                                    <td>{1 in this.state.activeAccs.power_entry ? this.state.activeAccs.power_entry['1'] : 0}</td>
                                    <td>{2 in this.state.activeAccs.power_entry ? this.state.activeAccs.power_entry['2'] : 0}</td>
                                    <td>{3 in this.state.activeAccs.power_entry ? this.state.activeAccs.power_entry['3'] : 0}</td>
                                    <td>{4 in this.state.activeAccs.power_entry ? this.state.activeAccs.power_entry['4'] : 0}</td>
                                    <td>{5 in this.state.activeAccs.power_entry ? this.state.activeAccs.power_entry['5'] : 0}</td>
                                    <td>{6 in this.state.activeAccs.power_entry ? this.state.activeAccs.power_entry['6'] : 0}</td>
                                    <td>{7 in this.state.activeAccs.power_entry ? this.state.activeAccs.power_entry['7'] : 0}</td>
                                    <td>{8 in this.state.activeAccs.power_entry ? this.state.activeAccs.power_entry['8'] : 0}</td>
                                    <td>{9 in this.state.activeAccs.power_entry ? this.state.activeAccs.power_entry['9'] : 0}</td>
                                    <td>{10 in this.state.activeAccs.power_entry ? this.state.activeAccs.power_entry['10'] : 0}</td>
                                    <td>{11 in this.state.activeAccs.power_entry ? this.state.activeAccs.power_entry['11'] : 0}</td>
                                    <td>{12 in this.state.activeAccs.power_entry ? this.state.activeAccs.power_entry['12'] : 0}</td>
                                </tr>
                                <tr>
                                    <td>Power 15</td>
                                    <td>{1 in this.state.activeAccs.power_15 ? this.state.activeAccs.power_15['1'] : 0}</td>
                                    <td>{2 in this.state.activeAccs.power_15 ? this.state.activeAccs.power_15['2'] : 0}</td>
                                    <td>{3 in this.state.activeAccs.power_15 ? this.state.activeAccs.power_15['3'] : 0}</td>
                                    <td>{4 in this.state.activeAccs.power_15 ? this.state.activeAccs.power_15['4'] : 0}</td>
                                    <td>{5 in this.state.activeAccs.power_15 ? this.state.activeAccs.power_15['5'] : 0}</td>
                                    <td>{6 in this.state.activeAccs.power_15 ? this.state.activeAccs.power_15['6'] : 0}</td>
                                    <td>{7 in this.state.activeAccs.power_15 ? this.state.activeAccs.power_15['7'] : 0}</td>
                                    <td>{8 in this.state.activeAccs.power_15 ? this.state.activeAccs.power_15['8'] : 0}</td>
                                    <td>{9 in this.state.activeAccs.power_15 ? this.state.activeAccs.power_15['9'] : 0}</td>
                                    <td>{10 in this.state.activeAccs.power_15 ? this.state.activeAccs.power_15['10'] : 0}</td>
                                    <td>{11 in this.state.activeAccs.power_15 ? this.state.activeAccs.power_15['11'] : 0}</td>
                                    <td>{12 in this.state.activeAccs.power_15 ? this.state.activeAccs.power_15['12'] : 0}</td>
                                </tr>
                                <tr>
                                    <td>Power 75</td>
                                    <td>{1 in this.state.activeAccs.power_75 ? this.state.activeAccs.power_75['1'] : 0}</td>
                                    <td>{2 in this.state.activeAccs.power_75 ? this.state.activeAccs.power_75['2'] : 0}</td>
                                    <td>{3 in this.state.activeAccs.power_75 ? this.state.activeAccs.power_75['3'] : 0}</td>
                                    <td>{4 in this.state.activeAccs.power_75 ? this.state.activeAccs.power_75['4'] : 0}</td>
                                    <td>{5 in this.state.activeAccs.power_75 ? this.state.activeAccs.power_75['5'] : 0}</td>
                                    <td>{6 in this.state.activeAccs.power_75 ? this.state.activeAccs.power_75['6'] : 0}</td>
                                    <td>{7 in this.state.activeAccs.power_75 ? this.state.activeAccs.power_75['7'] : 0}</td>
                                    <td>{8 in this.state.activeAccs.power_75 ? this.state.activeAccs.power_75['8'] : 0}</td>
                                    <td>{9 in this.state.activeAccs.power_75 ? this.state.activeAccs.power_75['9'] : 0}</td>
                                    <td>{10 in this.state.activeAccs.power_75 ? this.state.activeAccs.power_75['10'] : 0}</td>
                                    <td>{11 in this.state.activeAccs.power_75 ? this.state.activeAccs.power_75['11'] : 0}</td>
                                    <td>{12 in this.state.activeAccs.power_75 ? this.state.activeAccs.power_75['12'] : 0}</td>
                                </tr>
                                <tr>
                                    <td>Power Max</td>
                                    <td>{1 in this.state.activeAccs.power_max ? this.state.activeAccs.power_max['1'] : 0}</td>
                                    <td>{2 in this.state.activeAccs.power_max ? this.state.activeAccs.power_max['2'] : 0}</td>
                                    <td>{3 in this.state.activeAccs.power_max ? this.state.activeAccs.power_max['3'] : 0}</td>
                                    <td>{4 in this.state.activeAccs.power_max ? this.state.activeAccs.power_max['4'] : 0}</td>
                                    <td>{5 in this.state.activeAccs.power_max ? this.state.activeAccs.power_max['5'] : 0}</td>
                                    <td>{6 in this.state.activeAccs.power_max ? this.state.activeAccs.power_max['6'] : 0}</td>
                                    <td>{7 in this.state.activeAccs.power_max ? this.state.activeAccs.power_max['7'] : 0}</td>
                                    <td>{8 in this.state.activeAccs.power_max ? this.state.activeAccs.power_max['8'] : 0}</td>
                                    <td>{9 in this.state.activeAccs.power_max ? this.state.activeAccs.power_max['9'] : 0}</td>
                                    <td>{10 in this.state.activeAccs.power_max ? this.state.activeAccs.power_max['10'] : 0}</td>
                                    <td>{11 in this.state.activeAccs.power_max ? this.state.activeAccs.power_max['11'] : 0}</td>
                                    <td>{12 in this.state.activeAccs.power_max ? this.state.activeAccs.power_max['12'] : 0}</td>
                                </tr>
                                <tr className="last-td">
                                    <td>Total</td>
                                    <td>{this.handleActiveAccSum(1)}</td>
                                    <td>{this.handleActiveAccSum(2)}</td>
                                    <td>{this.handleActiveAccSum(3)}</td>
                                    <td>{this.handleActiveAccSum(4)}</td>
                                    <td>{this.handleActiveAccSum(5)}</td>
                                    <td>{this.handleActiveAccSum(6)}</td>
                                    <td>{this.handleActiveAccSum(7)}</td>
                                    <td>{this.handleActiveAccSum(8)}</td>
                                    <td>{this.handleActiveAccSum(9)}</td>
                                    <td>{this.handleActiveAccSum(10)}</td>
                                    <td>{this.handleActiveAccSum(11)}</td>
                                    <td>{this.handleActiveAccSum(12)}</td>
                                </tr>
                                <tr className="center-td">
                                    <td colSpan="13" align="center"><strong>Downloaded Files (Actives Only)	</strong></td>
                                </tr>
                                {this.state.downloadedFiles ? <><tr>
                                    <td>New Files</td>
                                    <td>{1 in this.state.downloadedFiles.new_files ? this.state.downloadedFiles.new_files['1'] : 0}</td>
                                    <td>{2 in this.state.downloadedFiles.new_files ? this.state.downloadedFiles.new_files['2'] : 0}</td>
                                    <td>{3 in this.state.downloadedFiles.new_files ? this.state.downloadedFiles.new_files['3'] : 0}</td>
                                    <td>{4 in this.state.downloadedFiles.new_files ? this.state.downloadedFiles.new_files['4'] : 0}</td>
                                    <td>{5 in this.state.downloadedFiles.new_files ? this.state.downloadedFiles.new_files['5'] : 0}</td>
                                    <td>{6 in this.state.downloadedFiles.new_files ? this.state.downloadedFiles.new_files['6'] : 0}</td>
                                    <td>{7 in this.state.downloadedFiles.new_files ? this.state.downloadedFiles.new_files['7'] : 0}</td>
                                    <td>{8 in this.state.downloadedFiles.new_files ? this.state.downloadedFiles.new_files['8'] : 0}</td>
                                    <td>{9 in this.state.downloadedFiles.new_files ? this.state.downloadedFiles.new_files['9'] : 0}</td>
                                    <td>{10 in this.state.downloadedFiles.new_files ? this.state.downloadedFiles.new_files['10'] : 0}</td>
                                    <td>{11 in this.state.downloadedFiles.new_files ? this.state.downloadedFiles.new_files['11'] : 0}</td>
                                    <td>{12 in this.state.downloadedFiles.new_files ? this.state.downloadedFiles.new_files['12'] : 0}</td>
                                </tr>
                                    <tr>
                                        <td>Updated Files with ALL Records</td>
                                        <td>{1 in this.state.downloadedFiles.updated_files_all_records ? this.state.downloadedFiles.updated_files_all_records['1'] : 0}</td>
                                        <td>{2 in this.state.downloadedFiles.updated_files_all_records ? this.state.downloadedFiles.updated_files_all_records['2'] : 0}</td>
                                        <td>{3 in this.state.downloadedFiles.updated_files_all_records ? this.state.downloadedFiles.updated_files_all_records['3'] : 0}</td>
                                        <td>{4 in this.state.downloadedFiles.updated_files_all_records ? this.state.downloadedFiles.updated_files_all_records['4'] : 0}</td>
                                        <td>{5 in this.state.downloadedFiles.updated_files_all_records ? this.state.downloadedFiles.updated_files_all_records['5'] : 0}</td>
                                        <td>{6 in this.state.downloadedFiles.updated_files_all_records ? this.state.downloadedFiles.updated_files_all_records['6'] : 0}</td>
                                        <td>{7 in this.state.downloadedFiles.updated_files_all_records ? this.state.downloadedFiles.updated_files_all_records['7'] : 0}</td>
                                        <td>{8 in this.state.downloadedFiles.updated_files_all_records ? this.state.downloadedFiles.updated_files_all_records['8'] : 0}</td>
                                        <td>{9 in this.state.downloadedFiles.updated_files_all_records ? this.state.downloadedFiles.updated_files_all_records['9'] : 0}</td>
                                        <td>{10 in this.state.downloadedFiles.updated_files_all_records ? this.state.downloadedFiles.updated_files_all_records['10'] : 0}</td>
                                        <td>{11 in this.state.downloadedFiles.updated_files_all_records ? this.state.downloadedFiles.updated_files_all_records['11'] : 0}</td>
                                        <td>{12 in this.state.downloadedFiles.updated_files_all_records ? this.state.downloadedFiles.updated_files_all_records['12'] : 0}</td>
                                    </tr>
                                    <tr>
                                        <td>Updated Files with Only Updates</td>
                                        <td>{1 in this.state.downloadedFiles.updated_files_update_records ? this.state.downloadedFiles.updated_files_update_records['1'] : 0}</td>
                                        <td>{2 in this.state.downloadedFiles.updated_files_update_records ? this.state.downloadedFiles.updated_files_update_records['2'] : 0}</td>
                                        <td>{3 in this.state.downloadedFiles.updated_files_update_records ? this.state.downloadedFiles.updated_files_update_records['3'] : 0}</td>
                                        <td>{4 in this.state.downloadedFiles.updated_files_update_records ? this.state.downloadedFiles.updated_files_update_records['4'] : 0}</td>
                                        <td>{5 in this.state.downloadedFiles.updated_files_update_records ? this.state.downloadedFiles.updated_files_update_records['5'] : 0}</td>
                                        <td>{6 in this.state.downloadedFiles.updated_files_update_records ? this.state.downloadedFiles.updated_files_update_records['6'] : 0}</td>
                                        <td>{7 in this.state.downloadedFiles.updated_files_update_records ? this.state.downloadedFiles.updated_files_update_records['7'] : 0}</td>
                                        <td>{8 in this.state.downloadedFiles.updated_files_update_records ? this.state.downloadedFiles.updated_files_update_records['8'] : 0}</td>
                                        <td>{9 in this.state.downloadedFiles.updated_files_update_records ? this.state.downloadedFiles.updated_files_update_records['9'] : 0}</td>
                                        <td>{10 in this.state.downloadedFiles.updated_files_update_records ? this.state.downloadedFiles.updated_files_update_records['10'] : 0}</td>
                                        <td>{11 in this.state.downloadedFiles.updated_files_update_records ? this.state.downloadedFiles.updated_files_update_records['11'] : 0}</td>
                                        <td>{12 in this.state.downloadedFiles.updated_files_update_records ? this.state.downloadedFiles.updated_files_update_records['12'] : 0}</td>
                                    </tr>
                                    <tr className="last-td">
                                        <td>Total</td>
                                        <td>{this.handleDownloadedFilesSum(1)}</td>
                                        <td>{this.handleDownloadedFilesSum(2)}</td>
                                        <td>{this.handleDownloadedFilesSum(3)}</td>
                                        <td>{this.handleDownloadedFilesSum(4)}</td>
                                        <td>{this.handleDownloadedFilesSum(5)}</td>
                                        <td>{this.handleDownloadedFilesSum(6)}</td>
                                        <td>{this.handleDownloadedFilesSum(7)}</td>
                                        <td>{this.handleDownloadedFilesSum(8)}</td>
                                        <td>{this.handleDownloadedFilesSum(9)}</td>
                                        <td>{this.handleDownloadedFilesSum(10)}</td>
                                        <td>{this.handleDownloadedFilesSum(11)}</td>
                                        <td>{this.handleDownloadedFilesSum(12)}</td>
                                    </tr></> : <tr></tr>}
                                <tr className="center-td">
                                    <td colSpan="13" align="center"><strong>Downloaded Records (Actives only)	</strong></td>
                                </tr>
                                {this.state.downloadedRecords ? <>
                                <tr>
                                    <td>Power Custom - avg</td>
                                    <td>{1 in this.state.downloadedRecords.power_custom ? this.state.downloadedRecords.power_custom['1'] : 0}</td>
                                    <td>{2 in this.state.downloadedRecords.power_custom ? this.state.downloadedRecords.power_custom['2'] : 0}</td>
                                    <td>{3 in this.state.downloadedRecords.power_custom ? this.state.downloadedRecords.power_custom['3'] : 0}</td>
                                    <td>{4 in this.state.downloadedRecords.power_custom ? this.state.downloadedRecords.power_custom['4'] : 0}</td>
                                    <td>{5 in this.state.downloadedRecords.power_custom ? this.state.downloadedRecords.power_custom['5'] : 0}</td>
                                    <td>{6 in this.state.downloadedRecords.power_custom ? this.state.downloadedRecords.power_custom['6'] : 0}</td>
                                    <td>{7 in this.state.downloadedRecords.power_custom ? this.state.downloadedRecords.power_custom['7'] : 0}</td>
                                    <td>{8 in this.state.downloadedRecords.power_custom ? this.state.downloadedRecords.power_custom['8'] : 0}</td>
                                    <td>{9 in this.state.downloadedRecords.power_custom ? this.state.downloadedRecords.power_custom['9'] : 0}</td>
                                    <td>{10 in this.state.downloadedRecords.power_custom ? this.state.downloadedRecords.power_custom['10'] : 0}</td>
                                    <td>{11 in this.state.downloadedRecords.power_custom ? this.state.downloadedRecords.power_custom['11'] : 0}</td>
                                    <td>{12 in this.state.downloadedRecords.power_custom ? this.state.downloadedRecords.power_custom['12'] : 0}</td>
                                </tr>
                                <tr>
                                    <td>Power Entry - avg</td>
                                    <td>{1 in this.state.downloadedRecords.power_entry ? this.state.downloadedRecords.power_entry['1'] : 0}</td>
                                    <td>{2 in this.state.downloadedRecords.power_entry ? this.state.downloadedRecords.power_entry['2'] : 0}</td>
                                    <td>{3 in this.state.downloadedRecords.power_entry ? this.state.downloadedRecords.power_entry['3'] : 0}</td>
                                    <td>{4 in this.state.downloadedRecords.power_entry ? this.state.downloadedRecords.power_entry['4'] : 0}</td>
                                    <td>{5 in this.state.downloadedRecords.power_entry ? this.state.downloadedRecords.power_entry['5'] : 0}</td>
                                    <td>{6 in this.state.downloadedRecords.power_entry ? this.state.downloadedRecords.power_entry['6'] : 0}</td>
                                    <td>{7 in this.state.downloadedRecords.power_entry ? this.state.downloadedRecords.power_entry['7'] : 0}</td>
                                    <td>{8 in this.state.downloadedRecords.power_entry ? this.state.downloadedRecords.power_entry['8'] : 0}</td>
                                    <td>{9 in this.state.downloadedRecords.power_entry ? this.state.downloadedRecords.power_entry['9'] : 0}</td>
                                    <td>{10 in this.state.downloadedRecords.power_entry ? this.state.downloadedRecords.power_entry['10'] : 0}</td>
                                    <td>{11 in this.state.downloadedRecords.power_entry ? this.state.downloadedRecords.power_entry['11'] : 0}</td>
                                    <td>{12 in this.state.downloadedRecords.power_entry ? this.state.downloadedRecords.power_entry['12'] : 0}</td>
                                </tr>
                                <tr>
                                    <td>Power 15 - avg</td>
                                    <td>{1 in this.state.downloadedRecords.power_15 ? this.state.downloadedRecords.power_15['1'] : 0}</td>
                                    <td>{2 in this.state.downloadedRecords.power_15 ? this.state.downloadedRecords.power_15['2'] : 0}</td>
                                    <td>{3 in this.state.downloadedRecords.power_15 ? this.state.downloadedRecords.power_15['3'] : 0}</td>
                                    <td>{4 in this.state.downloadedRecords.power_15 ? this.state.downloadedRecords.power_15['4'] : 0}</td>
                                    <td>{5 in this.state.downloadedRecords.power_15 ? this.state.downloadedRecords.power_15['5'] : 0}</td>
                                    <td>{6 in this.state.downloadedRecords.power_15 ? this.state.downloadedRecords.power_15['6'] : 0}</td>
                                    <td>{7 in this.state.downloadedRecords.power_15 ? this.state.downloadedRecords.power_15['7'] : 0}</td>
                                    <td>{8 in this.state.downloadedRecords.power_15 ? this.state.downloadedRecords.power_15['8'] : 0}</td>
                                    <td>{9 in this.state.downloadedRecords.power_15 ? this.state.downloadedRecords.power_15['9'] : 0}</td>
                                    <td>{10 in this.state.downloadedRecords.power_15 ? this.state.downloadedRecords.power_15['10'] : 0}</td>
                                    <td>{11 in this.state.downloadedRecords.power_15 ? this.state.downloadedRecords.power_15['11'] : 0}</td>
                                    <td>{12 in this.state.downloadedRecords.power_15 ? this.state.downloadedRecords.power_15['12'] : 0}</td>
                                </tr>
                                <tr>
                                    <td>Power 75 - avg</td>
                                    <td>{1 in this.state.downloadedRecords.power_75 ? this.state.downloadedRecords.power_75['1'] : 0}</td>
                                    <td>{2 in this.state.downloadedRecords.power_75 ? this.state.downloadedRecords.power_75['2'] : 0}</td>
                                    <td>{3 in this.state.downloadedRecords.power_75 ? this.state.downloadedRecords.power_75['3'] : 0}</td>
                                    <td>{4 in this.state.downloadedRecords.power_75 ? this.state.downloadedRecords.power_75['4'] : 0}</td>
                                    <td>{5 in this.state.downloadedRecords.power_75 ? this.state.downloadedRecords.power_75['5'] : 0}</td>
                                    <td>{6 in this.state.downloadedRecords.power_75 ? this.state.downloadedRecords.power_75['6'] : 0}</td>
                                    <td>{7 in this.state.downloadedRecords.power_75 ? this.state.downloadedRecords.power_75['7'] : 0}</td>
                                    <td>{8 in this.state.downloadedRecords.power_75 ? this.state.downloadedRecords.power_75['8'] : 0}</td>
                                    <td>{9 in this.state.downloadedRecords.power_75 ? this.state.downloadedRecords.power_75['9'] : 0}</td>
                                    <td>{10 in this.state.downloadedRecords.power_75 ? this.state.downloadedRecords.power_75['10'] : 0}</td>
                                    <td>{11 in this.state.downloadedRecords.power_75 ? this.state.downloadedRecords.power_75['11'] : 0}</td>
                                    <td>{12 in this.state.downloadedRecords.power_75 ? this.state.downloadedRecords.power_75['12'] : 0}</td>
                                </tr>
                                <tr>
                                    <td>Power Max - avg</td>
                                    <td>{1 in this.state.downloadedRecords.power_max ? this.state.downloadedRecords.power_max['1'] : 0}</td>
                                    <td>{2 in this.state.downloadedRecords.power_max ? this.state.downloadedRecords.power_max['2'] : 0}</td>
                                    <td>{3 in this.state.downloadedRecords.power_max ? this.state.downloadedRecords.power_max['3'] : 0}</td>
                                    <td>{4 in this.state.downloadedRecords.power_max ? this.state.downloadedRecords.power_max['4'] : 0}</td>
                                    <td>{5 in this.state.downloadedRecords.power_max ? this.state.downloadedRecords.power_max['5'] : 0}</td>
                                    <td>{6 in this.state.downloadedRecords.power_max ? this.state.downloadedRecords.power_max['6'] : 0}</td>
                                    <td>{7 in this.state.downloadedRecords.power_max ? this.state.downloadedRecords.power_max['7'] : 0}</td>
                                    <td>{8 in this.state.downloadedRecords.power_max ? this.state.downloadedRecords.power_max['8'] : 0}</td>
                                    <td>{9 in this.state.downloadedRecords.power_max ? this.state.downloadedRecords.power_max['9'] : 0}</td>
                                    <td>{10 in this.state.downloadedRecords.power_max ? this.state.downloadedRecords.power_max['10'] : 0}</td>
                                    <td>{11 in this.state.downloadedRecords.power_max ? this.state.downloadedRecords.power_max['11'] : 0}</td>
                                    <td>{12 in this.state.downloadedRecords.power_max ? this.state.downloadedRecords.power_max['12'] : 0}</td>
                                </tr>
                                <tr className="last-td">
                                    <td>Total - avg</td>
                                    <td>{this.handleDownloadedRecordsSum(1)}</td>
                                    <td>{this.handleDownloadedRecordsSum(2)}</td>
                                    <td>{this.handleDownloadedRecordsSum(3)}</td>
                                    <td>{this.handleDownloadedRecordsSum(4)}</td>
                                    <td>{this.handleDownloadedRecordsSum(5)}</td>
                                    <td>{this.handleDownloadedRecordsSum(6)}</td>
                                    <td>{this.handleDownloadedRecordsSum(7)}</td>
                                    <td>{this.handleDownloadedRecordsSum(8)}</td>
                                    <td>{this.handleDownloadedRecordsSum(9)}</td>
                                    <td>{this.handleDownloadedRecordsSum(10)}</td>
                                    <td>{this.handleDownloadedRecordsSum(11)}</td>
                                    <td>{this.handleDownloadedRecordsSum(12)}</td>
                                </tr></> : <tr></tr>}
                                <tr className="center-td">
                                    <td colSpan="13" align="center"><strong>Sub-Users (Actives Only)</strong></td>
                                </tr>
                                {this.state.subUsers ? <>
                                <tr>
                                    <td>Power Custom - % of accounts with sub-users</td>
                                    <td>{1 in this.state.subUsers.power_custom ? this.state.subUsers.power_custom['1'] : 0}</td>
                                    <td>{2 in this.state.subUsers.power_custom ? this.state.subUsers.power_custom['2'] : 0}</td>
                                    <td>{3 in this.state.subUsers.power_custom ? this.state.subUsers.power_custom['3'] : 0}</td>
                                    <td>{4 in this.state.subUsers.power_custom ? this.state.subUsers.power_custom['4'] : 0}</td>
                                    <td>{5 in this.state.subUsers.power_custom ? this.state.subUsers.power_custom['5'] : 0}</td>
                                    <td>{6 in this.state.subUsers.power_custom ? this.state.subUsers.power_custom['6'] : 0}</td>
                                    <td>{7 in this.state.subUsers.power_custom ? this.state.subUsers.power_custom['7'] : 0}</td>
                                    <td>{8 in this.state.subUsers.power_custom ? this.state.subUsers.power_custom['8'] : 0}</td>
                                    <td>{9 in this.state.subUsers.power_custom ? this.state.subUsers.power_custom['9'] : 0}</td>
                                    <td>{10 in this.state.subUsers.power_custom ? this.state.subUsers.power_custom['10'] : 0}</td>
                                    <td>{11 in this.state.subUsers.power_custom ? this.state.subUsers.power_custom['11'] : 0}</td>
                                    <td>{12 in this.state.subUsers.power_custom ? this.state.subUsers.power_custom['12'] : 0}</td>
                                </tr>
                                <tr>
                                    <td>Power Entry - % of accounts with sub-users</td>
                                    <td>{1 in this.state.subUsers.power_entry ? this.state.subUsers.power_entry['1'] : 0}</td>
                                    <td>{2 in this.state.subUsers.power_entry ? this.state.subUsers.power_entry['2'] : 0}</td>
                                    <td>{3 in this.state.subUsers.power_entry ? this.state.subUsers.power_entry['3'] : 0}</td>
                                    <td>{4 in this.state.subUsers.power_entry ? this.state.subUsers.power_entry['4'] : 0}</td>
                                    <td>{5 in this.state.subUsers.power_entry ? this.state.subUsers.power_entry['5'] : 0}</td>
                                    <td>{6 in this.state.subUsers.power_entry ? this.state.subUsers.power_entry['6'] : 0}</td>
                                    <td>{7 in this.state.subUsers.power_entry ? this.state.subUsers.power_entry['7'] : 0}</td>
                                    <td>{8 in this.state.subUsers.power_entry ? this.state.subUsers.power_entry['8'] : 0}</td>
                                    <td>{9 in this.state.subUsers.power_entry ? this.state.subUsers.power_entry['9'] : 0}</td>
                                    <td>{10 in this.state.subUsers.power_entry ? this.state.subUsers.power_entry['10'] : 0}</td>
                                    <td>{11 in this.state.subUsers.power_entry ? this.state.subUsers.power_entry['11'] : 0}</td>
                                    <td>{12 in this.state.subUsers.power_entry ? this.state.subUsers.power_entry['12'] : 0}</td>
                                </tr>
                                    <tr>
                                        <td>Power 15 - % of accounts with sub-users</td>
                                        <td>{1 in this.state.subUsers.power_15 ? this.state.subUsers.power_15['1'] : 0}</td>
                                        <td>{2 in this.state.subUsers.power_15 ? this.state.subUsers.power_15['2'] : 0}</td>
                                        <td>{3 in this.state.subUsers.power_15 ? this.state.subUsers.power_15['3'] : 0}</td>
                                        <td>{4 in this.state.subUsers.power_15 ? this.state.subUsers.power_15['4'] : 0}</td>
                                        <td>{5 in this.state.subUsers.power_15 ? this.state.subUsers.power_15['5'] : 0}</td>
                                        <td>{6 in this.state.subUsers.power_15 ? this.state.subUsers.power_15['6'] : 0}</td>
                                        <td>{7 in this.state.subUsers.power_15 ? this.state.subUsers.power_15['7'] : 0}</td>
                                        <td>{8 in this.state.subUsers.power_15 ? this.state.subUsers.power_15['8'] : 0}</td>
                                        <td>{9 in this.state.subUsers.power_15 ? this.state.subUsers.power_15['9'] : 0}</td>
                                        <td>{10 in this.state.subUsers.power_15 ? this.state.subUsers.power_15['10'] : 0}</td>
                                        <td>{11 in this.state.subUsers.power_15 ? this.state.subUsers.power_15['11'] : 0}</td>
                                        <td>{12 in this.state.subUsers.power_15 ? this.state.subUsers.power_15['12'] : 0}</td>
                                    </tr>
                                    <tr>
                                        <td>Power 75 - % of accounts with sub-users</td>
                                        <td>{1 in this.state.subUsers.power_75 ? this.state.subUsers.power_75['1'] : 0}</td>
                                        <td>{2 in this.state.subUsers.power_75 ? this.state.subUsers.power_75['2'] : 0}</td>
                                        <td>{3 in this.state.subUsers.power_75 ? this.state.subUsers.power_75['3'] : 0}</td>
                                        <td>{4 in this.state.subUsers.power_75 ? this.state.subUsers.power_75['4'] : 0}</td>
                                        <td>{5 in this.state.subUsers.power_75 ? this.state.subUsers.power_75['5'] : 0}</td>
                                        <td>{6 in this.state.subUsers.power_75 ? this.state.subUsers.power_75['6'] : 0}</td>
                                        <td>{7 in this.state.subUsers.power_75 ? this.state.subUsers.power_75['7'] : 0}</td>
                                        <td>{8 in this.state.subUsers.power_75 ? this.state.subUsers.power_75['8'] : 0}</td>
                                        <td>{9 in this.state.subUsers.power_75 ? this.state.subUsers.power_75['9'] : 0}</td>
                                        <td>{10 in this.state.subUsers.power_75 ? this.state.subUsers.power_75['10'] : 0}</td>
                                        <td>{11 in this.state.subUsers.power_75 ? this.state.subUsers.power_75['11'] : 0}</td>
                                        <td>{12 in this.state.subUsers.power_75 ? this.state.subUsers.power_75['12'] : 0}</td>
                                    </tr>
                                    <tr>
                                        <td>Power Max - % of accounts with sub-users</td>
                                        <td>{1 in this.state.subUsers.power_max ? this.state.subUsers.power_max['1'] : 0}</td>
                                        <td>{2 in this.state.subUsers.power_max ? this.state.subUsers.power_max['2'] : 0}</td>
                                        <td>{3 in this.state.subUsers.power_max ? this.state.subUsers.power_max['3'] : 0}</td>
                                        <td>{4 in this.state.subUsers.power_max ? this.state.subUsers.power_max['4'] : 0}</td>
                                        <td>{5 in this.state.subUsers.power_max ? this.state.subUsers.power_max['5'] : 0}</td>
                                        <td>{6 in this.state.subUsers.power_max ? this.state.subUsers.power_max['6'] : 0}</td>
                                        <td>{7 in this.state.subUsers.power_max ? this.state.subUsers.power_max['7'] : 0}</td>
                                        <td>{8 in this.state.subUsers.power_max ? this.state.subUsers.power_max['8'] : 0}</td>
                                        <td>{9 in this.state.subUsers.power_max ? this.state.subUsers.power_max['9'] : 0}</td>
                                        <td>{10 in this.state.subUsers.power_max ? this.state.subUsers.power_max['10'] : 0}</td>
                                        <td>{11 in this.state.subUsers.power_max ? this.state.subUsers.power_max['11'] : 0}</td>
                                        <td>{12 in this.state.subUsers.power_max ? this.state.subUsers.power_max['12'] : 0}</td>
                                    </tr>
                                    <tr className="last-td">
                                        <td>Total - % of accounts with sub-users</td>
                                        <td>{this.handleSubusersSum(1)}</td>
                                        <td>{this.handleSubusersSum(2)}</td>
                                        <td>{this.handleSubusersSum(3)}</td>
                                        <td>{this.handleSubusersSum(4)}</td>
                                        <td>{this.handleSubusersSum(5)}</td>
                                        <td>{this.handleSubusersSum(6)}</td>
                                        <td>{this.handleSubusersSum(7)}</td>
                                        <td>{this.handleSubusersSum(8)}</td>
                                        <td>{this.handleSubusersSum(9)}</td>
                                        <td>{this.handleSubusersSum(10)}</td>
                                        <td>{this.handleSubusersSum(11)}</td>
                                        <td>{this.handleSubusersSum(12)}</td>
                                    </tr></> : <tr></tr>}
                                <tr className="center-td">
                                    <td colSpan="13" align="center"><strong>Other</strong></td>
                                </tr>
                                {this.state.others ? <><tr>
                                    <td>New / Completed Power Entry Registration</td>
                                    <td>{1 in this.state.others.new_entry ? this.state.others.new_entry['1'] : 0}</td>
                                    <td>{2 in this.state.others.new_entry ? this.state.others.new_entry['2'] : 0}</td>
                                    <td>{3 in this.state.others.new_entry ? this.state.others.new_entry['3'] : 0}</td>
                                    <td>{4 in this.state.others.new_entry ? this.state.others.new_entry['4'] : 0}</td>
                                    <td>{5 in this.state.others.new_entry ? this.state.others.new_entry['5'] : 0}</td>
                                    <td>{6 in this.state.others.new_entry ? this.state.others.new_entry['6'] : 0}</td>
                                    <td>{7 in this.state.others.new_entry ? this.state.others.new_entry['7'] : 0}</td>
                                    <td>{8 in this.state.others.new_entry ? this.state.others.new_entry['8'] : 0}</td>
                                    <td>{9 in this.state.others.new_entry ? this.state.others.new_entry['9'] : 0}</td>
                                    <td>{10 in this.state.others.new_entry ? this.state.others.new_entry['10'] : 0}</td>
                                    <td>{11 in this.state.others.new_entry ? this.state.others.new_entry['11'] : 0}</td>
                                    <td>{12 in this.state.others.new_entry ? this.state.others.new_entry['12'] : 0}</td>
                                </tr>
                                    <tr>
                                        <td>Total # of Active Users (Primaries and Sub-Users)</td>
                                        <td>{1 in this.state.others.total_active_users ? this.state.others.total_active_users['1'] : 0}</td>
                                        <td>{2 in this.state.others.total_active_users ? this.state.others.total_active_users['2'] : 0}</td>
                                        <td>{3 in this.state.others.total_active_users ? this.state.others.total_active_users['3'] : 0}</td>
                                        <td>{4 in this.state.others.total_active_users ? this.state.others.total_active_users['4'] : 0}</td>
                                        <td>{5 in this.state.others.total_active_users ? this.state.others.total_active_users['5'] : 0}</td>
                                        <td>{6 in this.state.others.total_active_users ? this.state.others.total_active_users['6'] : 0}</td>
                                        <td>{7 in this.state.others.total_active_users ? this.state.others.total_active_users['7'] : 0}</td>
                                        <td>{8 in this.state.others.total_active_users ? this.state.others.total_active_users['8'] : 0}</td>
                                        <td>{9 in this.state.others.total_active_users ? this.state.others.total_active_users['9'] : 0}</td>
                                        <td>{10 in this.state.others.total_active_users ? this.state.others.total_active_users['10'] : 0}</td>
                                        <td>{11 in this.state.others.total_active_users ? this.state.others.total_active_users['11'] : 0}</td>
                                        <td>{12 in this.state.others.total_active_users ? this.state.others.total_active_users['12'] : 0}</td>
                                    </tr>
                                    <tr>
                                        <td >Total Available Records - Start of Month</td>
                                        <td>{1 in this.state.others.start_month_records ? this.state.others.start_month_records['1'] : 0}</td>
                                        <td>{2 in this.state.others.start_month_records ? this.state.others.start_month_records['2'] : 0}</td>
                                        <td>{3 in this.state.others.start_month_records ? this.state.others.start_month_records['3'] : 0}</td>
                                        <td>{4 in this.state.others.start_month_records ? this.state.others.start_month_records['4'] : 0}</td>
                                        <td>{5 in this.state.others.start_month_records ? this.state.others.start_month_records['5'] : 0}</td>
                                        <td>{6 in this.state.others.start_month_records ? this.state.others.start_month_records['6'] : 0}</td>
                                        <td>{7 in this.state.others.start_month_records ? this.state.others.start_month_records['7'] : 0}</td>
                                        <td>{8 in this.state.others.start_month_records ? this.state.others.start_month_records['8'] : 0}</td>
                                        <td>{9 in this.state.others.start_month_records ? this.state.others.start_month_records['9'] : 0}</td>
                                        <td>{10 in this.state.others.start_month_records ? this.state.others.start_month_records['10'] : 0}</td>
                                        <td>{11 in this.state.others.start_month_records ? this.state.others.start_month_records['11'] : 0}</td>
                                        <td>{12 in this.state.others.start_month_records ? this.state.others.start_month_records['12'] : 0}</td>
                                    </tr></> : <tr></tr>}
                            </> : <tr>
                                <td colSpan="13" align="center">
                                    <div className="text-center">
                                        <div
                                            className="spinner-border text-primary mt-3"
                                            style={{ width: "3rem", height: "3rem", marginLeft: "auto" }}
                                            role="status"
                                        >

                                            <span className="sr-only">Loading...</span>
                                        </div></div>
                                </td>
                            </tr>}
                        </tbody>
                    </table>
                </div>



            </section>

        );
    }
}

// Export
export default OverallStatsComponent;
