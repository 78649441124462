// Vendor Imports
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// Local Imports
import Form from "./Form";
import { changeSubUserPassword } from "../../../service/client";
import { setLoginUser } from "../../../reducers/client/actions";

// Change Password Component for sub user first logged in with temporary password
class ChangeSubUserPasswordComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toggle: false,
      error: false,
      backendErrors: null,
      loading: "",
    };
  }

  // function to update new password api call
  handleSubmit = (values) => {
    if (!this.state.toggle) {
      this.setState({ error: true });
      return;
    } else {
      this.setState({ error: false });
    }
    let params = {
      user_email: this.props.subUserDetails.user_email,
      sub_activate: this.props.subUserDetails.sub_activate,
      passWord: values.newpass,
      passWord2: values.confirmpass,
    };
    this.setState({ loading: "loading" });
    changeSubUserPassword(params).then((res) => this.handleResponse(res));
  };
  // function to set current logged in user detail into store
  handleResponse = (res) => {
    if (res.data.code === 200) {
      this.setState({
        backendErrors: null,
        loading: "",
      });
      this.props.dispatch(setLoginUser(res.data.data));
      this.props.history.push("/search");
      //window.location = "/search";
    } else if (res.data.code === 422) {
      this.setState({ backendErrors: res.data.data.message[0], loading: "" });
    } else {
      this.setState({ backendErrors: res.data.message, loading: "" });
    }
  };

  checkToggle = () => {
    this.setState({ toggle: !this.state.toggle });
    if (!this.state.toggle === true) {
      this.setState({ error: false });
    }
  };

  render() {
    return (
      <div>
        <section className="my-search">
          <div className="container">
            <div className="row">
              <div className="col">
                <h1 className="display-4 text-uppercase">Change Password</h1>
              </div>
            </div>
          </div>
        </section>
        <section className="container sub-reg-block">
          <div className="row">
            <div
              className="col-sm-8 col-xs-12 acc-subp"
              style={{ margin: "0 auto" }}
            >
              <h2 className="display-5 text-uppercase mt-5 account-test">
                {" "}
                Create your password to access your account
              </h2>
              <div className="inner background-grey p-2 f08em add-subscribe">
                <div className="row">
                  <div className="col">

                    {this.state.backendErrors ? (
                      <div
                        className="alert alertlogin alert-danger"
                        role="alert"
                        dangerouslySetInnerHTML={{
                          __html: this.state.backendErrors,
                        }}
                      ></div>
                    ) : null}
                    <Form
                      onSubmit={this.handleSubmit}
                      loading={this.state.loading}
                      termsChange={this.checkToggle}
                      errorMessage={this.state.error}
                    ></Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (store, props) => {
  return {
    subUserDetails: store.client.subUserChangePass,
  };
};

// Export
export default withRouter(
  connect(mapStateToProps)(ChangeSubUserPasswordComponent)
);
