import React from "react";
import EmptyRoute from "./EmptyRoute";
import Page404 from '../../components/PageNotFound';
export default (
  <React.Fragment>

    <EmptyRoute
      component={Page404}
    />
  </React.Fragment>
);
