// Vendor Imports
import React from "react";
import { Field, reduxForm } from "redux-form";
// Local Imports
import validate from "./validate";

// Input Field
const renderInputField = ({
  input,
  label,
  type,
  meta: { touched, error, warning }
}) => (
  <div>
    <input
      {...input}
      placeholder={label}
      type={type}
      className="form-control"
    />
    {touched &&
      ((error && <span className="text-danger">{error}</span>) ||
        (warning && <span className="text-danger">{warning}</span>))}
  </div>
);

// Login Form
const ProtectorForm = props => {
  const { handleSubmit } = props;
  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group loginInput">
        <Field
          name="email"
          type="email"
          required='required'
          label="Email Address"
          component={renderInputField}
        />
      </div>
      <div className="form-group loginInput">
        <Field
          name="password"
          type="password"
          required='required'
          label="Password"
          component={renderInputField}
        />
      </div>

      <div className="row align-items-center mb-3 login-link">
        <div className="col-sm-4 login-btn">
          <button type="submit" className={"btn btn-pa text-uppercase w-100  " + props.loading}>
            Login
          </button>
        </div>

      </div>
    </form>
  );
};

// Redux Form
const reduxLoginForm = reduxForm({
  // a unique name for the form
  form: "protector",
  validate
})(ProtectorForm);

// Export
export default reduxLoginForm;
