// Vendor Imports
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Helmet } from 'react-helmet';
// Local Imports
import Form from "./Form";
import { forgotPassword } from "../../../service/client";

// ForgotPassword Component
class ForgotPasswordComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backendErrors: null,
      loading: ""
    };
  }

  // Handlers
  handleSubmit = values => {
    let params = {
      eMail: values.email
    };
    this.setState({ loading: "loading" });
    forgotPassword(params).then(res => this.handleResponse(res));
  };

  // Response Handler
  handleResponse = res => {
    if (res.data.code === 200) {
      this.setState({
        backendErrors: null,
        loading: ""
      });
      this.props.history.push("/forgot-password-success");
    } else {
      this.setState({ backendErrors: res.data.message, loading: "" });
    }
  };

  render() {
    return (
      <>
        <Helmet>
          <title> Forgot Password - Power Almanac -</title>
        </Helmet>
        <div style={{ marginBottom: "11%" }}>
          <section className="container login-section forgotpw-block">
            <div className="row login-bg">
              <div className="col-sm-12 col-md-6">
                <img
                  src="images/login.jpg"
                  alt="Placeholder"
                  className="img-responsive"
                />
              </div>
              <div className="col-sm-12 col-md-6 login-right">
                <h1>Forgot My Password</h1>
                <p>
                  <i>
                    To reset your password, please enter the email you used to
                    set-up your account, and click SUBMIT. We’ll THEN send you an
                    email with a link to reset your password.
                </i>
                </p>
                {this.state.backendErrors ? (
                  <div
                    className="alert alert-danger"
                    role="alert"
                    dangerouslySetInnerHTML={{
                      __html: this.state.backendErrors
                    }}
                  ></div>
                ) : null}
                <Form
                  onSubmit={this.handleSubmit}
                  loading={this.state.loading}
                ></Form>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

// Export
export default withRouter(connect()(ForgotPasswordComponent));
