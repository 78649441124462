// Vendor Imports
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// Local Imports
import DmsTitleBar from "./DmsTitleBar";
import DmsTable from './DmsTable';
import LoginForm from "./Form";
import { dmsLogin } from "../../../service/dms";
import {
    setDmsUser
} from "../../../reducers/dms/actions";

// Dms Login Component
class DMSComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            backendErrors: null,
            loading: ""
        };
    }
    componentDidMount() {
        this.redirectTo();
    }

    // Redirection based on user
    redirectTo = () => {
        if (this.props.dmsAuthToken !== null) {
            if (this.props.dmsUser && this.props.dmsUser.access_level === 'Supervisor') {
                if (this.props.dmsUser.primary_role === 'ProcessInitiator') {
                    this.props.history.push("/dms/supervisor/pi-dashboard");
                }
                if (this.props.dmsUser.primary_role === 'QualityChecker') {
                    this.props.history.push("/dms/supervisor/qc-dashboard");
                }
                if (this.props.dmsUser.primary_role === 'FinalApprover') {
                    this.props.history.push("/dms/supervisor/fa-dashboard");
                }
            }
            else if (this.props.dmsUser && this.props.dmsUser.access_level === 'VoiceAgent') {
                this.props.history.push("/dms/voice-agent/dashboard");
            }
            else if (this.props.dmsUser && this.props.dmsUser.access_level === 'DialerAdmin') {
                this.props.history.push("/dms/dialer-admin/dashboard");
            }
            else if (this.props.dmsUser && this.props.dmsUser.access_level === 'RecencyManagementTeam') {
                this.props.history.push("/dms/rrt/dashboard");
            }
        } else {
            this.props.history.push("/dms");
        }
    }

    // Handlers
    handleSubmit = values => {
        let params = {
            userid: values.email,
            password: values.password
        };
        this.setState({ loading: "loading" });
        dmsLogin(params).then(res => this.handleResponse(res));
    };

    // Handle dms login response
    handleResponse = res => {
        if (res.data.code === 200) {
            this.setState({
                backendErrors: null,
                loading: ""
            });
            this.props.dispatch(setDmsUser(res.data.data));
            this.redirectTo();
        } else {
            this.setState({ backendErrors: res.data.message, loading: "" });
        }
    };

    render() {
        return (
            <>
                <section>
                    <DmsTitleBar />
                </section>
                <section style={{ marginBottom: '10px' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="white-bg">
                                    <DmsTable />
                                    <LoginForm
                                        onSubmit={this.handleSubmit}
                                        loading={this.state.loading}
                                        backendErrors={this.state.backendErrors} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );

    }
}

// Export
function mapStateToProps(store, props) {
    return {
        dmsAuthToken: store.dms.authToken ? store.dms.authToken : null,
        dmsUser: store.dms.dmsUser ? store.dms.dmsUser : null
    }
}

export default withRouter(connect(mapStateToProps)(DMSComponent));


