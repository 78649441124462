// Vendor Imports
import React from "react";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Helmet } from 'react-helmet';

// Local Imports
import Table from './Table';
import Form from './Form';
import { getBillingHistory } from "../../../service/admin";

// View Billing History
class ViewBillingHistoryComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tableDetails: null,
            addModal: false
        };
    }

    componentDidMount() {
        this.getBillingData();
    }

    getBillingData = () => {
        // Api call for getting billing data
        getBillingHistory().then(res => this.handleResponse(res));
    }

    // Handling response
    handleResponse = res => {
        if (res.data.code === 200) {
            this.setState({
                tableDetails: res.data.data,
            });
        }
    };

    // Modal toggle
    toggle = () => {
        this.setState({ addModal: !this.state.addModal });
    }

    render() {
        return (
            <>
                <Helmet>
                    <title> PA Admin - View Billing History </title>
                </Helmet>
                <section>
                    <div className="container admin-container">
                        <div className="row">
                            <div className="col-12 mt-3">
                                <section className="admin-title d-flex justify-content-between">
                                    <h1>View Billing History</h1>
                                    <button type="button" className="btn trans-button" onClick={this.toggle}>Add Transaction</button>
                                </section>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <Table tableDetails={this.state.tableDetails} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Modal isOpen={this.state.addModal} backdrop="static" centered={true} >
                    <ModalHeader toggle={this.toggle} className="backColor">Add Transaction</ModalHeader>
                    <ModalBody>
                        <Form toggle={this.toggle} refreshData={this.getBillingData} />
                    </ModalBody>
                </Modal >
            </>
        );

    }
}

// Export
export default ViewBillingHistoryComponent;
