// Validate file
const validate = values => {
    const errors = {};
    if (!values.f_name) {
        errors.f_name = "Enter the First Name";
    } else if (values.f_name.trim() === "") {
        errors.f_name = "Enter the First Name";
    }
    if (!values.l_name) {
        errors.l_name = "Enter the Last Name";
    } else if (values.l_name.trim() === "") {
        errors.l_name = "Enter the Last Name";
    }
    if (!values.gov_title) {
        errors.gov_title = "Enter the Government Title";
    } else if (values.gov_title.trim() === "") {
        errors.gov_title = "Enter the Government Title";
    }
    if (values.email !== '' && typeof values.email !== 'undefined') {
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = 'Invalid Email entered. Please try again.'
        }
    }
    if (!values.phone_number) {
        errors.phone_number = 'Enter the Phone Number.'
    } else if (values.phone_number.replace(/-/g, "") === "") {
        errors.phone_number = 'Enter the Phone Number.'
    } else if (values.phone_number.replace(/-/g, "").length !== 10) {
        errors.phone_number = 'Invalid Phone Number entered. Please try again.'
    } else if (!/^[0-9 -]+$/i.test(values.phone_number)) {
        errors.phone_number = 'Invalid Phone Number entered. Please try again.'
    }
    if (!values.mail_box) {
        errors.mail_box = 'Enter the Mailing Street Box';
    } else if (values.mail_box.trim() === "") {
        errors.mail_box = 'Enter the Mailing Street Box';
    }
    if (!values.mail_city) {
        errors.mail_city = 'Enter the Mailing City';
    } else if (values.mail_city.trim() === "") {
        errors.mail_city = 'Enter the Mailing City';
    }
    if (!values.mail_zip) {
        errors.mail_zip = 'Enter the Mailing Zip Code';
    } else if (values.mail_zip.trim().length !== 5) {
        errors.mail_zip = 'Invalid Zip Code entered. Please try again.';
    }
    return errors;
};

export default validate;
