// Vendor Imports
import React from "react";
import { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import Cookies from 'js-cookie';
import AES from "crypto-js/aes";
import Utf8 from "crypto-js/enc-utf8";

// Local Imports
import validate from "./validate";

// Input Field
const renderInputField = ({
  input,
  label,
  type,
  meta: { touched, error, warning }
}) => (
  <div>
    <input
      {...input}
      placeholder={label}
      type={type}
      className="form-control"
    />
    {touched &&
      ((error && <span className="text-danger">{error}</span>) ||
        (warning && <span className="text-danger">{warning}</span>))}
  </div>
);

// Login Form
class LoginForm extends Component {
  componentDidMount() {
    let cookie = Cookies.getJSON('user-details');
    if (cookie) {
      if(cookie.rememberMe){
        this.props.initialize({ email: this.decrypt(cookie.email, 'poweralmanac'), password: this.decrypt(cookie.password, 'poweralmanac')});
      }
    }
  }

  decrypt = (val, passphrase) => {
    const bytes = AES.decrypt(val, passphrase);
    const originalText = bytes.toString(Utf8);
    return originalText;
  }

  render() {
    const { handleSubmit } = this.props;
     return (
       <form onSubmit={handleSubmit}>
         <div className="form-group loginInput">
           <Field
             name="email"
             type="text"
             required='required'
             label="Email Address"
             component={renderInputField}
           />
         </div>
         <div className="form-group loginInput">
           <Field
             name="password"
             type="password"
             required='required'
             label="Password"
             component={renderInputField}
           />
         </div>
         <div className="form-group form-check remember-form">
           <label className="form-check-label remember-label" htmlFor="rememberMe">
             <input type="hidden" name="rememberMe" value="0" />
             <input
               className="form-check-input remember-check"
               type="checkbox"
               name="rememberMe"
               id="rememberMe"
               onChange={this.props.rememberMeFun}
               checked={this.props.rememberMeVal}
             />
             <span className="checkmark"></span>
           </label>
           <label className="pl-4">
             Remember Me
          
           </label>
         </div>
         <div className="row align-items-center mb-3 login-link">
           <div className="col-sm-4 login-btn">
             <button type="submit" className={"btn btn-pa text-uppercase w-100  " + this.props.loading}>
               Log in
             </button>
           </div>
           <div className="col-sm-4 reg-btn" style={{ textAlign: "center" }}>
             <Link to="/get-my-free-100-leads" className="text-uppercase">
               Register
             </Link>
           </div>
           <div className="col-sm-4 frg-btn pl-0" style={{ textAlign: "center" }}>
             <Link to="/forgot-password" className="text-uppercase">
               forgot Password
             </Link>
           </div>
         </div>
       </form>
     );
  }
}

// Redux Form
const reduxLoginForm = reduxForm({
  // a unique name for the form
  form: "login",
  validate
})(LoginForm);

// Export
export default reduxLoginForm;
