// Vendor Imports
import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { Helmet } from 'react-helmet';
import {  useDispatch,useSelector } from "react-redux";
import { setCheckout } from '../../../../reducers/client/actions';
import { searchCheckout } from '../../../../service/client';
// Local Imports
import OnlinePayment from '../PaymentMedhod/OnlinePayment'
import OfflinePayment from '../PaymentMedhod/OfflinePayment'
import { renderCount, renderCurrency } from '../../../../utils'

// Search based credit checkout page
function SearchCreditPurchase(props) {
  const [InVoiceData, setInVoiceData] = useState([]);
  const dispatch = useDispatch();
  const searchResult = useSelector((store) => store.client.searchResult);
  const bestPricing = useSelector((store) => store.client.bestPricing);
  const info = useSelector((store) => store.client.info);
  // get and set credit checkout details data
  useEffect(() => {
   
      let params = { paid_records: searchResult.paid_records, no_ofRecords: searchResult.lastSearchNumMatched };
      searchCheckout(params).then(res =>
        handleCheckoutResponse(res),
      )

  },[searchResult]);

  //function to checkout credit payment and update user account
  const handleCheckoutResponse = (res) => {
    dispatch(setCheckout(res.data.data));
    setInVoiceData(res.data.data.invoice);
  }
  if(!searchResult){
    return (
              <div className="row pt-5 text-center">
                <p>Payment information not available</p>
              </div>
    )
  }else{
  return (
    <>
      <Helmet>
        <title> Checkout - Power Almanac </title>
      </Helmet>
      <div >
        <section className="my-search">
          <div className="container">
            <div classNameCheckoutComponent="row">
              <div className="col">
                <h1 className="display-4 text-uppercase">
                  PAYMENT NEEDED TO COMPLETE DOWNLOAD
                </h1>
              </div>
            </div>
          </div>
        </section>

        <div className="container">
          {
            typeof InVoiceData === 'undefined' ?
              <div className="row pt-5 text-center">
                <p>Payment information not available</p>
              </div>
              :
              <div className="row pt-5">
                <div className="col-lg-6">

                  <table className="table payoption-table">
                    <thead>
                      <tr>
                        <th scope="row" className="border-0">Item Ordered</th>
                        <th scope="row" className="border-0">Credits Included</th>
                        <th scope="row" className="border-bottom border-top-0 text-left" >Unit Price</th>
                        <th scope="row" className="border-bottom border-top-0 text-right" >Total Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        InVoiceData.length !== 0 ?

                          InVoiceData.purchases.map(function (item, i) {
                            return (<tr key={i}>
                              <td className="border-bottom">
                                {item.name}
                              </td>
                              <td className="border-bottom">
                                {renderCount(item.no_of_records)}
                              </td>
                              <td>{renderCount(item.unit_price)}</td>
                              <td className="text-right">${renderCurrency(item.cost)}</td>
                            </tr>)
                          })
                          :
                          <div className="spinner-grow" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                      }
                      <tr>
                        <td> <strong>Total Purchase </strong></td>
                        <td ><strong>{renderCount(InVoiceData.totalPurchase)} </strong></td>
                        <td> </td>
                        <td className="text-right" >
                          <strong> ${renderCurrency(InVoiceData.totalPrice)}  </strong>
                        </td>
                      </tr>


                    </tbody>
                  </table>

                  <div className="row mt-3">
                    <div className="col-sm-12  text-left download-records">
                      <div className="row">
                        <div className="col-md-10 text-right">
                          <b>Total records in this file:</b>
                        </div>
                        <div className="col-md-2 text-right">
                          <b>{renderCount(searchResult.lastSearchNumMatched)}</b>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-10 text-right">
                          Total credits required to download the file:
                        </div>
                        <div className="col-md-2 text-right">{renderCount(searchResult.credit_required)}</div>
                      </div>
                      <div className="row">
                        <div className="col-md-10 text-right">
                          Total credits available:
                        </div>
                        <div className="col-md-2 text-right">{renderCount(info.creditsRemaining)}</div>
                      </div>
                      <div className="row">
                        <div className="col-md-10 text-right">
                          Credits you must purchase:
                        </div>
                        <div className="col-md-2 text-right">{bestPricing.length !== 0 ? renderCount(bestPricing.bestPrice.newCredits) : 0}</div>
                      </div>

                      <div className="row">
                        <div className="col-md-10 text-right">
                          Credit balance after you purchase and download:
                        </div>
                        <div className="col-md-2 text-right">{bestPricing.length !== 0 ? renderCount(bestPricing.bestPrice.leftover) : ""}</div>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="col-lg-6 pay-col">
                  <OnlinePayment history={props.history} data={InVoiceData} checkoutType="download" />
                  <OfflinePayment checkoutType="download" />
                </div>

              </div>
          }
          {searchResult.length !== 0 ?
            <div className="row">
              <div className="col-12  text-center">
                <Link to="/search" className="back_btn">
                  <span>
                    <i
                      className="fa fa-angle-double-left"
                      aria-hidden="true"
                    ></i>
                  </span>
                  Back to Search
                </Link>
              </div>
            </div>
            :
            ""
          }
        </div>
      </div>
    </>
  );
        }

}


// function mapStateToProps(store, props) {
//   return {
//     subscriptions: store.client.subscriptions ? store.client.subscriptions : [],
//     myaccount: store.client.myaccount ? store.client.myaccount : [],
//     plan: store.client.plan ? store.client.plan : [],
//     info: store.client.info ? store.client.info : [],
//     bestPricing: store.client.bestPricing ? store.client.bestPricing : [],
//     searchResult: store.client.searchResult ? store.client.searchResult : [],
//     checkout: store.client.checkout ? store.client.checkout : [],
//   }
// }

export default withRouter(SearchCreditPurchase);
