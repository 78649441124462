import getInstance from "../api";

export const doProtectorLogin = async data => {
  let Email = "admin@poweralmanac.com";
  let Pass = "BH/F^@m&zC_DZDKK";
  if (data.eMail !== Email) {
    return { success: false, message: "Incorrect Email" }
  }
  else if (data.passWord !== Pass) {
    return { success: false, message: "Incorrect password" }
  }
  else if (data.eMail === Email && Pass === data.passWord) {
    return { success: true, message: "Authendicated" }
  } else {
    return { success: false, message: "Incorrect Email or password" }
  }
};

export const doLogin = async data => {
  const api = await getInstance(true);
  return api.post("login", data);
};
export const getProcesslist = async () => {
  const api = await getInstance(true);
  return api.get("migration_api");
};

export const emailActivation = async data => {
  const api = await getInstance(true);
  return api.post(`emailactivation/${data.token}/${data.email}`, {});
};

export const doRegister = async data => {
  const api = await getInstance(true);
  return api.post("register", data);
};

export const renewalLogin = async data => {
  const api = await getInstance(true);
  return api.post("renewallogin", data);
};
export const ResendActivation = async data => {
  const api = await getInstance(true);
  return api.post("resendactivation", data);
};


export const getRenewUser = async data => {
  const api = await getInstance(true);
  return api.get(`getrenewal/${data}`);
};

export const forgotPassword = async data => {
  const api = await getInstance(true);
  return api.post("forgotpassword", data);
};

export const resetPassword = async data => {
  const api = await getInstance(true);
  return api.post(`passwordreset/${data.id1}/${data.id2}/${data.token}`, {
    newpass: data.pass,
    confirmpass: data.confirmpass
  });
};

export const getMyAccount = async () => {
  const api = await getInstance(false, "Client");
  return api.post("myaccount");
};
export const updateWeeklyAlert = async data => {
  const api = await getInstance(false, "Client");
  return api.post("emailalertsettings", data);
};
export const changePassword = async data => {
  const api = await getInstance(false, "Client");
  return api.post("changepassword", data);
};

export const changeSubUserPassword = async data => {
  const api = await getInstance(true);
  return api.post("subregister", data);
};

export const addSubUser = async data => {
  const api = await getInstance(false, "Client");
  return api.post("addsubuser", data);
};

export const deleteSubUser = async data => {
  const api = await getInstance(false, "Client");
  return api.post("deletesubuser", data);
};

export const updateSubUser = async data => {
  const api = await getInstance(false, "Client");
  return api.post("managesubuser", data);
};

export const getMyTransactions = async () => {
  const api = await getInstance(false, "Client");
  return api.post("mytransactionhistory");
};

export const getSubscriptions = async () => {
  const api = await getInstance(false, "Client");
  return api.get("subscriptionlist");
};

export const getMySavedSearches = async () => {
  const api = await getInstance(false, "Client");
  return api.get("mysavedsearch");
};

export const updateMySavedSearchName = async data => {
  const api = await getInstance(false, "Client");
  return api.post("updatesavedname", data);
};
export const deleteMySavedSearch = async data => {
  const api = await getInstance(false, "Client");
  return api.post("deleterecords", data);
};

export const reDownloadTotal = async id => {
  const api = await getInstance(false, "Client");
  return api.get(`saveddownloads/${id}`);
};

export const reDownloadUpdated = async id => {
  const api = await getInstance(false, "Client");
  return api.get(`savedupdatedownloads/${id}`);
};

export const saveMyFirstSearch = async data => {
  const api = await getInstance(false, "Client");
  return api.post("newsurvey", data);
};

export const savePhoneNumber = async data => {
  const api = await getInstance(false, "Client");
  return api.post("updatephone", data);
};
export const smsVerification = async data => {
  const api = await getInstance(false, "Client");
  return api.post("smsverification", data);
};

export const getCheckout = async data => {
  const api = await getInstance(false, "Client");
  return api.post("checkout", data);
};

export const PayByCheck = async data => {
  const api = await getInstance(false, "Client");
  return api.post("paybycheck", data);
};

export const VerifyPlaidAccount = async data => {
  const api = await getInstance(false, "Client");
  return api.post("verifyplaidaccount", data);
};

export const getSearchResult = async data => {
  const api = await getInstance(false, "Client");
  return api.post("search", data);
};


export const getbestPricing = async data => {
  const api = await getInstance(false, "Client");
  return api.post("bestpricing", data);
};

export const saveNewSearch = async data => {
  const api = await getInstance(false, "Client");
  return api.post("savedsearch", data);
};

export const getZipCodes = async text => {
  const api = await getInstance(false, "Client");
  return api.get(`searchzipcode/${text}`);
};

export const doNewDownload = async data => {
  const api = await getInstance(false, "Client");
  return api.post('pa_download', data);
};

export const searchCheckout = async data => {
  const api = await getInstance(false, "Client");
  return api.post('checkoutbydownload', data);
};


export const StripePackage = async data => {
  const api = await getInstance(false, "Client");
  return api.post('stripepackage', data);
};

export const StripeCredits = async data => {
  const api = await getInstance(false, "Client");
  return api.post('stripecredits', data);
};

export const StripeSearchCredits = async data => {
  const api = await getInstance(false, "Client");
  return api.post('stripesearchcredits', data);
};

export const StripePackageCredits = async data => {
  const api = await getInstance(false, "Client");
  return api.post('stripepackagecredits', data);
};

export const savedDownloadExpired = async id => {
  const api = await getInstance(false, "Client");
  return api.get(`savedexpireddownloads/${id}`);
};

export const downloadExpired = async data => {
  const api = await getInstance(false, "Client");
  return api.get(`expireddownloads/${data.unpaid}/${data.id}`);
};

export const upgradeRequest = async data => {
  const api = await getInstance(false, "Client");
  return api.post('upgraderequest', data);
};

export const creditRequest = async data => {
  const api = await getInstance(false, "Client");
  return api.post('creditrequest', data);
};

export const renewPowerEntryPlan = async () => {
  const api = await getInstance(false, "Client");
  return api.post('renewpowerentry');
};

export const doLogOff = async () => {
  const api = await getInstance(false, "Client");
  return api.post('logout');
};


// DMS
export const dmsLogin = async data => {
  const api = await getInstance(true);
  return api.post("/jxarh/login", data);
};

export const contactUs = async data => {
  const api = await getInstance(true);
  return api.post("contactus", data);
};

export const UpdateNotificationPopup = async data => {
  const api = await getInstance(false, "Client");
  return api.post("updateftpopup", data);
};


export const GetLinkToken = async data => {
  const api = await getInstance(false, "Client");
  return api.post("plaidaccount", data);
};

export const PowerEnterCreditBonus = async data => {
  const api = await getInstance(false, "Client");
  return api.post("updatecredits", data);
};

export const DownloadLocGovAccountFile = async () => {
  const api = await getInstance(false, "Client");
  return api.get("download-local-gov-account-file");
};


export const LocalGovAccountInfo = async () => {
  const api = await getInstance(false, "Client");
  return api.get("local-gov-info");
};
