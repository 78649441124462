import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { upgradeRequest } from '../../../service/client';
// subscription upgrade request modal for primary users
function UpgrdeRequest(props) {
  const {
    modal,
    toggle
  } = props;

  const [subject, setSubject] = React.useState("Upgrading My Power Almanac account");
  const [message, setMessage] = React.useState("Hi Power Almanac Team, \nI may be interested in upgrading, but I have some questions.  Can we schedule a short call to discuss?  Thanks!");
  const [loading, setLoading] = React.useState('');
  const [success, setSuccess] = React.useState(false);
  // funcation to submit upgrade request api
  const handleUpgradeRequest = () => {
    let params = {
      upgradeSubject: subject,
      upgradeMessageBody: message
    }
    setLoading('loading');
    setSuccess(false);
    upgradeRequest(params).then(res =>
      handleResponse(res),
    )
  }
  const handleResponse = (res) => {
    if (res.data.code === 200) {
      setSuccess(true);
      setLoading('');
      setTimeout(function () {
        props.toggle()
      }, 2000);
    }

  }

  return (
    <div>

      <Modal
        size="lg"
        isOpen={modal}
        toggle={toggle}
        backdrop={true}
        centered={true}
        className="modal-dialog">
        <ModalHeader toggle={toggle}>Upgrade Your Account</ModalHeader>
        <ModalBody>
          <table className="table table-bordered">
            <tbody>
              <tr>
                <td>
                  From:
                </td>
                <td>
                  {props.myaccount.User_FirstName} {props.myaccount.User_LastName} [ {props.myaccount.User_Email} ]
                </td>

              </tr>
              <tr>
                <td> To: </td>
                <td>Power Almanac Sales [sales@PowerAlmanac.com]</td>
              </tr>
              <tr>
                <td>Subject:</td>
                <td>
                  <input onChange={(e) => setSubject(e.target.value)} type="text" className="form-control" value={subject} id="upgradeSubject" name="upgradeSubject" />
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <textarea rows="5"
                    className=" custom-textarea "
                    id="upgradeMessageBody"
                    name="upgradeMessageBody"
                    onChange={(e) => setMessage(e.target.value)}
                    defaultValue={message}
                  >
                  </textarea>
                </td>
              </tr>

            </tbody>
          </table>
        </ModalBody>
        <ModalFooter>
          {
            success ?
              <div className="row" >
                <div className="col-lg-12 text-center">
                  <div className="alert alert-success" role="alert">
                    Your request has been submitted. We will be in touch shortly
            </div>
                </div>
              </div>
              :
              ""
          }

          <button type="submit" className={loading + " btn btn-pa btn-success"} onClick={handleUpgradeRequest} id="sendRequestSubmit">SEND YOUR REQUEST NOW</button>

          <button onClick={toggle} className="btn btn-pa btn-success btn-secondary">Cancel</button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

function mapStateToProps(store, props) {
  return {
    searchResult: store.client.searchResult ? store.client.searchResult : [],
    searchLoading: store.client.searchLoading,
    info: store.client.info ? store.client.info : [],
    myaccount: store.client.myaccount ? store.client.myaccount : [],
    plan: store.client.plan ? store.client.plan : [],
    bestPricing: store.client.bestPricing ? store.client.bestPricing : [],
    currentSearch: store.client.currentSearch ? store.client.currentSearch : [],

  }
}

export default connect(mapStateToProps, {})(UpgrdeRequest);