// Vendor Imports
import React from "react";
import { connect } from "react-redux";
import MaterialTable from "material-table";

// Local Imports
import { getRoleMoveMis } from "../../../../service/dms";
import { dateFormatted } from "../../../../utils";

// Role Movement Mis Table
class RoleMoveMisTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tableDetails: null,
            columns: [
                {
                    title: "Last Confirmed Date",
                    field: "last_confirmed_date_dt",
                    render: rowData => dateFormatted(rowData.last_confirmed_date_dt)
                },
                { title: "Number of Roles", field: "number_of_roles" },
                { title: "Governing Board Member", field: "governing_board_member" },
                { title: "Head of Purchasing", field: "head_of_purchasing" },
                { title: "Head Clerk", field: "Head_Clerk" },
                { title: "Head of IT", field: "Head_of_IT" },
                { title: "Head of Finance", field: "Head_of_Finance" },
                { title: "Head of Law Enforcement", field: "Head_of_Law_Enforcement" },
                { title: "Top Appointed Executive", field: "Top_Appointed_Executive" },
                { title: "Top Elected Official", field: "Top_Elected_Official" },
                { title: "Head of Public Works", field: "Head_of_Public_Works" },
                { title: "Head of Fire Protection Services", field: "Head_of_Fire_Protection_Services" },
                { title: "Head of Buildings", field: "Head_of_Buildings" },
                { title: "Head of Communications", field: "Head_of_Communications" },
                { title: "Head of HR", field: "Head_of_HR" },
                { title: "Head of Water Supply", field: "Head_of_Water_Supply" },
                { title: "Head of Parks and Recreation ", field: "Head_of_Parks_and_Recreation" },
                { title: "Head of Planning/Zoning ", field: "Head_of_Zoning" },
                { title: "Head of Economic Development ", field: "Head_of_Economic_Development" },
                { title: "Deputy Top Appointed Executive", field: "Deputy_Top_Appointed_Executive" },
                { title: "Head of Facilities Management", field: "HeadofFacilitiesManagement" },
                { title: "Head of Health Services", field: "HeadofHealthServices" },
                { title: "Head of Geographic Information System", field: "HeadofGeographicInformationSystem" },
                { title: "Head of Corrections", field: "HeadofCorrections" },
            ]
        };
    }

    componentDidMount() {
        // Api call to get data
        getRoleMoveMis().then(res => this.handleResponse(res));
    }

    // Response Handler
    handleResponse = res => {
        if (res.data.code === 200) {
            this.setState({
                tableDetails: res.data.data.role_movement_data
            });
        }
    };


    render() {
        return (
            <div>
                {this.state.tableDetails ? <div>
                    <section className="dmstable mt-3">
                        <div className="row voicetitle">
                            <div className="col-md-12"><h2>Role Movement MIS</h2></div>

                        </div>
                        {/* <div className="table-responsive">
                            <table className="table table-bordered ">
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            Last Confirmed Date</th>
                                        <th scope="col">Number of Roles</th>
                                        <th scope="col">Governing Board Member</th>
                                        <th scope="col">Head of Purchasing / Procurement</th>
                                        <th scope="col">Clerk</th>
                                        <th scope="col">Head of IT</th>
                                        <th scope="col">Head of Finance / Budgeting</th>
                                        <th scope="col">Head of Law Enforcement</th>
                                        <th scope="col">Top Appointed Executive</th>
                                        <th scope="col">Top Elected Official</th>
                                        <th scope="col">Head of Public Works</th>
                                        <th scope="col">Head of Fire Protection Services</th>
                                        <th scope="col">Head of Building Official</th>
                                        <th scope="col">Head of Communications</th>
                                        <th scope="col">Head of HR</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.tableDetails.map((listValue, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{listValue.last_confirmed_date}</td>
                                                <td>{listValue.number_of_roles}</td>
                                                <td>{listValue.governing_board_member}</td>
                                                <td>{listValue['head_of_purchasing/procurement']}</td>
                                                <td>{listValue.clerk}</td>
                                                <td>{listValue.Head_of_IT}</td>
                                                <td>{listValue['Head_of_Finance/Budgeting']}</td>
                                                <td>{listValue.Head_of_Law_Enforcement}</td>
                                                <td>{listValue.top_appointed_executive}</td>
                                                <td>{listValue.Top_Elected_Official}</td>
                                                <td>{listValue.Head_of_Public_Works}</td>
                                                <td>{listValue.Head_of_Fire_Protection_Services}</td>
                                                <td>{listValue.Head_Building_Official}</td>
                                                <td>{listValue.Head_of_Communications}</td>
                                                <td>{listValue.Head_of_HR}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div> */}
                        <MaterialTable
                            className="table display search-table table-responsive"
                            columns={this.state.columns}
                            data={this.state.tableDetails}
                            title=""
                            options={{
                                sorting: true,
                                actionsColumnIndex: -1,
                                pageSize: 10,
                                emptyRowsWhenPaging: false
                            }}
                        />
                    </section>
                </div> : <div className="text-center">
                    <div
                        className="spinner-border text-primary mt-3"
                        style={{ width: "3rem", height: "3rem", marginLeft: "auto" }}
                        role="status"
                    >

                        <span className="sr-only">Loading...</span>
                    </div></div>}
            </div>
        );
    }
}

// Export
export default connect()(RoleMoveMisTable);
