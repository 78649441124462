import getInstance from "../api";

// DMS
export const dmsLogin = async data => {
    const api = await getInstance(true);
    return api.post("/jxarh/login", data);
};

export const getMyActiveTasks = async () => {
    const api = await getInstance(false, "Dms");
    return api.get("/jxarh/pidashboard");
};

export const getVoiceAgents = async () => {
    const api = await getInstance(false, "Dms");
    return api.post("/jxarh/getemployeelist", {});
};

export const getAgeingMis = async () => {
    const api = await getInstance(false, "Dms");
    return api.get("/jxarh/ageingmis");
};

export const getRoleMoveMis = async () => {
    const api = await getInstance(false, "Dms");
    return api.get("/jxarh/rolemovementmis");
};

export const getNewOfficials = async () => {
    const api = await getInstance(false, "Dms");
    return api.get("/jxarh/newofficials");
};

export const getProductivity = async data => {
    const api = await getInstance(false, "Dms");
    return api.post("/jxarh/viewproductivity", data);
};

export const addNewOfficial = async data => {
    const api = await getInstance(false, "Dms");
    return api.post("/jxarh/newofficialsaddrole", data);
};

export const editNewOfficial = async data => {
    const api = await getInstance(false, "Dms");
    return api.post("/jxarh/newofficialseditrole", data);
};

export const updateStatusOfficial = async data => {
    const api = await getInstance(false, "Dms");
    return api.post("/jxarh/newofficialsupdatestatus", data);
};

export const getFaDashboard = async () => {
    const api = await getInstance(false, "Dms");
    return api.get("/jxarh/fadashboard");
};
export const getTaskInFaStatus = async (data) => {
    const api = await getInstance(false, "Dms");
    return api.post("/jxarh/qcfadashboarddata", data);
};
// export const getTaskinDU = async (data) => {
//     const api = await getInstance(false, "Dms");
//     return api.post("/jxarh/fadashboard", data);
// };

export const getQcDashboard = async () => {
    const api = await getInstance(false, "Dms");
    return api.get("/jxarh/qcdashboard");
};

export const addVoiceAgent = async data => {
    const api = await getInstance(false, "Dms");
    return api.post("/jxarh/createvoiceagents", data);
};

export const createTaskList = async data => {
    const api = await getInstance(false, "Dms");
    return api.post("/jxarh/createtask", data);
};

export const getVoiceAgentDash = async () => {
    const api = await getInstance(false, "Dms");
    return api.get("/jxarh/voiceagentdashboard");
};

export const getVoiceAgentEditScreen = async data => {
    const api = await getInstance(false, "Dms");
    return api.post("/jxarh/voiceagenteditgovt", data);
};

export const getQcEditScreen = async data => {
    const api = await getInstance(false, "Dms");
    return api.post("/jxarh/supervisoreditgovt", data);
};

export const updateVoiceAgentGov = async data => {
    const api = await getInstance(false, "Dms");
    return api.post("/jxarh/voiceagentupdategovt", data);
};

export const updateVoiceAgentGovInfo = async data => {
    const api = await getInstance(false, "Dms");
    return api.post("/jxarh/voiceagentupdategovtinfo", data);
};

export const validateZipCode = async data => {
    const api = await getInstance(false, "Dms");
    return api.post("/jxarh/validatezipcode", data);
};

export const addGoverningBoardMem = async data => {
    const api = await getInstance(false, "Dms");
    return api.post("/jxarh/addboardmember", data);
};

export const addHeadLawEnforcement = async data => {
    const api = await getInstance(false, "Dms");
    return api.post("/jxarh/addlawenforcement", data);
};

export const addNewDeputy = async data => {
    const api = await getInstance(false, "Dms");
    return api.post("/jxarh/adddeputyappointedofficials", data);
};

export const addNotes = async data => {
    const api = await getInstance(false, "Dms");
    return api.post("/jxarh/addnotes", data);
};

export const addDispostion = async data => {
    const api = await getInstance(false, "Dms");
    return api.post("/jxarh/adddisposition", data);
};

export const vaConfirmGov = async data => {
    const api = await getInstance(false, "Dms");
    return api.post("/jxarh/vaconfirm", data);
};

export const vaUnConfirmGov = async data => {
    const api = await getInstance(false, "Dms");
    return api.post("/jxarh/vaunconfirm", data);
};

export const supervisorUpdateGov = async data => {
    const api = await getInstance(false, "Dms");
    return api.post("/jxarh/supervisorUpdategovt", data);
};

export const qcConfirmGov = async data => {
    const api = await getInstance(false, "Dms");
    return api.post("/jxarh/qcconfirm", data);
};

export const qcSendBackVa = async data => {
    const api = await getInstance(false, "Dms");
    return api.post("/jxarh/qcsendbackva", data);
};

export const qcConfirmMultiSelected = async data => {
    const api = await getInstance(false, "Dms");
    return api.post("/jxarh/qcmultipleconfirm", data);
};

export const faConfirmGov = async data => {
    const api = await getInstance(false, "Dms");
    return api.post("/jxarh/faconfirm", data);
};

export const faSendBackVa = async data => {
    const api = await getInstance(false, "Dms");
    return api.post("/jxarh/fasendbackva", data);
};

export const faSendBackQc = async data => {
    const api = await getInstance(false, "Dms");
    return api.post("/jxarh/fasendbackqc", data);
};

export const faConfirmMultiSelected = async data => {
    const api = await getInstance(false, "Dms");
    return api.post("/jxarh/famultipleconfirm", data);
};

export const editGovrment = async data => {
    const api = await getInstance(false, "Dms");
    return api.post("/jxarh/editgovernment", data);
};

export const emailBounceCheck = async data => {
    const api = await getInstance(false, "Dms");
    return api.post("/jxarh/emailbouncecheckjxarh", data);
};

export const vaCancelGov = async data => {
    const api = await getInstance(false, "Dms");
    return api.post("/jxarh/cancelgovernment", data);
};

export const assignTask = async data => {
    const api = await getInstance(false, "Dms");
    return api.post("/jxarh/assigntask", data);
};

export const dialerAdminDash = async data => {
    const api = await getInstance(false, "Dms");
    return api.post("/jxarh/dashboarddialer", data);
};

export const qcGenerateCsv = async data => {
    const api = await getInstance(false, "Dms");
    return api.post("/jxarh/qcgeneratecsv", data);
};

export const daDownloadGovernment = async (data) => {
    const api = await getInstance(false, "Dms");
    return api.post("/jxarh/downloadgovernment", data);
};

export const rrtTableList = async () => {
    const api = await getInstance(false, "Dms");
    return api.post("/jxarh/uploadbademails", {});
};

export const rrtDownloadPersonList = async data => {
    const api = await getInstance(false, "Dms");
    return api.post("/jxarh/downloadpersonlist", data);
};

export const rrtDownloadAllEmails = async () => {
    const api = await getInstance(false, "Dms");
    return api.post("/jxarh/downloadallemails", {});
};

export const rrtUploadBadEmails = async data => {
    const api = await getInstance(false, "Dms");
    return api.post("/jxarh/uploadbademail", data);
};

export const unlockGoverment = async data => {
    const api = await getInstance(false, "Dms");
    return api.post("/jxarh/unlockgovernments", data);
};

export const unlockGovernmentList = async () => {
    const api = await getInstance(false, "Dms");
    return api.get("/jxarh/unlockgovernmentlist");
};

export const qcWeeklyReport = async () => {
    const api = await getInstance(false, "Dms");
    return api.get("/jxarh/qcweeklyreportcsv");
};

export const weeklyEmailQuality = async () => {
    const api = await getInstance(false, "Dms");
    return api.get("/jxarh/weeklyemailqualitycsv");
};

export const getQcMisList = async () => {
    const api = await getInstance(false, "Dms");
    return api.get("/jxarh/qcmissgovernment");
};

export const logoutDMS = async () => {
    const api = await getInstance(false, "Dms");
    return api.post("/jxarh/logout", {});
};


