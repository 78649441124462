// Vendor Imports
import React from "react";

// Dms Title bar
function DmsTitleBar() {

    return (
        <section className="titlebar">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h1>Power Almanac Database Management System</h1>
                    </div>
                </div>
            </div>
        </section>

    );
}

// Export
export default DmsTitleBar;
