// Vendor Imports
import React from "react";
import { Field, reduxForm } from "redux-form";


// Local Imports
import validate from "./validate";

// Input Field
const renderInputField = ({
    input,
    label,
    type,
    meta: { touched, error, warning }
}) => (
    <div>
        <input
            {...input}
            placeholder={label}
            type={type}
            className="form-control"
        />
        {touched &&
            ((error && <span className="text-danger">{error}</span>) ||
                (warning && <span className="text-danger">{warning}</span>))}
    </div>
);

// Add Gov Board Member Form
const AddGovBoardMemForm = props => {
    const { handleSubmit, emailCheck, toggle } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="mb-2">
                <label>First Name</label>
                <Field
                    name="f_name"
                    type="text"
                    component={renderInputField}
                />
            </div>

            <div className="mb-2">
                <label>Last Name</label>
                <Field
                    name="l_name"
                    type="text"
                    component={renderInputField}
                />
            </div>

            <div className="mb-2">
                <label>Government Title</label>
                <Field
                    name="gov_title"
                    type="text"
                    component={renderInputField}
                />
            </div>

            <div className="mb-2">
                <label>Email</label>
                <Field
                    id="email"
                    name="email"
                    type="email"
                    component={renderInputField}
                />
            </div>

            <div className="mb-2">
                <label>Phone Number</label>
                <Field
                    name="phone_number"
                    type="text"
                    component={renderInputField}
                />
            </div>

            <div className="mb-2">
                <label>Phone Ext</label>
                <Field
                    name="phone_ext"
                    type="text"
                    component={renderInputField}
                />
            </div>

            <div className="mb-2">
                <label>Mailing Street Box</label>
                <Field
                    name="mail_box"
                    type="text"
                    component={renderInputField}
                />
            </div>

            <div className="mb-2">
                <label>Mailing Suite Number</label>
                <Field
                    name="mail_suite"
                    type="text"
                    component={renderInputField}
                />
            </div>

            <div className="mb-2">
                <label>Mailing City</label>
                <Field
                    name="mail_city"
                    type="text"
                    component={renderInputField}
                />
            </div>

            <div className="mb-2">
                <label>Mailing Zip Code</label>
                <Field
                    name="mail_zip"
                    type="text"
                    component={renderInputField}
                />
            </div>

            <div className="mb-2">
                <label>Role Exist</label>
                <input
                    type="text"
                    className="form-control"
                    value="Yes"
                    readOnly
                />
            </div>

            <div className="mb-2">
                <label>Role</label>
                <input
                    type="text"
                    className="form-control"
                    value="Deputy Top Appointed Executive"
                    readOnly
                />
            </div>

            <div className="mb-2">
                <button type="button" className={"btn btn-secondary " + props.emailLoading} onClick={emailCheck}>Email Bounce Check</button>
            </div>

            {props.backendErrors ? (
                <div
                    className="alert alert-danger"
                    role="alert"
                    dangerouslySetInnerHTML={{
                        __html: props.backendErrors
                    }}
                ></div>
            ) : null}

            <div className="mt-4" style={{ textAlign: "right" }}>
                <button type="submit" className={"btn btn-primary backColor " + props.loading}>Add New Deputy Top Appointed Executive</button>&nbsp;
                <button type="button" className="btn btn-secondary" onClick={toggle}>Cancel</button>
            </div>
        </form>
    );
};

// Redux Form
const reduxAddGovBoardMemForm = reduxForm({
    // a unique name for the form
    form: "addGovBoardMem",
    validate
})(AddGovBoardMemForm);

// Export
export default reduxAddGovBoardMemForm;
