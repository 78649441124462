// Vendor Imports
import React, { useEffect } from "react";
import { Switch, useLocation } from "react-router-dom";

// Local Imports
import GuestRoutes from "./guests";
import ClientRoutes from "./clients";
import EmptyRoutes from "./empty";
import DmsRoutes from "./dms";
import AdminRoutes from "./admin";
import MaintenanceRoutes from "./maintenance";
import ReactGA from "react-ga";
//import { hotjar } from 'react-hotjar';
import TagManager from 'react-gtm-module';

//Google analytics code
ReactGA.initialize('UA-26302434-2');

//hotjar.initialize(1915149, 6);

const trackPage = (page) => {
  if (!page.includes('dms') && !page.includes('admin') && page !== ('get-my-free-100-leads')) {
    ReactGA.set({ page });
    ReactGA.pageview(page);
  }

};
const tagManagerArgs = {
  gtmId: 'GTM-M2ZHLW8'
}
TagManager.initialize(tagManagerArgs)



function usePageViews() {
  let location = useLocation();
  useEffect(() => {
    const page = location.pathname;
    trackPage(page);
  }, [location]);
}

// Routes Configuration
function Routes() {
  usePageViews();
  return (

    <Switch>
      {GuestRoutes.props.children}
      {MaintenanceRoutes.props.children}
      {ClientRoutes.props.children}
      {DmsRoutes.props.children}
      {AdminRoutes.props.children}
      {EmptyRoutes.props.children}
    </Switch>

  );
}

// Export
export default Routes;
