// Vendor Imports
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Swal from 'sweetalert2'

// Local Imports
import { rrtTableList, rrtDownloadPersonList, rrtDownloadAllEmails } from "../../../service/dms";
import Upload from './Upload';

// RRT Data list table
class Table extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tableDetails: null,
            loading: '',
            uploadModal: false,
        };
    }

    componentDidMount() {
        this.getDetails();
    }

    getDetails = () => {
        this.setState({
            tableDetails: null
        });
        // Api call to get rrt data list
        rrtTableList().then(res => this.handleResponse(res));
    }

    // Toggle upload bad email modal
    toggle = () => {
        this.setState({ uploadModal: !this.state.uploadModal })
    }

    // Handle api response
    handleResponse = res => {
        if (res.data.code === 200) {
            this.setState({
                tableDetails: res.data.data.badEmails
            });
        }
    };

    // Download Bad Emails
    downloadBadEmails = () => {
        this.setState({ loading: 'loading' });
        rrtDownloadAllEmails().then(res => {
            if (res.data.code === 200) {
                this.setState({ loading: '' });
                window.open(res.data.data.path, '_blank');
            } else {
                this.setState({ loading: '' });
                Swal.fire({
                    text: res.data.message,
                    showCloseButton: true,
                    confirmButtonText: "Ok",
                    icon: 'error',
                    allowOutsideClick: false
                });
                return;
            }
        })
    }

    // Download Persons List
    downloadPersonList = val => {
        rrtDownloadPersonList({ id: val }).then(res => {
            if (res.data.code === 200) {
                window.open(res.data.data.path, '_blank');
            } else {
                Swal.fire({
                    text: res.data.message,
                    showCloseButton: true,
                    confirmButtonText: "Ok",
                    icon: 'error',
                    allowOutsideClick: false
                });
                return;
            }
        })
    }

    // Download list
    downloadList = val => {
        window.open(val, '_blank');
    }

    render() {
        return (
            <div>
                {this.state.tableDetails ?
                    <div className="mb-4 mt-3">
                        <div className="mb-2 text-center">
                            <button type="button" style={{ color: 'white' }} className={"btn btn-warning mr-2 " + this.state.loading} onClick={this.downloadBadEmails}>Download All Emails </button>
                            <button type="button" className="btn btn-danger" onClick={this.toggle}>Upload Bad Emails</button>
                        </div>
                        <section className="dmstable">
                            <h2>Bad Emails Upload List</h2>
                            <div className="table-responsive">
                                <table className="table table-bordered ">
                                    <thead>
                                        <tr>
                                            <th scope="col">ID</th>
                                            <th scope="col">File Name</th>
                                            <th scope="col">Uploaded At</th>
                                            <th scope="col">Total Bad Emails</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Download</th>
                                            <th scope="col">Person List</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.tableDetails.map((listValue, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{listValue.id}</td>
                                                    <td>{listValue.name}</td>
                                                    <td>{listValue.uploaded_at}</td>
                                                    <td>{listValue.insert_count}</td>
                                                    <td>{listValue.status}</td>
                                                    <td><a href="javascript:void(0);"
                                                        onClick={() => this.downloadList(listValue.download_path)} >
                                                        Download
                                       </a>
                                                    </td>
                                                    <td>{listValue.status === "completed" ? <a href="javascript:void(0);"
                                                        onClick={() => this.downloadPersonList(listValue.id)} >
                                                        Download
                                       </a> : <span></span>}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </section>
                    </div> : <div className="text-center">
                        <div
                            className="spinner-border text-primary mt-3"
                            style={{ width: "3rem", height: "3rem", marginLeft: "auto" }}
                            role="status"
                        >
                            <span className="sr-only">Loading...</span>
                        </div></div>
                }

                {/* Upload bad emails modal */}
                <Upload modal={this.state.uploadModal} toggle={this.toggle} refreshPage={this.getDetails} />

            </div>
        );
    }
}

// Export
export default withRouter(connect()(Table));
