import React from "react";
import { useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import MaterialTable from 'material-table';

// Online billing history
function BillingList(props) {
  const BillingData = useSelector((store) => store.client.BillingHistory);

  const renderName = (row) => {
    if (row.payment_method === "card") {
      return (
        <>
          Stripe Card
          <br />
          <div className="text-small">Transaction ID:
           <a rel="noreferrer" target="_blank" href={row.trans_link} id="showInvoiceReceipt_1">{row.transaction_number}
            </a>
          </div>
        </>

      );
    }
    else if (row.payment_method === "ach_debit") {

      return (
        <>
          Stripe ACH
          <br />
          <div className="text-small">Transaction ID:
             <a rel="noreferrer" target="_blank" href={row.trans_link} id="showInvoiceReceipt_1">txn_1FqLNXK3wlN4zUpCd7liMARV
             </a>
          </div>
        </>

      );
    }
  };
  const localization = {
    body: {
      emptyDataSourceMessage: 'No matching records found',
    },
    toolbar: {
      searchTooltip: 'Quick Search',
      searchPlaceholder: 'Quick Search'
    }
  };
  const columns = [
    { title: "ITEM", field: 'item' },
    { title: "PAYMENT METHOD", field: 'payment_method', render: (rowData) => renderName(rowData) },
    { title: "DATE", field: 'transaction_date', defaultSort: "desc" },
    { title: "TOTAL", field: 'payment_amount', render: (rowData) => <NumberFormat value={rowData.payment_amount} displayType={'text'} thousandSeparator={true} prefix="$" /> },
  ];

  return (
    <MaterialTable
      localization={localization}
      className="table display search-table table-responsive"
      id="BillingTable"
      columns={columns}
      data={BillingData}
      title=""
      options={{ sorting: true, pageSize: 5, emptyRowsWhenPaging: false }}
    />
  );

}


export default BillingList;
