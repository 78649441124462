const validate = values => {
    const errors = {};
    if (!values.f_name) {
        errors.f_name = "Please enter your first name";
    } else if (values.f_name.trim() === '') {
        errors.f_name = "Please enter your first name";
    }
    if (!values.l_name) {
        errors.l_name = "Please enter your last name";
    } else if (values.l_name.trim() === '') {
        errors.l_name = "Please enter your last name";
    }
    if (!values.userid) {
        errors.userid = "Please enter your user id";
    } else if (values.userid.trim() === '') {
        errors.userid = "Please enter your user id";
    }
    if (!values.password) {
        errors.password = "Please enter your password";
    } else if (values.password.length < 8) {
        errors.password =
            "Your password must have at least 8 characters, including at least 1 uppercase, 1 lowercase, 1 digit, and 1 special character";
    } else if (!/[A-Z]+/g.test(values.password)) {
        errors.password =
            "Your password must have at least 8 characters, including at least 1 uppercase, 1 lowercase, 1 digit, and 1 special character";
    } else if (!/[a-z]+/g.test(values.password)) {
        errors.password =
            "Your password must have at least 8 characters, including at least 1 uppercase, 1 lowercase, 1 digit, and 1 special character";
    } else if (!/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(values.password)) {
        errors.password =
            "Your password must have at least 8 characters, including at least 1 uppercase, 1 lowercase, 1 digit, and 1 special character";
    } else if (!/[0-9]+/g.test(values.password)) {
        errors.password =
            "Your password must have at least 8 characters, including at least 1 uppercase, 1 lowercase, 1 digit, and 1 special character";
    }
    return errors;
};

export default validate;
