import React from "react";
import MaintenanceLayout from "../../layouts/MaintenanceLayout";
import { Route } from "react-router-dom";
import { connect } from 'react-redux';

// Maintenance Route
const componentWithLayout = (Component, props) => {
    return (
        <MaintenanceLayout>
            <Component {...props} />
        </MaintenanceLayout>
    );
};

class MaintenRoute extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    render() {
        const { menu, component: component, ...rest } = this.props;
        return (
            <Route
                {...rest}
                render={() => componentWithLayout(component, this.props)}
            />
        );
    }
}


export default connect()(MaintenRoute);

