// Vendor Imports
import React from "react";
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

// Local Imports
import { addVoiceAgent } from "../../../../service/dms";
import Form from "./Form";

// Add voice agent modal
class AddModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            backendErrors: null,
            loading: '',
        };
    }

    // Submit Handler
    handleSubmit = values => {
        let params = Object.assign(values, {
            access_level: "VoiceAgent",
            primary_role: "VoiceAgent"
        });
        this.setState({ loading: "loading" });
        addVoiceAgent(params).then(res => this.handleAddResponse(res));
    }

    // Add response handler
    handleAddResponse = res => {
        if (res.data.code === 200) {
            this.setState({
                backendErrors: null,
                loading: '',
            });
            this.props.addAgent(res.data.data.Employee)
            this.props.toggle();
        } else {
            this.setState({ backendErrors: res.data.message, loading: "" });
        }
    }

    // Close modal handler
    closeModal = () => {
        this.setState({
            backendErrors: null,
            loading: '',
        });
        this.props.toggle();
    }

    render() {
        return (
            <div>
                {/* Add Modal */}
                < Modal isOpen={this.props.modal} backdrop="static" centered={true}>
                    <ModalHeader toggle={this.closeModal}>Create new Voice Agent</ModalHeader>
                    <ModalBody>
                        {this.state.backendErrors ? (
                            <div
                                className="alert alert-danger"
                                role="alert"
                                dangerouslySetInnerHTML={{
                                    __html: this.state.backendErrors
                                }}
                            ></div>
                        ) : null}
                        <Form
                            onSubmit={this.handleSubmit}
                            loading={this.state.loading}
                        ></Form>
                    </ModalBody>
                </Modal >
            </div>
        );
    }
}

export default connect()(AddModal);
