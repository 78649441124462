import React from "react";
import GuestLayout from "../../layouts/GuestLayout";
import { Route, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
// Client Route
const componentWithLayout = (Component, props) => {
  return (
    <GuestLayout>
      <Component {...props} />
    </GuestLayout>
  );

};
class PublicRoute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }
  render() {
    const { menu, component: component, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={() => componentWithLayout(component, this.props)}
      />
    );
  }
}

// Export

function mapStateToProps(store, props) {
  return {

  }
}

export default withRouter(connect(mapStateToProps, {})(PublicRoute));

