// Vendor Imports
import React from "react";
import { Helmet } from 'react-helmet';
import Swal from 'sweetalert2'
// Local Imports
import Table from './Table';
import {  getMonthlyReport } from "../../../service/admin";

// Admin Dashboard
class DashboardComponent extends React.Component {
    handleMonthlyReportButton = () =>{
        getMonthlyReport().then(res => {
            if (res.data.code === 200) {
              window.open(res.data.data.path, '_blank');
            } else {
              Swal.fire({
                text: res.data.message,
                showCloseButton: true,
                confirmButtonText: "Ok",
                icon: 'error',
                allowOutsideClick: false
              });
              return;
            }
          })
    }
    render() {
        return (
            <>
                <Helmet>
                    <title> PA Admin - Dashboard </title>
                </Helmet>
                <section>
                    <div className="container admin-container">
                        <div className="row">
                            <div className="col-12">
                                <section className="admin-title admin-table d-flex mt-2">
                                    <h1>Dashboard</h1>
                                    <button type="button" className="btn btn-primary m-auto " onClick={this.handleMonthlyReportButton}>Monthly Report</button>
                                </section>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <Table />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );

    }
}

// Export
export default DashboardComponent;
