// Vendor Imports
import React from "react";
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

// Local Imports
import { addDispostion } from "../../../../../service/dms";
// import Form from "./Form";

// Add disposition modal
class AddDispositionModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            backendErrors: null,
            loading: '',
            disposition: "[Can't Connect] -> [No Answer]"
        };
    }

    // Handler
    handleSubmit = () => {
        let params = {
            jid: this.props.jid,
            gid: this.props.gid,
            note: this.state.disposition,
            confirmed: 0,
            etype: this.props.tableDetails.government_info.verification_type,
            total: this.getUnconfirmMarked(),
            page: 'supervisor'
        };
        this.setState({ loading: "loading" });
        addDispostion(params).then(res => this.handleResponse(res));
    }

    // All members
    getAllMembers = () => {
        let members = [this.props.tableDetails.top_elected_official];
        members.push(this.props.tableDetails.governing_board_member)
        members.push(this.props.tableDetails.top_appointed_executive)
        members.push(this.props.tableDetails.other_government_officials)
        let flatMembers = members.flat();
        let removeUndef = flatMembers.filter(f => f !== undefined)
        return removeUndef;
    }

    // Get unconfirmed
    getUnconfirmMarked = () => {
        let members = this.getAllMembers();
        let data = members.filter(g => g.mark === "marked");
        return data.length;
    }

    // Response Handler
    handleResponse = res => {
        if (res.data.code === 200) {
            this.setState({
                backendErrors: null,
                loading: '',
            });
            this.props.toggle();
            this.props.refreshPage();
        } else {
            this.setState({ backendErrors: res.data.message, loading: "" });
        }
    }

    // Close modal handler
    closeModal = () => {
        this.setState({
            backendErrors: null,
            loading: '',
        });
        this.props.toggle();
    }

    render() {
        return (
            <div>
                {/* Add Modal */}
                < Modal isOpen={this.props.modal} backdrop="static" centered={true}>
                    <ModalHeader toggle={this.closeModal} className="backColor"><h4>Add Disposition</h4></ModalHeader>
                    <ModalBody>
                        {this.state.backendErrors ? (
                            <div
                                className="alert alert-danger"
                                role="alert"
                                dangerouslySetInnerHTML={{
                                    __html: this.state.backendErrors
                                }}
                            ></div>
                        ) : null}
                        <div>
                            <div className="form-group">
                                <label htmlFor="dispositionSelect" style={{ fontSize: "14px" }}>Add Disposition to this Government</label>
                                <select className="form-control" id="dispositionSelect" value={this.state.disposition} onChange={e => this.setState({
                                    disposition: e.target.value
                                })}>
                                    <option value="[Can't Connect] -> [No Answer]">Can't Connect -{'>'} No Answer</option>
                                    <option value="[Can't Connect] -> [Answering Machine]">Can't Connect -{'>'} Answering Machine</option>
                                    <option value="[Can't Connect] -> [Refuse To Participate]">Can't Connect -{'>'} Refuse To Participate</option>
                                    <option value="[In Progress] -> [Left Voicemail]">In Progress -{'>'} Left Voicemail</option>
                                    <option value="[In Progress] -> [Sent eMail]">In Progress -{'>'} Sent eMail</option>
                                    <option value="[In Progress] -> [Sent Fax]">In Progress -{'>'} Sent Fax</option>
                                    <option value="[In Progress] -> [Partially Completed Call]">In Progress -{'>'} Partially Completed Call</option>
                                    <option value="[Almost Done] -> [Holding For Final Review]">Almost Done -{'>'} Holding For Final Review</option>
                                </select>
                            </div>
                            <div className="text-right">
                                <button type="button" className={"btn btn-primary backColor  " + this.state.loading} onClick={this.handleSubmit} >Add Disposition</button>&nbsp;
                                <button type="button" className="btn btn-secondary" onClick={this.closeModal}>Cancel</button>
                            </div>
                        </div>
                    </ModalBody>
                </Modal >
            </div>
        );
    }
}

export default connect()(AddDispositionModal);
