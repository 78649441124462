import React from 'react';
import { connect, useSelector } from 'react-redux';
import { renderCount, renderEmailPercent } from "../../../../utils"
// Fiscal year search stats
function ByFiscalYear(props) {
    let tamMode = useSelector(store => store.client.tamMode);
    let Summary = 'JSON_SUMMARY' in props.searchResult ? JSON.parse(props.searchResult.JSON_SUMMARY) : {};
    let Governments = 'JSON_GOVERNMENTS' in props.searchResult ? JSON.parse(props.searchResult.JSON_GOVERNMENTS) : {};
    let Officials = 'JSON_OFFICIALS' in props.searchResult ? JSON.parse(props.searchResult.JSON_OFFICIALS) : {};
    let fiscal = [{ label: "January", name: "Fiscal_January", govCount: "Fiscal_JanuaryGov", emailCount: "Fiscal_JanuaryEmail", count: 0 },
    { label: "February", name: "Fiscal_February", govCount: "Fiscal_FebruaryGov", emailCount: "Fiscal_FebruaryEmail", count: 0 },
    { label: "March", name: "Fiscal_March", govCount: "Fiscal_MarchGov", emailCount: "Fiscal_MarchEmail", count: 0 },
    { label: "April", name: "Fiscal_April", govCount: "Fiscal_AprilGov", emailCount: "Fiscal_AprilEmail", count: 0 },
    { label: "May", name: "Fiscal_May", govCount: "Fiscal_MayGov", emailCount: "Fiscal_MayEmail", count: 0 },
    { label: "June", name: "Fiscal_June", govCount: "Fiscal_JuneGov", emailCount: "Fiscal_JuneEmail", count: 0 },
    { label: "July", name: "Fiscal_July", govCount: "Fiscal_JulyGov", emailCount: "Fiscal_JulyEmail", count: 0 },
    { label: "August", name: "Fiscal_August", govCount: "Fiscal_AugustGov", emailCount: "Fiscal_AugustEmail", count: 0 },
    { label: "September", name: "Fiscal_September", govCount: "Fiscal_SeptemberGov", emailCount: "Fiscal_SeptemberEmail", count: 0 },
    { label: "October", name: "Fiscal_October", govCount: "Fiscal_OctoberGov", emailCount: "Fiscal_OctoberEmail", count: 0 },
    { label: "November", name: "Fiscal_November", govCount: "Fiscal_NovemberGov", emailCount: "Fiscal_NovemberEmail", count: 0 },
    { label: "December", name: "Fiscal_December", govCount: "Fiscal_DecemberGov", emailCount: "Fiscal_DecemberEmail", count: 0 },
    { label: "Not Avlb", name: "Fiscal_Not_Known", govCount: "Fiscal_Not_KnownGov", emailCount: "Fiscal_Not_KnownEmail", count: 0 }];

    return (
        <>
            <tbody id="searchResultsFiscalYearEnd">
                {
                    fiscal.map((month, index) => {
                        return (
                            <tr key={index}>
                                <td> <span dangerouslySetInnerHTML={{ __html: month.label }} /> </td>
                                <td>
                                    <span className="setToZero">{renderCount(Officials[month.name])} </span>
                                </td>
                                {
                                    tamMode ?
                                        <>
                                            <td>
                                                <span className="setToZero">{renderCount(Governments[month.govCount])}</span>
                                            </td>
                                            <td> {renderEmailPercent(Summary.TamGovs, Governments[month.govCount])}%</td>
                                        </> :
                                        <>
                                            <td>
                                                <span className="setToZero">{renderCount(Officials[month.emailCount])}</span>
                                            </td>
                                            <td> {renderEmailPercent(Officials[month.name], Officials[month.emailCount])}%</td>
                                        </>
                                }
                            </tr>
                        );
                    })
                }
            </tbody>

        </>

    )
}


function mapStateToProps(store, props) {
    return {
        searchResult: store.client.searchResult ? store.client.searchResult : []
    }
}

export default connect(mapStateToProps, {})(ByFiscalYear);
