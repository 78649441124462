import React from 'react';
import { connect, useSelector } from 'react-redux';
import { renderCount, renderEmailPercent } from "../../../../utils";

// population based search stats
function ByPopulation(props) {
    let tamMode = useSelector(store => store.client.tamMode);
    let Summary = 'JSON_SUMMARY' in props.searchResult ? JSON.parse(props.searchResult.JSON_SUMMARY) : {};
    let Governments = 'JSON_GOVERNMENTS' in props.searchResult ? JSON.parse(props.searchResult.JSON_GOVERNMENTS) : {};
    let Officials = 'JSON_OFFICIALS' in props.searchResult ? JSON.parse(props.searchResult.JSON_OFFICIALS) : {};
    let populations = [
        { label: "0 - 999", name: "P1", emailCount: "P1Email", govCount: "P1Gov" },
        { label: "1,000 - 2,499", name: "P2", emailCount: "P2Email", govCount: "P2Gov" },
        { label: "2,500 - 4,999", name: "P3", emailCount: "P3Email", govCount: "P3Gov" },
        { label: "5,000 - 9,999", name: "P4", emailCount: "P4Email", govCount: "P4Gov" },
        { label: "10,000 - 24,999", name: "P5", emailCount: "P5Email", govCount: "P5Gov" },
        { label: "25,000 - 49,999", name: "P6", emailCount: "P6Email", govCount: "P6Gov" },
        { label: "50,000 - 99,999", name: "P7", emailCount: "P7Email", govCount: "P7Gov" },
        { label: "100,000 - 249,999", name: "P8", emailCount: "P8Email", govCount: "P8Gov" },
        { label: "250,000 - 499,999", name: "P9", emailCount: "P9Email", govCount: "P9Gov" },
        { label: "500,000 - 999,999", name: "P10", emailCount: "P10Email", govCount: "P10Gov" },
        { label: "1,000,000 - 20,000,000", name: "P11", emailCount: "P11Email", govCount: "P11Gov" }
    ];

    return (
        <>
            <tbody id="searchResultsGovType">
                {
                    populations.map((row, index) => {
                        if (index === 0 && tamMode) {
                            return <></>
                        }
                        return (
                            <tr key={index}>
                                <td> <span dangerouslySetInnerHTML={{ __html: row.label }} /> </td>
                                <td>
                                    <span className="setToZero">{renderCount(Officials[row.name])} </span>
                                </td>
                                {
                                    tamMode ?
                                        <>
                                            <td>
                                                <span className="setToZero">{renderCount(Governments[row.govCount])}</span>
                                            </td>
                                            <td> {renderEmailPercent(Summary.TamGovs, Governments[row.govCount])}%</td>
                                        </> :
                                        <>
                                            <td>
                                                <span className="setToZero">{renderCount(Officials[row.emailCount])}</span>
                                            </td>
                                            <td> {renderEmailPercent(Officials[row.name], Officials[row.emailCount])}%</td>
                                        </>
                                }
                            </tr>
                        );
                    })
                }
            </tbody>

        </>

    )
}


function mapStateToProps(store, props) {
    return {
        searchResult: store.client.searchResult ? store.client.searchResult : []
    }
}

export default connect(mapStateToProps, {})(ByPopulation);
