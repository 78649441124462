// Vendor Imports
import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { Link } from "react-router-dom";

// Local Imports

// Input Field
const renderInputField = ({
  input,
  label,
  type,
  meta: { touched, error, warning }
}) => (
  <div>
    <input
      {...input}
      placeholder={label}
      type={type}
      className="form-control"
      disabled={true}
    />
    {touched &&
      ((error && <span className="text-danger">{error}</span>) ||
        (warning && <span className="text-danger">{warning}</span>))}
  </div>
);

// Renewal Form
class RenewSubscriptionForm extends Component {
  // component Life Cycle
  componentDidMount() {
    const initialValues = {
      firstName: this.props.userDetails.User_FirstName,
      lastName: this.props.userDetails.User_LastName,
      email: this.props.userDetails.User_Email,
      company: this.props.userDetails.User_CompanyName
    };
    this.props.initialize(initialValues);
  }

  render() {
    const { handleSubmit } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <Field
                name="firstName"
                id="firstName"
                type="text"
                label="First Name"
                component={renderInputField}
              />
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <Field
                name="lastName"
                id="lastName"
                type="text"
                label="Last Name"
                component={renderInputField}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="form-group">
              <Field
                name="email"
                id="email"
                type="email"
                label="Email Address"
                component={renderInputField}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="form-group">
              <Field
                name="company"
                id="company"
                type="text"
                label="Company"
                component={renderInputField}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="form-check form-group agree-form">
              <label
                htmlFor="AgreeToTOS"
                className="form-check-label agree-label"
              >
                I agree to the{" "}
                <a
                  href="https://poweralmanac.com/terms-of-service#et-main-area"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms of Service
                </a>
                <input
                  type="checkbox"
                  className="form-check-input agree-check"
                  name="AgreeToTOS"
                  id="AgreeToTOS"
                  onClick={this.props.termsChange}
                />
                <span className="checkmark"></span>
              </label>
              {this.props.errorMessage ? (
                <span className="text-danger">
                  You must agree to the TERMS OF SERVICE
                </span>
              ) : (
                <span></span>
              )}
            </div>
          </div>
        </div>

        <div className="row align-items-center">
          <div className="col-sm-7">
            <button
              type="submit"
              className={
                "btn btn-pa text-uppercase w-100  " + this.props.loading
              }
            >
              Submit
            </button>
          </div>
          <div className="col-sm-5">
            <Link to="/login" className="btn btn-pa text-uppercase w-100">
              Back
            </Link>
          </div>
        </div>
      </form>
    );
  }
}

// Redux Form
const reduxRenewSubscriptionForm = reduxForm({
  // a unique name for the form
  form: "renewSubscription"
})(RenewSubscriptionForm);

// Export
export default reduxRenewSubscriptionForm;
