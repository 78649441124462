// Vendor Imports
import React from "react";

import {
    Navbar,
    NavbarBrand,
} from 'reactstrap';

// Header Component
function Header(props) {
    return (
        <div>
            <header id="header" className="site-header shadow-sm">
                <Navbar color="light" light expand="md">
                    <NavbarBrand href="/">
                        <img
                            src="/images/logo/poweralmanac.svg"
                            alt="Power Almanac"
                            className="logo"
                            width="132"
                            height="63"
                        />
                    </NavbarBrand>
                </Navbar>
            </header>
        </div>
    );
}


export default Header;
