import React, {useEffect} from "react";
import {  useDispatch, useSelector } from 'react-redux';
import PhoneVerification from './PhoneVerification';
import Cookies from 'js-cookie';
import { renderCount } from "../../../../utils";
import {setSurveyformStep } from '../../../../reducers/client/actions';
// Phone number verification step component
function SearchConfirmation(props) {
  const dispatch = useDispatch();
  let searchResult = useSelector((store) => store.client.searchResult);
  let Summary = 'JSON_SUMMARY' in searchResult ? JSON.parse(searchResult.JSON_SUMMARY) : {};
  let searchCount = Summary.Num_Matched_Officials;
  const PhoneVerificiationForm = useSelector((store) => store.client.PhoneVerificiationForm);
  let forcePopulationUpdate = Cookies.get('forcePopulationUpdate');

  let FormStep = useSelector((store) => store.client.NewUserServeyFormStep);
  const newSurveyResponse = useSelector((store) => store.client.info.survey_response ? JSON.parse(store.client.info.survey_response) : "");
  useEffect(() => {
    if (newSurveyResponse !== "") {
      dispatch(setSurveyformStep({activeStep: 1, skipStep:FormStep.skipStep}));
    }
  },[]);
 
  return (
   <>
    {/* { searchCount<=100 & PhoneVerificiationForm.action !== 'verificationSuccess' && forcePopulationUpdate!=="true" ?
        <div className="row">
          <div className="col  text-center">
            <p> <strong>There are   <span className='text-danger'>{renderCount(searchCount)}</span>{ searchCount>1? " contact(s)" : "contact"} that match your choice.</strong></p>
            <p> <strong>Validate your mobile number to download them for FREE from your 100 Free credits. </strong></p>
            <p> <strong> The Balance (unused) credits will continue to be available in your Trial Account.</strong></p>
            <p>(You will receive a 6 digit verification code on your mobile.)</p>
          </div>
        </div>
      :
      <></>
    } */}
     { searchCount<=300 && PhoneVerificiationForm.action !== 'verificationSuccess' && forcePopulationUpdate!=="true" ?
      <div className="row">
        <div className="col-12 text-center">
          <p> <strong>There are <span className='text-danger'>{renderCount(searchCount)}</span> contact(s) that match your selections.</strong></p>
          {/*<p> <strong>Bonus! We'll add 100 more download credits to your account so you can explore some more.</strong></p>*/}
          {/*<p> <strong>Validate your mobile number to download them for FREE.</strong></p>*/}
          <p><strong>Validate your mobile number to claim your 100 FREE credits.</strong></p>
          <p>
          (You will receive a 6 digit verification code on your mobile.)
          </p>
        </div>
      </div>
        :
        <></>
      }

    {   FormStep.skipStep!==true && forcePopulationUpdate==="true"  && PhoneVerificiationForm.action !== 'verificationSuccess'?
      <div className="row">
        <div className="col-12 text-center">
          <p> <strong>To bring the number of matching contacts under your free limit, we've selected governments with populations over 25,000. </strong></p>
          <p> <strong>There are <span className='text-danger'>{renderCount(searchCount)}</span> contact(s) that match your selections.</strong></p>
          {/*<p> <strong>Bonus! We'll add 100 more download credits to your account so you can explore some more.</strong></p>*/}
          {/*<p><strong>Validate your mobile number to download them for FREE.</strong></p>*/}
          <p><strong>Validate your mobile number to claim your 100 FREE credits.</strong></p>
          <p>(You will receive a 6 digit verification code on your mobile.)</p>
        </div>
      </div>
        :
        <></>
    }

    {
      FormStep.skipStep===true?
      <div className="row">
        <div className="col-12 text-center">
        
          <p> <strong>Validate your mobile number.</strong></p>
          <p>
          (You will receive a 6 digit verification code on your mobile)
          </p>
        </div>
      </div>
      :
      <></>
    }
        <div className="row">
          <div className="col  text-center">
            <PhoneVerification toggle={props.toggle}/>
          </div>
        </div>
    {/* {  searchCount<=300 ?
        <div className="row">
          <div className="col  text-center">
            <PhoneVerification toggle={props.toggle}/>
          </div>
        </div>
        :
        <div className="row">
                <div className="col-12  text-center">
                  <Button   color="inherit" className={" ml-2 btn btn-secondary btn-pa  " }   disabled={activeStep === 0}  onClick={()=> dispatch(setSurveyformStep({activeStep: 0, skipStep:false}))} sx={{ mr: 1 }} >
                          Back
                    </Button>
                    
                </div>
            </div>
    } */}
   </>
  )
}


export default SearchConfirmation;
