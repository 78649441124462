// Vendor Imports
import React from "react";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Helmet } from 'react-helmet';

// Local Imports
import { getSubscriptionList } from "../../../service/admin";
import EditForm from './EditForm';

class SubscriptionHistoryComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tableDetails: null,
            editModal: false,
            editValues: {}
        };
    }

    componentDidMount() {
        this.subscriptionsList();
    }

    subscriptionsList = () => {
        // Api call to get subscription history
        getSubscriptionList().then(res => this.handleResponse(res));
    }

    // Handle response
    handleResponse = res => {
        if (res.data.code === 200) {
            this.setState({
                tableDetails: res.data.data.items,
            });
        }
    };


    // Open edit modal
    openEditModal = val => {
        this.setState({ editModal: true, editValues: val });
    }

    // Edit modal toggle
    editToggle = () => {
        this.setState({ editModal: false, editValues: {} });
    }

    render() {
        return (
            <>
                <Helmet>
                    <title> PA Admin - Subscription </title>
                </Helmet>
                <section>
                    <div className="container admin-container">
                        <div className="row">
                            <div className="col-12">
                                <section className="admin-title">
                                    <h1>Subscription</h1>
                                </section>
                            </div>
                        </div>
                        {this.state.tableDetails ? <div>


                            <div className="table-responsive">
                                <table className="table table-bordered ">
                                    <thead>
                                        <tr>
                                            <th scope="col">S.No</th>
                                            <th scope="col">Subscription Name</th>
                                            <th scope="col">Credits on Registration</th>
                                            <th scope="col">Credits on Renewal</th>
                                            <th scope="col">Subscription Annual Fees</th>
                                            <th scope="col">Subscription Cost Per DL</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.tableDetails.length === 0 ?
                                            <tr>
                                                <td colspan="6" align="center">No Subscription Found</td>
                                            </tr> :
                                            this.state.tableDetails.map((listValue, index) => {
                                                if (listValue.Sub_Level === "11" || listValue.Sub_Level === "9") {
                                                    listValue.Sub_AnnualFees = "NA";
                                                }
                                                if (listValue.Sub_Level === "10") {
                                                    listValue.Sub_CostPerDL = "NA";
                                                    listValue.Sub_NumReserves = "Unlimited";
                                                }
                                                if(listValue.monthly_renewal===null){
                                                    listValue.monthly_renewal='NA';
                                                }
                                                return (
                                                    <tr>
                                                        <td>{index + 1}</td>
                                                        <td>{listValue.Sub_Name}</td>
                                                        <td>{listValue.Sub_NumReserves}</td>
                                                        <td>{listValue.monthly_renewal}</td>
                                                        <td>{listValue.Sub_AnnualFees}</td>
                                                        <td>{listValue.Sub_CostPerDL}</td>
                                                        <td><a href="#" className="edit-btn" onClick={() => this.openEditModal(listValue)}>Edit</a></td>
                                                    </tr>
                                                );
                                            })
                                        }

                                    </tbody>
                                </table>
                            </div>
                        </div>
                            : <div className="text-center">
                                <div
                                    className="spinner-border text-primary mt-3"
                                    style={{ width: "3rem", height: "3rem", marginLeft: "auto" }}
                                    role="status"
                                >

                                    <span className="sr-only">Loading...</span>
                                </div></div>
                        }

                        {/* Edit subscription history */}
                        <Modal isOpen={this.state.editModal} backdrop="static" centered={true} >
                            <ModalHeader toggle={this.editToggle} className="backColor">Edit Subscription History</ModalHeader>
                            <ModalBody>
                                <EditForm toggle={this.editToggle} refreshData={this.subscriptionsList} editVal={this.state.editValues} />
                            </ModalBody>
                        </Modal >
                    </div>
                </section>
            </>
        );
    }

}

// Export
export default SubscriptionHistoryComponent;
