// Vendor Imports
import React from "react";
import { Field, reduxForm } from "redux-form";
import { Link } from "react-router-dom";

// Local Imports
import validate from "./validate";

// Input Field
const renderInputField = ({
  input,
  label,
  type,
  meta: { touched, error, warning }
}) => (
  <div>
    <input
      {...input}
      placeholder={label}
      type={type}
      className="form-control"
    />
    {touched &&
      ((error && <span className="text-danger">{error}</span>) ||
        (warning && <span className="text-danger">{warning}</span>))}
  </div>
);

// ResetPassword Form
const ResetPasswordForm = props => {
  const { handleSubmit } = props;
  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group loginInput">
        <Field
          name="newpass"
          type="password"
          label="New Password"
          component={renderInputField}
          minlength="8"
        />
      </div>

      <div className="form-group loginInput">
        <Field
          name="confirmpass"
          type="password"
          label="Confirm Password"
          component={renderInputField}
          minlength="8"
        />
      </div>

      <div className="row align-items-center login-link">
        <div className="col-sm-5 forgot-col">
          <button
            type="submit"
            className={"btn btn-pa text-uppercase w-100  " + props.loading}
          >
            Submit
          </button>
        </div>
        <div className="col-sm-5 return-lg">
          <Link to="/login" className="text-uppercase">
            Return To Login
          </Link>
        </div>
      </div>
    </form>
  );
};

// Redux Form
const reduxResetPasswordForm = reduxForm({
  // a unique name for the form
  form: "resetPassword",
  validate
})(ResetPasswordForm);

// Export
export default reduxResetPasswordForm;
