import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { creditRequest } from '../../../service/client';


// functional credit request modal to the primay user
function CreditRequest(props) {
  const {
    modal,
    toggle
  } = props;
  const [loading, setLoading] = useState('');
  const [success, setSuccess] = useState(false);

  const [subject, setSubject] = useState("More credits for my Power Almanac account");
  const [message, setMessage] = useState("");

  // function to handle credit request api 
  const handleCreditRequest = () => {
    let params = {
      creditRequestSubject: subject,
      creditRequestMessageBody: message !== "" ? message : "Hi " + props.myaccount.parent.User_FirstName + "  " + props.myaccount.parent.User_LastName + ", I need " + props.searchResult.credit_required + " more credits to download the records I need from Power Almanac.  Can you please assign more credits to my sub-user account."
    }
    setLoading('loading');
    setSuccess(false);
    creditRequest(params).then(res =>
      handleResponse(res),
    )
  }
  const handleResponse = (res) => {
    if (res.data.code === 200) {
      setSuccess(true);
      setLoading('');
      setTimeout(function () {
        props.toggle()
      }, 2000);
    }

  }
  return (
    <div>

      <Modal
        size="lg"
        isOpen={modal}
        toggle={toggle}
        backdrop={true}
        centered={true}
        className="modal-dialog">
        <ModalHeader toggle={toggle}>Credit Request</ModalHeader>
        <ModalBody>
          <table className="table table-bordered">
            <tbody>
              <tr>
                <td>
                  From:
                </td>
                <td>
                  <input readonly="readonly" type="text" className="form-control" value={props.myaccount.User_Email} id="from" name="from" />
                </td>

              </tr>
              <tr>
                <td> To: </td>
                <td>
                  <input readonly="readonly" type="text" className="form-control" value={props.myaccount.parent.User_Email} id="to" name="to" />
                </td>
              </tr>
              <tr>
                <td>Subject:</td>
                <td>
                  <input onChange={(e) => setSubject(e.target.value)} type="text" className="form-control" value={subject} id="creditRequestSubject" name="creditRequestSubject" />
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <textarea onChange={(e) => setMessage(e.target.value)} rows="5" className="form-control" id="creditRequestMessageBody" name="creditRequestMessageBody" defaultValue={"Hi " + props.myaccount.parent.User_FirstName + "  " + props.myaccount.parent.User_LastName + ", I need " + props.searchResult.credit_required + " more credits to download the records I need from Power Almanac.  Can you please assign more credits to my sub-user account."}></textarea>
                </td>
              </tr>

            </tbody>
          </table>
        </ModalBody>
        <ModalFooter>
          {
            success ?
              <div className="row" >
                <div className="col-lg-12 text-center">
                  <div className="alert alert-success" role="alert">
                    <strong>Success!</strong> <strong>Your CREDIT REQUEST has been sent!</strong>  Please check your <strong>MyAccount</strong> tab periodically to see if the Account Master user has allocated more credits to your account.
            </div>
                </div>
              </div>
              :
              ""
          }
          <button type="submit" className={loading + " btn btn-pa btn-success"} onClick={handleCreditRequest} id="sendRequestSubmit">SEND YOUR REQUEST NOW</button>

          <button onClick={toggle} className="btn btn-pa btn-success btn-secondary">Cancel</button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

function mapStateToProps(store, props) {
  return {
    searchResult: store.client.searchResult ? store.client.searchResult : [],
    searchLoading: store.client.searchLoading,
    info: store.client.info ? store.client.info : [],
    myaccount: store.client.myaccount ? store.client.myaccount : [],
    plan: store.client.plan ? store.client.plan : [],
    bestPricing: store.client.bestPricing ? store.client.bestPricing : [],
    currentSearch: store.client.currentSearch ? store.client.currentSearch : [],

  }
}

export default connect(mapStateToProps, {})(CreditRequest);