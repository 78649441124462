// Vendor Imports
import React from "react";
import { Field, reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import Button from '@material-ui/core/Button';
import ReCAPTCHA from "react-google-recaptcha";
// Local Imports
import validate from "./validate";

// Input Field
const renderInputField = ({
  input,
  label,
  type,
  className,
  meta: { touched, error, warning }
}) => (
  <div>
    <input {...input} placeholder={label} type={type} className={className} />
    {touched &&
      ((error && <span className="text-danger">{error}</span>) ||
        (warning && <span className="text-danger">{warning}</span>))}
  </div>
);
// Register Form
const RegisterForm = props => {
  const { handleSubmit } = props;
  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-sm-6 col-sm-12">
            <div className="form-group loginInput">
              <Field
                className="form-control"
                name="firstName"
                type="=text"
                label="First Name"
                component={renderInputField}
                maxlength="255"
              />
            </div>
          </div>
          <div className="col-sm-6 col-sm-12">
            <div className="form-group loginInput">
              <Field
                className="form-control"
                type="text"
                name="lastName"
                label="Last Name"
                component={renderInputField}
                maxlength="255"
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="form-group loginInput">
              <Field
                className="form-control"
                type="text"
                name="email"
                label="Work Email Address"
                component={renderInputField}
                maxlength="255"
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="form-group loginInput">
              <Field
                className="form-control"
                type="text"
                name="companyName"
                label="Company"
                component={renderInputField}
                maxlength="255"
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="form-group loginInput">
              <Field
                className="form-control"
                type="password"
                name="passWord"
                label="Create a Password"
                component={renderInputField}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="form-group loginInput">
              <Field
                className="form-control"
                type="password"
                name="passWord2"
                label="Confirm Password"
                component={renderInputField}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="form-check form-group agree-form">
              <label className="form-check-label agree-label" >

                <input
                  type="checkbox"
                  className="form-check-input agree-check"
                  name="AgreeToTOS"
                  id="AgreeToTOS"
                  onClick={props.termsChange}
                />
                <span className="checkmark"></span>

              </label>
              <label className="pl-4"  >
                I agree to the{" "}
                <a href="#"
                  onClick={() => props.openTos('Terms of Service')}
                  style={{ color: "#076599", cursor: "pointer" }}
                >
                  Terms of Service
                </a>

              </label>
              {props.cvalues.error ? (
                <span className="text-danger">
                  You must agree to the terms of service
                </span>
              ) : (
                <span></span>
              )}
            </div>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col">
            <div className="g-recaptcha">
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_SITE_KEY}
                onChange={props.onChange}
              />
            </div>
            {props.cvalues.captchaError ? (
              <span className="text-danger">
                Please confirm that you are not a robot
              </span>
            ) : (
              <span></span>
            )}
          </div>
        </div>
        <div className="row justify-content-center">

          <div className="col-sm-4">
            <Button
              variant="contained"
              type="submit"
              className={"btn btn-register w-100 button mb-10 " + props.cvalues.loading}
              id="registerSubmitBtn"
            >
             Sign Up
            </Button>
            <br/>
            <br/>
            <br/>
            <br/>
          </div>
        </div>

      </form>
    </>
  );
};

// Redux Form
const reduxRegisterForm = reduxForm({
  // a unique name for the form
  form: "registerForm",
  validate
})(RegisterForm);

// Export
export default reduxRegisterForm;
