// Vendor Imports
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Helmet } from 'react-helmet';

// Local Imports

// Maintenance Component
class MaintenanceComponent extends React.Component {
    render() {
        return (
            <>
                <Helmet>
                    <title>Power Almanac</title>
                </Helmet>
                <section className="container login-section login-block">
                    <div className="login-bg" style={{ textAlign: 'center', padding: '50px 0px' }}>
                        <div>
                            <h3 style={{ lineHeight: '3rem' }}>We’re down for scheduled maintenance right now.</h3>
                        </div>
                        <div>
                            <h3 style={{ lineHeight: '3rem' }}>We expect to have the site up and running on Thursday, April 25th</h3>
                        </div>
                        <div>
                            <h3 style={{ lineHeight: '3rem' }}>by 9:00am (EDT)  |  6:00am (PDT)</h3>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

// Export
export default withRouter(connect()(MaintenanceComponent));
