// Vendor Imports
import React from "react";
import Header from "./Header";

// Admin Layout
function ClientLayout(props) {

    return (
        <div>
            <Header />
            <main>{props.children}</main>
            {/* <Footer /> */}
        </div>
    );
};

// Export
export default ClientLayout;
