// Vendor Imports
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Helmet } from 'react-helmet';
import Swal from 'sweetalert2'

// Local Imports
import VoiceAgentEditTable from "./Table";
import AddDisposition from "./AddDisposition";
import AddNotes from "./AddNotes";
import AddVaNotes from "./AddVaNotes";
import { getQcEditScreen, qcConfirmGov, qcGenerateCsv } from "../../../../service/dms";
import { renderCount } from '../../../../utils';

// Edit QC Gov
class EditQc extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dmsUser: null,
            tableDetails: null,
            addDisposModal: false,
            addNotesModal: false,
            addVaNotesModal: false,
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.dmsUser !== null) {
            return {
                dmsUser: props.dmsUser
            };
        }
        return null;
    }

    componentDidMount() {
        this.getVoiceAgentEdit();
    }

    // Get voice agent gov details
    getVoiceAgentEdit = () => {
        this.setState({
            tableDetails: null
        });
        let params = {
            gid: this.props.computedMatch.params.gid,
            jid: this.props.computedMatch.params.jid,
            mode: this.props.computedMatch.params.mode,
            ge_ID: this.props.computedMatch.params.ge_ID
        }
        getQcEditScreen(params).then(res => this.handleResponse(res));
    }

    // Response Handler
    handleResponse = res => {
        if (res.data.code === 200) {
            this.setState({
                tableDetails: res.data.data
            });
        }
    };

    // Disposition toggle handler
    disposToggle = () => {
        this.setState({ addDisposModal: !this.state.addDisposModal })
    }

    // Notes toggle handler
    notesToggle = () => {
        this.setState({ addNotesModal: !this.state.addNotesModal })
    }

    // Va notes toggle Handler
    vaNotesToggle = () => {
        this.setState({ addVaNotesModal: !this.state.addVaNotesModal })
    }

    // Confirm gov handler
    confirmGovrment = () => {
        let params = {
            gid: this.props.computedMatch.params.gid,
            jid: this.props.computedMatch.params.jid,
        }
        qcConfirmGov(params).then(res => this.handleQcConfirm(res));
    }

    // Qc confirm handler
    handleQcConfirm = res => {
        if (res.data.code === 200) {
            this.props.history.push("/dms/supervisor/qc-dashboard");
        }
    }

    // Get gov details handler
    getGovDetails = () => {
        return this.state.tableDetails.government_info.Government_Place_Name + " " + this.state.tableDetails.government_info.Government_Type_Name + ", " + this.state.tableDetails.government_info.City + ", " + this.state.tableDetails.government_info.County_Name + ", " + this.state.tableDetails.government_info.state;
    }

    // Download csv handler
    downloadCsv = () => {
        let params = {
            gid: this.props.computedMatch.params.gid,
            jid: this.props.computedMatch.params.jid,
        }
        qcGenerateCsv(params).then(res => {
            if (res.data.code === 200) {
                window.open(res.data.data.path, '_blank');
            } else {
                Swal.fire({
                    text: res.data.message,
                    showCloseButton: true,
                    confirmButtonText: "Ok",
                    icon: 'error',
                    allowOutsideClick: false
                });
                return;
            }
        })
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>Supervisor - Edit Government</title>
                </Helmet>
                <section className="titlebar">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h1>Quality Checker Edit Government</h1>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div>
                        <div className="row change-row">
                            <div className="col-12">
                                <div className="white-bg">
                                    {this.state.tableDetails ? <div>
                                        <div className="container">
                                            <section className="dmstable edit-mode">

                                                <div className="table-responsive">
                                                    <table className="table table-bordered ">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col" colSpan="2" style={{ textAlign: "center" }}>EDIT MODE - {this.props.computedMatch.params.mode.toUpperCase()} {this.state.tableDetails.government_info.verification_type === "" ? "" : "-"} {this.state.tableDetails.government_info.verification_type}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td >Government Name / Location</td>
                                                                <td>{this.state.tableDetails.government_info.Government_Place_Name} {this.state.tableDetails.government_info.Government_Type_Name}, {this.state.tableDetails.government_info.state} {this.state.tableDetails.government_info.Government_Type === "county" ? "(" + this.state.tableDetails.government_info.County_Name + " County)" : ""}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Government Type</td>
                                                                <td>
                                                                    {this.state.tableDetails.government_info.Government_Type.toUpperCase()}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Population</td>
                                                                <td>{renderCount(this.state.tableDetails.government_info.Population)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Election Month</td>
                                                                <td>{this.state.tableDetails.government_info.Electron_Month.toUpperCase()}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Government ID</td>
                                                                <td>{this.state.tableDetails.government_info.Government_ID}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </section>

                                            <section className="edit-modenav">
                                                <div className="container">
                                                    <div className="d-flex justify-content-center">
                                                        <p>Government ID Status: <button type="submit" className="viewbtn">Quality Check</button></p>
                                                        <a className="mt-1" href={"http://www.google.com/search?q=" + this.getGovDetails()} target="_blank"><img src="/images/google.png" alt="google search" width="57" height="20" hspace="5" vspace="0" align="absmiddle" title="google search" /></a>
                                                    </div>
                                                    <div className="d-flex justify-content-center">
                                                        <ul>
                                                            <li><a href="#" onClick={this.confirmGovrment} style={{ fontWeight: "500" }}><i className="fa fa-plus mr-1"></i>Confirm Government</a></li>
                                                            <li><a href="#" onClick={this.vaNotesToggle} style={{ fontWeight: "500" }}><i className="fa fa-minus mr-1"></i> Send Back To VA </a></li>
                                                            <li><a href="#" style={{ fontWeight: "500" }} onClick={this.downloadCsv}><i className="fa fa-download mr-1"></i>Download CSV</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </section >
                                        </div>
                                        <VoiceAgentEditTable tableDetails={this.state.tableDetails} gid={this.props.computedMatch.params.gid}
                                            jid={this.props.computedMatch.params.jid} voiceAgentEdit={this.getVoiceAgentEdit} />

                                        <div className="row mt-3" style={{ textAlign: "center" }}>
                                            <div className="col-md-12">
                                                {/* <button type="button" className="btn add-btn" onClick={this.disposToggle}>Add Disposition</button>&nbsp; */}
                                                <button type="button" className="btn add-btn" onClick={this.notesToggle}>Add Notes</button>
                                            </div>

                                        </div>
                                    </div>
                                        : <div className="text-center">
                                            <div
                                                className="spinner-border text-primary mt-3"
                                                style={{ width: "3rem", height: "3rem", marginLeft: "auto" }}
                                                role="status"
                                            >
                                                <span className="sr-only">Loading...</span>
                                            </div></div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Add disposition modal */}
                <AddDisposition modal={this.state.addDisposModal} toggle={this.disposToggle} refreshPage={this.getVoiceAgentEdit} gid={this.props.computedMatch.params.gid}
                    jid={this.props.computedMatch.params.jid} tableDetails={this.state.tableDetails} />

                {/* Add notes modal */}
                <AddNotes modal={this.state.addNotesModal} toggle={this.notesToggle} refreshPage={this.getVoiceAgentEdit} gid={this.props.computedMatch.params.gid}
                    jid={this.props.computedMatch.params.jid} />

                {/* Add va notes modal */}
                <AddVaNotes modal={this.state.addVaNotesModal} toggle={this.vaNotesToggle} refreshPage={this.getVoiceAgentEdit} gid={this.props.computedMatch.params.gid}
                    jid={this.props.computedMatch.params.jid} />

            </div>
        );
    }
}

const mapStateToProps = (store, props) => {
    return {
        dmsUser: store.dms.dmsUser
    };
};

// Export
export default withRouter(connect(mapStateToProps)(EditQc));
