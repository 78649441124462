// Vendor Imports
import React from "react";
import { Helmet } from "react-helmet";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// Local Imports
import BillingHistory from "./BillingHistory";
import SubscriptionHistory from "./SubscriptionHistory";
import CreditHistory from "./CreditHistory";
import TransactionInformation from "./TransactionInformation";
import AccountActivationStatus from './AccountActivationStatus'
// import UserInformation from "./UserInformation";
import {
  getUserDetails,
  getEmailList,
  updateRegisteredAccount,
  sendResetPassword,
  resendActivationEmail,
  loginAsUser,
} from "../../../service/admin";
import { setLoginUser, SignOff } from "../../../reducers/client/actions";
import {  emailActivation,saveMyFirstSearch,savePhoneNumber,smsVerification } from '../../../service/client';

// Edit user component
class UserEditComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: "User Information",
      tableDetails: null,
      tempDetails: null,
      emailList: null,
      emailModal: false,
      flag: false,
      loading: "",
      activateLoading: false,
    };
  }

  // Tab change
  tabChange = (val) => {
    if (val === "User Information") {
      this.getUserDet();
    }
    if (
      this.objectEqualityCheck(this.state.tableDetails, this.state.tempDetails)
    ) {
      Swal.fire({
        title: "Confirmation",
        text: "Your changes will be lost if you don’t save it.",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonText: "Don't Save",
        cancelButtonText: "Back",
        icon: "question",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.setState({
            currentTab: val,
            tableDetails: null,
            tempDetails: null,
          });
        } else {
          return;
        }
      });
    } else {
      this.setState({ currentTab: val });
    }
  };

  // Objects equality check
  objectEqualityCheck = (obj1, obj2) => {
    if (obj1 === null && obj2 === null) {
      return false;
    }
    let keys = Object.keys(obj1);
    let values = keys.map((key) => {
      if (key === "Record_Flag") {
        let dflag = obj2[key] === "1";
        return dflag !== this.state.flag;
      } else {
        return obj1[key] !== obj2[key];
      }
    });
    return values.includes(true);
  };

  componentDidMount() {
    this.getUserDet();
  }

  // Get User details
  getUserDet = () => {
    this.setState({ tableDetails: null });
    getUserDetails(this.props.computedMatch.params.id).then((res) =>
      this.handleResponse(res)
    );
  };

  // Handle response
  handleResponse = (res) => {
    if (res.data.code === 200) {
      this.setState({
        tableDetails: res.data.data,
        tempDetails: res.data.data,
        flag: res.data.data.Record_Flag === "1",
      });
    }
  };

  // Api call to get email list
  handleEmailList = () => {
    this.setState({ emailModal: true });
    getEmailList().then((res) => {
      if (res.data.code === 200) {
        this.setState({
          emailList: res.data.data.values,
        });
      } else {
        Swal.fire({
          text: res.data.message,
          showCloseButton: true,
          confirmButtonText: "Ok",
          icon: "error",
          allowOutsideClick: false,
        });
        return;
      }
    });
  };

  // Handle resend activation email
  handleResendActivationEmail = () => {
    resendActivationEmail({ eMail: this.state.tempDetails.User_Email }).then(
      (res) => {
        if (res.data.code === 200) {
          Swal.fire({
            title: "Activation Email Send Successfully",
            showCloseButton: true,
            confirmButtonText: "Ok",
            icon: "success",
            allowOutsideClick: false,
          });
        } else {
          Swal.fire({
            text: res.data.message,
            showCloseButton: true,
            confirmButtonText: "Ok",
            icon: "error",
            allowOutsideClick: false,
          });
          return;
        }
      }
    );
  };

  // Handle reset password email
  handleSendResetPassword = () => {
    sendResetPassword({ id: this.state.tempDetails.RegUser_ID }).then((res) => {
      if (res.data.code === 200) {
        Swal.fire({
          title: "Reset Password Email Send Successfully",
          showCloseButton: true,
          confirmButtonText: "Ok",
          icon: "success",
          allowOutsideClick: false,
        });
      } else {
        Swal.fire({
          text: res.data.message,
          showCloseButton: true,
          confirmButtonText: "Ok",
          icon: "error",
          allowOutsideClick: false,
        });
        return;
      }
    });
  };

  // Get auth token
  getAuthToken = () => {
    let user = JSON.parse(localStorage.getItem("PA-client"));
    if (user === null) {
      return "null";
    } else if (user.authToken !== null) {
      return user.authToken;
    } else {
      return "null";
    }
  };

  // Hanle login as user
  handleLoginAsUser = () => {
    if (this.getAuthToken() === "null") {
      if (this.state.tableDetails.Account_Activated !== "1") {
        Swal.fire({
          text: "Account is NOT activated yet",
          showCloseButton: true,
          confirmButtonText: "Ok",
          icon: "error",
          allowOutsideClick: false,
        });
        return;
      }
      loginAsUser({ id: this.state.tempDetails.RegUser_ID }).then((res) => {
        if (res.data.code === 200) {
          this.props.dispatch(SignOff({}));
          this.props.dispatch(setLoginUser(res.data.data));
          // this.props.history.push("/search");
          if (this.loginUserTime) {
            clearTimeout(this.loginUserTime);
          }
          this.loginUserTime = setTimeout(this.loginAsUserWindow, 1000);
        } else if (res.data.code === 400 && "status" in res.data.data) {
          if (res.data.data.status === "renewal") {
            Swal.fire({
              text: "Account Expired",
              showCloseButton: true,
              confirmButtonText: "Ok",
              icon: "error",
              allowOutsideClick: false,
            });
            return;
          }
        } else {
          Swal.fire({
            html: res.data.message,
            showCloseButton: true,
            confirmButtonText: "Ok",
            icon: "error",
            allowOutsideClick: false,
          });
          return;
        }
      });
    } else {
      Swal.fire({
        text: "Already Logged in as Customer, please logout and Try Again",
        showCloseButton: true,
        confirmButtonText: "Ok",
        icon: "info",
        allowOutsideClick: false,
      });
    }
  };

  // Link to new window
  loginAsUserWindow = () => {
    window.open(`${window.location.origin}/search`, "_blank");
  };

  // Toggle email modal
  toggle = () => {
    this.setState({ emailModal: !this.state.emailModal, emailList: null });
  };

  // Form Submit
  handleSubmit = () => {
    if (this.state.tableDetails.User_Email === "") {
      Swal.fire({
        text: "Please enter email",
        showCloseButton: true,
        confirmButtonText: "Ok",
        icon: "warning",
        allowOutsideClick: false,
      });
      return;
    }
    if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
        this.state.tableDetails.User_Email
      )
    ) {
      Swal.fire({
        text: "Please enter valid email",
        showCloseButton: true,
        confirmButtonText: "Ok",
        icon: "warning",
        allowOutsideClick: false,
      });
      return;
    }
    if (this.state.tableDetails.User_FirstName.trim() === "") {
      Swal.fire({
        text: "Please enter first name",
        showCloseButton: true,
        confirmButtonText: "Ok",
        icon: "warning",
        allowOutsideClick: false,
      });
      return;
    }
    if (this.state.tableDetails.User_LastName.trim() === "") {
      Swal.fire({
        text: "Please enter last name",
        showCloseButton: true,
        confirmButtonText: "Ok",
        icon: "warning",
        allowOutsideClick: false,
      });
      return;
    }
    let params = {
      id: this.state.tableDetails.RegUser_ID,
      email: this.state.tableDetails.User_Email,
      firstname: this.state.tableDetails.User_FirstName,
      lastname: this.state.tableDetails.User_LastName,
      companyname: this.state.tableDetails.User_CompanyName,
      activated: parseInt(this.state.tableDetails.Account_Activated),
      dateend: this.state.tableDetails.Subscription_End_dt,
      agreed: this.state.tableDetails.Agreestoterms === "1" ? 1 : 0,
      notes: this.state.tableDetails.Record_Notes,
      is_internal: this.state.tableDetails.is_internal,
    };
    if (this.state.flag) {
      params["flag"] = 1;
    }
    if (this.state.tableDetails.User_Parent === "0") {
      params["userPhone"] = this.state.tableDetails.User_NewPhone;
    }
    if (this.state.tempDetails.Verified_Date === 1) {
      params["verified_date"] = this.state.tableDetails.Verified_Date;
    }
    this.setState({ loading: "loading" });
    updateRegisteredAccount(params).then((res) => this.handleUpdateRes(res));
  };

  // Handle form sumbit response
  handleUpdateRes = (res) => {
    if (res.data.code === 200) {
      Swal.fire({
        title: "Updated",
        showCloseButton: true,
        confirmButtonText: "Ok",
        icon: "success",
        allowOutsideClick: false,
      });
      this.setState({ loading: "" });
      this.getUserDet();
    } else {
      Swal.fire({
        text: res.data.message,
        showCloseButton: true,
        confirmButtonText: "Ok",
        icon: "error",
        allowOutsideClick: false,
      });
      this.setState({ loading: "" });
    }
  };
  handleActivateAccount(){
    this.setState({activateLoading: true});
  }
  handleActivateAccountClose(){
    this.props.dispatch(SignOff({}));
    this.getUserDet();
    this.setState({activateLoading: false});
  }

  // Handle flag user option
  handleFlag = () => {
    this.setState({ flag: !this.state.flag });
  };

  render() {
    return (
      <>
        <Helmet>
          <title> PA Admin - Edit Account Details </title>
        </Helmet>
        <section>
          <div className="container admin-container">
            <div className="row">
              <div className="col-5">
                <section className="admin-title">
                  <h1>{this.state.currentTab}</h1>
                </section>
              </div>
              <div className="col-7">
                {this.props.computedMatch.params.userType === "primary_user" ? (
                  <div className="sub-navbar">
                    <ul>
                      <li>
                        <a
                          href="#"
                          onClick={() => this.tabChange("User Information")}
                          className={
                            this.state.currentTab === "User Information"
                              ? "active"
                              : ""
                          }
                        >
                          User Information
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          onClick={() => this.tabChange("Billing History")}
                          className={
                            this.state.currentTab === "Billing History"
                              ? "active"
                              : ""
                          }
                        >
                          Billing History
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          onClick={() => this.tabChange("Subscription History")}
                          className={
                            this.state.currentTab === "Subscription History"
                              ? "active"
                              : ""
                          }
                        >
                          Subscription History
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          onClick={() => this.tabChange("Credit History")}
                          className={
                            this.state.currentTab === "Credit History"
                              ? "active"
                              : ""
                          }
                        >
                          Credit History
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          onClick={() =>
                            this.tabChange("Transaction Information")
                          }
                          className={
                            this.state.currentTab === "Transaction Information"
                              ? "active"
                              : ""
                          }
                        >
                          Transaction Information
                        </a>
                      </li>
                    </ul>
                  </div>
                ) : (
                  <div className="sub-user-navbar">
                    <ul className="d-flex flex-row-reverse">
                      <li>
                        <a
                          href="#"
                          onClick={() =>
                            this.tabChange("Transaction Information")
                          }
                          className={
                            this.state.currentTab === "Transaction Information"
                              ? "active"
                              : ""
                          }
                        >
                          Transaction Information
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          onClick={() => this.tabChange("User Information")}
                          className={
                            this.state.currentTab === "User Information"
                              ? "active"
                              : ""
                          }
                        >
                          User Information
                        </a>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card">
                  {this.state.currentTab === "User Information" ? (
                    <div>
                      <section className="admin-table">
                        {this.state.tableDetails ? (
                          <div className="table-responsive">
                            <table className="table table-bordered ">
                              <thead>
                                <tr>
                                  <th scope="col">User ID</th>
                                  <th scope="col">User Email</th>
                                  <th scope="col">Parent Account</th>
                                  <th scope="col">Subscription Level</th>
                                  {this.state.tableDetails.User_Parent ===
                                  "0" ? (
                                    <th scope="col">SMS Phone</th>
                                  ) : (
                                    <></>
                                  )}
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    {this.state.tableDetails.RegUser_ID}{" "}
                                    <span className="float-lt">
                                      {" "}
                                      (cannot change)
                                    </span>
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={this.state.tableDetails.User_Email}
                                      onChange={(e) =>
                                        this.setState({
                                          tableDetails: {
                                            ...this.state.tableDetails,
                                            User_Email: e.target.value,
                                          },
                                        })
                                      }
                                    />
                                  </td>
                                  <td>
                                    {this.state.tableDetails.User_Parent ===
                                    "0" ? (
                                      <>
                                        <span>
                                          {this.state.tableDetails.User_Type}
                                        </span>
                                        <span className="float-lt">
                                          (cannot change)
                                        </span>
                                      </>
                                    ) : (
                                      <>
                                        <div className="mb-1">
                                          {
                                            this.state.tableDetails
                                              .Parent_Username
                                          }
                                        </div>
                                        <div className="mb-1">
                                          (
                                          {this.state.tableDetails.Parent_Email}
                                          )
                                        </div>
                                        <div style={{ color: "red" }}>
                                          (cannot change)
                                        </div>
                                      </>
                                    )}
                                  </td>
                                  <td>
                                    {
                                      this.state.tableDetails
                                        .User_Subscription_Level
                                    }{" "}
                                    (
                                    {
                                      this.state.tableDetails
                                        .User_Subscription_Level_Text
                                    }
                                    )
                                  </td>
                                  {this.state.tableDetails.User_Parent ===
                                  "0" ? (
                                    <td>
                                      <input
                                        type="text"
                                        className="form-control"
                                        value={
                                          this.state.tableDetails.User_NewPhone
                                        }
                                        onChange={(e) =>
                                          this.setState({
                                            tableDetails: {
                                              ...this.state.tableDetails,
                                              User_NewPhone: e.target.value,
                                            },
                                          })
                                        }
                                      />
                                    </td>
                                  ) : (
                                    <></>
                                  )}
                                </tr>
                              </tbody>
                            </table>
                            <table className="table table-bordered ">
                              <thead>
                                <tr>
                                  <th scope="col">User Password</th>
                                  <th scope="col">First Name</th>
                                  <th scope="col">Last Name</th>
                                  <th scope="col">Company Name</th>
                                  <th scope="col">Is Internal ?</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <a
                                      href="#"
                                      onClick={this.handleLoginAsUser}
                                    >
                                      Log In As User
                                    </a>{" "}
                                    <br />
                                    <br />
                                    <a
                                      href="#"
                                      onClick={this.handleSendResetPassword}
                                    >
                                      Send Reset Password Email
                                    </a>
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={
                                        this.state.tableDetails.User_FirstName
                                      }
                                      onChange={(e) =>
                                        this.setState({
                                          tableDetails: {
                                            ...this.state.tableDetails,
                                            User_FirstName: e.target.value,
                                          },
                                        })
                                      }
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={
                                        this.state.tableDetails.User_LastName
                                      }
                                      onChange={(e) =>
                                        this.setState({
                                          tableDetails: {
                                            ...this.state.tableDetails,
                                            User_LastName: e.target.value,
                                          },
                                        })
                                      }
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={
                                        this.state.tableDetails.User_CompanyName
                                      }
                                      onChange={(e) =>
                                        this.setState({
                                          tableDetails: {
                                            ...this.state.tableDetails,
                                            User_CompanyName: e.target.value,
                                          },
                                        })
                                      }
                                    />
                                  </td>
                                  <td>
                                    <select className="form-control" name="is_internal" onChange={(e) =>
                                        this.setState({
                                          tableDetails: {
                                            ...this.state.tableDetails,
                                            is_internal: e.target.value,
                                          },
                                        })
                                      }>
                                      <option value="0" selected={
                                        this.state.tableDetails
                                          .is_internal === "0"
                                      }>Internal</option>
                                      <option value="1" selected={
                                        this.state.tableDetails
                                          .is_internal === "1"
                                      } >External</option>
                                    </select>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <table className="table table-bordered ">
                              <thead>
                                <tr>
                                  <th scope="col">Download Credits</th>
                                  <th scope="col">Account Activated</th>
                                  <th scope="col">Activation Code</th>
                                  <th scope="col">
                                    Agreed to Terms of Service
                                  </th>
                                  <th scope="col">Verified Date</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    {this.state.tableDetails.Download_Credits}
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control"
                                      value={
                                        this.state.tableDetails
                                          .Account_Activated
                                      }
                                      onChange={(e) =>
                                        this.setState({
                                          tableDetails: {
                                            ...this.state.tableDetails,
                                            Account_Activated: e.target.value,
                                          },
                                        })
                                      }
                                    />{" "}
                                    <br></br>{" "}
                                    <a
                                      href="#"
                                      onClick={this.handleResendActivationEmail}
                                    >
                                      Resend activation email
                                    </a>
                                  </td>
                                  <td>
                                    {this.state.tableDetails.Activation_Code}{" "}
                                    <span className="float-lt">
                                      {" "}
                                      (cannot change)
                                    </span>
                                  </td>
                                  <td>
                                    <input
                                      className="form-control-check"
                                      type="checkbox"
                                      name="remember"
                                      checked={
                                        this.state.tableDetails
                                          .Agreestoterms === "1"
                                      }
                                      readOnly
                                    />
                                    <span className="float-lt">
                                      (cannot change)
                                    </span>{" "}
                                  </td>
                                  {this.state.tempDetails.Verified_Date ===
                                  1 ? (
                                    <td>
                                      <input
                                        type="date"
                                        name="verified_date"
                                        id="verified_date"
                                        className="form-control"
                                        onChange={(e) =>
                                          this.setState({
                                            tableDetails: {
                                              ...this.state.tableDetails,
                                              Verified_Date: e.target.value,
                                            },
                                          })
                                        }
                                      />
                                    </td>
                                  ) : (
                                    <td>
                                      {this.state.tableDetails.Verified_Date}
                                    </td>
                                  )}
                                </tr>
                              </tbody>
                            </table>
                            <table className="table table-bordered ">
                              <thead>
                                <tr>
                                  <th scope="col">Date Registered</th>
                                  <th scope="col">Subscription Start</th>
                                  <th scope="col">Subscription End</th>
                                  <th scope="col">Last Log In</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    {this.state.tableDetails.Date_Registered}
                                    <span className="float-lt">
                                      (cannot change)
                                    </span>
                                  </td>
                                  <td>
                                    {this.state.tableDetails.Subscription_Start}
                                    <span className="float-lt">
                                      (cannot change)
                                    </span>
                                  </td>
                                  <td>
                                    {this.state.tableDetails.Subscription_End}{" "}
                                    <span className="float-lt">
                                      (cannot change)
                                    </span>
                                  </td>
                                  <td>
                                    {this.state.tableDetails.Last_Log_In}{" "}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <table className="table-copy table-bordered ">
                              <thead>
                                <tr>
                                  <th scope="col">Notes</th>
                                  <th scope="col">Flag User</th>
                                  <th scope="col" colpan="2">
                                    {" "}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <textarea
                                      className="form-control"
                                      rows="4"
                                      value={
                                        this.state.tableDetails.Record_Notes
                                      }
                                      onChange={(e) =>
                                        this.setState({
                                          tableDetails: {
                                            ...this.state.tableDetails,
                                            Record_Notes: e.target.value,
                                          },
                                        })
                                      }
                                    ></textarea>
                                  </td>
                                  <td>
                                    <input
                                      className="form-control-check"
                                      type="checkbox"
                                      name="remember"
                                      checked={this.state.flag}
                                      onClick={this.handleFlag}
                                    />
                                  </td>
                                  <td>
                                    <button
                                      type="button"
                                      className="btn btn-primary"
                                      onClick={this.handleEmailList}
                                    >
                                      Show All Emails
                                    </button>
                                         <button
                                        type="button"
                                        className={ "btn btn-primary "}
                                        onClick={()=>this.handleActivateAccount()}
                                      >
                                         Account Activation Wizard
                                      </button>
                                   
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan="3">
                                    <div className="align-ct">
                                      <button
                                        type="button"
                                        className={
                                          "btn btn-primary " +
                                          this.state.loading
                                        }
                                        onClick={this.handleSubmit}
                                      >
                                        Submit
                                      </button>
                                      
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        ) : (
                          <div className="text-center">
                            <div
                              className="spinner-border text-primary mt-3"
                              style={{
                                width: "3rem",
                                height: "3rem",
                                marginLeft: "auto",
                              }}
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          </div>
                        )}

                        {/* Email list modal */}
                        <Modal
                          isOpen={this.state.emailModal}
                          backdrop="static"
                          centered={true}
                        >
                          <ModalHeader
                            toggle={this.toggle}
                            className="backColor"
                          >
                            List of Emails
                          </ModalHeader>
                          <ModalBody>
                            {this.state.emailList ? (
                              this.state.emailList.map((listValue, index) => {
                                return <li>{listValue}</li>;
                              })
                            ) : (
                              <div className="text-center">
                                <div
                                  className="spinner-border text-primary mt-3"
                                  style={{
                                    width: "3rem",
                                    height: "3rem",
                                    marginLeft: "auto",
                                  }}
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              </div>
                            )}
                          </ModalBody>
                          <ModalFooter>
                            <button
                              type="button"
                              className="btn btn-secondary"
                              onClick={this.toggle}
                            >
                              Close
                            </button>
                          </ModalFooter>
                        </Modal>
                      </section>
                    </div>
                  ) : (
                    <span></span>
                  )}
                  {this.state.currentTab === "Billing History" ? (
                    <div>
                      <BillingHistory
                        userId={this.props.computedMatch.params.id}
                      />
                    </div>
                  ) : (
                    <span></span>
                  )}
                  {this.state.currentTab === "Subscription History" ? (
                    <div>
                      <SubscriptionHistory
                        userId={this.props.computedMatch.params.id}
                      />
                    </div>
                  ) : (
                    <span></span>
                  )}
                  {this.state.currentTab === "Credit History" ? (
                    <div>
                      <CreditHistory
                        userId={this.props.computedMatch.params.id}
                      />
                    </div>
                  ) : (
                    <span></span>
                  )}
                  {this.state.currentTab === "Transaction Information" ? (
                    <div>
                      <TransactionInformation
                        userId={this.props.computedMatch.params.id}
                        userType={this.props.computedMatch.params.userType}
                      />
                    </div>
                  ) : (
                    <span></span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
          {this.state.tableDetails!=null && this.state.activateLoading?
          <AccountActivationStatus Account={this.state.tableDetails}  modal={this.state.activateLoading} toggle={()=>this.handleActivateAccountClose()}/>
          :
          ""
  }
         
       
      </>
    );
  }
}

// Export
export default withRouter(connect()(UserEditComponent));
