// Vendor Imports
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Swal from 'sweetalert2'

// Local Imports
import { qcWeeklyReport } from "../../../../service/dms";
import { dateFormatted } from "../../../../utils";

class Table extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tableDetails: null,
            loading: '',
            columns: [
                {
                    title: "ID",
                    field: "job_id"
                },
                { title: "Government ID", field: "Government_ID" },
                { title: "Date of Confirm (by VA)", field: "disposition" },
                { title: "Date of Confirm (by QC)", field: "Government_Place_Name", render: rowData => rowData.Government_Place_Name.toUpperCase() },
                { title: "QC Name", field: "last_verified_date_dt", render: rowData => dateFormatted(rowData.last_verified_date_dt) },
            ]
        };
    }

    // componentDidMount() {
    //     this.getDetails();
    // }

    // getDetails = () => {
    //     this.setState({
    //         tableDetails: null
    //     });
    //     unlockGovernmentList().then(res => this.handleResponse(res));
    // }

    // handleResponse = res => {
    //     if (res.data.code === 200) {
    //         this.setState({
    //             tableDetails: res.data.data
    //         });
    //     }
    // };

    downloadQcReport = () => {
        this.setState({ loading: 'loading' });
        qcWeeklyReport().then(res => {
            if (res.data.code === 200) {
                this.setState({ loading: '' });
                window.open(res.data.data.path, '_blank');
            } else {
                this.setState({ loading: '' });
                Swal.fire({
                    text: res.data.message,
                    showCloseButton: true,
                    confirmButtonText: "Ok",
                    icon: 'error',
                    allowOutsideClick: false
                });
                return;
            }
        })
    }

    render() {
        return (
            <div>
                <div className="mb-4 mt-3">
                    <div className="mb-4 text-center">
                        <button className={'btn btn-info backColor ' + this.state.loading} onClick={this.downloadQcReport}>Weekly QC Report  <img src="/images/button-download.png" /></button>
                    </div>
                </div>
                {/* {this.state.tableDetails ?
                    <div className="mb-4 mt-3">
                        <div className="mb-2 text-center">
                            {this.state.selectedRows.length === 0 ? <button type="button" className="btn btn-info" disabled style={{ cursor: "not-allowed" }}>Unlock Government</button>
                                : <button type="button" className={"btn btn-info " + this.state.loading} onClick={this.handleUnlock}>Unlock Government</button>}

                        </div>
                        <section className="dmstable">
                            <MaterialTable
                                className="table display search-table table-responsive"
                                columns={this.state.columns}
                                data={this.state.tableDetails}
                                title=""
                                options={{
                                    sorting: true,
                                    actionsColumnIndex: -1,
                                    pageSize: 10,
                                    emptyRowsWhenPaging: false
                                }}
                            />
                        </section>
                    </div> : <div className="text-center">
                        <div
                            className="spinner-border text-primary mt-3"
                            style={{ width: "3rem", height: "3rem", marginLeft: "auto" }}
                            role="status"
                        >
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                } */}

            </div>
        );
    }
}

// Export
export default withRouter(connect()(Table));
