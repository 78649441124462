// Vendor Imports
import React from "react";
// Local Imports
import { getDashboardDetails } from "../../../service/admin";

// Admin Dashboard Table
class DashboardComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tableDetails: null
        };
    }

    componentDidMount() {
        // Api call to get dashboard details
        getDashboardDetails().then(res => this.handleResponse(res))
    }


   
    // Handling response
    handleResponse = res => {
        if (res.data.code === 200) {
            this.setState({
                tableDetails: res.data.data,
            });
        }
    };

    render() {
        return (
            <section className="admin-table">
                {this.state.tableDetails ? <div className="table-responsive">
                    <table className="table table-bordered ">
                        <thead>
                            <tr>
                                <th scope="col">Total # </th>
                                <th scope="col">NO.</th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    Total # of Master Accounts
                                </td>
                                <td>
                                    {this.state.tableDetails['Master Accounts']}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Total # of Sub-Users Accounts
                                </td>
                                <td>
                                    {this.state.tableDetails['Sub-Users Accounts']}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Total # of New Power Entry Subscribers
                                </td>
                                <td>
                                    {this.state.tableDetails['Freemium']}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Total # of Power Entry Subscribers
                                </td>
                                <td>
                                    {this.state.tableDetails['Power Entry']}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Total # of Power 15 Subscribers
                                </td>
                                <td>
                                    {this.state.tableDetails['Power 15']}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Total # of Power 75 Subscribers
                                </td>
                                <td>
                                    {this.state.tableDetails['Power 75']}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Total # of Power Max Subscribers
                                </td>
                                <td>
                                    {this.state.tableDetails['Power max']}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Total # of Paid Users
                                </td>
                                <td>
                                    {this.state.tableDetails['total_Paid Users']}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div> : <div className="text-center">
                    <div
                        className="spinner-border text-primary mt-3"
                        style={{ width: "3rem", height: "3rem", marginLeft: "auto" }}
                        role="status"
                    >

                        <span className="sr-only">Loading...</span>
                    </div></div>
                }
            </section>
        );
    }

}

// Export
export default DashboardComponent;
