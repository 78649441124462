// Vendor Imports
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// Local Imports
import Form from "./Form";
import { resetPassword } from "../../../service/client";
import loginImage from "../../../images/login.jpg";

// ResetPassword Component
class ResetPasswordComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backendErrors: null,
      loading: "",
      success: false,
    };
  }

  // Handlers
  handleSubmit = (values) => {
    let params = {
      id1: this.props.computedMatch.params.id1,
      id2: this.props.computedMatch.params.id2,
      token: this.props.computedMatch.params.token,
      pass: values.newpass,
      confirmpass: values.confirmpass,
    };
    this.setState({ loading: "loading" });
    resetPassword(params).then((res) => this.handleResponse(res));
  };

  // Response handler
  handleResponse = (res) => {
    if (res.data.code === 200) {
      this.setState({
        success: true,
        backendErrors: null,
        loading: "",
      });
    } else if (res.data.code === 422) {
      this.setState({ backendErrors: res.data.data.message[0], loading: "" });
    } else {
      this.setState({ backendErrors: res.data.message, loading: "" });
    }
  };

  render() {
    return (
      <div>
        <section className="container login-section forgotpw-block">
          <div className="row login-bg">
            <div className="col-sm-12 col-md-6">
              <img
                src={loginImage}
                alt="Placeholder"
                className="img-responsive"
              />
            </div>
            <div className="col-sm-12 col-md-6 login-right">
              <h1>Enter a New Password</h1>
              {this.state.backendErrors ? (
                <div
                  className="alert alert-danger"
                  role="alert"
                  dangerouslySetInnerHTML={{
                    __html: this.state.backendErrors,
                  }}
                ></div>
              ) : null}
              {this.state.success ? (
                <div className="alert alert-success">
                  <p className="lead">Password Reset Successful</p>
                  <p className="mb-0">
                    You may now continue to the{" "}
                    <Link to="/login">Login Page</Link>!
                  </p>
                </div>
              ) : (
                <Form
                  onSubmit={this.handleSubmit}
                  loading={this.state.loading}
                ></Form>
              )}
            </div>
          </div>
        </section>
      </div>
    );
  }
}

// Export
export default connect()(ResetPasswordComponent);
