// Vendor Imports
import React from "react";
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

// Local Imports
import { addNewOfficial } from "../../../../service/dms";

// Add new official modal
class AddModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            backendErrors: null,
            loading: '',
            role: '',
            e1: false,
            e4: false,
            e7: false,
            e10: false,
            success: false
        };
    }

    // Role change handler
    handleRoleChange = event => {
        this.setState({ role: event.target.value })
    }

    // E+1 handler
    handleE1value = () => {
        this.setState({ e1: !this.state.e1 })
    }

    // E+4 Handler
    handleE4value = () => {
        this.setState({ e4: !this.state.e4 })
    }

    // E+7 Handler
    handleE7value = () => {
        this.setState({ e7: !this.state.e7 })
    }

    // E+10 Handler
    handleE10value = () => {
        this.setState({ e10: !this.state.e10 })
    }

    // Form Submit Handler
    handleSubmit = () => {
        if (this.state.role.trim() === '') {
            this.setState({ backendErrors: "Please enter the role" })
            return;
        }
        this.setState({ backendErrors: null })
        var params = {
            role: this.state.role,
            etype: []
        }
        if (this.state.e1) {
            params.etype.push("E1")
        }
        if (this.state.e4) {
            params.etype.push("E4")
        }
        if (this.state.e7) {
            params.etype.push("E7")
        }
        if (this.state.e10) {
            params.etype.push("E10")
        }
        this.setState({ loading: "loading" });
        addNewOfficial(params).then(res => this.handleAddResponse(res));
    }

    // Api response handler
    handleAddResponse = res => {
        if (res.data.code === 200) {
            this.setState({
                backendErrors: null,
                loading: '',
                role: '',
                e1: false,
                e4: false,
                e7: false,
                e10: false,
                success: true
            });
            this.props.refreshPage();
        } else {
            this.setState({ backendErrors: "Unable to Process", loading: "" });
        }
    }

    // Close Modal handler
    closeModal = () => {
        this.setState({
            backendErrors: null,
            loading: '',
            role: '',
            e1: false,
            e4: false,
            e7: false,
            e10: false,
            success: false
        });
        this.props.toggle();
    }

    render() {
        return (
            <div>
                {/* Add Modal */}
                < Modal isOpen={this.props.modal} size="lg" backdrop="static" >
                    <ModalHeader toggle={this.closeModal}>Add New Role</ModalHeader>
                    <ModalBody>
                        {this.state.success ? <div className="alert alert-success">
                            Adding Head of Test is being process in backgroud
                         </div> :
                            <div>
                                <div className="form-group row">
                                    <label htmlFor="inputEmail" className="col-sm-4 col-form-label">Role *</label>
                                    <div className="col-sm-8">
                                        <input type="firstname" id="firstname" className="form-control" value={this.state.role} onChange={this.handleRoleChange} />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="inputPassword" className="col-sm-4 col-form-label">Operation Model</label>
                                    <div className="col-sm-8">
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" onClick={this.handleE1value} />
                                            <label className="form-check-label" htmlFor="inlineCheckbox1">E+1</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2" onClick={this.handleE4value} />
                                            <label className="form-check-label" htmlFor="inlineCheckbox2">E+4</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox" id="inlineCheckbox3" value="option3" onClick={this.handleE7value} />
                                            <label className="form-check-label" htmlFor="inlineCheckbox3">E+7</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox" id="inlineCheckbox4" value="option4" onClick={this.handleE10value} />
                                            <label className="form-check-label" htmlFor="inlineCheckbox4">E+10</label>
                                        </div>
                                    </div>
                                </div>
                                {this.state.backendErrors ? (
                                    <div
                                        className="alert alert-danger"
                                        role="alert"
                                        dangerouslySetInnerHTML={{
                                            __html: this.state.backendErrors
                                        }}
                                    ></div>
                                ) : null}
                                <div className="text-right">
                                    <button type="submit" onClick={this.handleSubmit} className={"btn btn-primary " + this.state.loading}>Submit</button>
                                </div>
                            </div>
                        }
                    </ModalBody>
                </Modal >
            </div>
        );
    }
}

export default connect()(AddModal);
