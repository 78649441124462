// Vendor Imports
import $ from "jquery";
import React from "react";
import { connect } from "react-redux";
import Swal from 'sweetalert2'
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

// Local Imports
import AddNew from "./AddGovBoardMember";
import AddLawEnforcement from "./AddLawEnforcement";
import AddNewDeputy from "./AddDeputyTopOppointedOfficial"
import { updateVoiceAgentGov, updateVoiceAgentGovInfo, validateZipCode, vaConfirmGov, vaUnConfirmGov, emailBounceCheck } from "../../../../service/dms";

// Voice agent edit gov 
class VoiceAgentEditTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            seeMore: false,
            addModal: false,
            addDeputyModal: false,
            addEnforcementModal: false,
            editTopOff: false,
            editValues: {},
            tempVal: {},
            Gov_name: '',
            members: [],
            dataToggle: '',
            emailToggle: false,
            emailMsg: '',
            loading: '',
            emailLoading: '',
            emailErrBorder: false
        };
        this.firstNameTimeout = false;
        this.lastNameTimeout = false;
    }

    componentDidMount() {
        let members = [this.props.tableDetails.top_elected_official];
        members.push(this.props.tableDetails.governing_board_member)
        members.push(this.props.tableDetails.other_government_officials)
        let flatMembers = members.flat();
        let removeUndef = flatMembers.filter(f => f !== undefined)
        let govConfirmed = 'government_confirmed' in this.props.tableDetails ? this.props.tableDetails.government_confirmed : [];
        let fullData = this.getAllMembers(removeUndef, govConfirmed);
        this.setState({ members: fullData });
    }

    // get all member handler
    getAllMembers = (members, govConfirmed) => {
        let memArray = [];
        members.forEach(m => {
            memArray.push({
                Email_Address: m.Email_Address,
                Email_Bounce: m.Email_Bounce,
                Email_Bounce_Color: m.Email_Bounce_Color,
                Email_Grade: m.Email_Grade,
                First_Name: m.First_Name,
                Government_Title: m.Government_Title,
                Last_Name: m.Last_Name,
                Mailing_City: m.Mailing_City,
                Mailing_State: m.Mailing_State,
                Mailing_Street_Box: m.Mailing_Street_Box,
                Mailing_Suite_Number: m.Mailing_Suite_Number,
                Mailng_Zip_Code: m.Mailng_Zip_Code,
                Official_ID: m.edit.Official_ID,
                Part_of_Governing_Board: m.Part_of_Governing_Board,
                Person_ID: m.Person_ID,
                PhoneGrade: m.Phone_Grade,
                PhoneGradeColor: m.Phone_Grade_color,
                Phone_Number: m.Phone_Number,
                Phone_Number_Ext: m.Phone_Number_Ext,
                Phone_Number_FMTD: m.Phone_Number_FMTD,
                Role: m.o_Role,
                Role_exists: m.Role_exists,
                bad_email: m.bad_email,
                primary_status: 0
            });
            memArray.push(m.edit);
        });
        govConfirmed.forEach(gov => {
            memArray.push({
                Email_Address: gov.Email_Address,
                Email_Bounce: gov.Email_Bounce,
                Email_Bounce_Color: gov.qc_Email_Bounce_Color,
                Email_Grade: gov.qc_Email_Grade_color,
                First_Name: gov.First_Name,
                Government_Title: gov.Government_Title,
                Last_Name: gov.Last_Name,
                Mailing_City: gov.Mailing_City,
                Mailing_State: gov.Mailing_State,
                Mailing_Street_Box: gov.Mailing_Street_Box,
                Mailing_Suite_Number: gov.Mailing_Suite_Number,
                Mailng_Zip_Code: gov.Mailng_Zip_Code,
                Official_ID: gov.Official_ID,
                Part_of_Governing_Board: gov.Part_of_Governing_Board,
                Person_ID: gov.Person_ID,
                PhoneGrade: gov.PhoneGrade,
                PhoneGradeColor: gov.Phone_Grade_color,
                Phone_Number: gov.Phone_Number,
                Phone_Number_Ext: gov.Phone_Number_Ext,
                Phone_Number_FMTD: gov.Phone_Number_FMTD,
                Role: gov.Role,
                Role_exists: gov.Role_exists,
                bad_email: gov.bad_email,
                primary_status: 0
            });
        })
        return memArray;
    }

    // See more toggle handler
    seeMoreToggle = () => {
        this.setState({ seeMore: !this.state.seeMore });
    }

    // Toggle handler
    toggle = () => {
        this.setState({ addModal: !this.state.addModal });
    }

    toggleAddDeputy = () => {
        let tpPopValue= [];
        if('top_appointed_executive' in this.props.tableDetails){
            this.props.tableDetails.top_appointed_executive.map((listPopValue, index) => {
                if(listPopValue.edit.Role==='Deputy Top Appointed Executive'){
                    tpPopValue.push(listPopValue.edit.Role_exists);
                }
            })
        
            if(tpPopValue.includes('No')){
                Swal.fire({
                    text: 'Please use the available Deputy TAO role row before adding more',
                    showCancelButton: false,
                    showCloseButton: true,
                    confirmButtonText: 'OK',
                    //cancelButtonText: 'cancel',
                    icon: 'question',
                    allowOutsideClick: false
                });
                return false;
            }
        }
        this.setState({ addDeputyModal: !this.state.addDeputyModal });
    }

    toggleAddEnforcement = () => {
        if(this.state.addEnforcementModal===true){
            this.setState({ addEnforcementModal: false });
            return false;
        }
        Swal.fire({
        //title: 'Law Enforcement',
        text: "Do you want to add a second Law Enforcement Official?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                this.setState({ addEnforcementModal: !this.state.addEnforcementModal });
            } else {
                this.setState({ addEnforcementModal: false });
            }
            
        })
        
    }

    // First name check handler
    firstNameCheck = () => {
        if (this.state.editValues.First_Name !== '') {
            let mem = this.state.members.filter(m => m.First_Name === this.state.editValues.First_Name);
            if (mem.length > 0) {
                Swal.fire({
                    text: 'Do you want to use ANOTHER EXISTING PERSON to replace the person in this role?',
                    showCancelButton: true,
                    showCloseButton: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                    icon: 'question',
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.setState({
                            editValues: mem[0]
                        });
                        return;
                    }
                })
            }
        }
    }

    // last name check handler
    lastNameCheck = () => {
        if (this.state.editValues.Last_Name !== '') {
            let mem = this.state.members.filter(m => m.Last_Name === this.state.editValues.Last_Name);
            if (mem.length > 0) {
                Swal.fire({
                    text: 'Do you want to use ANOTHER EXISTING PERSON to replace the person in this role?',
                    showCancelButton: true,
                    showCloseButton: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                    icon: 'question',
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.setState({
                            editValues: mem[0]
                        });
                    }
                })
            }
        }
    }

    // First name check handler
    firstNameDataListChange = event => {
        
            this.setState({
                editValues: {
                    ...this.state.editValues,
                    First_Name: event.target.value
            }
            });  
            if(this.state.editValues.Role==='Head of Law Enforcement' && this.state.editValues.Role_exists==='No')
            {
                //console.log('check',this.state.editValues);
                this.setState({
                    editValues: {
                        ...this.state.editValues,
                        Role_exists: 'No'
                    }
                });

            }
        
        if (this.firstNameTimeout) {
            clearTimeout(this.firstNameTimeout);
        }
        this.firstNameTimeout = setTimeout(this.firstNameCheck, 1000);
    }

    // last name check handler
    lastNameDataListChange = event => {
        this.setState({
            editValues: {
                ...this.state.editValues,
                Last_Name: event.target.value
            }
        });
        if(this.state.editValues.Role==='Head of Law Enforcement' && this.state.editValues.Role_exists==='No')
        {
            //console.log('bbbbb',this.state.editValues.Role_exists,'-----',this.state.editValues.Role)
            this.setState({
                editValues: {
                    ...this.state.editValues,
                    Role_exists: 'No'
                }
            });
        }
        if (this.lastNameTimeout) {
            clearTimeout(this.lastNameTimeout);
        }
        this.lastNameTimeout = setTimeout(this.lastNameCheck, 1000);
    }

    // Get data list values
    getDataListValues = value => {
        let data = this.state.members.map(m => m[value]);
        let filteredValues = [];
        data.forEach(d => {
            if (!filteredValues.includes(d)) {
                filteredValues.push(d)
            }
        });
        return filteredValues;
    }

    // Update gov information
    updateGovInfo = () => {
        if (this.state.editValues.Zip_Code.length !== 5) {
            Swal.fire({
                text: 'Invalid Zip Code entered. Please try again.',
                showCloseButton: true,
                confirmButtonText: "Ok",
                icon: 'error',
                allowOutsideClick: false
            });
            return;
        }
        var phonenumber = this.state.editValues.Government_PhoneNumber_FMTD;
        var phonenumberCLEANED = phonenumber.replace(/-/g, "");
        if (phonenumberCLEANED.length !== 10) {
            Swal.fire({
                text: 'Invalid Phone Number entered. Please try again.',
                showCloseButton: true,
                confirmButtonText: "Ok",
                icon: 'error',
                allowOutsideClick: false
            });
            return;
        }
        var pass_regex = /^[0-9 -]+$/;
        if (phonenumber !== '') {
            if (!phonenumber.match(pass_regex)) {
                Swal.fire({
                    text: 'Invalid Phone Number entered. Please try again.',
                    showCloseButton: true,
                    confirmButtonText: "Ok",
                    icon: 'error',
                    allowOutsideClick: false
                });
                return;
            }
        }
        if (this.state.editValues.Zip_Code !== '' && this.state.editValues.Zip_Code.length === 5) {
            this.setState({ loading: 'loading' });
            validateZipCode({
                queryString: this.state.editValues.Zip_Code
            }).then(res => {
                if (res.data.data.zipcode_status === 1) {
                    let params = {
                        jid: this.props.jid,
                        gid: this.props.gid,
                        phone: this.state.editValues.Government_PhoneNumber_FMTD,
                        Government_Designated_Name: this.state.editValues.Government_Designated_Name,
                        webaddress: this.state.editValues.Government_Web_Address,
                        updatedweb: this.state.editValues.Updated_website,
                        address: this.state.editValues.Address_Street_Box,
                        city: this.state.editValues.City,
                        zip: this.state.editValues.Zip_Code,
                        takenfrom: this.state.editValues.Taken_from,
                        electionmonth: this.state.editValues.Electron_Month,
                        p_phone: this.state.tempVal.Government_PhoneNumber_FMTD,
                        p_Government_Designated_Name: this.state.tempVal.Government_Designated_Name,
                        p_webaddress: this.state.tempVal.Government_Web_Address,
                        p_updatedweb: this.state.tempVal.Updated_website,
                        p_address: this.state.tempVal.Address_Street_Box,
                        p_city: this.state.tempVal.City,
                        p_zip: this.state.tempVal.Zip_Code,
                        p_takenfrom: this.state.tempVal.Taken_from,
                        p_electionmonth: this.state.tempVal.Electron_Month
                    }
                    updateVoiceAgentGovInfo(params).then(res => this.handleResponse(res));
                } else if (res.data.data.zipcode_status === 0) {
                    Swal.fire({
                        text: 'Invalid Zip Code entered. Please try again.',
                        showCloseButton: true,
                        confirmButtonText: "Ok",
                        icon: 'error',
                        allowOutsideClick: false
                    });
                    this.setState({ loading: '' });
                    return;
                }
            })
        }
    }

    // Get field values
    getFieldValue = val => {
        if (val === null) {
            return '';
        }
        else if (val.toLowerCase() === "none") {
            return '';
        } else {
            return val;
        }
    }


    // Role change handler for Role Expect Top Appointed Executive
    roleChange = event => {
        if (event.target.value === "Yes") {
            Swal.fire({
                text: 'Please confirm that you want to change ROLE EXISTS to "yes"?',
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonText: 'confirm',
                cancelButtonText: 'cancel',
                icon: 'question',
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    this.setState({
                        editValues: {
                            ...this.state.editValues,
                            Role_exists: 'Yes',
                            Government_Title: this.getFieldValue(this.state.tempVal.Government_Title),
                            First_Name: this.getFieldValue(this.state.tempVal.First_Name),
                            Last_Name: this.getFieldValue(this.state.tempVal.Last_Name),
                            Email_Address: this.getFieldValue(this.state.tempVal.Email_Address),
                            Phone_Number_FMTD: this.getFieldValue(this.state.tempVal.Phone_Number_FMTD),
                            Phone_Number_Ext: this.getFieldValue(this.state.tempVal.Phone_Number_Ext),
                            Mailing_Street_Box: this.getFieldValue(this.state.tempVal.Mailing_Street_Box),
                            Mailing_Suite_Number: this.getFieldValue(this.state.tempVal.Mailing_Suite_Number),
                            Mailing_City: this.getFieldValue(this.state.tempVal.Mailing_City),
                            Mailng_Zip_Code: this.getFieldValue(this.state.tempVal.Mailng_Zip_Code),
                            Person_ID: this.getFieldValue(this.state.tempVal.Person_ID),
                            Mailing_State: this.getFieldValue(this.state.tempVal.Mailing_State),
                            Email_Grade: this.getFieldValue(this.state.tempVal.Email_Grade)
                        }
                    })
                }
            })
        } else {
            Swal.fire({
                text: 'Please confirm that you want to change ROLE EXISTS to "no"?',
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonText: 'confirm',
                cancelButtonText: 'cancel',
                icon: 'question',
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    this.setState({
                        editValues: {
                            ...this.state.editValues,
                            Role_exists: 'No',
                            Government_Title: 'None',
                            First_Name: 'None',
                            Last_Name: 'None',
                            Email_Address: '',
                            Phone_Number_FMTD: '',
                            Phone_Number_Ext: 'None',
                            Mailing_Street_Box: 'None',
                            Mailing_Suite_Number: 'None',
                            Mailing_City: 'None',
                            Mailng_Zip_Code: '',
                            Person_ID: ''
                        }
                    })
                }
            })
        }
    }

    // Role change handler for Top Appointed Executive and their Deputy
    roleChangeTOP = event => {
        if (event.target.value === "Yes") {
            if(this.state.editValues.Role==='Deputy Top Appointed Executive')
            {
                if(this.props.tableDetails.top_official=='No')
                { 
                    Swal.fire({
                        text: 'Deputy Top Appointed Executive cannot be added without adding a Top Appointed Executive',
                        showCancelButton: false,
                        showCloseButton: false,
                        confirmButtonText: 'OK',
                        //cancelButtonText: 'cancel',
                        icon: 'question',
                        allowOutsideClick: false
                    }).then((result) => {
                        return false;
                    });
                    return false;
                }
            } 

            Swal.fire({
                text: 'Please confirm that you want to change ROLE EXISTS to "yes"?',
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonText: 'confirm',
                cancelButtonText: 'cancel',
                icon: 'question',
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    this.setState({
                        editValues: {
                            ...this.state.editValues,
                            Role_exists: 'Yes',
                            Government_Title: this.getFieldValue(this.state.tempVal.Government_Title),
                            First_Name: this.getFieldValue(this.state.tempVal.First_Name),
                            Last_Name: this.getFieldValue(this.state.tempVal.Last_Name),
                            Email_Address: this.getFieldValue(this.state.tempVal.Email_Address),
                            Phone_Number_FMTD: this.getFieldValue(this.state.tempVal.Phone_Number_FMTD),
                            Phone_Number_Ext: this.getFieldValue(this.state.tempVal.Phone_Number_Ext),
                            Mailing_Street_Box: this.getFieldValue(this.state.tempVal.Mailing_Street_Box),
                            Mailing_Suite_Number: this.getFieldValue(this.state.tempVal.Mailing_Suite_Number),
                            Mailing_City: this.getFieldValue(this.state.tempVal.Mailing_City),
                            Mailng_Zip_Code: this.getFieldValue(this.state.tempVal.Mailng_Zip_Code),
                            Person_ID: this.getFieldValue(this.state.tempVal.Person_ID),
                            Mailing_State: this.getFieldValue(this.state.tempVal.Mailing_State),
                            Email_Grade: this.getFieldValue(this.state.tempVal.Email_Grade)
                        }
                    })
                }
            })
        } else {
            if(this.state.editValues.Role==='Top Appointed Executive')
            {
                let tpValue= [];
                if('top_appointed_executive' in this.props.tableDetails){
                    this.props.tableDetails.top_appointed_executive.map((listTopValue, index) => {
                        if(listTopValue.edit.Role==='Deputy Top Appointed Executive'){
                            tpValue.push(listTopValue.edit.Role_exists);
                        }
                    })
                
                    if(tpValue.includes('Yes')){
                        Swal.fire({
                            text: 'TAO Role cannot be removed before DTAO roles',
                            showCancelButton: false,
                            showCloseButton: true,
                            confirmButtonText: 'OK',
                            //cancelButtonText: 'cancel',
                            icon: 'question',
                            allowOutsideClick: false
                        });
                        return false;
                    }
                }
            }

            Swal.fire({
                text: 'Please confirm that you want to change ROLE EXISTS to "no"?',
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonText: 'confirm',
                cancelButtonText: 'cancel',
                icon: 'question',
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    this.setState({
                        editValues: {
                            ...this.state.editValues,
                            Role_exists: 'No',
                            Government_Title: 'None',
                            First_Name: 'None',
                            Last_Name: 'None',
                            Email_Address: '',
                            Phone_Number_FMTD: '',
                            Phone_Number_Ext: 'None',
                            Mailing_Street_Box: 'None',
                            Mailing_Suite_Number: 'None',
                            Mailing_City: 'None',
                            Mailng_Zip_Code: '',
                            Person_ID: ''
                        }
                    })
                }
            })
        }
    }

    // Update Voice agent govs
    updateVoiceAgent = (rval) => {
        if (this.state.editValues.Role_exists === "No") {
            this.setState({ loading: 'loading' });
            var params = {
                oid: this.state.tempVal.Official_ID,
                jid: this.props.jid,
                gid: this.props.gid,
                role: this.state.editValues.Role,
                roleexist: this.state.editValues.Role_exists,
                title: this.state.editValues.Government_Title,
                fname: this.state.editValues.First_Name,
                lname: this.state.editValues.Last_Name,
                email: this.state.editValues.Email_Address,
                phone: this.state.editValues.Phone_Number_FMTD,
                phoneext: this.state.editValues.Phone_Number_Ext,
                mail_box: this.state.editValues.Mailing_Street_Box,
                mail_suite: this.state.editValues.Mailing_Suite_Number,
                mail_city: this.state.editValues.Mailing_City,
                mail_state: this.state.editValues.Mailing_State,
                mail_zip: this.state.editValues.Mailng_Zip_Code,
                partofboard: this.state.editValues.Part_of_Governing_Board,
                primary_id: 0,
                email_bounce: this.state.editValues.Email_Bounce,
                Person_ID: this.state.editValues.Person_ID,
                email_grade: ""
            }
            updateVoiceAgentGov(params).then(res => this.handleResponse(res));
        } else {
            if (this.state.editValues.Government_Title.trim() === "") {
                Swal.fire({
                    text: 'Enter the Government Title.',
                    showCloseButton: true,
                    confirmButtonText: "Ok",
                    icon: 'info',
                    allowOutsideClick: false
                });
                return;
            }
            if (this.state.editValues.First_Name.trim() === "") {
                Swal.fire({
                    text: "Enter the First Name",
                    showCloseButton: true,
                    confirmButtonText: "Ok",
                    icon: 'info',
                    allowOutsideClick: false
                });
                return;
            }
            if (this.state.editValues.Last_Name.trim() === "") {
                Swal.fire({
                    text: "Enter the Last Name",
                    showCloseButton: true,
                    confirmButtonText: "Ok",
                    icon: 'info',
                    allowOutsideClick: false
                });
                return;
            }
            if (this.state.editValues.Email_Address !== '') {
                var emailr_regex = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i;
                if (!this.state.editValues.Email_Address.match(emailr_regex)) {
                    Swal.fire({
                        text: 'Invalid Email entered. Please try again.',
                        showCloseButton: true,
                        confirmButtonText: "Ok",
                        icon: 'error',
                        allowOutsideClick: false
                    });
                    return;
                }
            }
            var phoneCLEANED = this.state.editValues.Phone_Number_FMTD.replace(/-/g, "");
            if (phoneCLEANED === '') {
                Swal.fire({
                    text: 'Enter the Phone Number',
                    showCloseButton: true,
                    confirmButtonText: "Ok",
                    icon: 'info',
                    allowOutsideClick: false
                });
                return;
            }
            if (phoneCLEANED !== '') {
                if (phoneCLEANED.length !== 10) {
                    Swal.fire({
                        text: 'Invalid Phone Number entered. Please try again.',
                        showCloseButton: true,
                        confirmButtonText: "Ok",
                        icon: 'error',
                        allowOutsideClick: false
                    });
                    return;
                }
                var pass_regexr = /^[0-9 -]+$/;
                if (this.state.editValues.Phone_Number_FMTD !== '') {
                    if (!this.state.editValues.Phone_Number_FMTD.match(pass_regexr)) {
                        Swal.fire({
                            text: 'Invalid Phone Number entered. Please try again.',
                            showCloseButton: true,
                            confirmButtonText: "Ok",
                            icon: 'error',
                            allowOutsideClick: false
                        });
                        return;
                    }
                }
            }
            if (this.state.editValues.Mailing_Street_Box.trim() === '') {
                Swal.fire({
                    text: 'Enter the Mailing Street Box',
                    showCloseButton: true,
                    confirmButtonText: "Ok",
                    icon: 'info',
                    allowOutsideClick: false
                });
                return;
            }
            if (this.state.editValues.Mailing_City.trim() === '') {
                Swal.fire({
                    text: 'Enter the Mailing City',
                    showCloseButton: true,
                    confirmButtonText: "Ok",
                    icon: 'info',
                    allowOutsideClick: false
                });
                return;
            }
            if (this.state.editValues.Mailng_Zip_Code.trim() === '') {
                Swal.fire({
                    text: 'Enter the Mailing Zip Code',
                    showCloseButton: true,
                    confirmButtonText: "Ok",
                    icon: 'info',
                    allowOutsideClick: false
                });
                return;
            }
            if (this.state.editValues.Mailng_Zip_Code.length !== 5) {
                Swal.fire({
                    text: 'Invalid Zip Code entered. Please try again.',
                    showCloseButton: true,
                    confirmButtonText: "Ok",
                    icon: 'error',
                    allowOutsideClick: false
                });
                return;
            }

            let titleTpCheck=[];
            let titleDpCheck=[];
            let nameTpCheck=[];
            if('top_appointed_executive' in this.props.tableDetails)
            {
                this.props.tableDetails.top_appointed_executive.map((listTopValue, index) => {
                        if(listTopValue.edit.Official_ID!=this.state.editValues.Official_ID)
                        {
                            nameTpCheck.push(listTopValue.edit.First_Name.trim()+listTopValue.edit.Last_Name.trim());
                        }
                        if(listTopValue.edit.Role==='Top Appointed Executive')
                        {
                            titleTpCheck.push(listTopValue.edit.Government_Title);
                        }
                        if(listTopValue.edit.Role==='Deputy Top Appointed Executive')
                        {
                            titleDpCheck.push(listTopValue.edit.Government_Title);
                        }
                        
                        
                })
            }

            if('top_elected_official' in this.props.tableDetails)
            {
                nameTpCheck.push(this.props.tableDetails.top_elected_official.edit.First_Name.trim()+this.props.tableDetails.top_elected_official.edit.Last_Name.trim());
                
            }

            if('governing_board_member' in this.props.tableDetails)
            {
                this.props.tableDetails.governing_board_member.map((listGBMValue, index) => {
                    
                        nameTpCheck.push(listGBMValue.edit.First_Name.trim()+listGBMValue.edit.Last_Name.trim());
                    
                })
            }
            
            if(rval==='Top Appointed Executive')
            {
               if(titleDpCheck.includes(this.state.editValues.Government_Title))
                {
                    Swal.fire({
                        text: 'TAO and DTAO cannot have the same title. Please correct and then proceed..',
                        showCancelButton: false,
                        showCloseButton: true,
                        confirmButtonText: 'OK',
                        //cancelButtonText: 'cancel',
                        icon: 'question',
                        allowOutsideClick: false
                    });
                    return false;
                } 
            }
            
            if(rval==='Deputy Top Appointed Executive')
            {
               if(titleTpCheck.includes(this.state.editValues.Government_Title))
                {
                    Swal.fire({
                        text: 'TAO and DTAO cannot have the same title. Please correct and then proceed..',
                        showCancelButton: false,
                        showCloseButton: true,
                        confirmButtonText: 'OK',
                        //cancelButtonText: 'cancel',
                        icon: 'question',
                        allowOutsideClick: false
                    });
                    return false;
                } 
            }
                 
            if(rval==='Deputy Top Appointed Executive' || rval==='Top Appointed Executive')
            {
                if(nameTpCheck.includes(this.state.editValues.First_Name.trim()+this.state.editValues.Last_Name.trim()))
                { 
                    Swal.fire({
                        text: 'DTAO and <TAO/TEO/GBM> cannot have the same person. Please correct and then proceed.',
                        showCancelButton: false,
                        showCloseButton: true,
                        confirmButtonText: 'OK',
                        //cancelButtonText: 'cancel',
                        icon: 'question',
                        allowOutsideClick: false
                    });
                    return false;
                } 
            }

            
            if (this.state.editValues.Mailng_Zip_Code !== '' && this.state.editValues.Mailng_Zip_Code.length === 5) {
                this.setState({ loading: 'loading' });
                validateZipCode({
                    queryString: this.state.editValues.Mailng_Zip_Code
                }).then(res => {
                    if (res.data.data.zipcode_status === 1) {
                        var params = {
                            oid: this.state.tempVal.Official_ID,
                            jid: this.props.jid,
                            gid: this.props.gid,
                            role: this.state.editValues.Role,
                            roleexist: this.state.editValues.Role_exists === "" ? "Yes" : this.state.editValues.Role_exists,
                            title: this.state.editValues.Government_Title,
                            fname: this.state.editValues.First_Name,
                            lname: this.state.editValues.Last_Name,
                            email: this.state.editValues.Email_Address,
                            phone: this.state.editValues.Phone_Number_FMTD,
                            phoneext: this.state.editValues.Phone_Number_Ext,
                            mail_box: this.state.editValues.Mailing_Street_Box,
                            mail_suite: this.state.editValues.Mailing_Suite_Number,
                            mail_city: this.state.editValues.Mailing_City,
                            mail_state: this.state.editValues.Mailing_State,
                            mail_zip: this.state.editValues.Mailng_Zip_Code,
                            partofboard: this.state.editValues.Part_of_Governing_Board,
                            email_bounce: this.state.editValues.Email_Bounce,
                            Person_ID: this.state.editValues.Person_ID,
                            email_grade: ""
                        }
                        if (params.roleexist !== this.state.tempVal.Role_exists) {
                            let data = Object.assign(params, {
                                primary_id: 0,
                            });
                            updateVoiceAgentGov(data).then(res => this.handleResponse(res));
                        } else if (this.firstLastCheck(this.state.editValues, this.state.tempVal)) {
                            Swal.fire({
                                text: 'Is this the SAME PERSON who is currently in this role?',
                                showCancelButton: true,
                                showCloseButton: true,
                                confirmButtonText: 'Yes',
                                cancelButtonText: 'No',
                                icon: 'question',
                                allowOutsideClick: false
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    let data = Object.assign(params, {
                                        primary_id: 1,
                                    });
                                    //console.log(data);
                                    //return false;
                                    updateVoiceAgentGov(data).then(res => this.handleResponse(res));
                                }
                                if (result.isDismissed && result.dismiss === "cancel") {
                                    let data = Object.assign(params, {
                                        primary_id: 0,
                                    });
                                    updateVoiceAgentGov(data).then(res => this.handleResponse(res));
                                }
                                if (result.isDismissed && result.dismiss === "close") {
                                    this.setState({ editValues: this.state.tempVal, loading: '' });
                                    return;
                                }
                            })
                        } else {
                            let data = Object.assign(params, {
                                primary_id: this.state.editValues.primary_status,
                            });
                            updateVoiceAgentGov(data).then(res => this.handleResponse(res));
                        }
                    } else if (res.data.data.zipcode_status === 0) {
                        Swal.fire({
                            text: 'Invalid Zip Code entered. Please try again.',
                            showCloseButton: true,
                            confirmButtonText: "Ok",
                            icon: 'error',
                            allowOutsideClick: false
                        });
                        this.setState({ loading: '' });
                    }
                })
            }
        }
    }

    // Response handler
    handleResponse = res => {
        if (res.data.code === 200) {
            this.props.voiceAgentEdit();
        } else {
            this.setState({ loading: '', emailLoading: '' });
        }
    };

    // Handle radio button
    handleRadioButton = value => {
        this.setState({
            editValues: {
                ...this.state.editValues,
                Part_of_Governing_Board: value
            }
        });
    }

    //Confirm gov handler
    confirmGov = ofId => {
        let params = {
            oid: ofId,
            jid: this.props.jid,
            gid: this.props.gid
        }
        vaConfirmGov(params).then(res => this.handleResponse(res))
    }

    // Unconfirm Gov handler
    unConfirmGov = ofId => {
        let params = {
            oid: ofId,
            jid: this.props.jid,
            gid: this.props.gid
        }
        vaUnConfirmGov(params).then(res => this.handleResponse(res))
    }

    // Show edit handler
    showEdit = (name, editVal) => {
        this.setState({ loading: '', emailLoading: '', emailErrBorder: false });
        if (this.objectEqualityCheck(this.state.editValues, this.state.tempVal)) {
            Swal.fire({
                title: "Confirmation",
                text: 'Your changes will be lost if you don’t save it.',
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonText: "Don't Save",
                cancelButtonText: 'Back',
                icon: 'question',
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    if (this.state.dataToggle !== '') {
                        $("#" + this.state.dataToggle).hide();
                    }
                    this.setStateValues(name, editVal);
                    $("#" + name).toggle();
                } else {
                    return;
                }
            })
        } else {
            if (this.state.dataToggle !== '') {
                $("#" + this.state.dataToggle).hide();
            }
            this.setStateValues(name, editVal);
            $("#" + name).toggle();
        }
    }

    // Hide edit handler
    hideEdit = name => {
        if (this.objectEqualityCheck(this.state.editValues, this.state.tempVal)) {
            Swal.fire({
                title: "Confirmation",
                text: 'Your changes will be lost if you don’t save it.',
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonText: "Don't Save",
                cancelButtonText: 'Back',
                icon: 'question',
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    this.setState({
                        editTopOff: false,
                        editValues: {},
                        tempVal: {},
                        dataToggle: ''
                    })
                    $("#" + name).hide();
                } else {
                    return;
                }
            })
        } else {
            this.setState({
                editTopOff: false,
                editValues: {},
                tempVal: {},
                dataToggle: ''
            })
            $("#" + name).hide();
        }
    }

    // Set state values handler
    setStateValues = (name, values) => {
        if (name === 'TopElectedOfficial') {
            this.setState({
                dataToggle: name,
                editValues: values,
                tempVal: values,
                editTopOff: true
            })
            return;
        } else {
            this.setState({
                dataToggle: name,
                editValues: values,
                tempVal: values,
            })
            return;
        }
    }

    // Object equality check handler
    objectEqualityCheck = (obj1, obj2) => {
        let keys = Object.keys(obj1);
        let values = keys.map(key => obj1[key] !== obj2[key]);
        return values.includes(true);
    }

    // Email check handler
    handleEmailCheck = val => {
        this.setState({ emailLoading: 'loading' });
        emailBounceCheck({ email: val }).then(res => {
            if (res.data.code === 200) {
                this.setState({ emailToggle: true, emailMsg: res.data.data.message, emailLoading: '' });
                if (res.data.data.status !== "valid") {
                    this.setState({ emailErrBorder: true });
                } else {
                    this.setState({ emailErrBorder: false });
                }
            } else {
                this.setState({ emailLoading: '', emailErrBorder: false });
            }
        })
    }

    // Email input class handler
    getEmailInputClass = emailErrBorder => {
        return emailErrBorder ? "errInput" : "";
    }

    // Close modal handler
    closeModal = () => {
        this.setState({ emailToggle: false, emailMsg: '' })
    }

    // Bad email class handler
    badEmailClass = val => {
        return val === 1 ? 'errborder' : "";
    }

    // First and last name check handler
    firstLastCheck = (val, val1) => {
        if (val.First_Name !== val1.First_Name) {
            return true;
        }
        if (val.Last_Name !== val1.Last_Name) {
            return true;
        }
        return false;
    }



    render() {
    
    let btn_disabled=false;
    if(this.props.tableDetails.top_official==='No')
    {
    btn_disabled=true;
    }
    
    /*Disable functionality for Add a Law Enforcement Start*/
    let inonConfirmedValues=[];
    if('head_of_law_enforcement' in this.props.tableDetails)
    {
        inonConfirmedValues = this.props.tableDetails.head_of_law_enforcement.filter((item1) => {
            return item1.edit.Role === "Head of Law Enforcement";
        });
        
    }
    
    let iValues = [];
    if('government_confirmed' in this.props.tableDetails)
    {
        iValues = this.props.tableDetails.government_confirmed.filter((item) => {
            return item.Role === "Head of Law Enforcement";
        });
        
    }
    
    let disableCnt=Number(inonConfirmedValues.length)+Number(iValues.length);

    let btn_law_disabled=false;

    if(disableCnt>=2)
    {
        btn_law_disabled=true;
    }
    let onlyValues=[];
    if(disableCnt===1)
    {
        if('head_of_law_enforcement' in this.props.tableDetails)
        {
            onlyValues = this.props.tableDetails.head_of_law_enforcement.filter((item2) => {
                if(item2.o_Role === "Head of Law Enforcement")
                {
                    return item2.edit.Role_exists === "No";
                    
                } else {
                    return [];

                }
            });
        }
        if(Number(onlyValues.length)>=1){
             btn_law_disabled=true;
        }
        
    }
    /*Disable functionality for Add a Law Enforcement End*/
    
    let governmentTitleerror = "";
        return (
            <div id="accordion">
                <section className="dmstable">
                    <h2>Government Information</h2>
                    <div className="table-responsive">
                        <table className="table table-bordered fixedTableLayout">
                            <thead>
                                <tr>
                                    <th scope="col" width="11.11%">Actions</th>
                                    <th scope="col" width="11.11%">Government Designated  Name</th>
                                    <th scope="col" width="11.11%">Government Phone Number</th>
                                    <th scope="col" width="11.11%">Physical Street Address</th>
                                    <th scope="col" width="11.11%">City</th>
                                    <th scope="col" width="11.11%">Zip Code</th>
                                    <th scope="col" width="11.11%">Government Web Address</th>
                                    <th scope="col" width="11.11%">Updated</th>
                                    <th scope="col" width="11.11%">Taken From</th>
                                    <th scope="col" width="11.11%">Election Month</th>
                                </tr>
                            </thead>
                            <tbody>
                                {'government_info' in this.props.tableDetails ?
                                    <>
                                        <tr>
                                            <td className={this.props.ePlusStatus ? 'marked' : ''}>{this.props.ePlusStatus ? <a href="javascript:void(0);" onClick={() => this.props.toggleEplusStatus(false)}>
                                                Confirm&nbsp;|&nbsp;
                                            </a> : <span></span>}
                                                <a href="javascript:void(0);"
                                                    onClick={() => this.showEdit('GovtInfo', this.props.tableDetails.government_info.edit)} >
                                                    Edit
                                                </a>
                                            </td>
                                            <td className={'color' + this.props.tableDetails.government_info.Government_Designated_Name_notes}>{this.props.tableDetails.government_info.Government_Designated_Name}</td>
                                            <td className={'color' + this.props.tableDetails.government_info.Government_PhoneNumber_FMTD_notes}>{this.props.tableDetails.government_info.government_phone_number_FMTD}</td>
                                            <td className={'color' + this.props.tableDetails.government_info.Address_Street_Box_notes}>{this.props.tableDetails.government_info.Address_Street_Box}</td>
                                            <td className={'color' + this.props.tableDetails.government_info.City_notes}>{this.props.tableDetails.government_info.City}</td>
                                            <td className={'color' + this.props.tableDetails.government_info.Zip_Code_notes}>{this.props.tableDetails.government_info.Zip_Code}</td>
                                            <td className={'customOverflow color' + this.props.tableDetails.government_info.Government_Web_Address_notes + ' ' + this.props.tableDetails.government_info.Government_Web_Address_weburlcheck}><a href={this.props.tableDetails.government_info.Government_Web_Address} target="_blank">{this.props.tableDetails.government_info.Government_Web_Address}</a></td>
                                            <td className={'color' + this.props.tableDetails.government_info.Updated_website_notes}>{this.props.tableDetails.government_info.Updated_website}</td>
                                            <td className={'customOverflow color' + this.props.tableDetails.government_info.Taken_from_notes + " " + this.props.tableDetails.government_info.Taken_from_urlcheck}><a href={this.props.tableDetails.government_info.Taken_from} target="_blank">{this.props.tableDetails.government_info.Taken_from}</a></td>
                                            <td className={'color' + this.props.tableDetails.government_info.Electron_Month_notes}>{this.props.tableDetails.government_info.Electron_Month}</td>
                                        </tr>

                                        <tr className="collapse" id="GovtInfo" data-parent="#accordion">
                                            <td></td>
                                            <td colSpan="9" className="edit-table">
                                                <table className="table ">
                                                    <thead>
                                                        <tr>
                                                        <th scope="col" width="11.11%">Government Desingated Name</th>
                                                            <th scope="col" width="11.11%">Government Phone Number</th>
                                                            <th scope="col" width="11.11%">Physical Street Address</th>
                                                            <th scope="col" width="11.11%">City</th>
                                                            <th scope="col" width="11.11%">Zip Code</th>
                                                            <th scope="col" width="11.11%">Government Web Address</th>
                                                            <th scope="col" width="11.11%">Updated</th>
                                                            <th scope="col" width="11.11%">Taken From</th>
                                                            <th scope="col" width="11.11%">Election Month</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                        <td ><input type="text" value={this.state.editValues.Government_Designated_Name} onChange={e => this.setState({
                                                                editValues: {
                                                                    ...this.state.editValues,
                                                                    Government_Designated_Name: e.target.value.replace(/[^0-9a-zA-Z- \s']+/ig, "")
                                                                }
                                                            })} /></td>
                                                           
                                                            <td ><input type="text" value={this.state.editValues.Government_PhoneNumber_FMTD} onChange={e => this.setState({
                                                                editValues: {
                                                                    ...this.state.editValues,
                                                                    Government_PhoneNumber_FMTD: e.target.value
                                                                }
                                                            })} /></td>
                                                            <td ><input type="text" value={this.state.editValues.Address_Street_Box} onChange={e => this.setState({
                                                                editValues: {
                                                                    ...this.state.editValues,
                                                                    Address_Street_Box: e.target.value
                                                                }
                                                            })} /></td>
                                                            <td ><input type="text" value={this.state.editValues.City} onChange={e => this.setState({
                                                                editValues: {
                                                                    ...this.state.editValues,
                                                                    City: e.target.value
                                                                }
                                                            })} /></td>
                                                            <td ><input type="text" value={this.state.editValues.Zip_Code} onChange={e => this.setState({
                                                                editValues: {
                                                                    ...this.state.editValues,
                                                                    Zip_Code: e.target.value
                                                                }
                                                            })} /></td>
                                                            <td ><input type="text" value={this.state.editValues.Government_Web_Address} onChange={e => this.setState({
                                                                editValues: {
                                                                    ...this.state.editValues,
                                                                    Government_Web_Address: e.target.value
                                                                }
                                                            })} /></td>
                                                            <td>
                                                                <select value={this.state.editValues.Updated_website} onChange={e => this.setState({
                                                                    editValues: {
                                                                        ...this.state.editValues,
                                                                        Updated_website: e.target.value
                                                                    }
                                                                })}>
                                                                    <option>Yes</option>
                                                                    <option>No</option>
                                                                    <option>Not Sure</option>
                                                                </select>
                                                            </td>
                                                            <td ><input type="text" value={this.state.editValues.Taken_from} onChange={e => this.setState({
                                                                editValues: {
                                                                    ...this.state.editValues,
                                                                    Taken_from: e.target.value
                                                                }
                                                            })} /></td>
                                                            <td>
                                                                <select name="electionmonth" value={this.state.editValues.Electron_Month} onChange={e => this.setState({
                                                                    editValues: {
                                                                        ...this.state.editValues,
                                                                        Electron_Month: e.target.value
                                                                    }
                                                                })}><option value="0">Unknown</option><option value="1">January</option><option value="2">February</option><option value="3">March</option><option value="4">April</option><option value="5">May</option><option value="6">June</option><option value="7">July</option><option value="8">August</option><option value="9">September</option><option value="10">October</option><option value="11">November</option><option value="12">December</option></select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="8" style={{ textAlign: "center" }}>
                                                                <button type="button" className={"btn btn-primary " + this.state.loading} onClick={this.updateGovInfo}>Submit</button>&nbsp;
                                                                <button type="button" className="btn btn-secondary" onClick={() => this.hideEdit('GovtInfo')}>Cancel</button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr> </> : <tr></tr>}
                            </tbody>
                        </table>
                    </div>
                </section>

                {/*  Top Elected Official */}
                <section className="dmstable">
                    <br />
                    <div className="row">
                        <div className="col-md-12">
                            <h2>Top Elected Official</h2>
                        </div>
                    </div>
                    {/* <div className="row Member-check-gov">
                        {this.state.editTopOff ?
                            <div className="col-md-12">
                                <p> Member of Governing Board</p>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" id="inlineCheckbox1" value="option1" checked={this.state.editValues.Part_of_Governing_Board === "Yes"} onClick={() => this.handleRadioButton('Yes')} />
                                    <label className="form-check-label" htmlFor="inlineCheckbox1">Yes</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" id="inlineCheckbox2" value="option2" checked={this.state.editValues.Part_of_Governing_Board === "No"} onClick={() => this.handleRadioButton('No')} />
                                    <label className="form-check-label" htmlFor="inlineCheckbox2">No</label>
                                </div>
                            </div>
                            :
                            <div className="col-md-12">
                                <p> Member of Governing Board</p>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" id="inlineCheckbox1" value="option1" checked={this.props.tableDetails.Part_of_Governing_Board === "Yes"} readOnly />
                                    <label className="form-check-label" htmlFor="inlineCheckbox1"  >Yes</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" id="inlineCheckbox2" value="option2" checked={this.props.tableDetails.Part_of_Governing_Board === "No"} readOnly />
                                    <label className="form-check-label" htmlFor="inlineCheckbox2"  >No</label>
                                </div>
                            </div>
                        }
                    </div>  */}
                    <div className="table-responsive">
                        <table className="table table-bordered fixedTableLayout">
                            <thead>
                                <tr>
                                    <th scope="col" width="6.66%">Actions</th>
                                    <th scope="col" width="6.66%">Role</th>
                                    <th scope="col" width="6.66%">Government Title</th>
                                    <th scope="col" width="6.66%">First Name</th>
                                    <th scope="col" width="6.66%">Last Name</th>
                                    <th scope="col" width="6.66%">Email</th>
                                    <th scope="col" width="6.66%">Email Grade</th>
                                    <th scope="col" width="6.66%">Phone Number</th>
                                    <th scope="col" width="6.66%">Phone Ext</th>
                                    <th scope="col" width="6.66%">Mailing Street Box</th>
                                    <th scope="col" width="6.66%">Mailing Suite Number</th>
                                    <th scope="col" width="6.66%">Mailing City</th>
                                    <th scope="col" width="6.66%">Mailing State</th>
                                    <th scope="col" width="6.66%">Mailing Zip Code</th>
                                    <th scope="col" width="6.66%">Person ID</th>
                                </tr>
                            </thead>
                            <tbody>
                                {'top_elected_official' in this.props.tableDetails ?
                                    <>
                                        <tr>
                                            <td className={this.props.tableDetails.top_elected_official.mark + " " + this.props.tableDetails.top_elected_official.long_verified_mark + " " + this.props.tableDetails.top_elected_official.edited}><a href="javascript:void(0);" onClick={() => this.confirmGov(this.props.tableDetails.top_elected_official.edit.Official_ID)}>
                                                Confirm
                                            </a> |&nbsp;
                                                <a href="javascript:void(0);" onClick={() => this.showEdit('TopElectedOfficial', this.props.tableDetails.top_elected_official.edit)
                                                }>
                                                    Edit
                                                </a>
                                            </td>
                                            <td>{this.props.tableDetails.top_elected_official.o_Role}</td>
                                            <td className={'color' + this.props.tableDetails.top_elected_official.Government_Title_notes}> <span className={this.props.tableDetails.top_elected_official.Government_Title.includes('/') ? "errborder" : ""}> {this.props.tableDetails.top_elected_official.Government_Title} </span> </td>
                                            <td className={'color' + this.props.tableDetails.top_elected_official.First_Name_notes}><span className={this.props.tableDetails.top_elected_official.First_Name_vacant}>{this.props.tableDetails.top_elected_official.First_Name}</span></td>
                                            <td className={'color' + this.props.tableDetails.top_elected_official.Last_Name_notes}><span className={this.props.tableDetails.top_elected_official.Last_Name_vacant}>{this.props.tableDetails.top_elected_official.Last_Name}</span></td>
                                            <td className={'color' + this.props.tableDetails.top_elected_official.Email_Address_notes}><span className={this.props.tableDetails.top_elected_official.Email_Bounce_Color + " " + + " " + this.badEmailClass(this.props.tableDetails.top_elected_official.bad_email)}>{this.props.tableDetails.top_elected_official.Email_Address}</span></td>
                                            <td className={'color' + this.props.tableDetails.top_elected_official.Email_Grade_color}>{this.props.tableDetails.top_elected_official.Email_Grade}</td>
                                            <td className={'color' + this.props.tableDetails.top_elected_official.Phone_Number_notes}><span className={this.props.tableDetails.top_elected_official.Phone_Grade_color}>{this.props.tableDetails.top_elected_official.Phone_Number_FMTD}</span></td>
                                            <td className={'color' + this.props.tableDetails.top_elected_official.Phone_Number_Ext_notes}>{this.props.tableDetails.top_elected_official.Phone_Number_Ext}</td>
                                            <td className={'color' + this.props.tableDetails.top_elected_official.Mailing_Street_Box_notes}>
                                                <span className={this.props.tableDetails.top_elected_official.Mailing_Street_Box_Error}>
                                                    {this.props.tableDetails.top_elected_official.Mailing_Street_Box}
                                                </span>
                                            </td>

                                            <td className={'color' + this.props.tableDetails.top_elected_official.Mailing_Suite_Number_notes}>{this.props.tableDetails.top_elected_official.Mailing_Suite_Number}</td>
                                            <td className={'color' + this.props.tableDetails.top_elected_official.Mailing_City_notes}>
                                                <span className={this.props.tableDetails.top_elected_official.Mailing_City_Error}>
                                                    {this.props.tableDetails.top_elected_official.Mailing_City}
                                                </span>
                                            </td>
                                            <td className={'color' + this.props.tableDetails.top_elected_official.Mailing_State_notes}>{this.props.tableDetails.top_elected_official.Mailing_State}</td>
                                            <td className={'color' + this.props.tableDetails.top_elected_official.Mailng_Zip_Code_notes}>
                                                <span className={this.props.tableDetails.top_elected_official.Mailng_Zip_Code_Error}>
                                                    {this.props.tableDetails.top_elected_official.Mailng_Zip_Code}
                                                </span>
                                            </td>
                                            <td className={' text-break color' + this.props.tableDetails.top_elected_official.Person_ID_notes}>{this.props.tableDetails.top_elected_official.Person_ID}</td>
                                        </tr>
                                        <tr className="collapse" id="TopElectedOfficial" data-parent="#accordion">
                                            <td colSpan="2"></td>
                                            <td colSpan="13" className="edit-table">
                                                <table className="table ">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" width="7.69%">Government Title</th>
                                                            <th scope="col" width="7.69%">First Name</th>
                                                            <th scope="col" width="7.69%">Last Name</th>
                                                            <th scope="col" width="7.69%">Email</th>
                                                            <th scope="col" width="7.69%">Email Grade</th>
                                                            <th scope="col" width="7.69%">Phone Number</th>
                                                            <th scope="col" width="7.69%">Phone Ext</th>
                                                            <th scope="col" width="7.69%">Mailing Street Box</th>
                                                            <th scope="col" width="7.69%">Mailing Suite Number</th>
                                                            <th scope="col" width="7.69%">Mailing City</th>
                                                            <th scope="col" width="7.69%">Mailing State</th>
                                                            <th scope="col" width="7.69%">Mailing Zip Code</th>
                                                            <th scope="col" width="7.69%">Person ID</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td ><input type="text" list="gov_titles" value={this.state.editValues.Government_Title} onChange={e => this.setState({
                                                                editValues: {
                                                                    ...this.state.editValues,
                                                                    Government_Title: e.target.value
                                                                }
                                                            })} />
                                                                <datalist id="gov_titles">
                                                                    {this.getDataListValues('Government_Title').map((listValue, index) => {
                                                                        return (
                                                                            <option value={listValue}>{listValue}</option>
                                                                        )
                                                                    })}
                                                                </datalist>
                                                            </td>
                                                            <td><input type="text" list="first_names" name="first_name" id="first_name" value={this.state.editValues.First_Name} onChange={this.firstNameDataListChange} />
                                                                <datalist id="first_names">
                                                                    {this.getDataListValues('First_Name').map((listValue, index) => {
                                                                        return (
                                                                            <option value={listValue}>{listValue}</option>
                                                                        )
                                                                    })}
                                                                </datalist></td>
                                                            <td><input type="text" list="last_names" name="last_name" id="last_name" value={this.state.editValues.Last_Name} onChange={this.lastNameDataListChange} /> <datalist id="last_names">
                                                                {this.getDataListValues('Last_Name').map((listValue, index) => {
                                                                    return (
                                                                        <option value={listValue}>{listValue}</option>
                                                                    )
                                                                })}
                                                            </datalist></td>
                                                            <td><input className={this.getEmailInputClass(this.state.emailErrBorder)} type="text" list="emails" name="email" id="email" value={this.state.editValues.Email_Address} onChange={e => this.setState({
                                                                editValues: {
                                                                    ...this.state.editValues,
                                                                    Email_Address: e.target.value
                                                                }
                                                            })} />
                                                                <datalist id="emails">
                                                                    {this.getDataListValues('Email_Address').map((listValue, index) => {
                                                                        return (
                                                                            <option value={listValue}>{listValue}</option>
                                                                        )
                                                                    })}
                                                                </datalist></td>
                                                            <td><input type="text" value={this.state.editValues.Email_Grade} onChange={e => this.setState({
                                                                editValues: {
                                                                    ...this.state.editValues,
                                                                    Email_Grade: e.target.value
                                                                }
                                                            })} readOnly /></td>

                                                            <td ><input type="text" value={this.state.editValues.Phone_Number_FMTD} onChange={e => this.setState({
                                                                editValues: {
                                                                    ...this.state.editValues,
                                                                    Phone_Number_FMTD: e.target.value
                                                                }
                                                            })} /></td>
                                                            <td ><input type="text" value={this.state.editValues.Phone_Number_Ext} onChange={e => this.setState({
                                                                editValues: {
                                                                    ...this.state.editValues,
                                                                    Phone_Number_Ext: e.target.value
                                                                }
                                                            })} /></td>
                                                            <td ><input type="text" value={this.state.editValues.Mailing_Street_Box} onChange={e => this.setState({
                                                                editValues: {
                                                                    ...this.state.editValues,
                                                                    Mailing_Street_Box: e.target.value
                                                                }
                                                            })} /></td>
                                                            <td ><input type="text" value={this.state.editValues.Mailing_Suite_Number} onChange={e => this.setState({
                                                                editValues: {
                                                                    ...this.state.editValues,
                                                                    Mailing_Suite_Number: e.target.value
                                                                }
                                                            })} /></td>
                                                            <td ><input type="text" value={this.state.editValues.Mailing_City} onChange={e => this.setState({
                                                                editValues: {
                                                                    ...this.state.editValues,
                                                                    Mailing_City: e.target.value
                                                                }
                                                            })} /></td>
                                                            <td ><input type="text" value={this.state.editValues.Mailing_State} onChange={e => this.setState({
                                                                editValues: {
                                                                    ...this.state.editValues,
                                                                    Mailing_State: e.target.value
                                                                }
                                                            })} /></td>
                                                            <td ><input type="text" value={this.state.editValues.Mailng_Zip_Code} onChange={e => this.setState({
                                                                editValues: {
                                                                    ...this.state.editValues,
                                                                    Mailng_Zip_Code: e.target.value
                                                                }
                                                            })} /></td>
                                                            <td ><input type="text" value={this.state.editValues.Person_ID === null ? '' : this.state.editValues.Person_ID} readOnly /></td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="13" style={{ textAlign: "center" }}>

                                                                <div className="row">
                                                                    <div className="col-md-2"></div>
                                                                    <div className="col-md-3">
                                                                        <div className="same-person">
                                                                            <span>Is Same Person
                                                                                <label >
                                                                                    <input type="radio" id="official" value="option1" checked={this.state.editValues.primary_status === 1} readOnly />Yes</label>
                                                                                <label >
                                                                                    <input type="radio" id="official1" value="option2" checked={this.state.editValues.primary_status === 0} readOnly />No</label>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-2">
                                                                        <button type="button" className={"btn btn-secondary " + this.state.emailLoading}
                                                                            onClick={() => this.handleEmailCheck(this.state.editValues.Email_Address)} >Email Bounce Check</button>
                                                                    </div>
                                                                    <div className="col-md-3">
                                                                        <button type="button" className={"btn btn-primary " + this.state.loading} onClick={() =>this.updateVoiceAgent(this.props.tableDetails.top_elected_official.edit.Role)}>Submit</button>&nbsp;
                                                                        <button type="button" className="btn btn-secondary" onClick={() => this.hideEdit('TopElectedOfficial')}>Cancel</button>
                                                                    </div>
                                                                    <div className="col-md-2"></div>
                                                                </div>

                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr></> :
                                    <tr>
                                    </tr>}

                            </tbody>
                        </table>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <button type="button" className="btn green-btn" onClick={this.toggle}>Add New Governing Board Member</button>
                        </div>
                    </div>
                </section>

                {/*  Governing Board Member */}
                <section className="dmstable">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>Governing Board Members</h2>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="table table-bordered fixedTableLayout">
                            <thead>
                                <tr>
                                    <th scope="col" width="6.66%">Actions</th>
                                    <th scope="col" width="6.66%">Role</th>
                                    <th scope="col" width="6.66%">Government Title</th>
                                    <th scope="col" width="6.66%">First Name</th>
                                    <th scope="col" width="6.66%">Last Name</th>
                                    <th scope="col" width="6.66%">Email</th>
                                    <th scope="col" width="6.66%">Email Grade</th>
                                    <th scope="col" width="6.66%">Phone Number</th>
                                    <th scope="col" width="6.66%">Phone Ext</th>
                                    <th scope="col" width="6.66%">Mailing Street Box</th>
                                    <th scope="col" width="6.66%">Mailing Suite Number</th>
                                    <th scope="col" width="6.66%">Mailing City</th>
                                    <th scope="col" width="6.66%">Mailing State</th>
                                    <th scope="col" width="6.66%">Mailing Zip Code</th>
                                    <th scope="col" width="6.66%">Person ID</th>
                                </tr>
                            </thead>
                            <tbody>
                                {'governing_board_member' in this.props.tableDetails ?
                                    this.props.tableDetails.governing_board_member.map((listValue, index) => {

                                        return (
                                            <>
                                                <tr key={index}>
                                                    <td className={listValue.mark + " " + listValue.long_verified_mark + " " + listValue.edited}><a href="javascript:void(0);" onClick={() => this.confirmGov(listValue.edit.Official_ID)}>
                                                        Confirm
                                                    </a> |&nbsp;
                                                        <a href="javascript:void(0);"
                                                            onClick={() => this.showEdit("GoverningMembers" + index, listValue.edit)
                                                            }>
                                                            Edit
                                                        </a>
                                                    </td>
                                                    <td>{listValue.o_Role}</td>
                                                    <td className={'color ' + listValue.Government_Title_notes}><span className={listValue.Government_Title_error}> {listValue.Government_Title}</span> </td>
                                                    <td className={'color' + listValue.First_Name_notes}><span className={listValue.First_Name_vacant}>{listValue.First_Name}</span></td>
                                                    <td className={'color' + listValue.Last_Name_notes}><span className={listValue.Last_Name_vacant}>{listValue.Last_Name}</span></td>
                                                    <td className={'color' + listValue.Email_Address_notes}><span className={listValue.Email_Bounce_Color + " " + this.badEmailClass(listValue.bad_email)}>{listValue.Email_Address}</span></td>
                                                    <td className={listValue.Email_Grade_color}>{listValue.Email_Grade}</td>
                                                    <td className={'color' + listValue.Phone_Number_notes}><span className={listValue.Phone_Grade_color}>{listValue.Phone_Number_FMTD}</span></td>
                                                    <td className={'color' + listValue.Phone_Number_Ext_notes}>{listValue.Phone_Number_Ext}</td>
                                                    <td className={'color' + listValue.Mailing_Street_Box_notes}>
                                                        <span className={listValue.Mailing_Street_Box_Error}> {listValue.Mailing_Street_Box}
                                                        </span>
                                                    </td>
                                                    <td className={'color' + listValue.Mailing_Suite_Number_notes}>{listValue.Mailing_Suite_Number}</td>
                                                    <td className={'color' + listValue.Mailing_City_notes}>
                                                        <span className={listValue.Mailing_City_Error}> {listValue.Mailing_City}
                                                        </span>
                                                    </td>
                                                    <td className={'color' + listValue.Mailing_State_notes}>{listValue.Mailing_State}</td>
                                                    <td className={'color' + listValue.Mailng_Zip_Code_notes}>
                                                        <span className={listValue.Mailng_Zip_Code_Error}>
                                                            {listValue.Mailng_Zip_Code}
                                                        </span>

                                                    </td>
                                                    <td className={'color' + listValue.Person_ID_notes}>{listValue.Person_ID}</td>
                                                </tr>
                                                <tr className="collapse" id={"GoverningMembers" + index} data-parent="#accordion">
                                                    <td></td>
                                                    <td colSpan="14" className="edit-table">
                                                        <table className="table ">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" width="7.14%">Role</th>
                                                                    <th scope="col" width="7.14%">Government Title</th>
                                                                    <th scope="col" width="7.14%">First Name</th>
                                                                    <th scope="col" width="7.14%">Last Name</th>
                                                                    <th scope="col" width="7.14%">Email</th>
                                                                    <th scope="col" width="7.14%">Email Grade</th>
                                                                    <th scope="col" width="7.14%">Phone Number</th>
                                                                    <th scope="col" width="7.14%">Phone Ext</th>
                                                                    <th scope="col" width="7.14%">Mailing Street Box</th>
                                                                    <th scope="col" width="7.14%">Mailing Suite Number</th>
                                                                    <th scope="col" width="7.14%">Mailing City</th>
                                                                    <th scope="col" width="7.14%">Mailing State</th>
                                                                    <th scope="col" width="7.14%">Mailing Zip Code</th>
                                                                    <th scope="col" width="7.14%">Person ID</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <select onChange={this.roleChange} value={this.state.editValues.Role_exists}>
                                                                            <option value="Yes">Yes</option>
                                                                            <option value="No">No</option>
                                                                        </select>
                                                                    </td>
                                                                    <td ><input type="text" list="gov_titles" value={this.state.editValues.Government_Title} onChange={e => this.setState({
                                                                        editValues: {
                                                                            ...this.state.editValues,
                                                                            Government_Title: e.target.value
                                                                        }
                                                                    })} readOnly={this.state.editValues.Role_exists === "No"} />
                                                                        <datalist id="gov_titles">
                                                                            {this.getDataListValues('Government_Title').map((listValue, index) => {
                                                                                return (
                                                                                    <option value={listValue}>{listValue}</option>
                                                                                )
                                                                            })}
                                                                        </datalist>
                                                                    </td>
                                                                    <td><input type="text" list="first_names" name="first_name" id="first_name" value={this.state.editValues.First_Name} onChange={this.firstNameDataListChange} readOnly={this.state.editValues.Role_exists === "No"} />
                                                                        <datalist id="first_names">
                                                                            {this.getDataListValues('First_Name').map((listValue, index) => {
                                                                                return (
                                                                                    <option value={listValue}>{listValue}</option>
                                                                                )
                                                                            })}
                                                                        </datalist></td>
                                                                    <td><input type="text" list="last_names" name="last_name" id="last_name" value={this.state.editValues.Last_Name} onChange={this.lastNameDataListChange} readOnly={this.state.editValues.Role_exists === "No"} /> <datalist id="last_names">
                                                                        {this.getDataListValues('Last_Name').map((listValue, index) => {
                                                                            return (
                                                                                <option value={listValue}>{listValue}</option>
                                                                            )
                                                                        })}
                                                                    </datalist></td>
                                                                    <td><input className={this.getEmailInputClass(this.state.emailErrBorder)} type="text" list="emails" name="email" id="email" value={this.state.editValues.Email_Address} onChange={e => this.setState({
                                                                        editValues: {
                                                                            ...this.state.editValues,
                                                                            Email_Address: e.target.value
                                                                        }
                                                                    })} readOnly={this.state.editValues.Role_exists === "No"} />
                                                                        <datalist id="emails">
                                                                            {this.getDataListValues('Email_Address').map((listValue, index) => {
                                                                                return (
                                                                                    <option value={listValue}>{listValue}</option>
                                                                                )
                                                                            })}
                                                                        </datalist></td>
                                                                    <td><input type="text" value={this.state.editValues.Email_Grade} onChange={e => this.setState({
                                                                        editValues: {
                                                                            ...this.state.editValues,
                                                                            Email_Grade: e.target.value
                                                                        }
                                                                    })} readOnly /></td>

                                                                    <td ><input type="text" value={this.state.editValues.Phone_Number_FMTD} onChange={e => this.setState({
                                                                        editValues: {
                                                                            ...this.state.editValues,
                                                                            Phone_Number_FMTD: e.target.value
                                                                        }
                                                                    })} readOnly={this.state.editValues.Role_exists === "No"} /></td>
                                                                    <td ><input type="text" value={this.state.editValues.Phone_Number_Ext} onChange={e => this.setState({
                                                                        editValues: {
                                                                            ...this.state.editValues,
                                                                            Phone_Number_Ext: e.target.value
                                                                        }
                                                                    })} readOnly={this.state.editValues.Role_exists === "No"} /></td>
                                                                    <td ><input type="text" value={this.state.editValues.Mailing_Street_Box} onChange={e => this.setState({
                                                                        editValues: {
                                                                            ...this.state.editValues,
                                                                            Mailing_Street_Box: e.target.value
                                                                        }
                                                                    })} readOnly={this.state.editValues.Role_exists === "No"} /></td>
                                                                    <td ><input type="text" value={this.state.editValues.Mailing_Suite_Number} onChange={e => this.setState({
                                                                        editValues: {
                                                                            ...this.state.editValues,
                                                                            Mailing_Suite_Number: e.target.value
                                                                        }
                                                                    })} readOnly={this.state.editValues.Role_exists === "No"} /></td>
                                                                    <td ><input type="text" value={this.state.editValues.Mailing_City} onChange={e => this.setState({
                                                                        editValues: {
                                                                            ...this.state.editValues,
                                                                            Mailing_City: e.target.value
                                                                        }
                                                                    })} readOnly={this.state.editValues.Role_exists === "No"} /></td>
                                                                    <td ><input type="text" value={this.state.editValues.Mailing_State} onChange={e => this.setState({
                                                                        editValues: {
                                                                            ...this.state.editValues,
                                                                            Mailing_State: e.target.value
                                                                        }
                                                                    })} readOnly={this.state.editValues.Role_exists === "No"} /></td>
                                                                    <td ><input type="text" value={this.state.editValues.Mailng_Zip_Code} onChange={e => this.setState({
                                                                        editValues: {
                                                                            ...this.state.editValues,
                                                                            Mailng_Zip_Code: e.target.value
                                                                        }
                                                                    })} readOnly={this.state.editValues.Role_exists === "No"} /></td>
                                                                    <td ><input type="text" value={this.state.editValues.Person_ID === null ? '' : this.state.editValues.Person_ID} readOnly /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan="14" style={{ textAlign: "center" }}>

                                                                        <div className="row">
                                                                            <div className="col-md-2"></div>
                                                                            <div className="col-md-3">
                                                                                <div className="same-person">
                                                                                    <span>Is Same Person
                                                                                        <label >
                                                                                            <input type="radio" id="official3" checked={this.state.editValues.primary_status === 1} readOnly />Yes </label>
                                                                                        <label >
                                                                                            <input type="radio" id="official4" checked={this.state.editValues.primary_status === 0} readOnly />No</label>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-2">
                                                                                <button type="button" className={"btn btn-secondary " + this.state.emailLoading} onClick={() => this.handleEmailCheck(this.state.editValues.Email_Address)}>Email Bounce Check</button>
                                                                            </div>
                                                                            <div className="col-md-3">
                                                                                <button type="button" className={"btn btn-primary " + this.state.loading} onClick={() =>this.updateVoiceAgent(listValue.edit.Role)}>Submit</button>&nbsp;
                                                                                <button type="button" className="btn btn-secondary" onClick={() => this.hideEdit("GoverningMembers" + index)}>Cancel</button>
                                                                            </div>
                                                                            <div className="col-md-2"></div>
                                                                        </div>

                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </>
                                        );
                                    })
                                    : <tr>
                                    </tr>}


                            </tbody>
                        </table>
                    </div>
                </section >

                {/*  Top Appointed Executive */}
                <section className="dmstable">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>Top Appointed Executive</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                        
                            <button type="button" className="btn green-btn" onClick={this.toggleAddDeputy} disabled={btn_disabled}>Add Deputy to Top Appointed Executive</button>
                        
                        
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="table table-bordered fixedTableLayout">
                            <thead>
                                <tr>
                                    <th scope="col" width="6.66%">Actions</th>
                                    <th scope="col" width="6.66%">Role</th>
                                    <th scope="col" width="6.66%">Government Title</th>
                                    <th scope="col" width="6.66%">First Name</th>
                                    <th scope="col" width="6.66%">Last Name</th>
                                    <th scope="col" width="6.66%">Email</th>
                                    <th scope="col" width="6.66%">Email Grade</th>
                                    <th scope="col" width="6.66%">Phone Number</th>
                                    <th scope="col" width="6.66%">Phone Ext</th>
                                    <th scope="col" width="6.66%">Mailing Street Box</th>
                                    <th scope="col" width="6.66%">Mailing Suite Number</th>
                                    <th scope="col" width="6.66%">Mailing City</th>
                                    <th scope="col" width="6.66%">Mailing State</th>
                                    <th scope="col" width="6.66%">Mailing Zip Code</th>
                                    <th scope="col" width="6.66%">Person ID</th>
                                </tr>
                            </thead>
                            <tbody>
                                {'top_appointed_executive' in this.props.tableDetails ?
                                    this.props.tableDetails.top_appointed_executive.map((listValue, index) => {
                                        return (
                                            <>
                                                <tr key={index}>
                                                    <td className={listValue.mark + " " + listValue.long_verified_mark + " " + listValue.edited}><a
                                                        href="javascript:void(0);" onClick={() => this.confirmGov(listValue.edit.Official_ID)}>
                                                        Confirm
                                                    </a> |&nbsp;
                                                    <a href="javascript:void(0);" onClick={() => this.showEdit("TopOppointedOfficials" + index, listValue.edit)}>Edit</a>
                                                    </td>
                                                    <td>{listValue.o_Role}</td>
                                                    <td className={'color' + listValue.Government_Title_notes}>{listValue.Government_Title}</td>
                                                    <td className={'color' + listValue.First_Name_notes}><span
                                                        className={listValue.First_Name_vacant}>{listValue.First_Name}</span></td>
                                                    <td className={'color' + listValue.Last_Name_notes}><span
                                                        className={listValue.Last_Name_vacant}>{listValue.Last_Name}</span></td>
                                                    <td className={'color' + listValue.Email_Address_notes}><span className={listValue.Email_Bounce_Color + " "
                                                        + this.badEmailClass(listValue.bad_email)}>{listValue.Email_Address}</span></td>
                                                    <td className={listValue.Email_Grade_color}>{listValue.Email_Grade}</td>
                                                    <td className={'color' + listValue.Phone_Number_notes}><span
                                                        className={listValue.Phone_Grade_color}>{listValue.Phone_Number_FMTD}</span></td>
                                                    <td className={'color' + listValue.Phone_Number_Ext_notes}>{listValue.Phone_Number_Ext}</td>
                                                    <td className={'color' + listValue.Mailing_Street_Box_notes}>{listValue.Mailing_Street_Box}</td>
                                                    <td className={'color' + listValue.Mailing_Suite_Number_notes}>{listValue.Mailing_Suite_Number}</td>
                                                    <td className={'color' + listValue.Mailing_City_notes}>{listValue.Mailing_City}</td>
                                                    <td className={'color' + listValue.Mailing_State_notes}>{listValue.Mailing_State}</td>
                                                    <td className={'color' + listValue.Mailng_Zip_Code_notes}>{listValue.Mailng_Zip_Code}</td>
                                                    <td className={'color' + listValue.Person_ID_notes}>{listValue.Person_ID}</td>
                                                </tr>
                                                <tr className="collapse" id={"TopOppointedOfficials" + index} data-parent="#accordion">
                                                    <td></td>
                                                    <td colSpan="14" className="edit-table">
                                                        <table className="table ">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" width="7.14%">Role</th>
                                                                    <th scope="col" width="7.14%">Government Title</th>
                                                                    <th scope="col" width="7.14%">First Name</th>
                                                                    <th scope="col" width="7.14%">Last Name</th>
                                                                    <th scope="col" width="7.14%">Email</th>
                                                                    <th scope="col" width="7.14%">Email Grade</th>
                                                                    <th scope="col" width="7.14%">Phone Number</th>
                                                                    <th scope="col" width="7.14%">Phone Ext</th>
                                                                    <th scope="col" width="7.14%">Mailing Street Box</th>
                                                                    <th scope="col" width="7.14%">Mailing Suite Number</th>
                                                                    <th scope="col" width="7.14%">Mailing City</th>
                                                                    <th scope="col" width="7.14%">Mailing State</th>
                                                                    <th scope="col" width="7.14%">Mailing Zip Code</th>
                                                                    <th scope="col" width="7.14%">Person ID</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <select onChange={this.roleChangeTOP} value={this.state.editValues.Role_exists}>
                                                                            <option value="Yes">Yes</option>
                                                                            <option value="No">No</option>
                                                                        </select>
                                                                    </td>
                                                                    <td><input type="text" list="gov_titles" value={this.state.editValues.Government_Title}
                                                                        onChange={e => this.setState({
                                                                            editValues: {
                                                                                ...this.state.editValues,
                                                                                Government_Title: e.target.value
                                                                            }
                                                                        })} readOnly={this.state.editValues.Role_exists === "No"} />
                                                                        <datalist id="gov_titles">
                                                                            {this.getDataListValues('Government_Title').map((listValue, index) => {
                                                                                return (
                                                                                    <option value={listValue}>{listValue}</option>
                                                                                )
                                                                            })}
                                                                        </datalist>
                                                                    </td>
                                                                    <td><input type="text" list="first_names" name="first_name" id="first_name"
                                                                        value={this.state.editValues.First_Name} onChange={this.firstNameDataListChange}
                                                                        readOnly={this.state.editValues.Role_exists === "No"} />
                                                                        <datalist id="first_names">
                                                                            {this.getDataListValues('First_Name').map((listValue, index) => {
                                                                                return (
                                                                                    <option value={listValue}>{listValue}</option>
                                                                                )
                                                                            })}
                                                                        </datalist>
                                                                    </td>
                                                                    <td><input type="text" list="last_names" name="last_name" id="last_name"
                                                                        value={this.state.editValues.Last_Name} onChange={this.lastNameDataListChange}
                                                                        readOnly={this.state.editValues.Role_exists === "No"} /> <datalist id="last_names">
                                                                            {this.getDataListValues('Last_Name').map((listValue, index) => {
                                                                                return (
                                                                                    <option value={listValue}>{listValue}</option>
                                                                                )
                                                                            })}
                                                                        </datalist></td>
                                                                    <td><input className={this.getEmailInputClass(this.state.emailErrBorder)} type="text" list="emails"
                                                                        name="email" id="email" value={this.state.editValues.Email_Address} onChange={e =>
                                                                            this.setState({
                                                                                editValues: {
                                                                                    ...this.state.editValues,
                                                                                    Email_Address: e.target.value
                                                                                }
                                                                            })} readOnly={this.state.editValues.Role_exists === "No"} />
                                                                        <datalist id="emails">
                                                                            {this.getDataListValues('Email_Address').map((listValue, index) => {
                                                                                return (
                                                                                    <option value={listValue}>{listValue}</option>
                                                                                )
                                                                            })}
                                                                        </datalist>
                                                                    </td>
                                                                    <td><input type="text" value={this.state.editValues.Email_Grade} onChange={e => this.setState({
                                                                        editValues: {
                                                                            ...this.state.editValues,
                                                                            Email_Grade: e.target.value
                                                                        }
                                                                    })} readOnly /></td>

                                                                    <td><input type="text" value={this.state.editValues.Phone_Number_FMTD} onChange={e => this.setState({
                                                                        editValues: {
                                                                            ...this.state.editValues,
                                                                            Phone_Number_FMTD: e.target.value
                                                                        }
                                                                    })} readOnly={this.state.editValues.Role_exists === "No"} /></td>
                                                                    <td><input type="text" value={this.state.editValues.Phone_Number_Ext} onChange={e => this.setState({
                                                                        editValues: {
                                                                            ...this.state.editValues,
                                                                            Phone_Number_Ext: e.target.value
                                                                        }
                                                                    })} readOnly={this.state.editValues.Role_exists === "No"} /></td>
                                                                    <td><input type="text" value={this.state.editValues.Mailing_Street_Box} onChange={e =>
                                                                        this.setState({
                                                                            editValues: {
                                                                                ...this.state.editValues,
                                                                                Mailing_Street_Box: e.target.value
                                                                            }
                                                                        })} readOnly={this.state.editValues.Role_exists === "No"} /></td>
                                                                    <td><input type="text" value={this.state.editValues.Mailing_Suite_Number} onChange={e =>
                                                                        this.setState({
                                                                            editValues: {
                                                                                ...this.state.editValues,
                                                                                Mailing_Suite_Number: e.target.value
                                                                            }
                                                                        })} readOnly={this.state.editValues.Role_exists === "No"} /></td>
                                                                    <td><input type="text" value={this.state.editValues.Mailing_City} onChange={e => this.setState({
                                                                        editValues: {
                                                                            ...this.state.editValues,
                                                                            Mailing_City: e.target.value
                                                                        }
                                                                    })} readOnly={this.state.editValues.Role_exists === "No"} /></td>
                                                                    <td><input type="text" value={this.state.editValues.Mailing_State} onChange={e => this.setState({
                                                                        editValues: {
                                                                            ...this.state.editValues,
                                                                            Mailing_State: e.target.value
                                                                        }
                                                                    })} readOnly={this.state.editValues.Role_exists === "No"} /></td>
                                                                    <td><input type="text" value={this.state.editValues.Mailng_Zip_Code} onChange={e => this.setState({
                                                                        editValues: {
                                                                            ...this.state.editValues,
                                                                            Mailng_Zip_Code: e.target.value
                                                                        }
                                                                    })} readOnly={this.state.editValues.Role_exists === "No"} /></td>
                                                                    <td><input type="text" value={this.state.editValues.Person_ID === null ? '' :
                                                                        this.state.editValues.Person_ID} readOnly /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan="14" style={{ textAlign: "center" }}>

                                                                        <div className="row">
                                                                            <div className="col-md-2"></div>
                                                                            <div className="col-md-3">
                                                                                <div className="same-person">
                                                                                    <span>Is Same Person
                                                                                        <label>
                                                                                            <input type="radio" id="official3" checked={this.state.editValues.primary_status === 1}
                                                                                                readOnly />Yes </label>
                                                                                        <label>
                                                                                            <input type="radio" id="official4" checked={this.state.editValues.primary_status === 0}
                                                                                                readOnly />No</label>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-2">
                                                                                <button type="button" className={"btn btn-secondary " + this.state.emailLoading} onClick={() => this.handleEmailCheck(this.state.editValues.Email_Address)}>Email Bounce Check</button>
                                                                            </div>
                                                                            <div className=" col-md-3">
                                                                                <button type="button" className={"btn btn-primary " + this.state.loading} onClick={() =>this.updateVoiceAgent(listValue.edit.Role)}>Submit</button>&nbsp;
                                                                                <button type=" button"
                                                                                    className="btn btn-secondary" onClick={() => this.hideEdit("TopOppointedOfficials" +
                                                                                        index)}>Cancel</button>
                                                                            </div>
                                                                            <div className="col-md-2"></div>
                                                                        </div>

                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </>
                                        );
                                    })
                                    : <tr>
                                    </tr>}


                            </tbody>
                        </table>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <button type="button" className="btn green-btn" onClick={this.toggleAddEnforcement} disabled={btn_law_disabled}>Add a Law Enforcement Official</button>
                        </div>
                    </div>
                </section>

                {/*  Head of Law Enforcement */}
                <section className="dmstable">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>Law Enforcement</h2>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="table table-bordered fixedTableLayout">
                            <thead>
                                <tr>
                                    <th scope="col" width="6.66%">Actions</th>
                                    <th scope="col" width="6.66%">Role</th>
                                    <th scope="col" width="6.66%">Government Title</th>
                                    <th scope="col" width="6.66%">First Name</th>
                                    <th scope="col" width="6.66%">Last Name</th>
                                    <th scope="col" width="6.66%">Email</th>
                                    <th scope="col" width="6.66%">Email Grade</th>
                                    <th scope="col" width="6.66%">Phone Number</th>
                                    <th scope="col" width="6.66%">Phone Ext</th>
                                    <th scope="col" width="6.66%">Mailing Street Box</th>
                                    <th scope="col" width="6.66%">Mailing Suite Number</th>
                                    <th scope="col" width="6.66%">Mailing City</th>
                                    <th scope="col" width="6.66%">Mailing State</th>
                                    <th scope="col" width="6.66%">Mailing Zip Code</th>
                                    <th scope="col" width="6.66%">Person ID</th>
                                </tr>
                            </thead>
                            <tbody>
                                {'head_of_law_enforcement' in this.props.tableDetails ?
                                    this.props.tableDetails.head_of_law_enforcement.map((listValue, index) => {

                                        return (
                                            <>
                                                <tr key={index}>
                                                    <td className={listValue.mark + " " + listValue.long_verified_mark + " " + listValue.edited}><a href="javascript:void(0);" onClick={() => this.confirmGov(listValue.edit.Official_ID)}>
                                                        Confirm
                                                    </a> |&nbsp;
                                                        <a href="javascript:void(0);"
                                                            onClick={() => this.showEdit("HeadOfLawEnforcement" + index, listValue.edit)
                                                            }>
                                                            Edit
                                                        </a>
                                                    </td>
                                                    <td>{listValue.o_Role}</td>
                                                    <td className={'color ' + listValue.Government_Title_notes}><span className={listValue.Government_Title_error}> {listValue.Government_Title}</span> </td>
                                                    <td className={'color' + listValue.First_Name_notes}><span className={listValue.First_Name_vacant}>{listValue.First_Name}</span></td>
                                                    <td className={'color' + listValue.Last_Name_notes}><span className={listValue.Last_Name_vacant}>{listValue.Last_Name}</span></td>
                                                    <td className={'color' + listValue.Email_Address_notes}><span className={listValue.Email_Bounce_Color + " " + this.badEmailClass(listValue.bad_email)}>{listValue.Email_Address}</span></td>
                                                    <td className={listValue.Email_Grade_color}>{listValue.Email_Grade}</td>
                                                    <td className={'color' + listValue.Phone_Number_notes}><span className={listValue.Phone_Grade_color}>{listValue.Phone_Number_FMTD}</span></td>
                                                    <td className={'color' + listValue.Phone_Number_Ext_notes}>{listValue.Phone_Number_Ext}</td>
                                                    <td className={'color' + listValue.Mailing_Street_Box_notes}>
                                                        <span className={listValue.Mailing_Street_Box_Error}> {listValue.Mailing_Street_Box}
                                                        </span>
                                                    </td>
                                                    <td className={'color' + listValue.Mailing_Suite_Number_notes}>{listValue.Mailing_Suite_Number}</td>
                                                    <td className={'color' + listValue.Mailing_City_notes}>
                                                        <span className={listValue.Mailing_City_Error}> {listValue.Mailing_City}
                                                        </span>
                                                    </td>
                                                    <td className={'color' + listValue.Mailing_State_notes}>{listValue.Mailing_State}</td>
                                                    <td className={'color' + listValue.Mailng_Zip_Code_notes}>
                                                        <span className={listValue.Mailng_Zip_Code_Error}>
                                                            {listValue.Mailng_Zip_Code}
                                                        </span>

                                                    </td>
                                                    <td className={'color' + listValue.Person_ID_notes}>{listValue.Person_ID}</td>
                                                </tr>
                                                <tr className="collapse" id={"HeadOfLawEnforcement" + index} data-parent="#accordion">
                                                    <td></td>
                                                    <td colSpan="14" className="edit-table">
                                                        <table className="table ">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" width="7.14%">Role</th>
                                                                    <th scope="col" width="7.14%">Government Title</th>
                                                                    <th scope="col" width="7.14%">First Name</th>
                                                                    <th scope="col" width="7.14%">Last Name</th>
                                                                    <th scope="col" width="7.14%">Email</th>
                                                                    <th scope="col" width="7.14%">Email Grade</th>
                                                                    <th scope="col" width="7.14%">Phone Number</th>
                                                                    <th scope="col" width="7.14%">Phone Ext</th>
                                                                    <th scope="col" width="7.14%">Mailing Street Box</th>
                                                                    <th scope="col" width="7.14%">Mailing Suite Number</th>
                                                                    <th scope="col" width="7.14%">Mailing City</th>
                                                                    <th scope="col" width="7.14%">Mailing State</th>
                                                                    <th scope="col" width="7.14%">Mailing Zip Code</th>
                                                                    <th scope="col" width="7.14%">Person ID</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <select onChange={this.roleChange} value={this.state.editValues.Role_exists}
                                                                            >
                                                                            <option value="Yes">Yes</option>
                                                                            <option value="No">No</option>
                                                                        </select>
                                                                    </td>
                                                                    <td ><input type="text" list="gov_titles" value={this.state.editValues.Government_Title} onChange={e => this.setState({
                                                                        editValues: {
                                                                            ...this.state.editValues,
                                                                            Government_Title: e.target.value
                                                                        }
                                                                    })} readOnly={this.state.editValues.Role_exists === "No"} />
                                                                        <datalist id="gov_titles">
                                                                            {this.getDataListValues('Government_Title').map((listValue, index) => {
                                                                                return (
                                                                                    <option value={listValue}>{listValue}</option>
                                                                                )
                                                                            })}
                                                                        </datalist>
                                                                    </td>
                                                                    <td><input type="text" list="first_names" name="first_name" id="first_name" value={this.state.editValues.First_Name} onChange={this.firstNameDataListChange} readOnly={this.state.editValues.Role_exists === "No"} />
                                                                        <datalist id="first_names">
                                                                            {this.getDataListValues('First_Name').map((listValue, index) => {
                                                                                return (
                                                                                    <option value={listValue}>{listValue}</option>
                                                                                )
                                                                            })}
                                                                        </datalist></td>
                                                                    <td><input type="text" list="last_names" name="last_name" id="last_name" value={this.state.editValues.Last_Name} onChange={this.lastNameDataListChange} readOnly={this.state.editValues.Role_exists === "No"} /> <datalist id="last_names">
                                                                        {this.getDataListValues('Last_Name').map((listValue, index) => {
                                                                            return (
                                                                                <option value={listValue}>{listValue}</option>
                                                                            )
                                                                        })}
                                                                    </datalist></td>
                                                                    <td><input className={this.getEmailInputClass(this.state.emailErrBorder)} type="text" list="emails" name="email" id="email" value={this.state.editValues.Email_Address} onChange={e => this.setState({
                                                                        editValues: {
                                                                            ...this.state.editValues,
                                                                            Email_Address: e.target.value
                                                                        }
                                                                    })} readOnly={this.state.editValues.Role_exists === "No"} />
                                                                        <datalist id="emails">
                                                                            {this.getDataListValues('Email_Address').map((listValue, index) => {
                                                                                return (
                                                                                    <option value={listValue}>{listValue}</option>
                                                                                )
                                                                            })}
                                                                        </datalist></td>
                                                                    <td><input type="text" value={this.state.editValues.Email_Grade} onChange={e => this.setState({
                                                                        editValues: {
                                                                            ...this.state.editValues,
                                                                            Email_Grade: e.target.value
                                                                        }
                                                                    })} readOnly /></td>

                                                                    <td ><input type="text" value={this.state.editValues.Phone_Number_FMTD} onChange={e => this.setState({
                                                                        editValues: {
                                                                            ...this.state.editValues,
                                                                            Phone_Number_FMTD: e.target.value
                                                                        }
                                                                    })} readOnly={this.state.editValues.Role_exists === "No"} /></td>
                                                                    <td ><input type="text" value={this.state.editValues.Phone_Number_Ext} onChange={e => this.setState({
                                                                        editValues: {
                                                                            ...this.state.editValues,
                                                                            Phone_Number_Ext: e.target.value
                                                                        }
                                                                    })} readOnly={this.state.editValues.Role_exists === "No"} /></td>
                                                                    <td ><input type="text" value={this.state.editValues.Mailing_Street_Box} onChange={e => this.setState({
                                                                        editValues: {
                                                                            ...this.state.editValues,
                                                                            Mailing_Street_Box: e.target.value
                                                                        }
                                                                    })} readOnly={this.state.editValues.Role_exists === "No"} /></td>
                                                                    <td ><input type="text" value={this.state.editValues.Mailing_Suite_Number} onChange={e => this.setState({
                                                                        editValues: {
                                                                            ...this.state.editValues,
                                                                            Mailing_Suite_Number: e.target.value
                                                                        }
                                                                    })} readOnly={this.state.editValues.Role_exists === "No"} /></td>
                                                                    <td ><input type="text" value={this.state.editValues.Mailing_City} onChange={e => this.setState({
                                                                        editValues: {
                                                                            ...this.state.editValues,
                                                                            Mailing_City: e.target.value
                                                                        }
                                                                    })} readOnly={this.state.editValues.Role_exists === "No"} /></td>
                                                                    <td ><input type="text" value={this.state.editValues.Mailing_State} onChange={e => this.setState({
                                                                        editValues: {
                                                                            ...this.state.editValues,
                                                                            Mailing_State: e.target.value
                                                                        }
                                                                    })} readOnly={this.state.editValues.Role_exists === "No"} /></td>
                                                                    <td ><input type="text" value={this.state.editValues.Mailng_Zip_Code} onChange={e => this.setState({
                                                                        editValues: {
                                                                            ...this.state.editValues,
                                                                            Mailng_Zip_Code: e.target.value
                                                                        }
                                                                    })} readOnly={this.state.editValues.Role_exists === "No"} /></td>
                                                                    <td ><input type="text" value={this.state.editValues.Person_ID === null ? '' : this.state.editValues.Person_ID} readOnly /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan="14" style={{ textAlign: "center" }}>

                                                                        <div className="row">
                                                                            <div className="col-md-2"></div>
                                                                            <div className="col-md-3">
                                                                                <div className="same-person">
                                                                                    <span>Is Same Person
                                                                                        <label >
                                                                                            <input type="radio" id="official3" checked={this.state.editValues.primary_status === 1} readOnly />Yes </label>
                                                                                        <label >
                                                                                            <input type="radio" id="official4" checked={this.state.editValues.primary_status === 0} readOnly />No</label>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-2">
                                                                                <button type="button" className={"btn btn-secondary " + this.state.emailLoading} onClick={() => this.handleEmailCheck(this.state.editValues.Email_Address)}>Email Bounce Check</button>
                                                                            </div>
                                                                            <div className="col-md-3">
                                                                                <button type="button" className={"btn btn-primary " + this.state.loading} onClick={() =>this.updateVoiceAgent(listValue.edit.Role)}>Submit</button>&nbsp;
                                                                                <button type="button" className="btn btn-secondary" onClick={() => this.hideEdit("HeadOfLawEnforcement" + index)}>Cancel</button>
                                                                            </div>
                                                                            <div className="col-md-2"></div>
                                                                        </div>

                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </>
                                        );
                                    })
                                    : <tr>
                                    </tr>}


                            </tbody>
                        </table>
                    </div>
                </section >

                {/*  Other Government Officials */}
                <section className="dmstable">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>Other Government Officials</h2>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="table table-bordered fixedTableLayout">
                            <thead>
                                <tr>
                                    <th scope="col" width="6.66%">Actions</th>
                                    <th scope="col" width="6.66%">Role</th>
                                    <th scope="col" width="6.66%">Government Title</th>
                                    <th scope="col" width="6.66%">First Name</th>
                                    <th scope="col" width="6.66%">Last Name</th>
                                    <th scope="col" width="6.66%">Email</th>
                                    <th scope="col" width="6.66%">Email Grade</th>
                                    <th scope="col" width="6.66%">Phone Number</th>
                                    <th scope="col" width="6.66%">Phone Ext</th>
                                    <th scope="col" width="6.66%">Mailing Street Box</th>
                                    <th scope="col" width="6.66%">Mailing Suite Number</th>
                                    <th scope="col" width="6.66%">Mailing City</th>
                                    <th scope="col" width="6.66%">Mailing State</th>
                                    <th scope="col" width="6.66%">Mailing Zip Code</th>
                                    <th scope="col" width="6.66%">Person ID</th>
                                </tr>
                            </thead>
                            <tbody>
                                {'other_government_officials' in this.props.tableDetails ?
                                    this.props.tableDetails.other_government_officials.map((listValue, index) => {
                                        return (
                                            <>
                                                <tr key={index}>
                                                    <td className={listValue.mark + " " + listValue.long_verified_mark + " " + listValue.edited}><a href="javascript:void(0);" onClick={() => this.confirmGov(listValue.edit.Official_ID)}>
                                                        Confirm
                                                    </a> |&nbsp;
                                                        <a href="javascript:void(0);" aria-controls={"OtherGovOfficial" + index}
                                                            onClick={() => this.showEdit("OtherGovOfficial" + index, listValue.edit)
                                                            }>
                                                            Edit
                                                        </a>
                                                    </td>
                                                    <td>{listValue.o_Role}</td>
                                                    <td className={'color' + listValue.Government_Title_notes} >
                                                        <span className={listValue.Government_Title_error}> {listValue.Government_Title}</span>
                                                    </td>
                                                    <td className={'color' + listValue.First_Name_notes}><span className={listValue.First_Name_vacant}>{listValue.First_Name}</span></td>
                                                    <td className={'color' + listValue.Last_Name_notes}><span className={listValue.Last_Name_vacant}>{listValue.Last_Name}</span></td>
                                                    <td className={'color' + listValue.Email_Address_notes}><span className={listValue.Email_Bounce_Color + " " + this.badEmailClass(listValue.bad_email)}>{listValue.Email_Address}</span></td>
                                                    <td className={listValue.Email_Grade_color}>{listValue.Email_Grade}</td>
                                                    <td className={'color' + listValue.Phone_Number_notes}><span className={listValue.Phone_Grade_color}>{listValue.Phone_Number_FMTD}</span></td>
                                                    <td className={'color' + listValue.Phone_Number_Ext_notes}>{listValue.Phone_Number_Ext}</td>
                                                    <td className={'color' + listValue.Mailing_Street_Box_notes}><span className={listValue.Mailing_Street_Box_Error}> {listValue.Mailing_Street_Box}</span>
                                                    </td>
                                                    <td className={'color' + listValue.Mailing_Suite_Number_notes}>{listValue.Mailing_Suite_Number}</td>
                                                    <td className={'color' + listValue.Mailing_City_notes}><span className={listValue.Mailing_City_Error}> {listValue.Mailing_City}</span> </td>
                                                    <td className={'color' + listValue.Mailing_State_notes}>{listValue.Mailing_State}</td>
                                                    <td className={'color' + listValue.Mailng_Zip_Code_notes}>
                                                        <span className={listValue.Mailng_Zip_Code_Error}>{listValue.Mailng_Zip_Code}
                                                        </span>
                                                    </td>
                                                    <td className={'color' + listValue.Person_ID_notes}>{listValue.Person_ID}</td>
                                                </tr>
                                                <tr className="collapse" id={"OtherGovOfficial" + index} data-parent="#accordion">
                                                    <td></td>
                                                    <td colSpan="14" className="edit-table">
                                                        <table className="table ">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" width="7.14%">Role</th>
                                                                    <th scope="col" width="7.14%">Government Title</th>
                                                                    <th scope="col" width="7.14%">First Name</th>
                                                                    <th scope="col" width="7.14%">Last Name</th>
                                                                    <th scope="col" width="7.14%">Email</th>
                                                                    <th scope="col" width="7.14%">Email Grade</th>
                                                                    <th scope="col" width="7.14%">Phone Number</th>
                                                                    <th scope="col" width="7.14%">Phone Ext</th>
                                                                    <th scope="col" width="7.14%">Mailing Street Box</th>
                                                                    <th scope="col" width="7.14%">Mailing Suite Number</th>
                                                                    <th scope="col" width="7.14%">Mailing City</th>
                                                                    <th scope="col" width="7.14%">Mailing State</th>
                                                                    <th scope="col" width="7.14%">Mailing Zip Code</th>
                                                                    <th scope="col" width="7.14%">Person ID</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <select onChange={this.roleChange} value={this.state.editValues.Role_exists}>
                                                                            <option value="Yes">Yes</option>
                                                                            <option value="No">No</option>
                                                                        </select>
                                                                    </td>
                                                                    <td ><input type="text" list="gov_titles" value={this.state.editValues.Government_Title} onChange={e => this.setState({
                                                                        editValues: {
                                                                            ...this.state.editValues,
                                                                            Government_Title: e.target.value
                                                                        }
                                                                    })} readOnly={this.state.editValues.Role_exists === "No"} />
                                                                        <datalist id="gov_titles">
                                                                            {this.getDataListValues('Government_Title').map((listValue, index) => {
                                                                                return (
                                                                                    <option value={listValue}>{listValue}</option>
                                                                                )
                                                                            })}
                                                                        </datalist>
                                                                    </td>
                                                                    <td><input type="text" list="first_names" name="first_name" id="first_name" value={this.state.editValues.First_Name} onChange={this.firstNameDataListChange} readOnly={this.state.editValues.Role_exists === "No"} />
                                                                        <datalist id="first_names">
                                                                            {this.getDataListValues('First_Name').map((listValue, index) => {
                                                                                return (
                                                                                    <option value={listValue}>{listValue}</option>
                                                                                )
                                                                            })}
                                                                        </datalist></td>
                                                                    <td><input type="text" list="last_names" name="last_name" id="last_name" value={this.state.editValues.Last_Name} onChange={this.lastNameDataListChange} readOnly={this.state.editValues.Role_exists === "No"} /> <datalist id="last_names">
                                                                        {this.getDataListValues('Last_Name').map((listValue, index) => {
                                                                            return (
                                                                                <option value={listValue}>{listValue}</option>
                                                                            )
                                                                        })}
                                                                    </datalist></td>
                                                                    <td><input className={this.getEmailInputClass(this.state.emailErrBorder)} type="text" list="emails" name="email" id="email" value={this.state.editValues.Email_Address} onChange={e => this.setState({
                                                                        editValues: {
                                                                            ...this.state.editValues,
                                                                            Email_Address: e.target.value
                                                                        }
                                                                    })} readOnly={this.state.editValues.Role_exists === "No"} />
                                                                        <datalist id="emails">
                                                                            {this.getDataListValues('Email_Address').map((listValue, index) => {
                                                                                return (
                                                                                    <option value={listValue}>{listValue}</option>
                                                                                )
                                                                            })}
                                                                        </datalist></td>
                                                                    <td><input type="text" value={this.state.editValues.Email_Grade} onChange={e => this.setState({
                                                                        editValues: {
                                                                            ...this.state.editValues,
                                                                            Email_Grade: e.target.value
                                                                        }
                                                                    })} readOnly /></td>

                                                                    <td ><input type="text" value={this.state.editValues.Phone_Number_FMTD} onChange={e => this.setState({
                                                                        editValues: {
                                                                            ...this.state.editValues,
                                                                            Phone_Number_FMTD: e.target.value
                                                                        }
                                                                    })} readOnly={this.state.editValues.Role_exists === "No"} /></td>
                                                                    <td ><input type="text" value={this.state.editValues.Phone_Number_Ext} onChange={e => this.setState({
                                                                        editValues: {
                                                                            ...this.state.editValues,
                                                                            Phone_Number_Ext: e.target.value
                                                                        }
                                                                    })} readOnly={this.state.editValues.Role_exists === "No"} /></td>
                                                                    <td ><input type="text" value={this.state.editValues.Mailing_Street_Box} onChange={e => this.setState({
                                                                        editValues: {
                                                                            ...this.state.editValues,
                                                                            Mailing_Street_Box: e.target.value
                                                                        }
                                                                    })} readOnly={this.state.editValues.Role_exists === "No"} /></td>
                                                                    <td ><input type="text" value={this.state.editValues.Mailing_Suite_Number} onChange={e => this.setState({
                                                                        editValues: {
                                                                            ...this.state.editValues,
                                                                            Mailing_Suite_Number: e.target.value
                                                                        }
                                                                    })} readOnly={this.state.editValues.Role_exists === "No"} /></td>
                                                                    <td ><input type="text" value={this.state.editValues.Mailing_City} onChange={e => this.setState({
                                                                        editValues: {
                                                                            ...this.state.editValues,
                                                                            Mailing_City: e.target.value
                                                                        }
                                                                    })} readOnly={this.state.editValues.Role_exists === "No"} /></td>
                                                                    <td ><input type="text" value={this.state.editValues.Mailing_State} onChange={e => this.setState({
                                                                        editValues: {
                                                                            ...this.state.editValues,
                                                                            Mailing_State: e.target.value
                                                                        }
                                                                    })} readOnly={this.state.editValues.Role_exists === "No"} /></td>
                                                                    <td ><input type="text" value={this.state.editValues.Mailng_Zip_Code} onChange={e => this.setState({
                                                                        editValues: {
                                                                            ...this.state.editValues,
                                                                            Mailng_Zip_Code: e.target.value
                                                                        }
                                                                    })} readOnly={this.state.editValues.Role_exists === "No"} /></td>
                                                                    <td ><input type="text" value={this.state.editValues.Person_ID === null ? '' : this.state.editValues.Person_ID} readOnly /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan="14" style={{ textAlign: "center" }}>

                                                                        <div className="row">
                                                                            <div className="col-md-2"></div>
                                                                            <div className="col-md-3">
                                                                                <div className="same-person">
                                                                                    <span>Is Same Person
                                                                                        <label >
                                                                                            <input type="radio" id="official5" checked={this.state.editValues.primary_status === 1} readOnly />Yes </label>
                                                                                        <label >
                                                                                            <input type="radio" id="official6" checked={this.state.editValues.primary_status === 0} readOnly />No</label>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-2">
                                                                                <button type="button" className={"btn btn-secondary " + this.state.emailLoading} onClick={() => this.handleEmailCheck(this.state.editValues.Email_Address)}>Email Bounce Check</button>
                                                                            </div>
                                                                            <div className="col-md-3">
                                                                                <button type="button" className={"btn btn-primary " + this.state.loading} onClick={() =>this.updateVoiceAgent(listValue.edit.Role)}>Submit</button>&nbsp;
                                                                                <button type="button" className="btn btn-secondary" onClick={() => this.hideEdit("OtherGovOfficial" + index)}>Cancel</button>
                                                                            </div>
                                                                            <div className="col-md-2"></div>
                                                                        </div>

                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </>
                                        );
                                    })
                                    : <tr>
                                    </tr>}

                            </tbody>
                        </table>
                    </div>
                </section>

                {/*  Officials Already Confirmed */}
                <section className="dmstable">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>Government Officials Already CONFIRMED</h2>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="table table-bordered ">
                            <thead>
                                <tr>
                                    <th scope="col" width="6.66%">Actions</th>
                                    <th scope="col" width="6.66%">Role</th>
                                    <th scope="col" width="6.66%">Role Exists</th>
                                    <th scope="col" width="6.66%">Government Title</th>
                                    <th scope="col" width="6.66%">First Name</th>
                                    <th scope="col" width="6.66%">Last Name</th>
                                    <th scope="col" width="6.66%">Email</th>
                                    <th scope="col" width="6.66%">Email Grade</th>
                                    <th scope="col" width="6.66%">Phone Number</th>
                                    <th scope="col" width="6.66%">Phone Ext</th>
                                    <th scope="col" width="6.66%">Mailing Street Box</th>
                                    <th scope="col" width="6.66%">Mailing Suite Number</th>
                                    <th scope="col" width="6.66%">Mailing City</th>
                                    <th scope="col" width="6.66%">Mailing State</th>
                                    <th scope="col" width="6.66%">Mailing Zip Code</th>
                                    {/* <th scope="col" width="6.66%">Governing Board?</th> */}
                                    <th scope="col" width="6.66%">Official ID</th>
                                    <th scope="col" width="6.66%">Person ID</th>
                                </tr>
                            </thead>
                            <tbody>
                                {'government_confirmed' in this.props.tableDetails ?
                                    this.props.tableDetails.government_confirmed.map((listValue, index) => {
                                        return (
                                            <>
                                                <tr key={index}>
                                                    <td className={listValue.mark + " " + listValue.long_verified_mark + " " + listValue.edited}><a href="javascript:void(0)" onClick={() => this.unConfirmGov(listValue.Official_ID)}>
                                                        Unconfirm
                                                    </a>
                                                    </td>
                                                    <td>{listValue.Role}</td>
                                                    <td className={'color' + listValue.Role_exists_otes}>{listValue.Role_exists}</td>
                                                    <td className={'color' + listValue.Government_Title_notes}><span className={listValue.Government_Title_error}> {listValue.Government_Title}</span> </td>
                                                    <td className={'color' + listValue.First_Name_notes}><span className={listValue.First_Name_vacant}>{listValue.First_Name}</span></td>
                                                    <td className={'color' + listValue.Last_Name_notes}><span className={listValue.Last_Name_vacant}>{listValue.Last_Name}</span></td>
                                                    <td className={'color' + listValue.Email_Address_notes}><span className={listValue.Email_Bounce_Color + " " + this.badEmailClass(listValue.bad_email)}>{listValue.Email_Address}</span></td>
                                                    <td className={listValue.Email_Grade_color}>{listValue.Email_Grade}</td>
                                                    <td className={'color' + listValue.Phone_Number_notes}><span className={listValue.Phone_Grade_color}>{listValue.Phone_Number_FMTD}</span></td>
                                                    <td className={'color' + listValue.Phone_Number_Ext_notes}>{listValue.Phone_Number_Ext}</td>
                                                    <td className={'color' + listValue.Mailing_Street_Box_notes}>{listValue.Mailing_Street_Box}</td>
                                                    <td className={'color' + listValue.Mailing_Suite_Number_notes}>{listValue.Mailing_Suite_Number}</td>
                                                    <td className={'color' + listValue.Mailing_City_notes}>{listValue.Mailing_City}</td>
                                                    <td className={'color' + listValue.Mailing_State_notes}>{listValue.Mailing_State}</td>
                                                    <td className={'color' + listValue.Mailng_Zip_Code_notes}>{listValue.Mailng_Zip_Code}</td>
                                                    {/* <td >{listValue.Part_of_Governing_Board}</td> */}

                                                    <td >{listValue.Official_ID}</td>
                                                    <td className={'color' + listValue.Person_ID_notes}>{listValue.Person_ID}</td>
                                                </tr>
                                            </>
                                        );
                                    })
                                    : <tr>
                                    </tr>}
                            </tbody>
                        </table>
                    </div>
                </section>

                {/*  Government History */}
                <section className="dmstable" id="SeeGovernment">
                    <div className="d-flex justify-content-center">
                        <div>
                            <h2>Government History</h2>
                        </div>
                        {!this.state.seeMore ? this.props.tableDetails.notes_with_limit.length === 0 ? "" : <div className="seeMoreLess" onClick={this.seeMoreToggle}>See More</div> :
                            this.props.tableDetails.notes_without_limit === 0 ? "" : <div className="seeMoreLess" onClick={this.seeMoreToggle}>See Less</div>}
                    </div>
                    <div className="table-responsive">
                        <table className="table table-bordered ">
                            <thead>
                                <tr>
                                    <th scope="col" >Date/Time</th>
                                    <th scope="col" className="text-center">Notes</th>
                                    <th scope="col" >Written By</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!this.state.seeMore ? this.props.tableDetails.notes_with_limit.map((listValue, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{listValue.Date_Time}</td>
                                            <td>{listValue.Notes}</td>
                                            <td>{listValue['Written By']}</td>
                                        </tr>
                                    );
                                }) : this.props.tableDetails.notes_without_limit.map((listValue, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{listValue.Date_Time}</td>
                                            <td>{listValue.Notes}</td>
                                            <td>{listValue['Written By']}</td>
                                        </tr>
                                    );
                                })}

                            </tbody>
                        </table>
                    </div>
                </section>

                <AddNew modal={this.state.addModal} toggle={this.toggle} refreshPage={this.props.voiceAgentEdit}
                    gid={this.props.gid}
                    jid={this.props.jid} />
                <AddNewDeputy modal={this.state.addDeputyModal} tableDetails={this.props.tableDetails} toggle={this.toggleAddDeputy} refreshPage={this.props.voiceAgentEdit}
                    gid={this.props.gid}
                    jid={this.props.jid} />

                <AddLawEnforcement modal={this.state.addEnforcementModal} toggle={this.toggleAddEnforcement} refreshPage={this.props.voiceAgentEdit}
                    gid={this.props.gid}
                    jid={this.props.jid} />
                    

                {/*  Email bounce*/}
                <Modal isOpen={this.state.emailToggle} backdrop="static" centered={true}>
                    <ModalHeader toggle={this.closeModal} className="backColor"><h4>Email Bounce Check Result</h4></ModalHeader>
                    <ModalBody>
                        <div>
                            <strong>
                                <div style={{ textTransform: 'uppercase' }}
                                    dangerouslySetInnerHTML={{
                                        __html: this.state.emailMsg
                                    }}
                                ></div>
                            </strong>
                            <div className="text-right">
                                <button type="button" className="btn btn-primary backColor" onClick={this.closeModal}>OK</button>&nbsp;
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </div >
        );
    }
}

// Export
export default connect()(VoiceAgentEditTable);
