// Vendor Imports
import React from "react";
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { formValueSelector } from 'redux-form'
import Swal from 'sweetalert2'

// Local Imports
import { addNewDeputy, validateZipCode, emailBounceCheck } from "../../../../../service/dms";
import Form from "./Form";

class AddModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            backendErrors: null,
            loading: '',
            emailLoading: '',
            emailToggle: false,
            emailMsg: ''
        };
    }

    // Submit Handler
    handleSubmit = values => {
        let titleDpCheck=[];
        let nameTpCheck=[];
        if('top_appointed_executive' in this.props.tableDetails)
        {
            this.props.tableDetails.top_appointed_executive.map((listTopValue, index) => {
                    
                    //titleDpCheck.push(listTopValue.edit.Government_Title);
                    
                    nameTpCheck.push(listTopValue.edit.First_Name.trim()+listTopValue.edit.Last_Name.trim());
                    if(listTopValue.edit.Role==='Top Appointed Executive')
                    {
                        titleDpCheck.push(listTopValue.edit.Government_Title);
                    }
            })
            

        }

        if('top_elected_official' in this.props.tableDetails)
        {
            nameTpCheck.push(this.props.tableDetails.top_elected_official.edit.First_Name.trim()+this.props.tableDetails.top_elected_official.edit.Last_Name.trim());
            
        }

        if('governing_board_member' in this.props.tableDetails)
        {
            this.props.tableDetails.governing_board_member.map((listGBMValue, index) => {
                
                    nameTpCheck.push(listGBMValue.edit.First_Name.trim()+listGBMValue.edit.Last_Name.trim());
                
            })
        }

        if(titleDpCheck.includes(values.gov_title))
        {
            Swal.fire({
                text: 'TAO and DTAO cannot have the same title. Please correct and then proceed..',
                showCancelButton: false,
                showCloseButton: true,
                confirmButtonText: 'OK',
                //cancelButtonText: 'cancel',
                icon: 'question',
                allowOutsideClick: false
            });
            return false;
        } 
        
        if(nameTpCheck.includes(values.f_name.trim()+values.l_name.trim()))
        { 
            Swal.fire({
                text: 'DTAO and <TAO/TEO/GBM> cannot have the same person. Please correct and then proceed.',
                showCancelButton: false,
                showCloseButton: true,
                confirmButtonText: 'OK',
                //cancelButtonText: 'cancel',
                icon: 'question',
                allowOutsideClick: false
            });
            return false;
        }
        this.setState({ loading: "loading", backendErrors: '' });
        //console.log(nameTpCheck);
        //return false;
        validateZipCode({
            queryString: values.mail_zip
        }).then(res => {
            if (res.data.data.zipcode_status === 1) {
                let params = {
                    jid: this.props.jid,
                    gid: this.props.gid,
                    roleexist: "Yes",
                    title: values.gov_title,
                    fname: values.f_name,
                    lname: values.l_name,
                    email: values.email,
                    phone: values.phone_number,
                    phoneext: values.phone_ext,
                    mail_box: values.mail_box,
                    mail_suite: values.mail_suite,
                    mail_city: values.mail_city,
                    mail_state: '',
                    mail_zip: values.mail_zip,
                    partofboard: '',
                    role: 'Deputy Top Appointed Executive',
                    email_bounce: ""
                }
                addNewDeputy(params).then(res => this.handleAddResponse(res));
            } else if (res.data.data.zipcode_status === 0) {
                this.setState({ backendErrors: 'Invalid Zip Code entered. Please try again.', loading: '' });
            }
        })
    }

    // Response Handler
    handleAddResponse = res => {
        if (res.data.code === 200) {
            this.setState({
                backendErrors: null,
                loading: '',
            });
            this.props.toggle();
            this.props.refreshPage();
        } else {
            this.setState({ backendErrors: res.data.message, loading: "" });
        }
    }

    // Close modal handler
    closeModal = () => {
        this.setState({
            backendErrors: null,
            loading: '',
            emailLoading: '',
            emailToggle: false,
            emailMsg: ''
        });
        this.props.toggle();
    }

    // Email check handler
    handleEmailCheck = () => {
        if (this.props.email !== undefined) {
            this.setState({ emailLoading: 'loading' });
            emailBounceCheck({ email: this.props.email }).then(res => {
                if (res.data.code === 200) {
                    this.setState({ emailToggle: true, emailMsg: res.data.data.message, emailLoading: '' })
                } else {
                    this.setState({ emailLoading: '' });
                }
            })
        } else {
            Swal.fire({
                text: "Enter Email Address",
                showCloseButton: true,
                confirmButtonText: "Ok",
                icon: 'error',
                allowOutsideClick: false
            });
        }
    }

    // close email modal handler
    closeEmailModal = () => {
        this.setState({ emailToggle: false, emailMsg: '' })
    }

    render() {
        return (
            <div>
                {/* Add Modal */}
                < Modal size="model-lg" isOpen={this.props.modal} backdrop="static" centered={true} >
                    <ModalHeader toggle={this.closeModal} className="backColor">Add New Deputy Top Oppointed Officials</ModalHeader>
                    <ModalBody>
                        <Form
                            onSubmit={this.handleSubmit}
                            loading={this.state.loading}
                            emailLoading={this.state.emailLoading}
                            emailCheck={this.handleEmailCheck}
                            toggle={this.closeModal}
                            backendErrors={this.state.backendErrors}
                        ></Form>
                    </ModalBody>
                </Modal >

                {/*  Email bounce*/}
                <Modal isOpen={this.state.emailToggle} backdrop="static" centered={true}>
                    <ModalHeader toggle={this.closeEmailModal} className="backColor"><h4>Email Bounce Check Result</h4></ModalHeader>
                    <ModalBody>
                        <div>
                            <strong>
                                <div style={{ textTransform: 'uppercase' }}
                                    dangerouslySetInnerHTML={{
                                        __html: this.state.emailMsg
                                    }}
                                ></div>
                            </strong>
                            <div className="text-right">
                                <button type="button" className="btn btn-primary backColor" onClick={this.closeEmailModal}>OK</button>&nbsp;
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </div >
        );
    }
}

const selector = formValueSelector('addGovBoardMem');

export default connect(state => ({
    email: selector(state, 'email')
}))(AddModal);
