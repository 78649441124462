import {
    SET_ADMIN_USER,
    REMOVE_ADMIN_USER
} from "./constants";

export function setAdminUser(payload) {
    return {
        type: SET_ADMIN_USER,
        payload
    };
}

export function removeAdminUser() {
    return {
        type: REMOVE_ADMIN_USER
    };
}