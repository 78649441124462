const validate = values => {
    const errors = {};
    if (!values.email) {
        errors.email = "Please enter user name";
    }
    if (!values.password) {
        errors.password = "Please enter the password";
    }
    return errors;
};

export default validate;
