// Vendor Imports
import React from "react";
import { connect } from "react-redux";

// Local Imports

// ForgotPasswordSuccess Component
class ForgotPasswordSuccessComponent extends React.Component {
  render() {
    return (
      <div >

        <section className="container login-section forgotpw-block">
          <div className="row login-bg">
            <div className="col-sm-12 col-md-6">
              <img
                src="images/login.jpg"
                alt="Placeholder"
                className="img-responsive"
              />
            </div>
            <div className="col-sm-12 col-md-6" style={{ paddingTop: "15px" }}>
              <h2>Password Recovery Confirmation</h2>
              <p className="lead">
                If the email address exists in the system, an email has been
                sent with instructions on how to reset your password.
              </p>
              <p>
                Make sure you check your email settings to ensure you can get
                email from <strong>poweralmanac.com</strong>, and check your
                spam folder if you don't find it in your inbox.
              </p>
              <p>
                If you still don't receive an email after five minutes, resubmit
                the form.
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

// Export
export default connect()(ForgotPasswordSuccessComponent);
