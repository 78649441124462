import React, { useState, useEffect, } from "react";
import { connect, useSelector , useDispatch} from 'react-redux';
import { withRouter } from "react-router-dom";
import { Helmet } from 'react-helmet';
import Cookies from 'js-cookie';
import SearchBar from './SearchBar';
import SearchInfo from './SearchInfo';
import SearchFilter from './SearchFilter';
import SearchStats from './SearchStats';
import MyFirstSearch from './MyFirstSearch';
import PowerEntryAlertModal from './PowerEntryAlert'
import NotificationAlert from './NotificationAlert';
import UpgrdeRequestModal from '../../client/UpgradeSubscription/UpgrdeRequestModal'
import {setSurveyformStep, setBestPricing, refreshUserDetails,setPhoneVerificationFrom } from '../../../reducers/client/actions';
import {getbestPricing, getMyAccount } from '../../../service/client';
// search page main functional component
function SearchComponent(props) {
    const dispatch = useDispatch();
    let [modal, setModal] = useState(false);
    let [activeStep, setActiveStep] = useState('');
    let [powerEntryAlert, setPowerEntryAlert] = useState(false);
    const [upgradeModal, setUpgradeModal] = useState(false);
    // function to show upgrade form modal
    const toggleUpgrade = () => {
        setUpgradeModal(!upgradeModal)
    };

    const toggle = () => setModal(!modal);
    let notificationView = Cookies.get('notification');

    // console.log(" props.isAdminLogin ", props.isAdminLogin === null);
    // console.log("props.plan.Sub_Level ", props.plan.Sub_Level !== "11");
    // console.log("parseInt(props.myaccount.ft_pop_up) <= 3 ", parseInt(props.myaccount.ft_pop_up) < 1);
    // console.log("notificationView  ", notificationView === "false");
    // console.log("testtt", props.isAdminLogin === null && props.plan.Sub_Level !== "11" && parseInt(props.myaccount.ft_pop_up) < 3 && notificationView === "false")
    // toggle powerentry alert modal based on expiry date left
    const togglePowerEntryAlert = (option) => {
        Cookies.set('powerentryalert', true);
        if (option === 'upgrade') {
            props.history.push('/upgrade-subscription');
        } else if (option === 'ShowupgradeModal') {
            toggleUpgrade();
            setPowerEntryAlert(!powerEntryAlert);
        } else if (option === '') {
            Cookies.set('powerentryalert', true);
            setPowerEntryAlert(!powerEntryAlert);
        }

    }
    // constractor to display my first search form
    useEffect(() => {
        if (window.analytics) {
          window.analytics.page();
        }
        if (props.info.showUserSurvey === true && modal === false && props.info.survey_response === "") {
            setModal(true);
            dispatch(setSurveyformStep({activeStep: 0, skipStep:false}));
            dispatch(setPhoneVerificationFrom([]));
        } else if (props.info.showUserSurvey === false && modal === false && props.myaccount.smsDateVerified === null) {
            setModal(true);
            //dispatch(setSurveyformStep(1));
        }
        // condition to show power entry user alert modal
        if (props.info.subscription_remaining_days && (parseInt(props.plan.Sub_Level)===9 || parseInt(props.plan.Sub_Level) === 11 ) && typeof Cookies.get('powerentryalert') === "undefined" && modal === false && props.myaccount.smsDateVerified !== null) {
            setPowerEntryAlert(true);
        }
        
     
      if (props.searchResult) {
        let unpaid = 0;
        if (props.plan.Sub_Level !== "10") {
            unpaid = parseInt(props.searchResult.lastSearchNumMatched);
        }
        let bestParams = {
            checkout_paid_records: props.searchResult.checkout_paid_records,
            checkout_unpaid_records: unpaid,
          }
        getbestPricing(bestParams).then(response =>
          dispatch(setBestPricing(response.data.data)),
        )
      } 
        getMyAccount().then(res =>
            dispatch(refreshUserDetails(res.data.data))
        );

    },[]);


    // return search page compined component element and templates
    return (
        <>
            <Helmet>
                <title data-heap-redact-text> Search - Power Almanac</title>
                <meta name="description" content="Search our whole database consisting of 250,000+ government officials with their contact information. Narrow your search by location, role, and many other extensive filters."></meta>
            </Helmet>
            <SearchBar />
            <section className="container mt-3">
                <div className="row">
                    <div className="col-md-4 search-govblock col-sm-12 ">
                        <br />
                        <SearchInfo />
                        <SearchFilter />
                    </div>
                    <div className="col-md-8 col-sm-12 search-gov pt-4">
                        <SearchStats />
                    </div>
                </div>
            </section>
            {
                modal && props.info.isParent ?
                    <MyFirstSearch modal={modal}  toggle={toggle} />
                    :
                    ""
            }
{/* 
            {
                powerEntryAlert && (props.plan.Sub_Level === "11" || props.plan.Sub_Level === "9" )?
                    <PowerEntryAlertModal left={props.info.subscription_remaining_days} modal={powerEntryAlert} toggle={togglePowerEntryAlert} />
                    :
                    ""
            } */}

            {
                props.isAdminLogin === null && (props.plan.Sub_Level !== "11" || props.plan.Sub_Level !== "9" ) && parseInt(props.myaccount.ft_pop_up) < 3 && notificationView === "false" && props.myaccount.smsDateVerified!==null ?
                    <NotificationAlert modal={(props.plan.Sub_Level !== "11" || props.plan.Sub_Level !== "9" ) && parseInt(props.myaccount.ft_pop_up) < 3 && notificationView === "false" && props.myaccount.smsDateVerified!==null ? true : false} />
                    :
                    ""
            }

            {
                upgradeModal ?
                    <UpgrdeRequestModal modal={upgradeModal} toggle={toggleUpgrade} />
                    :
                    ""
            }



        </>
    );


}
// get and set initial properties
function mapStateToProps(store, props) {
    return {
        isAdminLogin: store.admin.authToken ? store.admin.authToken : null,
        myaccount: store.client.myaccount ? store.client.myaccount : [],
        info: store.client.info ? store.client.info : [],
        plan: store.client.plan ? store.client.plan : [],
        NewUserServeyForm: store.client.NewUserServeyForm ? store.client.NewUserServeyForm : [],
        searchResult: store.client.searchResult ? store.client.searchResult : [],
        currentSearch: store.client.currentSearch ? store.client.currentSearch : [],
        bestPricing: store.client.bestPricing ? store.client.bestPricing : [],
    }
}

export default withRouter(connect(mapStateToProps, {})(SearchComponent));
