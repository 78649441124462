// Vendor Imports
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";


// Local Imports
import { getVoiceAgentDash } from "../../../../service/dms";

// Voice Agent Table
class VoiceAgentTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    // Get edit link handler
    getEditLink = val => {
        // let geId = val.ge_ID === null ? "" : val.ge_ID;
        return `/dms/voice-agent/edit/${val.Government_ID}/${val.job_id}/${val.category}/${val.ge_ID}`
    }

    // Get month name handler
    getMonthName = val => {
        const monthNames = ["", "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        return monthNames[val];
    }

    render() {
        return (
            <div>
                {this.props.tableDetails ? <div>
                    <section className="dmstable mt-3">
                        <h2>New Tasks - Regular</h2>
                        <div className="table-responsive">
                            <table className="table table-bordered ">
                                <thead>
                                    <tr>
                                        <th scope="col">Actions</th>
                                        <th scope="col">Allocation Date</th>
                                        <th scope="col">Govt ID</th>
                                        <th scope="col">Govt Name</th>
                                        <th scope="col">Govt Type</th>
                                        <th scope="col">Govt Location (county,state)</th>
                                        <th scope="col">Time Zone</th>
                                        <th scope="col"> # Ofcls</th>
                                        <th scope="col">Last Confirmed</th>
                                        <th scope="col">Election Month</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.tableDetails.NewTasksRegular.map((listValue, index) => {
                                        return (
                                            <tr key={index}>
                                                <td><Link to={this.getEditLink(listValue)}>Edit</Link></td>
                                                <td>{listValue.allocation_date}</td>
                                                <td>{listValue.Government_ID}</td>
                                                <td>{listValue.Government_Place_Name}</td>
                                                <td>{listValue.Government_Type_Name}({listValue.Government_Type})</td>
                                                <td>{listValue.location}</td>
                                                <td>{listValue.TZ}</td>
                                                <td>{listValue.num_officials}</td>
                                                <td>{listValue.confirmed}</td>
                                                <td>{this.getMonthName(listValue.election_month)}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </section>

                    <section className="dmstable mt-3">
                        <h2>New Tasks - Out of Turn</h2>
                        <div className="table-responsive">
                            <table className="table table-bordered ">
                                <thead>
                                    <tr>
                                        <th scope="col">Actions</th>
                                        <th scope="col">Allocation Date</th>
                                        <th scope="col">Govt ID</th>
                                        <th scope="col">Govt Name</th>
                                        <th scope="col">Govt Type</th>
                                        <th scope="col">Govt Location (county,state)</th>
                                        <th scope="col">Time Zone</th>
                                        <th scope="col"> # Ofcls</th>
                                        <th scope="col">Last Confirmed</th>
                                        <th scope="col">Election Month</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.tableDetails.NewTasksOutofTurn.map((listValue, index) => {
                                        return (
                                            <tr key={index}>
                                                <td><Link to={this.getEditLink(listValue)}>Edit</Link></td>
                                                <td>{listValue.allocation_date}</td>
                                                <td>{listValue.Government_ID}</td>
                                                <td>{listValue.Government_Place_Name}</td>
                                                <td>{listValue.Government_Type_Name}({listValue.Government_Type})</td>
                                                <td>{listValue.location}</td>
                                                <td>{listValue.TZ}</td>
                                                <td>{listValue.num_officials}</td>
                                                <td>{listValue.confirmed}</td>
                                                <td>{this.getMonthName(listValue.election_month)}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </section>

                    <section className="dmstable mt-3">
                        <h2>Rejected by Quality</h2>
                        <div className="table-responsive">
                            <table className="table table-bordered ">
                                <thead>
                                    <tr>
                                        <th scope="col">Actions</th>
                                        <th scope="col">Allocation Date</th>
                                        <th scope="col">Govt ID</th>
                                        <th scope="col">Govt Name</th>
                                        <th scope="col">Govt Type</th>
                                        <th scope="col">Govt Location (county,state)</th>
                                        <th scope="col">Time Zone</th>
                                        <th scope="col"> # Ofcls</th>
                                        <th scope="col">Last Confirmed</th>
                                        <th scope="col">Election Month</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.tableDetails.RejectedByQuality.map((listValue, index) => {
                                        return (
                                            <tr key={index}>
                                                <td><Link to={this.getEditLink(listValue)}>Edit</Link></td>
                                                <td>{listValue.allocation_date}</td>
                                                <td>{listValue.Government_ID}</td>
                                                <td>{listValue.Government_Place_Name}</td>
                                                <td>{listValue.Government_Type_Name}({listValue.Government_Type})</td>
                                                <td>{listValue.location}</td>
                                                <td>{listValue.TZ}</td>
                                                <td>{listValue.num_officials}</td>
                                                <td>{listValue.confirmed}</td>
                                                <td>{this.getMonthName(listValue.election_month)}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </section>

                    <section className="dmstable mt-3">
                        <h2>Recently Completed Tasks</h2>
                        <div className="table-responsive">
                            <table className="table table-bordered ">
                                <thead>
                                    <tr>
                                        <th scope="col">Actions</th>
                                        <th scope="col">Govt ID</th>
                                        <th scope="col">Govt Name</th>
                                        <th scope="col">Govt Type</th>
                                        <th scope="col">Govt Location (county,state)</th>
                                        <th scope="col">Time Zone</th>
                                        <th scope="col"> # Ofcls</th>
                                        <th scope="col"># Edits (%)</th>
                                        <th scope="col">Disposition</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.tableDetails.RecentlyCompletedTasks.map((listValue, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>Completed</td>
                                                <td>{listValue.Government_ID}</td>
                                                {
                                                    listValue.state === "done" ?
                                                        <>
                                                            <td colSpan="4">Information Not Available Yet - Task is in Data Uploader</td>
                                                            <td>{listValue.num_officials}</td>
                                                            <td>TBD</td>
                                                            <td>NA</td>
                                                        </> :
                                                        <>
                                                            <td>{listValue.Government_Place_Name}</td>
                                                            <td>{listValue.Government_Type_Name}({listValue.Government_Type})</td>
                                                            <td>{listValue.location}</td>
                                                            <td>{listValue.TZ}</td>
                                                            <td>{listValue.num_officials}</td>
                                                            <td>{listValue.numOfficialEdits}({listValue.numOfficialEditsPCT}%)</td>
                                                            <td>NA</td>
                                                        </>
                                                }

                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </section>

                </div> : <div className="text-center">
                        <div
                            className="spinner-border text-primary mt-3"
                            style={{ width: "3rem", height: "3rem", marginLeft: "auto" }}
                            role="status"
                        >
                            <span className="sr-only">Loading...</span>
                        </div></div>}
            </div>
        );
    }
}

// Export
export default connect()(VoiceAgentTable);
