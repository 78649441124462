import React, { useState } from 'react';
import { useSelector } from "react-redux";
import { renderCurrency } from '../../../../utils'
import {
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { StripePackage, StripeCredits, StripeSearchCredits, StripePackageCredits } from '../../../../service/client';
import $ from 'jquery';

// Stripe  card element and form
function CheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();
 
  const currentSearch = useSelector((store) => store.client.currentSearch);
  const checkout = useSelector((store) => store.client.checkout);
  const searchResult = useSelector((store) => store.client.searchResult);
  const bestPricing = useSelector((store) => store.client.bestPricing);
  

  let [loading, setloading] = useState('');
  let [stripeError, setStripeError] = useState('');
  $(".CardField-cvc").attr('placeholder', 'CVV');

  // function to make stripe based online payment
  const handleSubmit = async (event) => {
    setStripeError('');
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);
    stripe.createToken(cardElement).then(function (result) {
      if (result.error) {
        setStripeError(result);
      }
      else {
        setloading('loading');
        processCardPayment(result);
      }
    });

  };
  // function to process card payment api
  const processCardPayment = (result) => {
    let params = {};
    if (checkout.type === "credits") { // credit checkout api 
      params = {
        stripeToken: result.token.id,
        amount: checkout.invoice.totalPrice,
        description: "Purchase extra credits",
        plan_type: "credits",
        no_ofCredits: checkout.invoice.creditsIncluded
      }
      setloading('loading');
      StripeCredits(params).then(res =>
        handleCheckoutResponse(res),
      )
    } else if (checkout.type === "subscription") { // subscription package api

      params = {
        stripeToken: result.token.id,
        amount: checkout.invoice.totalPrice,
        description: "Upgrade plan",
        plan_type: "package",
        id: checkout.id
      }
      setloading('loading');
      StripePackage(params).then(res =>
        handleCheckoutResponse(res),
      )
    }
    else if (checkout.type === "download") { //  continue to download after checkout api
      if (bestPricing.option === "Upgrade") {
        params = {
          stripeToken: result.token.id,
          amount: checkout.invoice.totalPrice,
          description: bestPricing.pricing_option,
          plan_type: "package_and_credits",
          no_ofCredits: checkout.invoice.totalPurchase,
          downloadname: currentSearch.name,
          scrvr: searchResult.scrvr,
          checkout_paid_records: searchResult.paid_records,
          Num_Matched_Officials: searchResult.lastSearchNumMatched,
          referrer: "",
          record_id: ""
        }
        setloading('loading');
        StripePackageCredits(params).then(res =>
          handleCheckoutResponse(res),
        )
      } else { // checkout credit purchase api and continue to download
        params = {
          stripeToken: result.token.id,
          amount:checkout.invoice.totalPrice,
          description:bestPricing.pricing_option,
          no_ofCredits:checkout.invoice.totalPurchase,
          plan_type: "search_credits",
          downloadname:currentSearch.name,
          scrvr:searchResult.scrvr
        }
        setloading('loading');
        StripeSearchCredits(params).then(res =>
          handleCheckoutResponse(res),
        )
      }
    }
  }

  // function to handle checkout response to continue download or updating user account credit balance
  const handleCheckoutResponse = (response) => {
    setloading('');
    props.callBack(response);
  }

  return (
    <>

      <form id="payment-form" onSubmit={handleSubmit}>
        <div className="stripe_card_wrap">
          <CardElement onChange={(e) => {
            setStripeError(e);
          }} style={{ base: { fontSize: '18px' } }} />
          {
            stripeError !== '' && typeof stripeError.error !== 'undefined' ?
              <div className="alert alert-danger mt-3" role="alert">
                {stripeError.error.message}
              </div>
              :
              ""
          }
          <button id="stripeSbmitBtn" className={loading} type="submit" disabled={!stripe}>
            Pay ${renderCurrency(checkout.invoice.totalPrice)}
          </button>

        </div>
      </form>
    </>
  );
}


// function mapStateToProps(store, props) {
//   return {
//     myaccount: store.client.myaccount ? store.client.myaccount : [],
//     plan: store.client.plan ? store.client.plan : [],
//     searchResult: store.client.searchResult ? store.client.searchResult : [],
//     currentSearch: store.client.currentSearch ? store.client.currentSearch : [],
//     checkout: store.client.checkout ? store.client.checkout : [],
//     bestPricing: store.client.bestPricing ? store.client.bestPricing : [],
//   }
// }

export default CheckoutForm;

