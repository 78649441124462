import {
    SET_ADMIN_USER,
    REMOVE_ADMIN_USER
} from "./constants";

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

let userData = JSON.parse(localStorage.getItem("PA-admin"));

const initialState = {
    authToken: userData ? userData.token : null,
    adminUser: userData ? userData.employee : null,
};

const userReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_ADMIN_USER:
            return {
                ...state,
                adminUser: action.payload.admin,
                authToken: action.payload.token
            };
        case REMOVE_ADMIN_USER:
            return {
                ...state,
                adminUser: null,
                authToken: null
            };
        default:
            return state;
    }
};
const persistConfig = {
    keyPrefix: "PA-",
    key: "admin",
    storage
};

export default persistReducer(persistConfig, userReducer);
