import React, { useState, useCallback, useEffect } from 'react';
import {  useSelector, useDispatch } from "react-redux";
import { usePlaidLink } from 'react-plaid-link';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import { VerifyPlaidAccount, StripePackage, StripeCredits, StripeSearchCredits, StripePackageCredits, getMyTransactions, getMyAccount } from '../../../../service/client';
import { setMyTransactions, refreshUserDetails, setLinkToken } from "../../../../reducers/client/actions";
import { renderCurrency } from '../../../../utils';
import Swal from 'sweetalert2'
function OAuthLink(props) {
  let [verifiedBankAccounts, setVerifiedBankAccounts] = useState("");
  let [processingPayment, setProcessingPayment] = useState('');
  let [selectedAccount, setSelectedAccount] = useState('');
  const [title, setTitle] = useState('Stripe Payment Option');
  const [modal, setModal] = useState(false);
  let [stripeError, setStripeError] = useState('');
  let [stripeSuccess, setStripeSuccess] = useState('');

  const checkout = useSelector((store) => store.client.checkout);
  const bestPricing = useSelector((store) => store.client.bestPricing);
  const searchResult = useSelector((store) => store.client.searchResult);
  const currentSearch = useSelector((store) => store.client.currentSearch);
  // The Link token from the first Link initialization
  let linkToken = useSelector(store => store.client.plaidLink);
  const dispatch = useDispatch();
  // function to verify by back account credential
  const onSuccess = useCallback((public_token, metadata) => {
    setVerifiedBankAccounts(metadata);
  });
  const onExit = (err, metatdata) => {
    //setVerifiedBankAccounts("");
    // handle error...
  };
  const config: Parameters<typeof usePlaidLink>[0] = {
    token: linkToken,
    env: process.env.REACT_APP_PLAID_MODE,
    receivedRedirectUri: window.location.href, //the redirect URI with an OAuth state ID parameter
    onSuccess,
    onExit,
  };
  const { open, ready, error } = usePlaidLink(config);
  // automatically reinitialize Link
  useEffect(() => {
    if (ready) {
      open();
    }
  }, [ready, open]);

  const getAccessTokenAndPay = () => {
    let params = {
      public_token: verifiedBankAccounts.public_token,
      account_id: selectedAccount
    }
    setProcessingPayment('loading');
    //api to generate stripe token with verified back account and plaid
    VerifyPlaidAccount(params).then(res =>
      handlePlaidResponse(res),
    )
  }
  const handlePlaidResponse = (res) => {
    if (res.data.code === 200) {
      let data = res.data.data.verifyPlaidAccount;
      if (typeof data !== "undefined" && data.status === "success") {
        CheckOutByACH(data.token);
      }
    } else {
      Swal.fire({
        title: "Payments is failed",
        text: '',
        icon: '',
        showCancelButton: false,
        cancelButtonText: 'Close'
      });
      setProcessingPayment('');
    }
  }

  const CheckOutByACH = (access_token) => {
    let params = {};
    if (checkout.type === "credits") { // api to checkout credits
      params = {
        stripeToken: access_token,
        amount: checkout.invoice.totalPrice,
        description: "Purchase extra credits",
        plan_type: "credits",
        no_ofCredits: checkout.invoice.creditsIncluded
      }
      setProcessingPayment('loading');
      StripeCredits(params).then(res =>
        handleCheckoutResponse(res),
      )
    } else if (checkout.type === "subscription") { // api to upgrade subscription plan
      params = {
        stripeToken: access_token,
        amount: checkout.invoice.totalPrice,
        description: "Upgrade plan",
        plan_type: "package",
        id: checkout.id
      }
      setProcessingPayment('loading');
      StripePackage(params).then(res =>
        handleCheckoutResponse(res),
      )
    }
    else if (checkout.type === "download") {
      if (bestPricing.option === "Upgrade") { // apit to checkout subscription package with extra credits and proceed to download
        params = {
          stripeToken: access_token,
          amount: checkout.invoice.totalPrice,
          description: bestPricing.pricing_option,
          plan_type: "package_and_credits",
          no_ofCredits: checkout.invoice.totalPurchase,
          downloadname: currentSearch.name,
          scrvr: searchResult.scrvr,
          checkout_paid_records: searchResult.paid_records,
          Num_Matched_Officials: searchResult.lastSearchNumMatched,
          referrer: "",
          record_id: ""
        }
        setProcessingPayment('loading');
        StripePackageCredits(params).then(res =>
          handleCheckoutResponse(res),
        )
      } else { // apit to checkout search  extra credits and proceed to download
        params = {
          stripeToken: access_token,
          amount: checkout.invoice.totalPrice,
          description: bestPricing.pricing_option,
          no_ofCredits: checkout.invoice.totalPurchase,
          plan_type: "search_credits",
          downloadname: currentSearch.name,
          scrvr: searchResult.scrvr
        }
        setProcessingPayment('loading');
        StripeSearchCredits(params).then(res =>
          handleCheckoutResponse(res),
        )
      }
    }
  }

  // call back function to susscessfull checkout response for download or refreshing user account
  const handleCheckoutResponse = (response) => {
    setProcessingPayment('');
    if (response.data.code === 200) {
      setTitle("Payment Success");
      if (checkout.type === "download") {
        setStripeSuccess(response.data.data.reponseMessage);
      } else {
        setStripeSuccess(response.data.data.reponseMessage);
      }
      getMyAccount().then(res =>
        dispatch(refreshUserDetails(res.data.data))
      );
      getMyTransactions().then(res =>
        dispatch(setMyTransactions(res.data.data)),
      )
      setModal(true);
    } else {
      setStripeError({ error: { message: "Payment Failed! Try again" } });

    }
    //props.callBack(response);
  }

  const HandleClose = () => {

    dispatch(setLinkToken(''));
    if (stripeSuccess === "") {
      props.toggle();
    } else {
      if (checkout.type === "download") {
        if (currentSearch.expired === true) {
          props.history.push('/confirm-expire-download/' + currentSearch.id);
        } else {
          props.history.push('/search/download');
        }
      } else {
        props.history.push('/my-account');
      }
    }
  }


  return (
    <>
      <div style={{ marginBottom: "5%" }}>
        <section className="my-search">
          <div className="container">
            <div className="row">
              <div className="col">
                <ul className="breadcrumb">

                  <li>Payment Confirmation</li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <div className="container">
          <div className="col-6 m-auto mr-0">
            <div className="panel panel-primary download-trans pt-5">
              <div className="panel-heading account-test text-center">
                PAYMENT CONFIRMATION
              </div>
              <div className="panel-body background-grey add-subscribe ">
                <div className="row ">

                  <div className="m-auto mr-0 text-center" id="payment-bank-form">
                    <div className="stripebankapi  mt-3">

                      {verifiedBankAccounts != "" ?
                        <table className="table  table-hover">
                          <thead>
                            <tr>
                              <th>Select Account</th>
                              <th>Bank Name</th>
                              <th>Last4</th>
                              <th>Type</th>
                              {/* <th>Available</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {
                              verifiedBankAccounts.accounts.map(function (account, i) {
                                return (<tr>
                                  <td className="border-bottom">
                                    <input name="account" onChange={() => setSelectedAccount(account.id)} value={account.id} type="radio" />
                                  </td>
                                  <td className="border-bottom">
                                    {account.name}
                                  </td>
                                  <td className="border-bottom">
                                    {account.mask}
                                  </td>
                                  <td className="border-bottom">
                                    {account.subtype}
                                  </td>
                                  {/* <td className="border-bottom">
                                    {account.balances.localized.available}
                                  </td> */}

                                </tr>)
                              })

                            }
                          </tbody>
                        </table>
                        :
                        ""
                      }
                    </div>
                    {
                      selectedAccount !== "" ?
                        <button onClick={getAccessTokenAndPay} id="stripeACHSbmitBtn" className={processingPayment}>
                          Pay ${renderCurrency(checkout.invoice.totalPrice)}
                        </button>
                        :
                        ""
                    }

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        size="lg"
        isOpen={modal}
        toggle={HandleClose}
        backdrop={false}
        centered={true}
        className="modal-dialog"
      >
        <ModalHeader id="stripe_modal_header_wrap" toggle={HandleClose}>{title}</ModalHeader>
        <ModalBody id="stripe_modal_body_wrap">
          {stripeError !== '' ?
            <div className="alert alert-danger mt-3" role="alert">
              {stripeError.error.message}
              <span
                dangerouslySetInnerHTML={{
                  __html: stripeError.error.message
                }}
              />
            </div>
            :
            ""
          }
          {stripeSuccess !== "" ?
            <div className="alert alert-success mt-3" role="alert">
              <span
                dangerouslySetInnerHTML={{
                  __html: stripeSuccess
                }}
              />
            </div>
            :
            ""
          }
        </ModalBody>
      </Modal>
    </>
  )
};

export default OAuthLink;
