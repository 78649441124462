// Vendor Imports
import React from "react";
import { Route, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
// Local Imports
import ClientLayout from "../../layouts/ClientLayout";

// Client Route
const componentWithLayout = (ClientComponent, props) => {
  if (props.authToken) {
    return (
      <ClientLayout>
        <ClientComponent {...props} />
      </ClientLayout>
    );
  } else {
    props.history.push("/login");
  }
};

class ClientRoute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }
  render() {
    const { menu, component: ClientComponent, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={() => componentWithLayout(ClientComponent, this.props)}
      />
    );
  }
}

// Export

function mapStateToProps(store, props) {
  return {
    authToken: store.client.authToken ? store.client.authToken : null
  }
}

export default withRouter(connect(mapStateToProps, {})(ClientRoute));
