// Vendor Imports
import React from "react";
import Swal from 'sweetalert2';
import moment from 'moment';

// Local Imports
import { updateUserSubscriptionHistory } from "../../../../service/admin";

// Edit Subscription Form
class EditSubscriptionForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            backendErrors: null,
            loading: '',
            item: "",
            desc: "",
            startDate: "",
            endDate: "",
            subscriptionDetails: this.props.subData
        };
    }

    componentDidMount() {
        this.setState({
            item: this.getSubscription(this.props.editVal.NAME, this.state.subscriptionDetails),
            desc: this.props.editVal.DESCRIPTION,
            startDate: this.getFormattedDate(this.props.editVal.SUBSCRIPTION_DATE_START),
            endDate: this.getFormattedDate(this.props.editVal.SUBSCRIPTION_DATE_END)
        })
    }

    // get user subscription value
    getSubscription = (val,subs) => {
        let name = subs.filter(plan => plan.Sub_Name === val);
        return name[0].Sub_Level;
        
    }

    // Get formatted Date
    getFormattedDate = date => {
        return moment(date).format('YYYY-MM-DD');
    }

    // Form Submit
    handleSubmit = () => {
        if (this.state.item === '') {
            Swal.fire({
                text: 'Select the subscription',
                showCloseButton: true,
                confirmButtonText: "Ok",
                icon: 'warning',
                allowOutsideClick: false
            });
            return;
        }
        if (this.state.startDate === '') {
            Swal.fire({
                text: 'Select the Startdate',
                showCloseButton: true,
                confirmButtonText: "Ok",
                icon: 'warning',
                allowOutsideClick: false
            });
            return;
        }
        if (this.state.endDate === '') {
            Swal.fire({
                text: 'Select the Enddate',
                showCloseButton: true,
                confirmButtonText: "Ok",
                icon: 'warning',
                allowOutsideClick: false
            });
            return;
        }
        if (this.state.desc === '') {
            Swal.fire({
                text: 'Enter the Description',
                showCloseButton: true,
                confirmButtonText: "Ok",
                icon: 'warning',
                allowOutsideClick: false
            });
            return;
        }
        if (this.state.startDate > this.state.endDate) {
            Swal.fire({
                text: 'End date cannot be earlier than start date',
                showCloseButton: true,
                confirmButtonText: "Ok",
                icon: 'warning',
                allowOutsideClick: false
            });
            return;
        }
        let params = {
            sub_reg_id: this.props.regId,
            subscription: this.state.item,
            subscription_desc: this.state.desc,
            datestart: this.state.startDate,
            dateend: this.state.endDate,
            sub_edit_id: this.props.editVal.SUBSC_ID,
            sub_edit_end_date: this.props.editVal.SUBSCRIPTION_DATE_END
        }
        this.setState({ loading: "loading" });
        updateUserSubscriptionHistory(params).then(res => this.handleAddResponse(res));
    }

    // Hanlde edit response
    handleAddResponse = res => {
        if (res.data.code === 200) {
            Swal.fire({
                title: 'Subscription History Updated',
                showCloseButton: true,
                confirmButtonText: "Ok",
                icon: 'success',
                allowOutsideClick: false
            });
            this.props.refreshData();
            this.closeModal();

        } else {
            this.setState({ backendErrors: res.data.message, loading: "" });
        }
    }

    // Close edit modal
    closeModal = () => {
        this.setState({
            backendErrors: null,
            loading: '',
            item: "",
            desc: "",
            startDate: "",
            endDate: ""
        });
        this.props.toggle();
    }

    // Get min date
    getMinDate = date => {
        if (date === "") {
            return moment().format('YYYY-MM-DD');
        } else {
            return moment(date).format('YYYY-MM-DD');
        }
    }

    render() {
        return (
            <div>
                <div className="mb-3">
                    <label>Item</label>
                    <select name="subscription" id="subscription" className="form-control" value={this.state.item}
                        onChange={e => this.setState({
                            item: e.target.value
                        })}>
                        <option value="">Select Plan</option>
                        {
                        this.state.subscriptionDetails.map((listValue, index) => {
                            return (
                                <option value={listValue.Sub_Level}>{listValue.Sub_Name  }   ({listValue.Sub_SKU})</option>
                            );
                        })
                        }
                    </select>
                </div>

                <div className="mb-3">
                    <label>Start Date</label>
                    <input type="date" name="date_start" id="date_start" className="form-control" value={this.state.startDate}
                        onChange={e => this.setState({
                            startDate: e.target.value
                        })} />
                </div>

                <div className="mb-3">
                    <label>End Date</label>
                    <input type="date" name="date_end" id="date_end" className="form-control" min={this.getMinDate(this.state.startDate)} value={this.state.endDate}
                        onChange={e => this.setState({
                            endDate: e.target.value
                        })} />
                </div>

                <div className="mb-3">
                    <label>Description</label>
                    <textarea className="form-control text-area" id="exampleFormControlTextarea1" rows="3" placeholder="Description" value={this.state.desc}
                        onChange={e => this.setState({
                            desc: e.target.value
                        })}></textarea>
                </div>

                {
                    this.state.backendErrors ? (
                        <div
                            className="alert alert-danger"
                            role="alert"
                            dangerouslySetInnerHTML={{
                                __html: this.state.backendErrors
                            }}
                        ></div>
                    ) : null
                }

                <div className="mt-4" style={{ textAlign: "right" }}>
                    <button type="button" className={"btn btn-primary backColor " + this.state.loading} onClick={this.handleSubmit}>Save</button>&nbsp;
                    <button type="button" className="btn btn-secondary" onClick={this.closeModal}>Cancel</button>
                </div>
            </div>
        );
    }
};


// Export
export default EditSubscriptionForm;
