// Vendor Imports
import React from "react";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

// Local Imports
import { getUserBillingHistory } from "../../../../service/admin";
import AddForm from './AddForm';
import EditForm from './EditForm';

// Billing History 
class BillingHistoryComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tableDetails: null,
            addModal: false,
            editModal: false,
            editValues: {}
        };
    }

    componentDidMount() {
        this.getBillings();
    }

    getBillings = () => {
        // Api call to get billing history
        getUserBillingHistory(this.props.userId).then(res => this.handleResponse(res));
    }

    // Handle api response
    handleResponse = res => {
        if (res.data.code === 200) {
            this.setState({
                tableDetails: res.data.data,
            });
        }
    };

    // toggle add modal
    toggle = () => {
        this.setState({ addModal: !this.state.addModal });
    }

    // Open edit modal
    openEditModal = val => {
        this.setState({ editModal: true, editValues: val });
    }

    // Edit modal toggle
    editToggle = () => {
        this.setState({ editModal: false, editValues: {} });
    }

    render() {
        return (
            <section className="admin-table">
                {this.state.tableDetails ? <div>
                    <div className="row">
                        <div className="col-md-12">
                            <button type="button" className="btn add-btn" onClick={this.toggle}>+ Add New</button>
                        </div>
                    </div>

                    <div className="table-responsive">
                        <table className="table table-bordered ">
                            <thead>
                                <tr>
                                    <th scope="col">Item</th>
                                    <th scope="col">Payment Method</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Total</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.tableDetails.length === 0 ?
                                    <tr>
                                        <td colSpan="5" align="center">No Billing History Found</td>
                                    </tr> :
                                    this.state.tableDetails.map((listValue, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{listValue.Item}</td>
                                                <td>{listValue.Payment_Method1}<br /><br /><div
                                                    dangerouslySetInnerHTML={{
                                                        __html: listValue.Payment_Method2
                                                    }}
                                                ></div></td>
                                                <td>{listValue.Date}</td>
                                                <td>${listValue.Total}</td>
                                                {
                                                    listValue.Edit === 1 ?
                                                        <td><a href="#" className="edit-btn" onClick={() => this.openEditModal(listValue)}>Edit</a></td> : <td></td>
                                                }
                                            </tr>
                                        );
                                    })
                                }

                            </tbody>
                        </table>
                    </div>
                </div>
                    : <div className="text-center">
                        <div
                            className="spinner-border text-primary mt-3"
                            style={{ width: "3rem", height: "3rem", marginLeft: "auto" }}
                            role="status"
                        >

                            <span className="sr-only">Loading...</span>
                        </div></div>
                }

                {/* Add Billing History */}
                <Modal isOpen={this.state.addModal} backdrop="static" centered={true} >
                    <ModalHeader toggle={this.toggle} className="backColor">Add Billing History</ModalHeader>
                    <ModalBody>
                        <AddForm toggle={this.toggle} refreshData={this.getBillings} regId={this.props.userId} />
                    </ModalBody>
                </Modal >

                {/* Edit Billing History */}
                <Modal isOpen={this.state.editModal} backdrop="static" centered={true} >
                    <ModalHeader toggle={this.editToggle} className="backColor">Edit Billing History</ModalHeader>
                    <ModalBody>
                        <EditForm toggle={this.editToggle} refreshData={this.getBillings} regId={this.props.userId} editVal={this.state.editValues} />
                    </ModalBody>
                </Modal >
            </section>

        );
    }

}

// Export
export default BillingHistoryComponent;
