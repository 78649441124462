// Vendor Imports
import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { useSelector } from "react-redux";

// Local Imports
import OnlinePayment from '../PaymentMedhod/OnlinePayment'
import OfflinePayment from '../PaymentMedhod/OfflinePayment'
import { renderCount, renderCurrency } from '../../../../utils'

// checkout page for expired download records
function SearchCreditPurchase(props) {
  const [InVoiceData, setInVoiceData] = useState([]);
  const checkout = useSelector((store) => store.client.checkout);
  const searchResult = useSelector((store) => store.client.searchResult);
  useEffect(() => {
    setInVoiceData(checkout.invoice);
  },[checkout]);

  return (
    <>
      <Helmet>
        <title> Checkout - Power Almanac </title>
      </Helmet>
      <div >
        <section className="my-search">
          <div className="container">
            <div classNameCheckoutComponent="row">
              <div className="col">
                <h1 className="display-4 text-uppercase">
                  PAYMENT NEEDED TO COMPLETE DOWNLOAD
              </h1>
              </div>
            </div>
          </div>
        </section>

        <div className="container">
          <div className="row pt-5">
            <div className="col-lg-6">

              <table className="table payoption-table">
                <thead>
                  <tr>
                    <th scope="row" className="border-0">Item Ordered</th>
                    <th scope="row" className="border-0">Credits Included</th>
                    <th scope="row" className="border-bottom border-top-0 text-left" >Unit Price</th>
                    <th scope="row" className="border-bottom border-top-0 text-right" >Total Price</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    InVoiceData.length !== 0 ?

                      InVoiceData.purchases.map(function (item, i) {
                        return (<tr>
                          <td className="border-bottom">
                            {item.name}
                          </td>
                          <td className="border-bottom">
                            {renderCount(item.no_of_records)}
                          </td>
                          <td>{renderCount(item.unit_price)}</td>
                          <td className="text-right">${renderCurrency(item.cost)}</td>
                        </tr>)
                      })
                      :
                      <div className="spinner-grow" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                  }
                  <tr>
                    <td> <strong>Total Purchase </strong></td>
                    <td ><strong>{renderCount(InVoiceData.totalPurchase)} </strong></td>
                    <td> </td>
                    <td className="text-right" >
                      <strong> ${renderCurrency(InVoiceData.totalPrice)}  </strong>
                    </td>
                  </tr>


                </tbody>
              </table>
            </div>


            <div className="col-lg-6 pay-col">
              <OnlinePayment history={props.history} data={InVoiceData} checkoutType="download" />
              <OfflinePayment checkoutType="download" />
            </div>

          </div>
          {searchResult.length !== 0 ?
            <div className="row">
              <div className="col-12  text-center">
                <Link to="/search" className="back_btn">
                  <span>
                    <i
                      className="fa fa-angle-double-left"
                      aria-hidden="true"
                    ></i>
                  </span>
              Back to Search
            </Link>
              </div>
            </div>
            :
            ""
          }
        </div>
      </div>
    </>
  );

}

export default withRouter(SearchCreditPurchase);
