// Vendor Imports
import React from "react";
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

// Local Imports
import { editNewOfficial } from "../../../../service/dms";

// Edit officials modal
class EditModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            backendErrors: null,
            loading: '',
            role: '',
            e1: false,
            e4: false,
            e7: false,
            e10: false,
            success: false
        };
    }

    componentDidMount() {
        let data = this.props.official.etype.split(',');
        this.setState({
            role: this.props.official.role_name,
            e1: data.includes("E1"),
            e4: data.includes("E4"),
            e7: data.includes("E7"),
            e10: data.includes("E10")
        })
    }

    // E+1 Handler
    handleE1value = () => {
        this.setState({ e1: !this.state.e1 })
    }

    // E+4 Handler
    handleE4value = () => {
        this.setState({ e4: !this.state.e4 })
    }

    // E+7 Handler
    handleE7value = () => {
        this.setState({ e7: !this.state.e7 })
    }

    // E+10 Handler
    handleE10value = () => {
        this.setState({ e10: !this.state.e10 })
    }

    // Form Submit
    handleSubmit = () => {
        this.setState({ backendErrors: null })
        var params = {
            id: this.props.official.id,
            etype: []
        }
        if (this.state.e1) {
            params.etype.push("E1")
        }
        if (this.state.e4) {
            params.etype.push("E4")
        }
        if (this.state.e7) {
            params.etype.push("E7")
        }
        if (this.state.e10) {
            params.etype.push("E10")
        }
        this.setState({ loading: "loading" });
        editNewOfficial(params).then(res => this.handleEditResponse(res));
    }

    // Api reponse handler
    handleEditResponse = res => {
        if (res.data.code === 200) {
            this.setState({
                backendErrors: null,
                loading: '',
                success: true
            });
            this.props.toggle();
            this.props.refreshPage();
        } else {
            this.setState({ backendErrors: "Unable to Process", loading: "" });
        }
    }

    // Close Modal Handler 
    closeModal = () => {
        this.setState({
            backendErrors: null,
            loading: '',
            success: false
        });
        this.props.toggle();
    }

    render() {
        return (
            <div>
                {/* Add Modal */}
                < Modal isOpen={this.props.modal} size="lg" backdrop="static" >
                    <ModalHeader toggle={this.closeModal}>Edit Role</ModalHeader>
                    <ModalBody>
                        {this.state.success ? <div className="alert alert-success">
                            Successfully updated!!
                         </div> :
                            <div>
                                <div className="form-group row">
                                    <label htmlFor="inputEmail" className="col-sm-4 col-form-label">Role *</label>
                                    <div className="col-sm-8">
                                        <input type="text" id="role" className="form-control" value={this.state.role} readOnly />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="inputPassword" className="col-sm-4 col-form-label">Operation Model</label>
                                    <div className="col-sm-8">
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" onClick={this.handleE1value} checked={this.state.e1} />
                                            <label className="form-check-label" htmlFor="inlineCheckbox1">E+1</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2" onClick={this.handleE4value} checked={this.state.e4} />
                                            <label className="form-check-label" htmlFor="inlineCheckbox2">E+4</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox" id="inlineCheckbox3" value="option3" onClick={this.handleE7value} checked={this.state.e7} />
                                            <label className="form-check-label" htmlFor="inlineCheckbox3">E+7</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox" id="inlineCheckbox4" value="option3" onClick={this.handleE10value} checked={this.state.e10} />
                                            <label className="form-check-label" htmlFor="inlineCheckbox4">E+10</label>
                                        </div>
                                    </div>
                                </div>
                                {this.state.backendErrors ? (
                                    <div
                                        className="alert alert-danger"
                                        role="alert"
                                        dangerouslySetInnerHTML={{
                                            __html: this.state.backendErrors
                                        }}
                                    ></div>
                                ) : null}
                                <button type="submit" onClick={this.handleSubmit} className={"btn btn-primary " + this.state.loading}>Submit</button>
                            </div>
                        }
                    </ModalBody>
                </Modal >
            </div>
        );
    }
}

export default connect()(EditModal);
