// Vendor Imports
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Swal from 'sweetalert2'
import { Helmet } from 'react-helmet';


// Local Imports
import VoiceAgentEditTable from "./Table";
import AddDisposition from "./AddDisposition";
import AddNotes from "./AddNotes";
import { getVoiceAgentEditScreen, vaCancelGov } from "../../../../service/dms";
import { renderCount } from '../../../../utils'

// Voice agent Edit gov
class VoiceAgentDash extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dmsUser: null,
      tableDetails: null,
      addDisposModal: false,
      addNotesModal: false,
      ePlusStatus: false
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.dmsUser !== null) {
      return {
        dmsUser: props.dmsUser
      };
    }
    return null;
  }

  componentDidMount() {
    this.getVoiceAgentEdit();
  }

  // api call to get voice agent edit gov
  getVoiceAgentEdit = () => {
    this.setState({
      tableDetails: null
    });
    let params = {
      gid: this.props.computedMatch.params.gid,
      jid: this.props.computedMatch.params.jid,
      mode: this.props.computedMatch.params.mode,
      ge_ID: this.props.computedMatch.params.ge_ID
    }
    getVoiceAgentEditScreen(params).then(res => this.handleResponse(res));
  }

  // Response handler
  handleResponse = res => {
    if (res.data.code === 200) {
      this.setState({
        tableDetails: res.data.data,
        ePlusStatus: res.data.data.government_info.verification_type === "E+7"
      });
    }
  };

  // Toggle e plus status handler
  toggleEplusStatus = val => {
    this.setState({ ePlusStatus: val })
  }

  // Disposition toggle handler
  disposToggle = () => {
    this.setState({ addDisposModal: !this.state.addDisposModal })
  }

  // Notes toggle handler
  notesToggle = () => {
    this.setState({ addNotesModal: !this.state.addNotesModal })
  }

  // Cancel gov handler
  cancelGov = () => {
    let params = {
      gid: this.props.computedMatch.params.gid,
      jid: this.props.computedMatch.params.jid,
    }
    Swal.fire({
      title: "Cancel Government",
      text: 'Are you sure you want to Cancel this government?',
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonText: "Proceed",
      cancelButtonText: 'Go Back',
      icon: 'question',
      allowOutsideClick: false
    }).then((result) => {
      if (result.isConfirmed) {
        vaCancelGov(params).then(res => {
          if (res.data.code === 200) {
            this.props.history.push("/dms/voice-agent/dashboard");
          } else {
            Swal.fire({
              text: res.data.message,
              showCloseButton: true,
              confirmButtonText: "Ok",
              icon: 'error',
              allowOutsideClick: false
            });
            return;
          }
        })
      }
    })
  }

  // Get gov detail handler
  getGovDetails = () => {
    return this.state.tableDetails.government_info.Government_Place_Name + " " + this.state.tableDetails.government_info.Government_Type + ", " + this.state.tableDetails.government_info.City + ", " + this.state.tableDetails.government_info.County_Name + ", " + this.state.tableDetails.government_info.state;
  }

  // Get gov location name handler
  getGovNameLoc = () => {
    if (this.state.tableDetails.government_info.Government_Type !== "county") {
      return this.state.tableDetails.government_info.Government_Place_Name + " " + this.state.tableDetails.government_info.edit.Government_Type_Name + ", " + this.state.tableDetails.government_info.state + " ( " + this.state.tableDetails.government_info.County_Name + " County ) ";
    } else {
      return this.state.tableDetails.government_info.Government_Place_Name + " " + this.state.tableDetails.government_info.edit.Government_Type_Name + "," + this.state.tableDetails.government_info.state;
    }
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Voice Agent - Edit Government</title>
        </Helmet>
        <div>

          <section className="titlebar">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h1>Voice Agent Edit Government</h1>
                </div>
              </div>
            </div>
          </section>

          <section>
            <div>
              <div className="row change-row">
                <div className="col-12">
                  <div className="white-bg">
                    {this.state.tableDetails ? <div>
                      <div className="container">
                        <section className="dmstable edit-mode">

                          <div className="table-responsive">
                            <table className="table table-bordered ">
                              <thead>
                                <tr>
                                  <th scope="col" colSpan="2" style={{ textAlign: "center" }}>EDIT MODE - {this.props.computedMatch.params.mode.toUpperCase()} {this.state.tableDetails.government_info.verification_type === "" ? "" : "-"} {this.state.tableDetails.government_info.verification_type}</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td >Government Name / Location</td>
                                  <td className="text-uppercase">{this.getGovNameLoc()} </td>
                                </tr>
                                <tr>
                                  <td>Government Type</td>
                                  <td>
                                    {this.state.tableDetails.government_info.Government_Type.toUpperCase()}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Population</td>
                                  <td>{renderCount(this.state.tableDetails.government_info.Population)}</td>
                                </tr>
                                <tr>
                                  <td>Election Month</td>
                                  <td>{this.state.tableDetails.government_info.Electron_Month.toUpperCase()}</td>
                                </tr>
                                <tr>
                                  <td>Government ID</td>
                                  <td>{this.state.tableDetails.government_info.Government_ID}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </section>
                      </div>
                      <section className="edit-modenav">
                        <div className="container">
                          <div className="d-flex justify-content-center">
                            <ul>
                              <li><a href="#" onClick={this.cancelGov}>Cancel</a></li>
                              <li><a href="#" onClick={this.disposToggle} >Add Disposition </a></li>
                              <li><a href="#" onClick={this.notesToggle}>Add Notes</a></li>
                              <li><a href="#SeeGovernment">See Government History</a></li>
                              <li><a href={"http://www.google.com/search?q=" + this.getGovDetails()} target="_blank"><img src="/images/google.png" alt="google search" width="57" height="20" hspace="5" vspace="0" align="absmiddle" title="google search" /></a></li>
                            </ul>
                          </div>
                        </div>
                      </section>
                      <VoiceAgentEditTable tableDetails={this.state.tableDetails} gid={this.props.computedMatch.params.gid}
                        jid={this.props.computedMatch.params.jid} voiceAgentEdit={this.getVoiceAgentEdit}
                        ePlusStatus={this.state.ePlusStatus} toggleEplusStatus={this.toggleEplusStatus} />
                    </div>
                      : <div className="text-center">
                        <div
                          className="spinner-border text-primary mt-3"
                          style={{ width: "3rem", height: "3rem", marginLeft: "auto" }}
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div></div>}
                  </div>
                </div>
              </div>
            </div>
          </section>

          <AddDisposition modal={this.state.addDisposModal} toggle={this.disposToggle} refreshPage={this.getVoiceAgentEdit} gid={this.props.computedMatch.params.gid}
            jid={this.props.computedMatch.params.jid} tableDetails={this.state.tableDetails} ePlusStatus={this.state.ePlusStatus} />
          <AddNotes modal={this.state.addNotesModal} toggle={this.notesToggle} refreshPage={this.getVoiceAgentEdit} gid={this.props.computedMatch.params.gid}
            jid={this.props.computedMatch.params.jid} />

        </div>
      </>
    );
  }
}

const mapStateToProps = (store, props) => {
  return {
    dmsUser: store.dms.dmsUser
  };
};

// Export
export default withRouter(connect(mapStateToProps)(VoiceAgentDash));
