// Vendor Imports
import React from "react";

// Local Imports
import Header from "./Header";
import Footer from "./Footer";

// Maintenance Layout
const MaintenanceLayout = props => {
    return (
        <div>
            <Header></Header>
            <main>
                <div className="wrapper">
                    {props.children}
                </div>
            </main>
            <Footer></Footer>
        </div>
    );
};

// Export
export default MaintenanceLayout;
