// Vendor Imports
import React from "react";
import { connect } from "react-redux";
import moment from 'moment';

// Local Imports
import { getProductivity } from "../../../../service/dms";

// View Productivity Table
class ViewProductivityTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tableDetails: null,
            fromDate: '',
            toDate: '',
            fromError: false,
            toError: false,
            greaterError: false,
            loading: ''
        };
    }

    componentDidMount() {
        // Api call to get productivity data
        getProductivity({
            "show": "2",
            "datestart": "",
            "dateend": ""
        }).then(res => this.handleResponse(res));
    }

    // Response Handler
    handleResponse = res => {
        if (res.data.code === 200) {
            this.setState({
                tableDetails: res.data.data.viewProductivity,
                loading: ''
            });
        } else {
            this.setState({ loading: '' });
        }
    };

    // Get date handler
    getDate() {
        let date = new Date();
        return moment(date).format('YYYY-MM-DD');
    }

    // Submit Handler
    submit = () => {
        if (this.state.fromDate === '') {
            this.setState({ fromError: true })
            return;
        }
        this.setState({ fromError: false })
        if (this.state.toDate === '') {
            this.setState({ toError: true })
            return;
        }
        this.setState({ toError: false })
        let start = new Date(this.state.fromDate);
        let end = new Date(this.state.toDate);
        if (start > end) {
            this.setState({ greaterError: true })
            return;
        }
        this.setState({ greaterError: false })
        let params = {
            show: "2",
            datestart: this.state.fromDate,
            dateend: this.state.toDate
        }
        this.setState({ loading: 'loading' });
        getProductivity(params).then(res => this.handleResponse(res));
    }

    // From Date change handler
    fromDateChange = event => {
        this.setState({ fromDate: event.target.value, fromError: false })
    }

    // To Date change Handler
    toDateChange = event => {
        this.setState({ toDate: event.target.value, toError: false })
    }

    // Get total count handler
    totalCount = () => {
        const data = this.state.tableDetails.map(val => val['#GovtsConfirmed'])
        const reducer = (accumulator, currentValue) => accumulator + currentValue;
        return data.reduce(reducer)
    }


    render() {
        return (
            <div>
                <section className="daterange mt-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-3">
                                <h1><u>Today</u> ({this.getDate()})</h1>
                            </div>
                            <div className="col-3">
                                <input className="form-control rounded" type="date" id="datepicker" onChange={this.fromDateChange} />
                                {this.state.fromError ? <span className="text-danger">Please Select</span> : <span></span>}
                            </div>
                            <div className="col-3">
                                <input className="form-control rounded" type="date" id="datepicker" onChange={this.toDateChange} />
                                {this.state.toError ? <span className="text-danger">Please Select</span> : <span></span>}
                                {this.state.greaterError ? <span className="text-danger">End date cannot be earlier than start date</span> : <span></span>}
                            </div>
                            <div className="col-3">
                                <button type="button" className={"btn blue-btn " + this.state.loading} onClick={this.submit}>Refresh Date Range</button>
                            </div>
                        </div>
                    </div>
                </section >
                {this.state.tableDetails ? <div>
                    <section className="dmstable mt-3">
                        <div className="row voicetitle">
                            <div className="col-md-12"><h2>Productivity</h2></div>

                        </div>
                        <div className="table-responsive">
                            <table className="table table-bordered ">
                                <thead>
                                    <tr>
                                        <th scope="col">Employee ID</th>
                                        <th scope="col">First Name</th>
                                        <th scope="col">Last Name</th>
                                        <th scope="col">User ID</th>
                                        <th scope="col">#Govts Confirmed</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.tableDetails.map((listValue, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{listValue.emp_id}</td>
                                                <td>{listValue.first_name}</td>
                                                <td>{listValue.last_name}</td>
                                                <td>{listValue.user_id}</td>
                                                <td>{listValue['#GovtsConfirmed']}</td>
                                            </tr>
                                        );
                                    })}
                                    <tr>
                                        <td colSpan="3"></td>
                                        <td><strong>Total</strong></td>
                                        <td><strong>{this.totalCount()}</strong></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </section>
                </div> : <div className="text-center">
                        <div
                            className="spinner-border text-primary mt-3"
                            style={{ width: "3rem", height: "3rem", marginLeft: "auto" }}
                            role="status"
                        >

                            <span className="sr-only">Loading...</span>
                        </div></div>}
            </div>
        );
    }
}

// Export
export default connect()(ViewProductivityTable);
