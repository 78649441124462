// Vendor Imports
import React from "react";
import { connect } from "react-redux";
import { Helmet } from 'react-helmet';

// Local Imports
import ViewProductivityTable from "./Table";

// View Productivity 
class ViewProductivity extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dmsUser: null
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.dmsUser !== null) {
            return {
                dmsUser: props.dmsUser
            };
        }
        return null;
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>Supervisor Access - Productivity</title>
                </Helmet>
                <section className="titlebar">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h1>Supervisor Dashboard</h1>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="white-bg">
                                    <section className="dmstable">

                                        <div className="table-responsive">
                                            <table className="table table-bordered ">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Logged In As</th>
                                                        <th scope="col">Access Level</th>
                                                        <th scope="col">Primary Role</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.dmsUser ?
                                                        <tr>
                                                            <td>{this.state.dmsUser.first_name} {this.state.dmsUser.last_name}</td>
                                                            <td>{this.state.dmsUser.access_level}</td>
                                                            <td>{this.state.dmsUser.primary_role}</td>
                                                        </tr>
                                                        : <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>}
                                                </tbody>
                                            </table>
                                        </div>
                                    </section>
                                    <ViewProductivityTable></ViewProductivityTable>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

const mapStateToProps = (store, props) => {
    return {
        dmsUser: store.dms.dmsUser
    };
};

// Export
export default connect(mapStateToProps)(ViewProductivity);
