import React from 'react';
import { connect, useSelector } from 'react-redux';
import { renderCount, renderEmailPercent } from "../../../../utils";
// government type search stats
function ByGovType(props) {
    let tamMode = useSelector(store => store.client.tamMode);
    let Summary = 'JSON_SUMMARY' in props.searchResult ? JSON.parse(props.searchResult.JSON_SUMMARY) : {};
    let Governments = 'JSON_GOVERNMENTS' in props.searchResult ? JSON.parse(props.searchResult.JSON_GOVERNMENTS) : {};
    let Officials = 'JSON_OFFICIALS' in props.searchResult ? JSON.parse(props.searchResult.JSON_OFFICIALS) : {};
    let govTypes = [
        { label: "City / Municipality", name: "municipality", govCount: "municipalityGov", emailCount: "municipalityEmail" },
        { label: "Township", name: "township", govCount: "townshipGov", emailCount: "townshipEmail" },
        { label: "County", name: "county", govCount: "countyGov", emailCount: "countyEmail" },
    ];

    return (
        <>
            <tbody id="searchResultsGovType">
                {
                    govTypes.map((month, index) => {
                        return (
                            <tr key={index}>
                                <td> <span dangerouslySetInnerHTML={{ __html: month.label }} /> </td>
                                <td>
                                    <span className="setToZero">{renderCount(Officials[month.name])} </span>
                                </td>
                                {
                                    tamMode ?
                                        <>
                                            <td>
                                                <span className="setToZero">{renderCount(Governments[month.govCount])}</span>
                                            </td>
                                            <td> {renderEmailPercent(Summary.TamGovs, Governments[month.govCount])}%</td>
                                        </> :
                                        <>
                                            <td>
                                                <span className="setToZero">{renderCount(Officials[month.emailCount])}</span>
                                            </td>
                                            <td> {renderEmailPercent(Officials[month.name], Officials[month.emailCount])}%</td>
                                        </>
                                }
                            </tr>
                        );
                    })
                }
            </tbody>

        </>

    )
}


function mapStateToProps(store, props) {
    return {
        searchResult: store.client.searchResult ? store.client.searchResult : []
    }
}

export default connect(mapStateToProps, {})(ByGovType);
