// Vendor Imports
import React from "react";
import { useSelector } from 'react-redux';

// Local Imports
import Footer from "../GuestLayout/Footer";
import Header from "./Header";

// Client Layout
function ClientLayout(props) {
  let tamMode = useSelector(store => store.client.tamMode);
  let TAMTheme = "wrapper ";
  if (tamMode && window.location.pathname === '/search') {
    TAMTheme = "wrapper TAMTheme";
  }
  return (

    <div>

      <Header />
      <main className={TAMTheme} >{props.children}</main>

      <Footer />
    </div>
  );
};

// Export
export default ClientLayout;
