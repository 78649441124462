// Vendor Imports
import React from "react";

// Local Imports
import Header from "./Header";

// Dms Layout
function DmsLayout(props) {
    return (
        <div>
            <Header />
            <main>{props.children}</main>
        </div>
    );
};

// Export
export default DmsLayout;
