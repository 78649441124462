import React from 'react';
import { connect,useSelector } from 'react-redux';
import Nouislider from 'nouislider-react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { getAppliedPopulation } from '../../../../utils';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
let processing = false;
function PopulationToolTip(){
  const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
      color: theme.palette.common.black,
    },
    tooltip: {
      backgroundColor: theme.palette.common.black,
      fontSize: "1rem"
    },
  }));
  const classes = useStylesBootstrap();
  return (
    <Tooltip  classes={classes} placement="top" arrow title="Based on Census Bureau 2021 population data.">
      <sup><i className=' fa fa-info-circle'> </i>    </sup>
    </Tooltip>
  )
}
// population filter component
class Population extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      PreviousParams: '',
      expanded: '',
      textValue: null,
      percent: null,
      value: 0,
      disabled: false,
      start: [0, 20000000],
      renderVal: [0, 20000000],
      range: {
        min: 0,
        max: 100,
      },
      ref: null,
      validate: "",
    };
    this.PopulationChange = this.PopulationChange.bind(this);

  }
  // set population filter lable to pipes
  onUpdate = index => (render, handle, value, un, percent) => {

    this.updateSliderStepsText();
    return true;
  };
  // set inital or loaded saved search filter parameter
  componentDidMount() {
    this.updateSliderStepsText();
    let appliedVal = [];
    if (Object.keys(this.props.currentSearch).length !== 0 && this.state.PreviousParams !== this.props.currentSearch.search_params) {
      this.setState({ PreviousParams: this.props.currentSearch.search_params });
      appliedVal = getAppliedPopulation(this.props.currentSearch.search_params);
      this.setState({ start: getAppliedPopulation(this.props.currentSearch.search_params) });
      this.setState({ renderVal: getAppliedPopulation(this.props.currentSearch.search_params) });
      if (appliedVal.length !== 0 && (appliedVal[0] !== 0 || appliedVal[1] !== 20000000) && this.state.expanded === "") {
        this.setState({ expanded: "populationPanel" });
      }

      if (this.props.tamMode === true && appliedVal[0] === 0) {
        this.setState({ start: [1000, appliedVal[1]], renderVal: [1000, appliedVal[1]] });
        //this.props.triggerSearch('', false);
      }
    }

  }
  // update population value 
  componentDidUpdate() {
    //console.log("65", this.state)
    let appliedVal = getAppliedPopulation(this.state.PreviousParams);
    //console.log("appliedVal 68", appliedVal)
    if (Object.keys(this.props.currentSearch).length !== 0 && this.state.PreviousParams !== this.props.currentSearch.search_params) {

      this.setState({ PreviousParams: this.props.currentSearch.search_params });
      appliedVal = getAppliedPopulation(this.props.currentSearch.search_params);
      // console.log("72", this.props.currentSearch)
      // console.log("appliedVal 73", appliedVal)
      this.setState({ start: getAppliedPopulation(this.props.currentSearch.search_params) });
      this.setState({ renderVal: getAppliedPopulation(this.props.currentSearch.search_params) });
      if (appliedVal.length !== 0 && (appliedVal[0] !== 0 || appliedVal[1] !== 20000000) && this.state.expanded === "") {
        this.setState({ expanded: "populationPanel" });
      }
    }
    if (this.props.tamMode && this.state.start[0] === 0 && this.state.renderVal[0] === 0 && appliedVal[1] !== 1000) {
      //console.log("78");
      this.setState({
        renderVal: [1000, this.state.start[1]]
      });
      this.props.triggerSearch('', false,this.props.filterTrigger);
      //this.setState({ validate: "Population range should be greaterthan 0 in TAM Mode" });
    } //else if (this.props.tamMode === false && this.state.renderVal[0] !== appliedVal[0] && this.state.renderVal[1] !== appliedVal[1]) {
    //   console.log("aaaaaaaaa", this.state.renderVal);
    //   console.log("tesssss", appliedVal);
    //   this.setState({
    //     renderVal: appliedVal
    //   });
    //   this.props.triggerSearch('', false);
    //this.setState({ validate: "Population range should be greaterthan 0 in TAM Mode" });
    //}

    if (this.state.renderVal.length !== 0 && this.state.renderVal[0] === this.state.renderVal[1]) {
      this.setState({
        renderVal: this.state.start
      });
    }

    this.updateSliderStepsText();
  }
  // update population slide lable text 
  updateSliderStepsText() {

    var steps = document.getElementsByClassName("noUi-value");
    for (let index = 0; index < steps.length; index++) {
      const element = steps[index];
      let value = parseInt(element.innerText);
      if (value >= 1000000) {
        value /= 1000000;
        element.innerText = value + 'mil';
      }
      if (value >= 1000) {
        value /= 1000;
        element.innerText = value + 'k';
      }
      if (value < 10000 && value > 999) {
        element.innerText = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    }

  }
  // function to capture population value and trigger search api
  PopulationChange() {
    let currentValue = this.state.renderVal;
    let startValue = this.state.start;

    if (currentValue[0] === currentValue[1]) {
      this.setState({
        start: [startValue[0], startValue[1]]
      });
      this.setState({ validate: "Population range can not be same" });
    }
    else if (this.props.tamMode && currentValue[0] === 0) {
      this.setState({
        renderVal: [startValue[0], currentValue[1]]
      });
      //this.setState({ validate: "Population range should be greaterthan 0 in TAM Mode" });
    }
    else {
      this.setState({
        start: [startValue[0], startValue[1]]
      });
      this.props.triggerSearch('', false,this.props.filterTrigger);
    }
  }
  // function to capture changes on slider and validation
  onSlide = (value) => {

    this.setState({ validate: "" });
    this.setState({
      renderVal: [parseInt(value[0]), parseInt(value[1])]
    });
    if (processing) {
      clearTimeout(processing);
    }
    processing = setTimeout(
      () => this.PopulationChange(),
      2000
    );
  };
  // set population filter to be closed  / opened
  handleChangeAccordian = (panel) => (event, newExpanded) => {
    if (newExpanded === false) {
      this.setState({ expanded: '' });
    } else {
      this.setState({ expanded: "populationPanel" });
    }
  };


  render() {
    return (
      <div className="row">
        <div className="col ">
          <Accordion className="accordianLine" expanded={this.state.expanded === 'populationPanel'} onChange={this.handleChangeAccordian('populationPanel')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="populationPanela-content"
              id="populationPanela-header"
            >
              <Typography className=""> Population <PopulationToolTip /></Typography>
              <input type="hidden" className="sliderTrigger" name="populationMoreThan" value={this.state.renderVal[0]} id="populationMoreThan" />
              <input type="hidden" className="sliderTrigger" name="populationThan" value={this.state.renderVal[1]} id="populationLessThan" />
            </AccordionSummary>
            <AccordionDetails>


              <section onLoad={this.updateSliderStepsText()} className="PopulationSlider">

                <div className="noUiSlider" >

                  <p className="text-small text-center text-danger mb-2 w-100">{this.state.validate} </p>
                  <Nouislider
                    connect={true}
                    snap
                    start={this.state.renderVal}
                    behaviour="tap"
                    pips={{
                      mode: 'steps', density: (9), values: 9, stepped: true
                    }}
                    range={{
                      min: 0,
                      "9%": [1000, 2500],
                      "18%": [2500, 5000],
                      "27%": [5000, 10000],
                      "36%": [10000, 25000],
                      "45%": [25000, 50000],
                      "54%": [50000, 100000],
                      "63%": [100000,250000],
                      "72%": [250000, 500000],
                      "81%": [500000, 1000000],
                      "90%": [1000000, 20000000],
                      max: 20000000
                    }}
                    onSlide={this.onSlide}
                    onUpdate={this.onUpdate()}
                  />

                </div>
              </section>
            </AccordionDetails>
          </Accordion>

        </div>
      </div >
    );
  }
}
function mapStateToProps(store, props) {
  return {
    currentSearch: store.client.currentSearch ? store.client.currentSearch : [],
    searchLoading: store.client.searchLoading,
    tamMode: store.client.tamMode
  }
}

export default connect(mapStateToProps, {})(Population);

