// Vendor Imports
import React from "react";
import Swal from 'sweetalert2';
import moment from 'moment';
import { CFormCheck } from '@coreui/bootstrap-react'
import { updateAPIUserList } from "../../../service/admin";

class EditAPIUserFormComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            backendErrors: null,
            loading: '',
            username: this.props.editVal.username,
            email: this.props.editVal.email,
            start_date: this.props.editVal.start_date,
            end_date: this.props.editVal.end_date,
            password: this.props.editVal.password,
            roleData:this.props.editVal.roleData,
        };
    }

    handleChange = (event) =>{
        const isChecked = event.target.checked;
        if(isChecked)
        {
            this.setState({roleData: [...this.state.roleData,event.target.value] });
        } else {
            const index = this.state.roleData.indexOf(event.target.value);
            this.state.roleData.splice(index,1);
            this.setState({roleData: this.state.roleData });
        }
    }
    

    componentDidMount() {
        this.setState({
            username: this.props.editVal.username,
            email: this.props.editVal.email,
            start_date: this.props.editVal.start_date,
            end_date: this.props.editVal.end_date,
            user_unique_key: this.props.editVal.user_unique_key

        })
    }


    handleSubmit = () => {
        let pass=this.state.password;
        if (this.state.username === "") {
            Swal.fire({
                text: 'Enter the User Name',
                showCloseButton: true,
                confirmButtonText: "Ok",
                icon: 'warning',
                allowOutsideClick: false
            });
            return;
        }
        if (this.state.start_date === "") {
            Swal.fire({
                text: 'Please Select the Start Date',
                showCloseButton: true,
                confirmButtonText: "Ok",
                icon: 'warning',
                allowOutsideClick: false
            });
            return;
        }

        if (this.state.end_date === "") {
            Swal.fire({
                text: 'Please Select the End Date',
                showCloseButton: true,
                confirmButtonText: "Ok",
                icon: 'warning',
                allowOutsideClick: false
            });
            return;
        }

        /*if (pass !== "" && pass.length<6) {
            Swal.fire({
                text: 'Password should be atleast 6 characters',
                showCloseButton: true,
                confirmButtonText: "Ok",
                icon: 'warning',
                allowOutsideClick: false
            });
            return;
        }*/

        if (this.state.roleData.length===0) {
            Swal.fire({
                text: 'Plase select the role',
                showCloseButton: true,
                confirmButtonText: "Ok",
                icon: 'warning',
                allowOutsideClick: false
            });
            return;
        }
        


        let params = {
            id: this.props.editVal.id,
            username: this.state.username,
            password: this.state.password,
            start_date: this.state.start_date,
            end_date: this.state.end_date,
            role: this.state.roleData,
        }
        
        this.setState({ loading: "loading" });
        updateAPIUserList(params).then(res => this.handleUpdateResponse(res));
    }

    // Hanlde edit response
    handleUpdateResponse = res => {
        if (res.data.code === 200) {
            Swal.fire({
                title: 'API User Updated',
                showCloseButton: true,
                confirmButtonText: "Ok",
                icon: 'success',
                allowOutsideClick: false
            });
            this.props.refreshData();
            this.closeModal();

        } else {
            this.setState({ backendErrors: res.data.message, loading: "" });
            Swal.fire({
                title: res.data.message,
                showCloseButton: true,
                confirmButtonText: "Ok",
                icon: 'warning',
                allowOutsideClick: false
            });
            return;
        }
    }

    // Close edit modal
    closeModal = () => {
        this.setState({
            backendErrors: null,
            loading: '',
            sub_name: "",
            sub_reserves: "",
            sub_fees: "",
            sub_cost_per_dl: ""
        });
        this.props.toggle();
    }

    render() {
        
        let readOnly = true;
        return (
            <div>
                <div className="mb-3">
                    <label>User Name</label>
                    <input type="text" name="username" id="username" className="form-control" defaultValue={this.state.username}
                        onChange={e => this.setState({
                            username: e.target.value
                        })} />
                </div>

                <div className="mb-3">
                    <label>Email</label>
                    <input readOnly={readOnly} type="text" name="email" id="email" className="form-control" defaultValue={this.state.email}
                        onChange={e => this.setState({
                            email: e.target.value
                        })} />
                </div>

                <div className="mb-3">
                    <label>Password</label>
                    <input type="password" name="password" className="form-control" onChange={e => this.setState({password: e.target.value})} />
                </div>

                <div className="mb-3">
                    <label>Start Date</label>
                    <input type="datetime-local" name="start_date" id="start_date" className="form-control" defaultValue={this.state.start_date} onChange={e => this.setState({start_date: e.target.value})}/>
                </div>

                <div className="mb-3">
                    <label>End Date</label>
                    <input type="datetime-local" name="end_date" id="end_date" className="form-control" defaultValue={this.state.end_date} onChange={e => this.setState({end_date: e.target.value})}/>
                </div>

                
                <div className="mb-6">
                    <label>Role</label>
                    <CFormCheck id="flexCheckDefault" label="Head of Corrections" value="HeadofCorrections" onChange={this.handleChange} defaultChecked={this.state.roleData.includes('HeadofCorrections') ? true : false}/>
                    <CFormCheck id="" label="Head of Geographic Information System" value="HeadofGeographicInformationSystem" onChange={this.handleChange} defaultChecked={this.state.roleData.includes('HeadofGeographicInformationSystem') ? true : false}/>
                    <CFormCheck id="" label="Head of Health Services" value="HeadofHealthServices" onChange={this.handleChange} defaultChecked={this.state.roleData.includes('HeadofHealthServices') ? true : false}/>
                    <CFormCheck id="" label="Head of Facilities Management" value="HeadofFacilitiesManagement" onChange={this.handleChange} defaultChecked={this.state.roleData.includes('HeadofFacilitiesManagement') ? true : false}/>
                    <CFormCheck id="" label="Head of Economic Development" value="EconomicDevelopment" onChange={this.handleChange} defaultChecked={this.state.roleData.includes('EconomicDevelopment') ? true : false}/>
                    <CFormCheck id="" label="Head of Planning/Zoning" value="Zoning" onChange={this.handleChange} defaultChecked={this.state.roleData.includes('Zoning') ? true : false}/>
                    <CFormCheck id="" label="Head of Parks and Recreation" value="ParksandRecreation" onChange={this.handleChange} defaultChecked={this.state.roleData.includes('ParksandRecreation') ? true : false}/>
                    <CFormCheck id="" label="Head of Water Supply" value="HeadofWaterSupply" onChange={this.handleChange} defaultChecked={this.state.roleData.includes('HeadofWaterSupply') ? true : false}/>
                    <CFormCheck id="" label="Head of IT" value="HeadofIT" onChange={this.handleChange} defaultChecked={this.state.roleData.includes('HeadofIT') ? true : false}/>
                    <CFormCheck id="" label="Head of Finance" value="HeadofFinance/Budgeting" onChange={this.handleChange} defaultChecked={this.state.roleData.includes('HeadofFinance/Budgeting') ? true : false}/>
                    <CFormCheck id="" label="Head of Purchasing" value="HeadofPurchasing/Procurement" onChange={this.handleChange} defaultChecked={this.state.roleData.includes('HeadofPurchasing/Procurement') ? true : false}/>
                    <CFormCheck id="" label="Head Clerk" value="Clerk" onChange={this.handleChange} defaultChecked={this.state.roleData.includes('Clerk') ? true : false}/>
                    <CFormCheck id="" label="Head of Public Works" value="HeadofPublicWorks" onChange={this.handleChange} defaultChecked={this.state.roleData.includes('HeadofPublicWorks') ? true : false}/>
                    <CFormCheck id="" label="Head of Law Enforcement" value="HeadofLawEnforcement" onChange={this.handleChange} defaultChecked={this.state.roleData.includes('HeadofLawEnforcement') ? true : false}/>
                    <CFormCheck id="" label="Head of Fire Protection Services" value="HeadofFireProtectionServices" onChange={this.handleChange} defaultChecked={this.state.roleData.includes('HeadofFireProtectionServices') ? true : false}/>
                    <CFormCheck id="" label="Head of Buildings" value="HeadBuildingOfficial" onChange={this.handleChange} defaultChecked={this.state.roleData.includes('HeadBuildingOfficial') ? true : false}/>
                    <CFormCheck id="" label="Head of Communications" value="HeadofCommunications" onChange={this.handleChange} defaultChecked={this.state.roleData.includes('HeadofCommunications') ? true : false}/>
                    <CFormCheck id="" label="Head of HR" value="HeadofHR" onChange={this.handleChange} defaultChecked={this.state.roleData.includes('HeadofHR') ? true : false}/>
                    <CFormCheck id="" label="Top Elected Official" value="TopElectedOfficial" onChange={this.handleChange} defaultChecked={this.state.roleData.includes('TopElectedOfficial') ? true : false}/>
                    <CFormCheck id="" label="Governing Board Members" value="GoveningBoardMember" onChange={this.handleChange} defaultChecked={this.state.roleData.includes('GoveningBoardMember') ? true : false}/>
                    <CFormCheck id="" label="Top Appointed Executive" value="TopAppointedOfficial" onChange={this.handleChange} defaultChecked={this.state.roleData.includes('TopAppointedOfficial') ? true : false}/>
                    <CFormCheck id="" label="Deputy Top Appointed Executive" value="DeputyTopAppointedExecutive" onChange={this.handleChange} defaultChecked={this.state.roleData.includes('DeputyTopAppointedExecutive') ? true : false}/>
                </div>
                

                <div className="mb-3">
                    <input type="button" className="btn btn-primary" onClick={this.handleSubmit} value="Update"
                    />&nbsp;
                    <button type="button" className="btn btn-secondary" onClick={this.closeModal}>Cancel</button>
                </div>

            </div>
        );
    }
};

// Export
export default EditAPIUserFormComponent;