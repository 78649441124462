import getInstance from "../api";

// DMS
export const adminLogin = async data => {
    const api = await getInstance(true);
    return api.post("/admin/adminlogin", data);
};

export const getRegisteredAccounts = async data => {
    const api = await getInstance(false, "Admin");
    return api.post("/admin/getregisteredaccount", data);
};

export const getPaypalTransactions = async () => {
    const api = await getInstance(false, "Admin");
    return api.post("/admin/getpaypaltransaction", {});
};

export const getBillingHistory = async () => {
    const api = await getInstance(false, "Admin");
    return api.get("/admin/getbillinghistory");
};

export const getPaypalDetails = async id => {
    const api = await getInstance(false, "Admin");
    return api.get(`/admin/getpaypaldetails/${id}`);
};

export const getAllUsers = async () => {
    const api = await getInstance(false, "Admin");
    return api.get("/admin/getallusers");
};

export const addBillingTrans = async data => {
    const api = await getInstance(false, "Admin");
    return api.post("/admin/addbillinghistory", data);
};

export const getActiveAccStats = async () => {
    const api = await getInstance(false, "Admin");
    return api.get("/admin/getactiveaccountstats");
};

export const getUserDetails = async id => {
    const api = await getInstance(false, "Admin");
    return api.get(`/admin/getuserdetails/${id}`);
};

export const getEmailList = async () => {
    const api = await getInstance(false, "Admin");
    return api.get('/admin/getalluseremail');
};

export const getUserBillingHistory = async id => {
    const api = await getInstance(false, "Admin");
    return api.get(`/admin/getuserbillinghistory/${id}`);
};

export const getUserSubscriptionHistory = async id => {
    const api = await getInstance(false, "Admin");
    return api.get(`/admin/getusersubscription/${id}`);
};

export const getUserCreditHistory = async id => {
    const api = await getInstance(false, "Admin");
    return api.get(`/admin/getusercredithistory/${id}`);
};

export const getUserTransInformation = async id => {
    const api = await getInstance(false, "Admin");
    return api.get(`/admin/gettransactioninformation/${id}`);
};

export const addUserBillingHistory = async data => {
    const api = await getInstance(false, "Admin");
    return api.post("/admin/adduserbillinghistory", data);
};

export const updateUserBillingHistory = async data => {
    const api = await getInstance(false, "Admin");
    return api.post("/admin/adduserbillinghistory", data);
};

export const addUserSubscriptionHistory = async data => {
    const api = await getInstance(false, "Admin");
    return api.post("/admin/addusersubscription", data);
};

export const updateUserSubscriptionHistory = async data => {
    const api = await getInstance(false, "Admin");
    return api.post("/admin/updateusersubscription", data);
};

export const addUserCreditHistory = async data => {
    const api = await getInstance(false, "Admin");
    return api.post("/admin/addusercredithistory", data);
};

export const updateUserCreditHistory = async data => {
    const api = await getInstance(false, "Admin");
    return api.post("/admin/addusercredithistory", data);
};

export const updateRegisteredAccount = async data => {
    const api = await getInstance(false, "Admin");
    return api.post("/admin/updateregisteredaccount", data);
};

export const getDashboardDetails = async () => {
    const api = await getInstance(false, "Admin");
    return api.get("/admin/dashboard");
};

export const getOverallActiveStats = async () => {
    const api = await getInstance(false, "Admin");
    return api.get("/admin/getoverallactivestats");
};

export const getOverAllDownloadFiles = async () => {
    const api = await getInstance(false, "Admin");
    return api.get("/admin/getoveralldownloadfiles");
};

export const getOverAllDownloadRecords = async () => {
    const api = await getInstance(false, "Admin");
    return api.get("/admin/getoveralldownloadrecords");
};

export const getOverAllActiveSubusers = async () => {
    const api = await getInstance(false, "Admin");
    return api.get("/admin/getoverallactivesubusers");
};

export const getOverAllOtherCounts = async () => {
    const api = await getInstance(false, "Admin");
    return api.get("/admin/getoverallothercounts");
};

export const resendActivationEmail = async data => {
    const api = await getInstance(false, "Admin");
    return api.post("/resendactivation", data);
};

export const sendResetPassword = async data => {
    const api = await getInstance(false, "Admin");
    return api.post("/admin/sendresetpassword", data);
};

export const loginAsUser = async data => {
    const api = await getInstance(false, "Admin");
    return api.post("/adminlogin", data);
};

export const getSubscriptionList = async data => {
    const api = await getInstance(false, "Admin");
    return api.get("/admin/getsubscriptionlist", data);
};

export const updateUserEndSubscription = async data => {
    const api = await getInstance(false, "Admin");
    return api.post("/admin/updateendsubscriptionlist", data);
};

export const updateSubscription = async data => {
    const api = await getInstance(false, "Admin");
    return api.post("/admin/updatesubscriptionlist", data);
};

export const logoutAdmin = async () => {
    const api = await getInstance(false, "Admin");
    return api.post("/admin/logout", {});
};

export const getMonthlyReport = async id => {
    const api = await getInstance(false, "Admin");
    return api.get(`/admin/mis_report`);
};

export const completeActivation = async id => {
    const api = await getInstance(false, "Admin");
    return api.post(`/admin/completeactivation/${id}`);
};

export const getAPIUserList = async id => {
    const api = await getInstance(false, "Admin");
    return api.post(`/admin/api_user_lists`);
};

export const updateAPIUserList = async data => {
    const api = await getInstance(false, "Admin");
    return api.post("/admin/update_api_user", data);
};

export const addAPIUserList = async data => {
    const api = await getInstance(false, "Admin");
    return api.post("/admin/add_api_user", data);
};
